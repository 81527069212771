import React from 'react';

import { Typography } from '@mui/material';
import EnhancedTable from '../../../../../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../../../../../lib/designSystem/Button';

const MissingApprovalContainer = (props: any) => {
  const { missingApprovals, comitee, table, approvals, setTable } = props;

  if (!comitee)
    return (
      <div style={{ padding: '10px 0px' }}>
        <Typography variant="body1">
          <b>(Comité pendiente)</b>
        </Typography>
        <Typography variant="body1">
          Debes esperar a que se le asigne un comité para poder revisar esta
          solicitud.
        </Typography>
      </div>
    );

  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 0px',
          }}
        >
          <div>
            <Typography variant="body1" fontWeight={600}>
              Aprobaciones necesarias por {comitee.name}
            </Typography>
            <Typography variant="body1">
              Estas son las aprobaciones que faltan para terminar la revisión de
              la solicitud.
            </Typography>
          </div>
          <div>
            {table === 'comitee' ? (
              <CustomButton onClick={() => setTable('approvals')}>
                Ver aprobaciones
              </CustomButton>
            ) : (
              <CustomButton onClick={() => setTable('comitee')}>
                Ver comité
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      {table === 'approvals' ? (
        <EnhancedTable
          data={approvals}
          dataHeaders={[
            { key: 'user', label: 'Usuario' },
            { key: 'userEmail', label: 'Email' },
            { key: 'role', label: 'Rol' },
            { key: 'status', label: 'Estado', type: 'status' },
            { key: 'reason', label: 'Observación' },
          ]}
          rowsPerPageDefault={5}
        />
      ) : (
        <EnhancedTable
          data={missingApprovals}
          dataHeaders={[
            { key: 'name', label: 'Nombre' },
            { key: 'approvedCountRequired', label: 'Requeridas' },
            {
              key: 'approved',
              label: 'Revisado',
              type: 'vission',
            },
            {
              key: 'namesThatApprovedThisRole',
              label: 'Aprobado por',
            },
          ]}
          rowsPerPageDefault={5}
        />
      )}
    </div>
  );
};

export default MissingApprovalContainer;
