import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBusinessContact,
  deleteBusinessContact,
  getBusinessContacts,
  selectContact,
  selectContactInvoicing,
  unSelectContactInvoicing,
  updateBusinessContact,
} from '../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../lib/functions/utils';
import { AuthState } from '../../../../../services/redux/authSlice';

export const ContactForm = (props: any) => {
  const companyRoles = [
    { id: 'Gerente de finanzas', name: 'Gerente de finanzas' },
    { id: 'Gerente general', name: 'Gerente general' },
    { id: 'Gerente de operaciones', name: 'Gerente de operaciones' },
    { id: 'Gerente de ventas', name: 'Gerente de ventas' },
    { id: 'Jefe de finanzas', name: 'Jefe de finanzas' },
    { id: 'Jefe de operaciones', name: 'Jefe de operaciones' },
    { id: 'Ejecutivo', name: 'Ejecutivo' },
    { id: 'Analista', name: 'Analista' },
    { id: 'Asistente', name: 'Asistente' },
    { id: 'Otro', name: 'Otro' },
  ];
  const { selectedContact, setSelectedContact, tiny } = props;

  const itemSize = tiny ? 3 : 12;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={itemSize}>
          <TextField
            label="Nombre"
            value={selectedContact.name}
            fullWidth
            onChange={(e) =>
              setSelectedContact({ ...selectedContact, name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={itemSize}>
          <TextField
            label="Email"
            value={selectedContact.email}
            fullWidth
            onChange={(e) =>
              setSelectedContact({ ...selectedContact, email: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={itemSize}>
          <TextField
            label="Teléfono"
            value={selectedContact.phone}
            fullWidth
            placeholder="5691232133"
            onChange={(e) =>
              setSelectedContact({ ...selectedContact, phone: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={itemSize}>
          <FormControl fullWidth>
            <InputLabel id="filter-label">{'Rol'}</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={selectedContact.role}
              onChange={(e) =>
                setSelectedContact({
                  ...selectedContact,
                  role: e.target.value,
                })
              }
              label={'Rol'}
            >
              {companyRoles.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

interface Contact {
  id: number;
  name: string;
  email: string;
  phone: string;
  role: string;
  selected: boolean;
  invoicing: boolean;
}

const ContactsComponent = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { businessId } = props;
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any>({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const mockContact = {
    id: null,
    name: '',
    email: '',
    role: '',
  };
  const { id, page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const pageBusinessId = businessId || id;

  const setUp = async () => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    const contactsResponse = await getBusinessContacts(
      dispatch,
      pageBusinessId,
    );
    console.log(contactsResponse);
    setContacts(contactsResponse);
    setLoading(false);
  };

  const handleCreate = () => {
    setOpenEdit(true);
    setSelectedContact(mockContact);
  };

  const handleEdit = (contactId: any) => {
    const contact = contacts.find((c: any) => c.id === contactId);
    if (!contact) return;

    setOpenEdit(true);
    setSelectedContact(contact);
  };

  const validateAttributes = () => {
    if (
      selectedContact.name === '' ||
      selectedContact.email === '' ||
      selectedContact.role === ''
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    // if (!validateChileanPhone(selectedContact.phone)) {
    //   alert('El teléfono debe ser un número chileno');
    //   return false;
    // }
    return true;
  };

  const handleSave = async () => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    if (!validateAttributes()) {
      return;
    }
    setLoading(true);
    try {
      const response = selectedContact.id
        ? await updateBusinessContact(dispatch, pageBusinessId, {
            contactId: selectedContact.id,
            name: selectedContact.name,
            email: selectedContact.email,
            phone: selectedContact.phone,
            role: selectedContact.role,
          })
        : await createBusinessContact(dispatch, pageBusinessId, {
            id: Number(pageBusinessId),
            name: selectedContact.name,
            email: selectedContact.email,
            phone: selectedContact.phone,
            role: selectedContact.role,
          });
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  const handleConfirmDelete = (contactId: number) => {
    const contact = contacts.find((c: any) => c.id === contactId);
    console.log(contact, contactId, contacts);

    if (!contact) return;
    setSelectedContact(contact);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = async (contactId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await deleteBusinessContact(dispatch, pageBusinessId, {
        contactId,
      });
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  const handleSelectMain = async (contactId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await selectContact(dispatch, pageBusinessId, {
        id: Number(id),
        contactId,
      });
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleSelectInvoicing = async (contactId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await selectContactInvoicing(dispatch, pageBusinessId, {
        id: Number(id),
        contactId,
      });
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  const handleUnSelectInvoicing = async (contactId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await unSelectContactInvoicing(
        dispatch,
        pageBusinessId,
        {
          id: Number(id),
          contactId,
        },
      );
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress relative />;

  const canEdit = () => validatePermissions(user, 'businesses_create_new');
  const check = (contactID: number, markType: string): boolean => {
    if (!canEdit()) return false;

    const contact = contacts.some(
      (x: any) => x.id === contactID && x.invoicing,
    );

    switch (markType) {
      case 'mark':
        return !contact;
      case 'unmark':
        return contact;
      default:
        return false;
    }
  };

  const actions = [
    {
      action: handleSelectMain,
      actionLabel: 'Seleccionar como principal',
      canShow: canEdit,
    },
    {
      action: handleSelectInvoicing,
      actionLabel: 'Seleccionar como contacto de facturación',
      canShow: (e: any) => check(e, 'mark'),
    },
    {
      action: handleUnSelectInvoicing,
      actionLabel: 'Desmarcar como contacto de facturación',
      canShow: (e: any) => check(e, 'unmark'),
    },
    {
      action: handleEdit,
      actionLabel: 'Editar',
      canShow: canEdit,
    },
    {
      action: handleConfirmDelete,
      actionLabel: 'Eliminar',
      canShow: canEdit,
    },
  ];

  const buttons: CustomButtonProps[] = [
    {
      name: 'Crear',
      action: handleCreate,
      color: 'primary',
      disabled: !canEdit(),
    },
  ];

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={buttons}
        title="Acá podrás ver y editar los contactos de esta empresa"
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'phone', label: 'Número' },
          { key: 'email', label: 'Email ' },
          { key: 'role', label: 'Rol' },
          { key: 'selected', label: 'Principal', type: 'boolean-active-null' },
          {
            key: 'invoicing',
            label: 'Facturación',
            type: 'boolean-active-null',
          },
        ]}
        data={contacts}
        actions={actions}
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
      <Dialog fullWidth open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>
          {selectedContact.id ? 'Editar' : 'Crear'} contacto
        </DialogTitle>
        <DialogContent>
          <ContactForm
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setOpenEdit(false);
            }}
          >
            Salir
          </CustomButton>
          <CustomButton color="primary" onClick={handleSave}>
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Eliminar contacto</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro que deseas eliminar a {selectedContact.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setConfirmDeleteOpen(false);
            }}
          >
            Cancelar
          </CustomButton>
          <CustomButton
            color="error"
            onClick={() => {
              handleDelete(selectedContact.id);
              setConfirmDeleteOpen(false);
            }}
          >
            Eliminar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactsComponent;

// 56232130900	jhalabi@7cumbres.cl
