export const chileanCommunes: any = {
  1: [
    { id: 2, name: 'Camarones' },
    { id: 1, name: 'Arica' },
    { id: 3, name: 'Putre' },
    { id: 4, name: 'General Lagos' },
  ],
  2: [
    { id: 5, name: 'Iquique' },
    { id: 6, name: 'Alto Hospicio' },
    { id: 7, name: 'Pozo Almonte' },
    { id: 8, name: 'Camiña' },
    { id: 9, name: 'Colchane' },
    { id: 10, name: 'Huara' },
    { id: 11, name: 'Pica' },
  ],
  3: [
    { id: 12, name: 'Antofagasta' },
    { id: 13, name: 'Mejillones' },
    { id: 14, name: 'Sierra Gorda' },
    { id: 15, name: 'Taltal' },
    { id: 16, name: 'Calama' },
    { id: 17, name: 'Ollagüe' },
    { id: 18, name: 'San Pedro de Atacama' },
    { id: 19, name: 'Tocopilla' },
    { id: 20, name: 'María Elena' },
  ],
  4: [
    { id: 21, name: 'Copiapó' },
    { id: 22, name: 'Caldera' },
    { id: 23, name: 'Tierra Amarilla' },
    { id: 24, name: 'Chañaral' },
    { id: 25, name: 'Diego de Almagro' },
    { id: 26, name: 'Vallenar' },
    { id: 27, name: 'Alto del Carmen' },
    { id: 28, name: 'Freirina' },
    { id: 29, name: 'Huasco' },
  ],
  5: [
    { id: 30, name: 'La Serena' },
    { id: 31, name: 'Coquimbo' },
    { id: 32, name: 'Andacollo' },
    { id: 33, name: 'La Higuera' },
    { id: 34, name: 'Paihuano' },
    { id: 35, name: 'Vicuña' },
    { id: 36, name: 'Illapel' },
    { id: 37, name: 'Canela' },
    { id: 38, name: 'Los Vilos' },
    { id: 39, name: 'Salamanca' },
    { id: 40, name: 'Ovalle' },
    { id: 41, name: 'Combarbalá' },
    { id: 42, name: 'Monte Patria' },
    { id: 43, name: 'Punitaqui' },
    { id: 44, name: 'Río Hurtado' },
    { id: 45, name: 'Valparaíso' },
    { id: 46, name: 'Casablanca' },
    { id: 47, name: 'Concón' },
    { id: 48, name: 'Juan Fernández' },
    { id: 49, name: 'Puchuncaví' },
    { id: 50, name: 'Quintero' },
    { id: 51, name: 'Viña del Mar' },
    { id: 52, name: 'Isla de Pascua' },
    { id: 53, name: 'Los Andes' },
    { id: 54, name: 'Calle Larga' },
    { id: 55, name: 'Rinconada' },
    { id: 56, name: 'San Esteban' },
    { id: 57, name: 'La Ligua' },
    { id: 58, name: 'Cabildo' },
    { id: 59, name: 'Papudo' },
    { id: 60, name: 'Petorca' },
    { id: 61, name: 'Zapallar' },
  ],
  6: [
    { id: 62, name: 'Rancagua' },
    { id: 63, name: 'Codegua' },
    { id: 64, name: 'Coinco' },
    { id: 65, name: 'Coltauco' },
    { id: 66, name: 'Doñihue' },
    { id: 67, name: 'Graneros' },
    { id: 68, name: 'Las Cabras' },
    { id: 69, name: 'Machalí' },
    { id: 70, name: 'Malloa' },
    { id: 71, name: 'Mostazal' },
    { id: 72, name: 'Olivar' },
    { id: 73, name: 'Peumo' },
    { id: 74, name: 'Pichidegua' },
    { id: 75, name: 'Quinta de Tilcoco' },
    { id: 76, name: 'Rengo' },
    { id: 77, name: 'Requínoa' },
    { id: 78, name: 'San Vicente' },
  ],
  7: [
    { id: 79, name: 'Talca' },
    { id: 80, name: 'Constitución' },
    { id: 81, name: 'Curepto' },
    { id: 82, name: 'Empedrado' },
    { id: 83, name: 'Maule' },
    { id: 84, name: 'Pelarco' },
    { id: 85, name: 'Pencahue' },
    { id: 86, name: 'Río Claro' },
    { id: 87, name: 'San Clemente' },
    { id: 88, name: 'San Rafael' },
    { id: 89, name: 'Cauquenes' },
    { id: 90, name: 'Chanco' },
    { id: 91, name: 'Pelluhue' },
  ],
  8: [
    { id: 92, name: 'Concepción' },
    { id: 93, name: 'Coronel' },
    { id: 94, name: 'Chiguayante' },
    { id: 95, name: 'Florida' },
    { id: 96, name: 'Hualqui' },
    { id: 97, name: 'Lota' },
    { id: 98, name: 'Penco' },
    { id: 99, name: 'San Pedro de la Paz' },
    { id: 100, name: 'Santa Juana' },
    { id: 101, name: 'Talcahuano' },
    { id: 102, name: 'Tomé' },
    { id: 103, name: 'Hualpén' },
    { id: 104, name: 'Lebu' },
    { id: 105, name: 'Arauco' },
    { id: 106, name: 'Cañete' },
    { id: 107, name: 'Contulmo' },
    { id: 108, name: 'Curanilahue' },
    { id: 109, name: 'Los Álamos' },
    { id: 110, name: 'Tirúa' },
  ],
  9: [
    { id: 111, name: 'Temuco' },
    { id: 112, name: 'Carahue' },
    { id: 113, name: 'Cunco' },
    { id: 114, name: 'Curarrehue' },
    { id: 115, name: 'Freire' },
    { id: 116, name: 'Galvarino' },
    { id: 117, name: 'Gorbea' },
    { id: 118, name: 'Lautaro' },
    { id: 119, name: 'Loncoche' },
    { id: 120, name: 'Melipeuco' },
    { id: 121, name: 'Nueva Imperial' },
    { id: 122, name: 'Padre Las Casas' },
    { id: 123, name: 'Perquenco' },
    { id: 124, name: 'Pitrufquén' },
    { id: 125, name: 'Pucón' },
    { id: 126, name: 'Saavedra' },
    { id: 127, name: 'Teodoro Schmidt' },
    { id: 128, name: 'Toltén' },
    { id: 129, name: 'Vilcún' },
    { id: 130, name: 'Villarrica' },
    { id: 131, name: 'Cholchol' },
    { id: 132, name: 'Angol' },
    { id: 133, name: 'Collipulli' },
    { id: 134, name: 'Curacautín' },
    { id: 135, name: 'Ercilla' },
    { id: 136, name: 'Lonquimay' },
    { id: 137, name: 'Los Sauces' },
    { id: 138, name: 'Lumaco' },
    { id: 139, name: 'Purén' },
    { id: 140, name: 'Renaico' },
    { id: 141, name: 'Traiguén' },
    { id: 142, name: 'Victoria' },
  ],
  10: [
    { id: 143, name: 'Puerto Montt' },
    { id: 144, name: 'Calbuco' },
    { id: 145, name: 'Cochamó' },
    { id: 146, name: 'Fresia' },
    { id: 147, name: 'Frutillar' },
    { id: 148, name: 'Los Muermos' },
    { id: 149, name: 'Llanquihue' },
    { id: 150, name: 'Maullín' },
    { id: 151, name: 'Puerto Varas' },
    { id: 152, name: 'Castro' },
    { id: 153, name: 'Ancud' },
    { id: 154, name: 'Chonchi' },
    { id: 155, name: 'Curaco de Vélez' },
    { id: 156, name: 'Dalcahue' },
    { id: 157, name: 'Puqueldón' },
    { id: 158, name: 'Queilén' },
    { id: 159, name: 'Quellón' },
    { id: 160, name: 'Quemchi' },
    { id: 161, name: 'Quinchao' },
    { id: 162, name: 'Osorno' },
    { id: 163, name: 'Puerto Octay' },
    { id: 164, name: 'Purranque' },
    { id: 165, name: 'Puyehue' },
    { id: 166, name: 'Río' },
  ],
  11: [
    { id: 167, name: 'Coihaique' },
    { id: 168, name: 'Lago Verde' },
    { id: 169, name: 'Aisén' },
    { id: 170, name: 'Cisnes' },
    { id: 171, name: 'Guaitecas' },
    { id: 172, name: 'Cochrane' },
    { id: 173, name: 'O’Higgins' },
    { id: 174, name: 'Tortel' },
    { id: 175, name: 'Chile Chico' },
    { id: 176, name: 'Río Ibáñez' },
  ],
  12: [
    { id: 177, name: 'Punta Arenas' },
    { id: 178, name: 'Laguna Blanca' },
    { id: 179, name: 'Río Verde' },
    { id: 180, name: 'San Gregorio' },
    { id: 181, name: 'Cabo de Hornos' },
    { id: 182, name: 'Antártica' },
    { id: 183, name: 'Porvenir' },
    { id: 184, name: 'Primavera' },
    { id: 185, name: 'Timaukel' },
    { id: 186, name: 'Natales' },
    { id: 187, name: 'Torres del Paine' },
  ],
  13: [
    { id: 188, name: 'Santiago' },
    { id: 189, name: 'Cerrillos' },
    { id: 190, name: 'Cerro Navia' },
    { id: 191, name: 'Conchalí' },
    { id: 192, name: 'El Bosque' },
    { id: 193, name: 'Estación Central' },
    { id: 194, name: 'Huechuraba' },
    { id: 195, name: 'Independencia' },
    { id: 196, name: 'La Cisterna' },
    { id: 197, name: 'La Florida' },
    { id: 198, name: 'La Granja' },
    { id: 199, name: 'La Pintana' },
    { id: 200, name: 'La Reina' },
    { id: 201, name: 'Las Condes' },
    { id: 202, name: 'Lo Barnechea' },
    { id: 203, name: 'Lo Espejo' },
    { id: 204, name: 'Lo Prado' },
    { id: 205, name: 'Macul' },
    { id: 206, name: 'Maipú' },
    { id: 207, name: 'Ñuñoa' },
    { id: 208, name: 'Pedro Aguirre Cerda' },
    { id: 209, name: 'Peñalolén' },
    { id: 210, name: 'Providencia' },
    { id: 211, name: 'Pudahuel' },
    { id: 212, name: 'Quilicura' },
    { id: 213, name: 'Quinta Normal' },
    { id: 214, name: 'Recoleta' },
    { id: 215, name: 'Renca' },
    { id: 216, name: 'San Joaquín' },
    { id: 217, name: 'San Miguel' },
    { id: 218, name: 'San Ramón' },
    { id: 219, name: 'Vitacura' },
    {
      id: 220,
      name: 'Puente Alto',
    },
    { id: 221, name: 'Pirque' },
    { id: 222, name: 'San José de Maipo' },
    { id: 223, name: 'Colina' },
    { id: 224, name: 'Lampa' },
    { id: 225, name: 'Tiltil' },
    { id: 226, name: 'San Bernardo' },
    { id: 227, name: 'Buin' },
    { id: 228, name: 'Calera de Tango' },
    { id: 229, name: 'Paine' },
    { id: 230, name: 'Melipilla' },
    { id: 231, name: 'Alhué' },
    { id: 232, name: 'Curacaví' },
    { id: 233, name: 'María Pinto' },
    { id: 234, name: 'San Pedro' },
    { id: 235, name: 'Talagante' },
    { id: 236, name: 'El Monte' },
    { id: 237, name: 'Isla de Maipo' },
    { id: 238, name: 'Padre Hurtado' },
    { id: 239, name: 'Peñaflor' },
  ],
  14: [
    { id: 240, name: 'Valdivia' },
    { id: 241, name: 'Corral' },
    { id: 242, name: 'Lanco' },
    { id: 243, name: 'Los Lagos' },
    { id: 244, name: 'Máfil' },
    { id: 245, name: 'Mariquina' },
    { id: 246, name: 'Paillaco' },
    { id: 247, name: 'Panguipulli' },
    { id: 248, name: 'La Unión' },
    { id: 249, name: 'Futrono' },
    { id: 250, name: 'Lago Ranco' },
    { id: 251, name: 'Río Bueno' },
  ],
  15: [
    { id: 252, name: 'Arica' },
    { id: 253, name: 'Camarones' },
    { id: 254, name: 'General Lagos' },
    { id: 255, name: 'Putre' },
  ],
  16: [
    { id: 256, name: 'Chillán' },
    { id: 257, name: 'Bulnes' },
    { id: 258, name: 'Cobquecura' },
    { id: 259, name: 'Coelemu' },
    { id: 260, name: 'Coihueco' },
    { id: 261, name: 'Chillán Viejo' },
    { id: 262, name: 'El Carmen' },
    { id: 263, name: 'Ninhue' },
    { id: 264, name: 'Ñiquén' },
    { id: 265, name: 'Pemuco' },
    { id: 266, name: 'Pinto' },
    { id: 267, name: 'Portezuelo' },
    { id: 268, name: 'Quillón' },
    { id: 269, name: 'Quirihue' },
    { id: 270, name: 'Ránquil' },
    { id: 271, name: 'San Carlos' },
    { id: 272, name: 'San Fabián' },
    { id: 273, name: 'San Ignacio' },
    { id: 274, name: 'San Nicolás' },
    { id: 275, name: 'Treguaco' },
    { id: 276, name: 'Yungay' },
  ],
};

export const chileanRegions = [
  { id: 1, name: 'Tarapacá' },
  { id: 2, name: 'Antofagasta' },
  { id: 3, name: 'Atacama' },
  { id: 4, name: 'Coquimbo' },
  { id: 5, name: 'Valparaíso' },
  { id: 6, name: 'O’Higgins' },
  { id: 7, name: 'Maule' },
  { id: 8, name: 'Biobío' },
  { id: 9, name: 'Araucanía' },
  { id: 10, name: 'Los Lagos' },
  { id: 11, name: 'Aysén' },
  { id: 12, name: 'Magallanes' },
  { id: 13, name: 'Metropolitana' },
  { id: 14, name: 'Los Ríos' },
  { id: 15, name: 'Arica y Parinacota' },
  { id: 16, name: 'Ñuble' },
];

export const compnaySizes = [
  { id: 1, name: 'Micro' },
  { id: 2, name: 'Pequeña' },
  { id: 3, name: 'Mediana' },
  { id: 4, name: 'Grande' },
];

export const economicSector = [
  { id: 11, name: 'AGRICULTURA Y GANADERIA EXCEPTO FRUTICULTURA' },
  { id: 82, name: 'BIENES INMUEBLES Y SERVICIOS PRESTADOS A EMPRESAS' },
  { id: 61, name: 'COMERCIO AL POR MAYOR' },
  { id: 62, name: 'COMERCIO AL POR MENOR, RESTAURANTES Y HOTELES' },
  { id: 72, name: 'COMUNICACIONES' },
  { id: 51, name: 'CONSTRUCCION DE VIVIENDAS' },
  { id: 1, name: 'CREDITOS DE CONSUMO' },
  { id: 41, name: 'ELECTRICIDAD GAS Y AGUA' },
  { id: 81, name: 'ESTABLECIMIENTOS FINANCIEROS Y DE SEGUROS' },
  { id: 21, name: 'EXPLOTACION DE MINAS Y CANTERAS' },
  { id: 36, name: 'FAB.DE PROD.MIN. METAL.Y NO METAL, MAQUIN. Y EQUIP' },
  { id: 12, name: 'FRUTICULTURA' },
  { id: 33, name: 'INDUSTRIA DE LA MADERA Y MUEBLES' },
  { id: 34, name: 'INDUSTRIA DEL PAPEL, IMPRENTA Y EDITORIALES' },
  { id: 32, name: 'INDUSTRIA TEXTIL Y DEL CUERO' },
  { id: 39, name: 'OTRAS INDUSTRIAS MANUFACTURERAS' },
  { id: 52, name: 'OTRAS OBRAS Y CONSTRUCCIONES' },
  { id: 14, name: 'PESCA' },
  { id: 2, name: 'PRESTAMOS HIPOTECARIOS PARA LA VIVIENDA' },
  { id: 35, name: 'PROD.QUIM. Y DERIV.DEL PETROLEO CARBON CAUCHO Y PL' },
  { id: 22, name: 'PRODUCCION DE PETROLEO CRUDO Y GAS NATURAL' },
  { id: 31, name: 'PRODUCTOS ALIMENTICIOS BEBIDAS Y TABACOS' },
  { id: 91, name: 'SERVICIOS COMUNALES, SOCIALES Y PERSONALES' },
  { id: 13, name: 'SILVICULTURA Y EXTRACCION DE MADERA' },
  { id: 71, name: 'TRANSPORTES  Y ALMACENAMIENTOS' },
];

export const economicActivity = [
  { id: 61512, name: '61512 - Abastecedora de vidrios, espejos y cristales' },
  { id: 1, name: '1 - Actividades no especificadas' },
  {
    id: 91001,
    name: '91001 - Administración Pública y Defensa (incluye Cajas de',
  },
  { id: 81024, name: '81024 - Administradoras de Fondos de Pensiones' },
  { id: 83272, name: '83272 - Agencias de empleos' },
  { id: 62312, name: '62312 - Agencias de lotería, de polla, polla gol, etc.' },
  { id: 71911, name: '71911 - Agencias de turismo (incluye venta de pasajes)' },
  {
    id: 94162,
    name: '94162 - Agencias periodísticas, de información y noticias',
  },
  {
    id: 71912,
    name: '71912 - Agentes de aduanas; asesorías en comercio exterior',
  },
  { id: 82002, name: '82002 - Agentes de seguros' },
  { id: 62191, name: '62191 - Alimentos para animales' },
  { id: 62101, name: '62101 - Almacenes de comestibles' },
  { id: 31212, name: '31212 - Almidón y sus derivados' },
  {
    id: 95202,
    name: '95202 - Alquiler de ropa blanca y reparación de ropa y art',
  },
  {
    id: 83301,
    name: '83301 - Alquiler o arrendamiento de maquinaria y equipo',
  },
  { id: 62515, name: '62515 - Antigüedades y galerías de arte' },
  {
    id: 62513,
    name: '62513 - Aparatos y artefactos eléctricos para iluminación',
  },
  { id: 11128, name: '11128 - Apicultura' },
  { id: 62535, name: '62535 - Árboles y plantas' },
  { id: 62514, name: '62514 - Armerías, artículos de caza y pesca' },
  { id: 83241, name: '83241 - Arquitectos' },
  {
    id: 83101,
    name: '83101 - Arrendamiento y administración de bienes inmuebles',
  },
  { id: 71123, name: '71123 - Arriendo de automóviles' },
  {
    id: 62565,
    name: '62565 - Arriendo de cosas muebles (excepto automóviles, qu',
  },
  { id: 11112, name: '11112 - Arroz' },
  { id: 62511, name: '62511 - Artículos de aseo y detergentes' },
  { id: 62519, name: '62519 - Artículos de fotografía y óptica; audífonos' },
  {
    id: 62512,
    name: '62512 - Artículos del hogar: Cocinas, máquinas de lavar, v',
  },
  { id: 62523, name: '62523 - Artículos médicos' },
  { id: 62518, name: '62518 - Artículos ortopédicos' },
  { id: 62521, name: '62521 - Artículos plásticos y de caucho' },
  { id: 62516, name: '62516 - Artículos religiosos' },
  { id: 62522, name: '62522 - Artículos típicos (artesanía)' },
  { id: 62520, name: '62520 - Artículos y artefactos usados' },
  {
    id: 33111,
    name: '33111 - Aserraderos y otros talleres para preparar la made',
  },
  {
    id: 83264,
    name: '83264 - Asesorías económicas, financieras, gerenciales y a',
  },
  { id: 83222, name: '83222 - Asesorías tributarias' },
  { id: 81021, name: '81021 - Asociaciones de ahorro y préstamo' },
  { id: 38411, name: '38411 - Astilleros' },
  {
    id: 94151,
    name: '94151 - Autores, compositores y otros artistas independien',
  },
  { id: 62122, name: '62122 - Aves y huevos' },
  { id: 62527, name: '62527 - Baldosas' },
  {
    id: 36103,
    name: '36103 - Baldosines (excluye baldosas y revestimientos que v',
  },
  { id: 81011, name: '81011 - Bancos' },
  { id: 62525, name: '62525 - Barracas de fierro' },
  { id: 62526, name: '62526 - Barracas de madera' },
  { id: 62524, name: '62524 - Bazares, cordonerías y paqueterías' },
  {
    id: 94201,
    name: '94201 - Bibliotecas, museos, jardines botánicos y zoológic',
  },
  { id: 62528, name: '62528 - Bicicletas y sus repuestos' },
  { id: 93501, name: '93501 - Bolsas de comercio' },
  {
    id: 62111,
    name: '62111 - Botillerías: Depósitos de vinos, licores y bebidas',
  },
  { id: 62517, name: '62517 - Boutiques' },
  { id: 62411, name: '62411 - Calzado' },
  { id: 42001, name: '42001 - Captación, purificación y distribución de agua' },
  { id: 62121, name: '62121 - Carnicerías' },
  { id: 33114, name: '33114 - Carpintería de obra' },
  { id: 62415, name: '62415 - Carteras' },
  {
    id: 81031,
    name: '81031 - Casas de cambio y operaciones con divisas, excepto',
  },
  { id: 62530, name: '62530 - Casas de deportes' },
  {
    id: 62529,
    name: '62529 - Casas de música, instrumentos musicales, discos, r',
  },
  { id: 62001, name: '62001 - Casas de remate, martilleros' },
  { id: 33115, name: '33115 - Casas prefabricadas de madera' },
  { id: 62534, name: '62534 - Casas prefabricadas, rodantes' },
  { id: 13021, name: '13021 - Caza de ballena, foca, lobo marino y otros' },
  { id: 11301, name: '11301 - Caza ordinaria y mediante trampas' },
  { id: 93313, name: '93313 - Centros médicos' },
  { id: 11111, name: '11111 - Cereales, oleaginosas y forrajeras' },
  { id: 62311, name: '62311 - Cigarrerías, tabacos y útiles para fumar' },
  {
    id: 94144,
    name: '94144 - Cines, teatros y distribución de películas cinemat',
  },
  {
    id: 94908,
    name: '94908 - Circos, pistas de patinaje y otros servicios de di',
  },
  { id: 93322, name: '93322 - Clínicas veterinarias' },
  { id: 94906, name: '94906 - Clubes de deportes, estadios y piscinas' },
  { id: 62431, name: '62431 - Colchones' },
  { id: 93504, name: '93504 - Colegios profesionales' },
  {
    id: 62531,
    name: '62531 - Combustibles sólidos y líquidos (excepto estacione',
  },
  {
    id: 62570,
    name: '62570 - Comercio al por menor no clasificado en otra parte',
  },
  {
    id: 61911,
    name: '61911 - Comercio por mayor no clasificado en otra parte',
  },
  { id: 81032, name: '81032 - Comisionistas de la bolsa' },
  { id: 82001, name: '82001 - Compañías de seguros' },
  { id: 61521, name: '61521 - Compra y venta al por mayor de metales' },
  { id: 72001, name: '72001 - Comunicaciones' },
  { id: 31211, name: '31211 - Condimentos, mostazas y vinagres' },
  {
    id: 32322,
    name: '32322 - Confección de alfombras, felpudos y otros artículo',
  },
  { id: 32124, name: '32124 - Confección de artículos de lona y bolsas' },
  { id: 32121, name: '32121 - Confección de frazadas, mantas y cortinas' },
  { id: 32206, name: '32206 - Confección de impermeables, casacas, parkas' },
  { id: 32201, name: '32201 - Confección de prendas de vestir' },
  { id: 32204, name: '32204 - Confección de prendas de vestir de cueros' },
  { id: 32205, name: '32205 - Confección de prendas de vestir de piel' },
  {
    id: 22122,
    name: '22122 - Confección de sábanas, frazadas de almohadas, mant',
  },
  {
    id: 32202,
    name: '32202 - Confección de sombreros, guantes y otros accesorio',
  },
  { id: 32207, name: '32207 - Confección de uniformes y accesorios' },
  { id: 62181, name: '62181 - Confiterías' },
  {
    id: 31134,
    name: '31134 - Conservas, caldos concentrados y otrosalimentos des',
  },
  {
    id: 50021,
    name: '50021 - Construcción de caminos, puentes, aeropuertos, obr',
  },
  { id: 50026, name: '50026 - Construcción de campos de deportes' },
  { id: 38412, name: '38412 - Construcción de motores y piezas para naves' },
  { id: 50028, name: '50028 - Construcción de obras marítimas' },
  {
    id: 50025,
    name: '50025 - Construcción de obras sanitarias y alcantarillado',
  },
  {
    id: 50027,
    name: '50027 - Construcción de refinerías, plantas industriales,',
  },
  { id: 50013, name: '50013 - Construcción de viviendas' },
  {
    id: 50034,
    name: '50034 - Construcción e instalación de sistemas de aire aco',
  },
  {
    id: 38431,
    name: '38431 - Construcción y montaje de vehículos automóviles',
  },
  {
    id: 50022,
    name: '50022 - Construcción y pavimentación de aceras y calzadas',
  },
  {
    id: 38314,
    name: '38314 - Construcción y reparación de aparatos de soldadura',
  },
  { id: 50011, name: '50011 - Construcción y reparación de edificios' },
  {
    id: 38312,
    name: '38312 - Construcción y reparación de equipos para la gener',
  },
  {
    id: 38311,
    name: '38311 - Construcción y reparación de motores eléctricos',
  },
  {
    id: 38315,
    name: '38315 - Construcción y reparación de otros aparatos indust',
  },
  {
    id: 71112,
    name: '71112 - Construcción y reparación del material rodante y m',
  },
  {
    id: 50035,
    name: '50035 - Construcción y/o instalación de impermeabilización',
  },
  {
    id: 50036,
    name: '50036 - Construcción y/o instalación de obras no clasifica',
  },
  {
    id: 38421,
    name: '38421 - Construcción, reparación y modificación de maquina',
  },
  { id: 83242, name: '83242 - Constructores' },
  { id: 50037, name: '50037 - Contratistas y subcontratistas' },
  { id: 61115, name: '61115 - Corredor de vinos' },
  { id: 83103, name: '83103 - Corredores de propiedades' },
  { id: 61111, name: '61111 - Corretaje agrícola, frutícola y lechera' },
  { id: 61127, name: '61127 - Corretaje de ganado (ferias de ganado)' },
  {
    id: 12201,
    name: '12201 - Corta de madera, desbaste,productos forestales cor',
  },
  { id: 95915, name: '95915 - Cosmetólogos' },
  {
    id: 11126,
    name: '11126 - Cría de animales destinados a la producción de pie',
  },
  { id: 11122, name: '11122 - Cría de animales finos' },
  {
    id: 11127,
    name: '11127 - Cría de aves, para producción de carnes y huevos',
  },
  { id: 11124, name: '11124 - Cría de ganado ovino y su explotación lanera' },
  { id: 11125, name: '11125 - Cría de ganado porcino' },
  {
    id: 11129,
    name: '11129 - Cría y explotación de animales no clasificados en',
  },
  { id: 11121, name: '11121 - Crías de ganado bovino' },
  { id: 62532, name: '62532 - Cristalerías, lozas, porcelanas, menajes, etc.' },
  {
    id: 93401,
    name: '93401 - Cruz Roja, guarderías infantiles, asilos de ancian',
  },
  { id: 32311, name: '32311 - Curtiduría y talleres de acabado' },
  { id: 50012, name: '50012 - Demolición de edificios' },
  {
    id: 71921,
    name: '71921 - Depósitos y almacenamiento y otros servicios conex',
  },
  { id: 31311, name: '31311 - Destilación de alcohol etílico' },
  {
    id: 31312,
    name: '31312 - Destilación, rectificación de bebidas alcohólicas',
  },
  { id: 11203, name: '11203 - Destrucción de plagas; fumigación' },
  { id: 83243, name: '83243 - Dibujantes' },
  { id: 83273, name: '83273 - Diseñadores' },
  { id: 61514, name: '61514 - Distribuidora de materiales de construcción' },
  {
    id: 61414,
    name: '61414 - Distribuidora de plásticos y mayoristas en envases',
  },
  { id: 61522, name: '61522 - Distribuidora de productos metálicos' },
  {
    id: 61386,
    name: '61386 - Distribuidora, importadora y exportadora de libros',
  },
  {
    id: 61321,
    name: '61321 - Distribuidores de bebidas no alcohólicas, aguas mi',
  },
  { id: 61384, name: '61384 - Distribuidores mayoristas de papel' },
  { id: 31133, name: '31133 - Dulces, mermeladas, jaleas' },
  { id: 38134, name: '38134 - Edificios metálicos prefabricados' },
  { id: 34204, name: '34204 - Editoriales' },
  {
    id: 11192,
    name: '11192 - Elaborac. de produc. agropec. en explotación agríc',
  },
  {
    id: 31152,
    name: '31152 - Elaboración de aceites y grasas animales no comest',
  },
  {
    id: 31151,
    name: '31151 - Elaboración de aceites y grasas vegetales y subpro',
  },
  {
    id: 31221,
    name: '31221 - Elaboración de alimentos preparados para animales',
  },
  {
    id: 31341,
    name: '31341 - Elaboración de bebidas y aguas minerales gasificad',
  },
  {
    id: 31174,
    name: '31174 - Elaboración de fideos, tallarines y otras pastas',
  },
  {
    id: 31331,
    name: '31331 - Elaboración de malta, cerveza y bebidas malteadas',
  },
  { id: 31132, name: '31132 - Elaboración de pasas, frutas y legumbres secas' },
  {
    id: 31141,
    name: '31141 - Elaboración de pescado, crustáceos y otros aliment',
  },
  { id: 31163, name: '31163 - Elaboración de semillas secas de leguminosas' },
  {
    id: 31322,
    name: '31322 - Elaboración de sidras y otras bebidas fermentadas,',
  },
  { id: 31215, name: '31215 - Elaboración de té' },
  {
    id: 31131,
    name: '31131 - Elaboración y envasado de frutas y legumbres (incl',
  },
  {
    id: 61323,
    name: '61323 - Embotelladores mayoristas de alcoholes y licores',
  },
  { id: 94131, name: '94131 - Emisiones de radio y televisión' },
  { id: 95931, name: '95931 - Empleados' },
  { id: 93101, name: '93101 - Enseñanza escuelas primarias y secundarias' },
  { id: 33121, name: '33121 - Envases de madera y de caña' },
  {
    id: 62537,
    name: '62537 - Equipo profesional y científico: Instrumentos de m',
  },
  { id: 94143, name: '94143 - Escenografía e iluminación' },
  { id: 93110, name: '93110 - Escuelas de modelos' },
  {
    id: 93111,
    name: '93111 - Escuelas especializadas; sordomudos, dificultades',
  },
  {
    id: 93106,
    name: '93106 - Escuelas o institutos técnicos, profesionales o co',
  },
  {
    id: 38196,
    name: '38196 - Esmaltado, barnizado, lacado, galvanizado, chapado',
  },
  {
    id: 62536,
    name: '62536 - Estaciones de servicios (ventas de bencina, lubric',
  },
  { id: 32114, name: '32114 - Estampados' },
  { id: 38192, name: '38192 - Estampados de metal y productos de tornería' },
  { id: 95921, name: '95921 - Estudios fotográficos, fotógrafos' },
  { id: 12101, name: '12101 - Explotación de bosques' },
  { id: 13051, name: '13051 - Explotación de frutos acuáticos, algas, etc.' },
  { id: 71122, name: '71122 - Explotación de instalaciones conexas' },
  {
    id: 29031,
    name: '29031 - Explotación de minas de sal, incluida la molienda',
  },
  { id: 21001, name: '21001 - Explotación de minas y carbón' },
  {
    id: 31153,
    name: '31153 - Extracción de aceites de pescado y de otros animal',
  },
  { id: 23011, name: '23011 - Extracción de mineral de hierro' },
  {
    id: 29090,
    name: '29090 - Extracción de minerales no clasificadosen otra par',
  },
  {
    id: 29021,
    name: '29021 - Extracción de minerales para la fabricación de abo',
  },
  { id: 29014, name: '29014 - Extracción de piedra caliza y yeso' },
  { id: 29011, name: '29011 - Extracción de piedra, arcilla y arena' },
  { id: 35121, name: '35121 - Fabricación de abonos' },
  {
    id: 38392,
    name: '38392 - Fabricación de ampolletas, tubos eléctricos, focos',
  },
  {
    id: 38326,
    name: '38326 - Fabricación de aparatos y válvulas de radiografía,',
  },
  {
    id: 39031,
    name: '39031 - Fabricación de artículos de deporte, atletismo y c',
  },
  { id: 38522, name: '38522 - Fabricación de artículos de fotografía' },
  {
    id: 34191,
    name: '34191 - Fabricación de artículos de pulpa, papel y cartón',
  },
  {
    id: 38195,
    name: '38195 - Fabricación de artículos sanitarios y de plomería',
  },
  {
    id: 38441,
    name: '38441 - Fabricación de bicicletas y motocicletas y sus pie',
  },
  {
    id: 39099,
    name: '39099 - Fabricación de botones, pelucas, fantasías y otros',
  },
  {
    id: 35402,
    name: '35402 - Fabricación de briquetas de combustibles y otros p',
  },
  { id: 31191, name: '31191 - Fabricación de cacao y chocolate en polvo' },
  {
    id: 32402,
    name: '32402 - Fabricación de calzado de tela y otros materiales,',
  },
  { id: 32401, name: '32401 - Fabricación de calzado y botas de cuero' },
  { id: 35511, name: '35511 - Fabricación de cámaras y neumáticos o llantas' },
  { id: 36921, name: '36921 - Fabricación de cemento, cal y yeso' },
  { id: 36101, name: '36101 - Fabricación de cerámicas' },
  { id: 35291, name: '35291 - Fabricación de ceras' },
  { id: 31401, name: '31401 - Fabricación de cigarrillos y cigarros' },
  {
    id: 35294,
    name: '35294 - Fabricación de colas, adhesivos, aprestos y cement',
  },
  {
    id: 31192,
    name: '31192 - Fabricación de confites, frutas confitadas y toda',
  },
  { id: 38111, name: '38111 - Fabricación de cuchillería' },
  { id: 35292, name: '35292 - Fabricación de desinfectantes y desodorizantes' },
  {
    id: 38323,
    name: '38323 - Fabricación de discos, cintas magnéticas, cassette',
  },
  { id: 34121, name: '34121 - Fabricación de envases de papel y cartón' },
  {
    id: 38322,
    name: '38322 - Fabricación de equipos de grabación y reproducción',
  },
  {
    id: 38324,
    name: '38324 - Fabricación de equipos y aparatos de comunicacione',
  },
  { id: 39095, name: '39095 - Fabricación de escobas y cepillos' },
  { id: 36202, name: '36202 - Fabricación de espejos y cristales' },
  {
    id: 38131,
    name: '38131 - Fabricación de estructuras y estanques metálicos',
  },
  { id: 35293, name: '35293 - Fabricación de explosivos y municiones' },
  {
    id: 35133,
    name: '35133 - Fabricación de fibras artificiales excepto el vidr',
  },
  { id: 31172, name: '31172 - Fabricación de galletas' },
  {
    id: 38133,
    name: '38133 - Fabricación de galpones, puertas y ventanas metáli',
  },
  {
    id: 31123,
    name: '31123 - Fabricación de helados, sorbetes y otros postres',
  },
  {
    id: 38112,
    name: '38112 - Fabricación de herramientas manuales y de artículo',
  },
  { id: 31213, name: '31213 - Fabricación de hielo natural' },
  {
    id: 38331,
    name: '38331 - Fabricación de hornos, asadores, tostadoras y bati',
  },
  { id: 39021, name: '39021 - Fabricación de instrumentos de música' },
  { id: 38521, name: '38521 - Fabricación de instrumentos de óptica' },
  {
    id: 38391,
    name: '38391 - Fabricación de interruptores, enchufes, tableros,',
  },
  { id: 35231, name: '35231 - Fabricación de jabones, detergentes y champúes' },
  {
    id: 39011,
    name: '39011 - Fabricación de joyas (excluye las fantasías, que v',
  },
  { id: 39091, name: '39091 - Fabricación de juguetes' },
  { id: 36911, name: '36911 - Fabricación de ladrillos' },
  { id: 38393, name: '38393 - Fabricación de lámparas' },
  {
    id: 39093,
    name: '39093 - Fabricación de lápices, bolígrafos y demás artícul',
  },
  {
    id: 31122,
    name: '31122 - Fabricación de leche condensada, en polvo y elabor',
  },
  { id: 38523, name: '38523 - Fabricación de lentes y artículos oftálmicos' },
  {
    id: 32331,
    name: '32331 - Fabricación de maletas, carteras, billeteras, ciga',
  },
  {
    id: 31121,
    name: '31121 - Fabricación de mantequilla, queso, quesillos, crem',
  },
  {
    id: 38491,
    name: '38491 - Fabricación de material de transporte no clasifica',
  },
  {
    id: 38394,
    name: '38394 - Fabricación de material eléctrico no clasificado e',
  },
  { id: 36915, name: '36915 - Fabricación de material refractorio' },
  {
    id: 35401,
    name: '35401 - Fabricación de materiales para pavimento y techado',
  },
  { id: 35132, name: '35132 - Fabricación de materias plásticas' },
  { id: 32131, name: '32131 - Fabricación de medias y calcetines' },
  {
    id: 38121,
    name: '38121 - Fabricación de muebles y accesorios principalmente',
  },
  {
    id: 33201,
    name: '33201 - Fabricación de muebles y accesorios, excepto los q',
  },
  {
    id: 32333,
    name: '32333 - Fabricación de otros artículos análogos no clasifi',
  },
  { id: 31402, name: '31402 - Fabricación de otros productos del tabaco' },
  {
    id: 32117,
    name: '32117 - Fabricación de otros productos no clasificados en',
  },
  {
    id: 31171,
    name: '31171 - Fabricación de pan y demás productos de panadería',
  },
  { id: 39094, name: '39094 - Fabricación de pantallas para lámparas' },
  { id: 34112, name: '34112 - Fabricación de papel y cartón' },
  { id: 39092, name: '39092 - Fabricación de paraguas y bastones' },
  {
    id: 35232,
    name: '35232 - Fabricación de perfumes, cosméticos, lociones, pas',
  },
  { id: 33204, name: '33204 - Fabricación de persianas' },
  {
    id: 38325,
    name: '38325 - Fabricación de piezas y accesorios para radio, tel',
  },
  {
    id: 38432,
    name: '38432 - Fabricación de piezas y accesorios para vehículos',
  },
  {
    id: 35211,
    name: '35211 - Fabricación de pinturas, barnices, lacas, esmaltes',
  },
  {
    id: 35122,
    name: '35122 - Fabricación de plaguicidas, insecticidas, fungicid',
  },
  {
    id: 38332,
    name: '38332 - Fabricación de planchadoras, ventiladores, enecera',
  },
  { id: 39012, name: '39012 - Fabricación de platería' },
  { id: 35212, name: '35212 - Fabricación de productos conexos' },
  {
    id: 35591,
    name: '35591 - Fabricación de productos de caucho no clasificados',
  },
  {
    id: 33193,
    name: '33193 - Fabricación de productos de madera y de corcho no',
  },
  {
    id: 35221,
    name: '35221 - Fabricación de productos farmacéuticos y medicamen',
  },
  {
    id: 38197,
    name: '38197 - Fabricación de productos metálicos no clasificados',
  },
  {
    id: 36991,
    name: '36991 - Fabricación de productos minerales no metálicos no',
  },
  {
    id: 35601,
    name: '35601 - Fabricación de productos plásticos diversos no cla',
  },
  {
    id: 37101,
    name: '37101 - Fabricación de productos primarios de hierro y ace',
  },
  {
    id: 37201,
    name: '37201 - Fabricación de productos primarios de metales no f',
  },
  {
    id: 35111,
    name: '35111 - Fabricación de productos químicos industriales bás',
  },
  { id: 33113, name: '33113 - Fabricación de puertas y ventanas' },
  { id: 34111, name: '34111 - Fabricación de pulpa de madera' },
  {
    id: 38321,
    name: '38321 - Fabricación de receptores de radio y de televisión',
  },
  { id: 36914, name: '36914 - Fabricación de rejillas y tabiques' },
  { id: 38531, name: '38531 - Fabricación de relojes y de sus piezas' },
  { id: 35131, name: '35131 - Fabricación de resinas sintéticas' },
  {
    id: 32332,
    name: '32332 - Fabricación de sillas de montar, arneses, látigos',
  },
  {
    id: 32151,
    name: '32151 - Fabricación de sogas, cables, cordeles, redes y ot',
  },
  { id: 32141, name: '32141 - Fabricación de tapices y alfombras' },
  {
    id: 34203,
    name: '34203 - Fabricación de tarjetas, sobres y papel de escribi',
  },
  {
    id: 32191,
    name: '32191 - Fabricación de textiles no clasificados en otra pa',
  },
  { id: 35296, name: '35296 - Fabricación de tintas' },
  {
    id: 38194,
    name: '38194 - Fabricación de tornillos, clavos, grapas, alambres',
  },
  { id: 35295, name: '35295 - Fabricación de velas de alumbrar' },
  { id: 36201, name: '36201 - Fabricación de vidrios planos y templados' },
  {
    id: 31321,
    name: '31321 - Fabricación de vinos (las empresas que sólo embote',
  },
  { id: 32132, name: '32132 - Fabricación y acabado de tejidos de punto' },
  { id: 31181, name: '31181 - Fabricación y refinación de azúcar' },
  {
    id: 38221,
    name: '38221 - Fabricación y reparación de maquinaria y equipo p',
  },
  {
    id: 38451,
    name: '38451 - Fabricación y reparación de aeronaves y de sus par',
  },
  {
    id: 38511,
    name: '38511 - Fabricación y reparación de instrumentos científic',
  },
  {
    id: 38241,
    name: '38241 - Fabricación y reparación de maquinaria para prepar',
  },
  {
    id: 38242,
    name: '38242 - Fabricación y reparación de maquinaria textil y qu',
  },
  {
    id: 38291,
    name: '38291 - Fabricación y reparación de maquinaria y equipo no',
  },
  {
    id: 38246,
    name: '38246 - Fabricación y reparación de maquinaria y equipo pa',
  },
  {
    id: 38231,
    name: '38231 - Fabricación y reparación de maquinaria y equipo pa',
  },
  {
    id: 38247,
    name: '38247 - Fabricación y reparación de maquinaria y equipos p',
  },
  {
    id: 38251,
    name: '38251 - Fabricación y reparación de máquinas y equipos de',
  },
  {
    id: 38252,
    name: '38252 - Fabricación y reparación de máquinas y equipos de',
  },
  {
    id: 38244,
    name: '38244 - Fabricación y reparación de máquinas y equipos de',
  },
  {
    id: 38211,
    name: '38211 - Fabricación y reparación de motores, turbinas y má',
  },
  { id: 62554, name: '62554 - Farmacias y perfumerías' },
  {
    id: 94907,
    name: '94907 - Ferias de exposición de productos de la industria,',
  },
  {
    id: 62538,
    name: '62538 - Ferreterías y almacenes de pintura, barnices y ute',
  },
  { id: 81022, name: '81022 - Financieras' },
  { id: 62541, name: '62541 - Flores' },
  { id: 11171, name: '11171 - Flores, plantas de interior y viveros' },
  { id: 12103, name: '12103 - Forestación' },
  {
    id: 34205,
    name: '34205 - Fotocopias y otros servicios relacionados con las',
  },
  { id: 34202, name: '34202 - Fotograbado y litografía' },
  {
    id: 31112,
    name: '31112 - Frigoríficos relacionados con la conservación de t',
  },
  { id: 11132, name: '11132 - Frutales' },
  { id: 62162, name: '62162 - Fruterías' },
  {
    id: 41011,
    name: '41011 - Generación, transmisión y distribución de electric',
  },
  { id: 23031, name: '23031 - Gran minería del cobre' },
  { id: 62410, name: '62410 - Grandes tiendas' },
  { id: 62182, name: '62182 - Helados, hielo' },
  { id: 32111, name: '32111 - Hilandería' },
  { id: 94905, name: '94905 - Hipódromos' },
  { id: 38191, name: '38191 - Hojalatería (incluye envases de lata)' },
  { id: 11114, name: '11114 - Hortalizas y legumbres' },
  {
    id: 93312,
    name: '93312 - Hospitales, sanatorios, clínicas y otras instituci',
  },
  { id: 63211, name: '63211 - Hoteles, hosterías, moteles, cabañas' },
  {
    id: 61561,
    name: '61561 - Importadores y distribuidores de automóviles, cami',
  },
  { id: 34201, name: '34201 - Imprenta y encuadernación' },
  {
    id: 31219,
    name: '31219 - Industrias alimenticias no clasificadas en otra pa',
  },
  {
    id: 83265,
    name: '83265 - Ingenieros Agrónomos (Enólogos, Ingenieros de Suel',
  },
  { id: 83266, name: '83266 - Ingenieros Calculistas' },
  { id: 83261, name: '83261 - Ingenieros Civiles' },
  { id: 83262, name: '83262 - Ingenieros Comerciales' },
  { id: 83263, name: '83263 - Ingenieros de Sistemas' },
  { id: 83268, name: '83268 - Ingenieros Electrónicos, Eléctricos' },
  { id: 83267, name: '83267 - Ingenieros Mecánicos' },
  { id: 93331, name: '93331 - Ingenieros Químicos, Químicos Farmacéuticos' },
  {
    id: 50033,
    name: '50033 - Instalación de agua, de desagüe y artículos sanita',
  },
  {
    id: 50032,
    name: '50032 - Instalación de equipos eléctricos en los edificios',
  },
  {
    id: 50029,
    name: '50029 - Instalaciones de sistemas de comunicación, plantas',
  },
  { id: 50024, name: '50024 - Instalaciones hidráulicas, embalses y canales' },
  {
    id: 82003,
    name: '82003 - Instituciones de salud previsionales -Isapre- excl',
  },
  {
    id: 93201,
    name: '93201 - Institutos de investigación de ciencias biológicas',
  },
  {
    id: 93202,
    name: '93202 - Institutos meteorológicos e investigaciones médica',
  },
  { id: 83252, name: '83252 - Investigación de mercados' },
  { id: 93103, name: '93103 - Jardines infantiles y parvularios' },
  { id: 62545, name: '62545 - Joyerías, relojerías y fantasías' },
  { id: 2, name: '2 - Jubilados, pensionados, montepiados, estudiantes,' },
  { id: 62544, name: '62544 - Jugueterías y venta de juegos infantiles' },
  { id: 93316, name: '93316 - Laboratorios dentales' },
  { id: 93315, name: '93315 - Laboratorios médicos' },
  {
    id: 37102,
    name: '37102 - Laminadoras y otras industrias básicas de hierro y',
  },
  { id: 95201, name: '95201 - Lavanderías, lavasecos y tintorerías' },
  { id: 31214, name: '31214 - Levaduras' },
  { id: 62547, name: '62547 - Librerías, papelerías y artículos de oficina' },
  { id: 33112, name: '33112 - Maderas terciadas, prensadas y aglomeradas' },
  { id: 62421, name: '62421 - Maleterías y talabarterías' },
  { id: 62550, name: '62550 - Máquinas de oficina, cálculo y contabilidad' },
  { id: 62552, name: '62552 - Máquinas y motores y sus repuestos' },
  { id: 62553, name: '62553 - Marcos, cuadros' },
  { id: 31113, name: '31113 - Matanza de aves' },
  { id: 31111, name: '31111 - Matanza de ganado' },
  { id: 62551, name: '62551 - Materiales de construcción' },
  { id: 61122, name: '61122 - Mayorista de lanas' },
  { id: 61117, name: '61117 - Mayoristas de aves y huevos' },
  {
    id: 61417,
    name: '61417 - Mayoristas de cañerías y otros artículos de plásti',
  },
  { id: 61124, name: '61124 - Mayoristas de cecinas' },
  { id: 61415, name: '61415 - Mayoristas de juguetes' },
  { id: 61381, name: '61381 - Mayoristas de maderas' },
  { id: 61126, name: '61126 - Mayoristas de mariscos' },
  { id: 61211, name: '61211 - Mayoristas de minerales' },
  {
    id: 61531,
    name: '61531 - Mayoristas de motores, máquinas y equipos y sus re',
  },
  { id: 61523, name: '61523 - Mayoristas de muebles y accesorios metálicos' },
  { id: 61125, name: '61125 - Mayoristas de pescados' },
  { id: 61344, name: '61344 - Mayoristas de pieles y cueros' },
  { id: 61343, name: '61343 - Mayoristas de prendas de vestir' },
  { id: 61345, name: '61345 - Mayoristas de talabartería' },
  { id: 61346, name: '61346 - Mayoristas en calzados' },
  { id: 61511, name: '61511 - Mayoristas en porcelana y loza' },
  { id: 61333, name: '61333 - Mayoristas en tabacos y cigarrillos' },
  { id: 61341, name: '61341 - Mayoristas textiles' },
  {
    id: 61524,
    name: '61524 - Mayoristas y distribuidoras de artículos de ferret',
  },
  { id: 23032, name: '23032 - Mediana minería del cobre' },
  { id: 93311, name: '93311 - Médicos' },
  { id: 31161, name: '31161 - Molinos harineros y otros' },
  {
    id: 38135,
    name: '38135 - Montaje e instalación de elementos prefabricados',
  },
  { id: 62549, name: '62549 - Mueblerías' },
  { id: 83213, name: '83213 - Notarios Públicos' },
  {
    id: 50031,
    name: '50031 - Obras de conservación, mantención, limpieza y pint',
  },
  { id: 50023, name: '50023 - Obras de vías férreas' },
  { id: 95932, name: '95932 - Obreros' },
  { id: 93314, name: '93314 - Odontólogos' },
  {
    id: 11139,
    name: '11139 - Olivos, nogales y frutos no clasificados en otra p',
  },
  {
    id: 96001,
    name: '96001 - Organismos internacionales y otros organismos extr',
  },
  { id: 93502, name: '93502 - Organizaciones profesionales' },
  { id: 93910, name: '93910 - Organizaciones religiosas' },
  { id: 13031, name: '13031 - Ostricultura' },
  {
    id: 12109,
    name: '12109 - Otras actividades afines (incluye recolección de p',
  },
  { id: 93509, name: '93509 - Otras asociaciones' },
  { id: 93107, name: '93107 - Otras escuelas (de música, de choferes, etc.)' },
  { id: 11191, name: '11191 - Otros cultivos no clasificados en otra parte' },
  {
    id: 63119,
    name: '63119 - Otros establecimientos que expenden comidas y bebi',
  },
  { id: 61349, name: '61349 - Otros mayoristas no clasificados' },
  {
    id: 23041,
    name: '23041 - Otros minerales metálicos: Molibdeno, plomo, zinc,',
  },
  { id: 61129, name: '61129 - Otros no clasificados' },
  { id: 61563, name: '61563 - Otros no clasificados' },
  { id: 62459, name: '62459 - Otros no clasificados' },
  { id: 62199, name: '62199 - Otros no clasificados en otra parte' },
  {
    id: 36104,
    name: '36104 - Otros objetos de barro, loza o porcelana no clasif',
  },
  {
    id: 11119,
    name: '11119 - Otros productos agrícolas no clasificados en otras',
  },
  {
    id: 36203,
    name: '36203 - Otros productos de vidrio no clasificados en otra',
  },
  {
    id: 35299,
    name: '35299 - Otros productos químicos diversos no clasificados',
  },
  { id: 93332, name: '93332 - Otros profesionales de la salud' },
  { id: 11205, name: '11205 - Otros servicios agrícolas' },
  { id: 83269, name: '83269 - Otros servicios de ingeniería' },
  {
    id: 95191,
    name: '95191 - Otros servicios de reparación no clasificados en o',
  },
  {
    id: 81042,
    name: '81042 - Otros servicios financieros (excluye accionistas,',
  },
  {
    id: 95991,
    name: '95991 - Otros servicios personales no clasificados en otra',
  },
  {
    id: 83291,
    name: '83291 - Otros servicios prestados a empresas no especifica',
  },
  {
    id: 83245,
    name: '83245 - Otros servicios técnicos relacionados con la const',
  },
  {
    id: 71131,
    name: '71131 - Otros servicios terrestres de transporte de pasaje',
  },
  { id: 11115, name: '11115 - Papas' },
  { id: 36204, name: '36204 - Parabrisas y vidrios para vehículos' },
  { id: 94904, name: '94904 - Parques y salas de atracciones' },
  { id: 81049, name: '81049 - Participación de socios de empresas' },
  { id: 31173, name: '31173 - Pastelería' },
  { id: 93317, name: '93317 - Pedicuros (Podólogos)' },
  { id: 95911, name: '95911 - Peluquerías y salones de belleza' },
  { id: 23033, name: '23033 - Pequeña minería del cobre' },
  { id: 94161, name: '94161 - Periodistas' },
  {
    id: 13011,
    name: '13011 - Pesca de altura, litoral costera y en estuarios',
  },
  { id: 62141, name: '62141 - Pescaderías y mariscos' },
  { id: 71161, name: '71161 - Playas y edificios de estacionamientos' },
  { id: 62412, name: '62412 - Prendas de vestir (excepto calzado)' },
  {
    id: 31115,
    name: '31115 - Preparación de fiambres, embutidos y conservas de',
  },
  { id: 32321, name: '32321 - Preparación y teñido de pieles' },
  { id: 35222, name: '35222 - Preparados farmacéuticos para animales' },
  { id: 83212, name: '83212 - Procuradores' },
  { id: 31154, name: '31154 - Producción de harina de pescado' },
  {
    id: 38512,
    name: '38512 - Producción de instrumentos y suministros de cirugí',
  },
  { id: 11123, name: '11123 - Producción de leche' },
  { id: 94111, name: '94111 - Producción de películas cinematográficas' },
  { id: 22001, name: '22001 - Producción de petróleo crudo y gas natural' },
  { id: 41021, name: '41021 - Producción y distribución de gas' },
  {
    id: 41031,
    name: '41031 - Producción y distribución de vapor y agua caliente',
  },
  { id: 94141, name: '94141 - Productores teatrales' },
  { id: 61311, name: '61311 - Productos alimenticios' },
  { id: 62171, name: '62171 - Puestos de pan (sólo venta de pan)' },
  { id: 35512, name: '35512 - Recauchaje' },
  {
    id: 11201,
    name: '11201 - Recolección, empacado, trilla, descaramiento y des',
  },
  { id: 38434, name: '38434 - Rectificación de motores' },
  { id: 35201, name: '35201 - Refinería de petróleo' },
  {
    id: 81041,
    name: '81041 - Rentistas de capitales mobiliarios, cuyo principal',
  },
  { id: 95131, name: '95131 - Reparación de automóviles y motocicletas' },
  {
    id: 95111,
    name: '95111 - Reparación de calzado y otros artículos de cuero',
  },
  { id: 95141, name: '95141 - Reparación de relojes y joyas' },
  { id: 11302, name: '11302 - Repoblación de animales' },
  {
    id: 61412,
    name: '61412 - Representantes y distribuidores de productos quími',
  },
  { id: 13041, name: '13041 - Reproducción de peces y mariscos' },
  { id: 63212, name: '63212 - Residenciales y casas de pensión' },
  {
    id: 63111,
    name: '63111 - Restaurantes, parrilladas, bares, cantinas, clubes',
  },
  { id: 62417, name: '62417 - Ropa interior, pijamas, medias y calcetines' },
  { id: 62131, name: '62131 - Rotiserías y fiambrerías' },
  { id: 11202, name: '11202 - Roturación y siembra' },
  { id: 62433, name: '62433 - Sábanas, cubrecamas, manteles, toallas, etc.' },
  { id: 94902, name: '94902 - Salas de billar, bowling, etc.' },
  { id: 29022, name: '29022 - Salitre natural' },
  { id: 95913, name: '95913 - Salones de masajes, baños turcos, saunas' },
  {
    id: 63112,
    name: '63112 - Salones de té, boites, cabarets, discotheques, dri',
  },
  { id: 62556, name: '62556 - Sanitarios' },
  { id: 62557, name: '62557 - Semillas, abonos y plaguicidas' },
  { id: 11151, name: '11151 - Sericultura' },
  {
    id: 71213,
    name: '71213 - Servicio relacionado con el transporte por agua',
  },
  {
    id: 95934,
    name: '95934 - Servicios de buffet y arriendo de locales para mat',
  },
  { id: 96002, name: '96002 - SERVICIOS DE CAPACITACION' },
  { id: 63113, name: '63113 - Servicios de comida preparada' },
  { id: 83221, name: '83221 - Servicios de contabilidad y auditorías' },
  {
    id: 83231,
    name: '83231 - Servicios de procesamientos de datos y de computac',
  },
  { id: 83251, name: '83251 - Servicios de publicidad' },
  { id: 92001, name: '92001 - Servicios de saneamiento y similares' },
  { id: 83271, name: '83271 - Servicios de taquimecanografía' },
  { id: 95301, name: '95301 - Servicios domésticos' },
  { id: 12102, name: '12102 - Servicios forestales' },
  { id: 83244, name: '83244 - Servicios geológicos y de prospección' },
  { id: 83211, name: '83211 - Servicios jurídicos (abogados)' },
  { id: 71312, name: '71312 - Servicios relacionados' },
  {
    id: 93991,
    name: '93991 - Servicios sociales y comunales conexos, no clasifi',
  },
  { id: 93503, name: '93503 - Sindicatos' },
  {
    id: 81023,
    name: '81023 - Sociedades de inversión y administración de fondos',
  },
  { id: 62416, name: '62416 - Sombreros, guantes, pañuelos y corbatas' },
  { id: 62103, name: '62103 - Supermercados' },
  { id: 11113, name: '11113 - Tabaco' },
  {
    id: 32126,
    name: '32126 - Talleres de despuntes, plizados y encarrujados par',
  },
  { id: 95121, name: '95121 - Talleres de reparaciones eléctricas' },
  { id: 62432, name: '62432 - Tapicerías, alfombras, cortinas' },
  {
    id: 32112,
    name: '32112 - Tejidos (se excluye prendas de vestir de lana, que',
  },
  { id: 62413, name: '62413 - Tiendas de género, telas y sedas' },
  { id: 32113, name: '32113 - Tintorerías industriales y acabado de textiles' },
  { id: 31216, name: '31216 - Tostado y molienda de café' },
  { id: 71311, name: '71311 - Transporte aéreo' },
  { id: 71141, name: '71141 - Transporte de carga por carretera' },
  { id: 71111, name: '71111 - Transporte ferroviario y servicios conexos' },
  { id: 71211, name: '71211 - Transporte oceánico o de cabotaje' },
  { id: 71212, name: '71212 - Transporte por vías de navegación interior' },
  { id: 71121, name: '71121 - transporte urbano, suburbano e interurbano' },
  { id: 93105, name: '93105 - Universidades' },
  { id: 83102, name: '83102 - Urbanización y loteos' },
  { id: 62558, name: '62558 - Vehículos motorizados y sus repuestos' },
  {
    id: 62559,
    name: '62559 - Vehículos no motorizados y sus repuestos (excepto',
  },
  { id: 62104, name: '62104 - Venta de café y té' },
  { id: 62102, name: '62102 - Venta de galletas' },
  { id: 62414, name: '62414 - Venta de lana' },
  { id: 62151, name: '62151 - Venta de leche y productos lácteos' },
  { id: 62161, name: '62161 - Verdulerías' },
  { id: 93321, name: '93321 - Veterinarios' },
  { id: 11131, name: '11131 - Vid' },
  { id: 62560, name: '62560 - Yerberías' },
];
