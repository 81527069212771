import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions, MenuItem, Select} from '@mui/material'
import { handleError } from '../../../../../../../lib/functions/errors';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { addFinancialStatementNode, editFinancialStatementNode } from '../../../../../../../lib/api';
import { validateParams } from '../../../../../../../lib/functions/utils';

function EditNode(props: any) {
  const {
    setLoading,
    dispatch,
    activateSuccess,
    setFailed,
    setOpenEditDialog,
    openEditDialog,
    handleInputChange,
    selectedNode,
    fatherOptions,
    fetchSetupData,
    newNode,
    treeData,
    setNewNode
  } = props
  const [formErrorMessage, setFormErrorMessage] = useState(''); // ... your state setup for formErrorMessage
  const validateExisting = () => {
    if (fatherOptions.find((x: any) => x.key === selectedNode.key)) return false

    return true
  }
  const handleEditDialog = async() => {
    if (!validateParams(selectedNode, ['financialStatementsTreeTypeId', 'father', 'key'], alert)) return 
    if (newNode && !validateExisting()) {
      return alert('Cuenta ya existe')
    }

    setLoading(true)
    try{
      newNode 
      ? await addFinancialStatementNode(dispatch, selectedNode) 
      : await editFinancialStatementNode(dispatch, selectedNode)
      await fetchSetupData()
      activateSuccess(`Se ha ${newNode ? 'creado' : 'actualizado'} la cuenta.`)
    } catch (error: any) {
      console.log(error)
      setFailed(true)
      handleError(error)
    }
    setLoading(false)
    setOpenEditDialog(false);
  }

  const handleOnClose = () => {
    setOpenEditDialog(false)
    setNewNode(false)
  }
  return (
    <Dialog open={openEditDialog} onClose={() => handleOnClose()}>
    <DialogTitle>{newNode ? 'Agregar cuenta':'Editar cuenta'}</DialogTitle>
    <DialogContent style={{paddingTop: 10}}>
      {newNode && 
        <TextField
          label="Tipo de balance"
          select
          name="financialStatementsTreeTypeId"
          value={selectedNode.financialStatementsTreeTypeId} 
          onChange={handleInputChange}
          fullWidth
          style={{marginTop: '10px'}}
        >
          {treeData.map((x: any) => <MenuItem value={x.id}>{x.name}</MenuItem>)}
          {/* Agrega más opciones según lo necesites */}
        </TextField>
        }
      <TextField
        label="Padre"
        name="father"
        select
        value={selectedNode.father} 
        onChange={handleInputChange}
        fullWidth
        disabled={!selectedNode.financialStatementsTreeTypeId}
        style={{marginTop: '10px'}}

      >
        {fatherOptions.map((x: any) => x.id !== selectedNode.id && <MenuItem value={x.id}>{x.key}</MenuItem>)}
        {/* Agrega más opciones según lo necesites */}
      </TextField>
      <TextField margin="dense" name='key' label="Nombre de la cuenta" fullWidth value={selectedNode.key} onChange={handleInputChange}/>

      <Typography variant="body1">{formErrorMessage}</Typography>
    </DialogContent>
    <DialogActions>
      <CustomButton onClick={() => handleOnClose()} color="secondary">
        Cancelar
      </CustomButton>
      <CustomButton onClick={() => {
        // Handle update logic here
        handleEditDialog();
      }} color="primary">
        Guardar
      </CustomButton>
    </DialogActions>
  </Dialog>
  );
}

export default EditNode;
