import React from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { Typography } from '@mui/material';

const bankruptcyHeaders = [
  { key: 'Rol', label: 'Rol' },
  { key: 'Procedimiento Concursal', label: 'Procedimiento ' },
  { key: 'Deudor', label: 'Deudor' },
  { key: 'Veedor Liquidador Titular', label: 'Veedor Liquidador Titular' },
  { key: 'Nombre Publicación', label: 'Publicación' },
  { key: 'Tribunal', label: 'Tribunal' },
  { key: 'Fecha Publicación', label: 'Publicación' },
];

const BankruptcyResponse = (props: any) => {
  const { response, identifier, searched } = props;

  console.log();

  if (!Object.keys(response).length)
    return (
      <div style={{ padding: '0px' }}>
        <Typography variant="body1">
          {searched ? 'No se encontraron resultados' : ''}{' '}
        </Typography>
      </div>
    );

  return (
    <EnhancedTable
      exportToFile="xlsx"
      dataHeaders={bankruptcyHeaders}
      data={response[identifier] || []}
      rowsPerPageDefault={10}
    />
  );
};

export default BankruptcyResponse;
