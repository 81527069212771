import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

interface BreadCrumb {
  title: string;
  link: string;
}

interface BreadCrumbProps {
  links: BreadCrumb[];
}

export default function BreadCrumb(props: BreadCrumbProps) {
  const { links } = props;

  return (
    <div role="presentation" style={{ paddingTop: 14 }}>
      <Breadcrumbs aria-label="breadcrumb">
        {links.map((breadCrumb) => (
          <Link
            underline="hover"
            color="inherit"
            href={breadCrumb.link}
            key={breadCrumb.title}
            sx={{ fontWeight: 400, fontSize: '30px', color: 'black' }}
          >
            {breadCrumb.title}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}
