import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { createProduct, updateProduct } from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

const EditProductDialog = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    setSuccess,
    setSuccessMessage,
    setFailed,
    setLoading,
    fetchProducts,
    product,
  } = props;
  const dispatch = useDispatch();
  const [requirements, setRequirements] = useState<
    { name: string; description: string }[]
  >(
    product.requirements.map((x: any) => ({
      name: x.name,
      description: x.description,
    })),
  );
  const addEmptyRequirement = () => {
    setRequirements([...requirements, { name: '', description: '' }]);
  };

  const deleteRequirement = (index: number) => {
    const newArray = [...requirements];
    newArray.splice(index, 1);
    setRequirements(newArray);
  };

  const validateBody = () => {
    if (requirements.length === 0) {
      alert('Agrega al menos un requerimiento');
      return false;
    } else {
      for (let i = 0; i < requirements.length; i++) {
        if (!requirements[i].name || !requirements[i].description) {
          alert('Completa todos los campos de los requerimientos');
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateBody()) return;
    setLoading(true);
    try {
      await updateProduct(dispatch, { id: product.id, requirements });
      await fetchProducts();
      setSuccess(true);
      setSuccessMessage('Producto editado correctamente');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Editar producto</DialogTitle>
      <DialogContent>
        <div>
          <div>
            <Typography variant="body1">
              <b>{product.productType}</b>
            </Typography>
            <Typography variant="body1">
              {product.productTypeDescription}
            </Typography>
          </div>
          <div style={{ padding: '8px 0px' }}>
            <Typography variant="body1">
              <b>Requerimientos</b>
            </Typography>
            {requirements.map((x: any, index: any) => (
              <div key={index} style={{ display: 'flex' }}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={5}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nombre del requerimiento"
                      type="text"
                      fullWidth
                      value={x.name}
                      onChange={(e) => {
                        const newArray = [...requirements];
                        newArray[index].name = e.target.value;
                        setRequirements(newArray);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="description"
                      label="Descripción del requerimiento"
                      type="text"
                      fullWidth
                      value={x.description}
                      onChange={(e) => {
                        const newArray = [...requirements];
                        newArray[index].description = e.target.value;
                        setRequirements(newArray);
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <IconButton onClick={() => deleteRequirement(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            ))}
            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={11}></Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                <IconButton onClick={() => addEmptyRequirement()}>
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          Editar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductDialog;
