import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import {
  deleteCollectionManagementState,
  getCollectionManagementStates,
} from '../../../../../../lib/api/collectionManagementStates';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { CircularProgress } from '@mui/material';
import CreateState from './components/CreateState';
import UpdateState from './components/UpdateState';

const CollectionStates: React.FC = () => {
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [reload, setReload] = useState(0);
  const [openUpdateStateModal, setOpenUpdateStateModal] = useState(false);
  const [nameToUpdate, setNameToUpdate] = useState('');
  const [idToUpdate, setIdToUpdate] = useState(0);
  const [managementOptions, setManagementOptions] = useState([]);

  const pageActions: CustomButtonProps[] = [
    {
      action: () => setOpenNew(true),
      name: 'Crear estado',
      color: 'primary',
    },
  ];

  const tableActions = [
    {
      action: (value: number) => {
        handleDelete(value);
      },
      actionLabel: 'Eliminar',
      canShow: () => true,
    },
    {
      action: (value: number) => {
        handleUpdate(value);
      },
      actionLabel: 'Editar',
      canShow: () => true,
    },
  ];

  const handleUpdate = (id: number) => {
    const state: any = states.find((state: any) => state.id === id);
    if (state) {
      console.log(state.name);
      setNameToUpdate(state.name);
      setIdToUpdate(id);
      setManagementOptions(state.collectionManagementOptions);
      setOpenUpdateStateModal(true);
    } else {
      alert('Error inesperado, intente nuevamente');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteCollectionManagementState(dispatch, id);
      setReload(reload + 1);
    } catch (e) {
      alert('Error inesperado, intente nuevamente');
    }
  };

  const getStates = async () => {
    const states = await getCollectionManagementStates(dispatch);
    const data = states.map((state: any) => {
      return {
        id: state.id,
        name: state.name,
        optionsAmount: state.collectionManagementOptions.length,
      };
    });
    setData(data);
    setStates(states);
  };

  useEffect(() => {
    setLoading(true);
    getStates();
    setLoading(false);
  }, [reload]);

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los estados de cobranza."
        variant="body1"
        buttons={pageActions}
        type="subHeader"
      />
      {loading && <CircularProgress />}
      {!loading && (
        <EnhancedTable
          dataHeaders={[
            { key: 'name', label: 'Nombre estado' },
            {
              key: 'optionsAmount',
              label: 'Cantidad de sub-tipos',
              type: 'chip',
            },
          ]}
          data={data}
          actions={tableActions}
          showActions
          rowsPerPageDefault={10}
        />
      )}
      <CreateState
        open={openNew}
        setOpen={setOpenNew}
        reload={reload}
        setReload={setReload}
      />
      <UpdateState
        open={openUpdateStateModal}
        setOpen={setOpenUpdateStateModal}
        reload={reload}
        setReload={setReload}
        currentName={nameToUpdate}
        id={idToUpdate}
        managementOptions={managementOptions}
      />
    </div>
  );
};

export default CollectionStates;
