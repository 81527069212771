import React, { createContext, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import Sidebar from '../SiderBar';

const styles = createStyles({
  layoutWrapper: {
    display: 'flex',
    height: '100vh', // Covers the entire viewport height
  },
  content: {
    width: '100%',
    flexGrow: 1, // Allows it to grow and consume available space
    display: 'flex', // Added to facilitate flex-based layout
    flexDirection: 'column', // Stack children vertically
  },
  layout: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '0px 20px',
    whiteSpace: 'nowrap',
    backgroundColor: '#F8FAFC',
    boxSizing: 'border-box',
  },
  loading: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});
interface ILoadingContext {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export const LoadingContext = createContext<ILoadingContext | null>(null);

interface LayoutProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ classes, children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <div className={classes.layoutWrapper}>
        <Sidebar />
        <div className={classes.content}>
          <div className={`${classes.layout}`}>
            {loading ? <CircularProgress /> : children}
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
};

export default withStyles(styles)(Layout);
