import React from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { ContactForm } from '../../../../../../BusinessProfile/Profile/components/Contacts';

const ConfirmInvoiceContactForm = (props: any) => {
  const { newContact, setNewContact, contacts, setContact, contact } = props;
  return (
    <div>
      {newContact ? (
        <div>
          {!contacts.length ? (
            <Typography variant="body1" padding="4px 0px">
              No encotramos contactos para este pagador, a continuación agrega
              uno:
            </Typography>
          ) : (
            <Typography variant="body1" padding="10px 0px">
              Nuevo contacto{' '}
              {contacts.length && (
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => setNewContact(false)}
                >
                  o elige uno existente
                </span>
              )}
            </Typography>
          )}
          <ContactForm
            selectedContact={contact}
            setSelectedContact={setContact}
            tiny
          />
        </div>
      ) : (
        <div>
          <Typography variant="body1" padding="10px 0px">
            Selecciona un contacto{' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => setNewContact(true)}
            >
              o agrega uno nuevo
            </span>
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="filter-label">{'Contacto'}</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={contact.id}
              onChange={(e) => {
                const contact = contacts.find(
                  (x: any) => x.id === e.target.value,
                );
                setContact(contact);
              }}
              label={'Contacto'}
            >
              {contacts.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default ConfirmInvoiceContactForm;
