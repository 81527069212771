import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Download } from '@mui/icons-material';
import UploaderInLine from '../../designSystem/UploaderInLine';
import { getRequiredFiles, uploadFilesFromRequest } from '../../api/files';
import CenteredCircularProgress from '../../designSystem/CircularProgress';
import { AuthState } from '../../../services/redux/authSlice';
import {
  expressDownloadFilesFromRequest,
  expressGetRequiredFiles,
  expressUploadFilesFromRequest,
} from '../../api/express/files';
import { makeStyles, createStyles } from '@mui/styles';
import { formatDateToLocal } from '../../designSystem/Table/cellFormat';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '4px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
  }),
);

const firstCellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '4px 16px',
};

export const RequirementItem = (props: any) => {
  const { name, status, requestId, setUp, requestType, fileName, updatedAt } =
    props;
  const [file, setFile] = useState<any>(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [miniLoading, setMiniLoading] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const handleDownload = async (fileName: any) => {
    try {
      if (!user?.clientId) {
        throw new Error('No se ha encontrado el clientId');
      }
      const data = {
        fileName: fileName,
        requestType,
      };
      setLoading(true);

      const response = await expressDownloadFilesFromRequest(
        dispatch,
        data,
        user.clientId,
      );
      if (response) {
        window.open(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadFiles = async () => {
    setMiniLoading(true);
    try {
      const formData = new FormData();
      // Change the file Name to name prop
      const newName = `${name}.${file.name.split('.').pop()}`;
      const fileToUpload = new File([file], newName, { type: file.type });
      formData.append('files', fileToUpload);
      console.log('fileToUpload', fileToUpload);
      if (user?.express) {
        if (!user?.clientId) {
          throw new Error('No se ha encontrado el clientId');
        }
        await expressUploadFilesFromRequest(
          dispatch,
          formData,
          {
            requestId,
            requestType,
          },
          user?.clientId,
        );
      } else {
        await uploadFilesFromRequest(dispatch, formData, {
          requestId,
          requestType,
        });
      }
      await setUp();
      // setSuccessOpen('Se han subido tus archivos con éxito');
    } catch (error) {
      console.log(error);
    }
    setMiniLoading(false);
  };
  return (
    <TableRow>
      <TableCell style={firstCellStyle}>{name}</TableCell>
      <TableCell>
        {updatedAt ? formatDateToLocal(updatedAt, false) : 'No hay datos'}
      </TableCell>
      <TableCell style={{ textAlign: 'right' }}>
        {miniLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {!status ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <UploaderInLine
                  handleFileChange={handleFileChange}
                  uploadedFileName={uploadedFileName}
                  handleSave={uploadFiles}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <IconButton
                  onClick={() => handleDownload(fileName)}
                  disabled={loading}
                >
                  <Download color="primary" />
                </IconButton>
                <UploaderInLine
                  handleFileChange={handleFileChange}
                  uploadedFileName={uploadedFileName}
                  handleSave={uploadFiles}
                />
              </div>
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

const UploadList = (props: any) => {
  const { products, requestType, requestId } = props;
  const [loading, setLoading] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const classes = useStyles();

  const dispatch = useDispatch();

  const getRequirementsDone = async () => {
    setLoading(true);
    try {
      console.log('user', user);
      if (user?.express) {
        if (!user?.clientId) {
          throw new Error('No se ha encontrado el clientId');
        }
        const response = await expressGetRequiredFiles(
          dispatch,
          requestType,
          requestId,
          products,
          user?.clientId,
        );
        setRequirements(response);
      } else {
        const response = await getRequiredFiles(
          dispatch,
          requestType,
          requestId,
          products,
        );
        setRequirements(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequirementsDone();
  }, []);

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }

  return (
    <div>
      <div style={{ paddingTop: 20 }}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead style={{ background: '#efefef' }}>
              <TableRow>
                <TableCell style={firstCellStyle}>Documento</TableCell>
                <TableCell>Subido el</TableCell>
                <TableCell style={{ textAlign: 'right' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {requirements.map((requirement: any, index: number) => (
                <RequirementItem
                  key={index}
                  name={requirement.name}
                  status={requirement.status}
                  fileName={requirement.file}
                  updatedAt={requirement.updatedAt}
                  setUp={getRequirementsDone}
                  requestType={requestType}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UploadList;
