import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../../../lib/designSystem/Alert';
import { getResellPayments } from '../../../../../../../../../lib/api/reSell/factoring';
import {
  formatDate,
  validatePermissions,
} from '../../../../../../../../../lib/functions/utils';
import FundPayment from './components/FundPayment';
import { AuthState } from '../../../../../../../../../services/redux/authSlice';

// import Management from './components/Management';
// import NewManagement from './components/NewManagement';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'date', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
];

const ResellPayments = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { resell } = props;
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [payments, setPayments] = useState<any>([]);
  const [fundPayment, setFundPayment] = useState<any>({});
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const fetchFundPayments = async () => {
    setLoading(true);
    try {
      const response = await getResellPayments(dispatch, resell.id);
      setPayments(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleRegisterPayment = () => {
    console.log('resell', resell);
    setFundPayment({
      amount: resell.document.amount,
      date: formatDate(new Date()),
    });
    setOpenPaymentModal(true);
    // setOpenModal(true);
  };

  const canRegisterPayment = () => {
    const amountRegistered = payments.reduce(
      (acc: number, payment: any) => acc + payment.amount,
      0,
    );
    return amountRegistered < resell.document.amount;
  };

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleRegisterPayment(),
      disabled:
        !canRegisterPayment() ||
        !validatePermissions(user, 'financing_fundSale_new'),
      name: 'Nuevo Pago',
      color: 'primary',
    },
  ];

  useEffect(() => {
    fetchFundPayments();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todos los avisos de pagos registrados para este fondo"
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={payments}
        rowsPerPageDefault={50}
      />
      {openPaymentModal && (
        <FundPayment
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          resell={resell}
          setSuccess={setSuccess}
          setUp={fetchFundPayments}
          fundPayment={fundPayment}
          setFundPayment={setFundPayment}
        />
      )}

      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default ResellPayments;
