import { requestHandlerPublic } from '../../../api';

export const executeRutificator = async (
  companyRecieverId: string,
  ruts: string[],
) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: `public/express/${companyRecieverId}/rutificator`,
    data: { ruts },
  });
  if (response) return response.data;
};
