import { requestHandler } from '../../api';

export const pauseBusinessCompanyRelation = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/enrollment/businessCompanyRelation/pause`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const resumeBusinessCompanyRelation = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/enrollment/businessCompanyRelation/resume`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
