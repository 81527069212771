import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  completeFutureCollectionManagement,
  getFutureCollectionManagementsByCompanyId,
} from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  flattenArrayObject,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewManagement from '../Documents/components/NewManagement';

const adminHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio documento', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const regularHeaders: { key: string; label: string; type: any }[] = [
  { key: 'name', label: 'Nombre', type: '' },
  { key: 'issuedDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'paymentDate', label: 'Fecha pago', type: 'date' },
  { key: 'stakeholderName', label: 'Deudor', type: '' },
  { key: 'folio', label: 'Folio factura', type: '' },
  { key: 'amountRemaining', label: 'Monto adeudado', type: 'money' },
];

const NextManagements: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collectionsData, setCollectionsData] = useState<any[]>([]);
  const [filters, setFilters] = useState<
    { key: string; options: string[]; label: string }[]
  >([]);
  const [refresh, setRefresh] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [selectedCompanyId, setSelecetedCompanyId] = useState(0);
  const [open, setOpen] = useState(false);
  const [managements, setManagements] = useState<any[]>([]);

  const deleteCollection = async (id: number) => {
    const request = await completeFutureCollectionManagement(dispatch, id);
  };

  const handleManagement = async (id: number) => {
    const request = collectionsData.find((x: any) => x.id === id) || {
      id: '',
      collectionManagements: [],
      folio: 0,
    };
    console.log(request);
    setManagements(request.collectionManagements);
    setSelectedInvoice(request.id);
    setSelectedRequest(request);
    setOpen(true);
    setOpenModal(true);
  };

  const canShowOpenDetails = (id: number) => {
    return true;
  };

  const actions = [
    {
      action: handleManagement,
      actionLabel: 'Completar',
      canShow: canShowOpenDetails,
    },
  ];

  const fetchCollections = async () => {
    const results: any[] =
      flattenArrayObject(
        await getFutureCollectionManagementsByCompanyId(dispatch),
      ) || [];
    setCollectionsData(results);
    const businessesOptions = [
      ...new Set(results.map((x: any) => x.invoice_business_name)),
    ];
    const ownerOptions = [
      ...new Set(results.map((x: any) => x.userOwner_name)),
    ];
    const stakeHolderOptions = [
      ...new Set(results.map((x: any) => x.invoice_stakeholder_name)),
    ];
    if (validatePermissions(user, 'collection_admin')) {
      setFilters([
        {
          key: 'invoice_business_name',
          label: 'Cliente',
          options: businessesOptions,
        },
        {
          key: 'invoice_stakeholder_name',
          label: 'Deudor',
          options: stakeHolderOptions,
        },
        { key: 'userOwner_name', label: 'Usuario', options: ownerOptions },
      ]);
    } else {
      setFilters([
        {
          key: 'invoice_business_name',
          label: 'Cliente',
          options: businessesOptions,
        },
        {
          key: 'invoice_stakeholder_name',
          label: 'Deudor',
          options: stakeHolderOptions,
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCollections();
  }, [refresh]);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTable
        caption={'Próximas gestiones de cobranza'}
        dataHeaders={
          validatePermissions(user, 'collection_admin')
            ? adminHeaders
            : regularHeaders
        }
        data={collectionsData}
        rowsPerPageDefault={100}
        actions={actions}
        showActions
      />
      <CustomAlert
        open={openAlert}
        title="Gestión completada"
        onClose={() => {
          setOpenAlert(false);
        }}
        setOpen={setOpenAlert}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
      <NewManagement
        openModal={openModal}
        setOpenModal={setOpenModal}
        invoiceId={selectedInvoice}
        setOpenAlert={setOpenAlert}
        setInvoiceId={setSelectedInvoice}
        request={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        companyId={selectedCompanyId}
        setCompanyId={setSelecetedCompanyId}
      />
    </div>
  );
};

export default NextManagements;
