import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import {
  formatDate,
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../../lib/functions/utils';
import {
  createFactoringExtension,
  getAvailableDocumentsForFactoringExtension,
  getDocumentWithExtensionData,
  getFactoringExtension,
  updateFactoringExtensionParams,
} from '../../../../../../../../../lib/api/extension/factoring';
import { Cancel, Edit } from '@mui/icons-material';
import { isDateAWeekDay } from '../../../../../../../../../lib/functions/dates';

const DocuemntFormParameters = (props: any) => {
  const {
    document,
    extension,
    setExtension,
    setCanModifyParams,
    canModifiyParams,
  } = props;
  const calculateDays = (start: Date, end: Date) => {
    const days = Math.floor(
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
    );
    return days;
  };
  const [days, setDays] = useState(
    calculateDays(new Date(document.paymentDate), new Date(extension.dueDate)),
  );
  const handleChangeDate = (e: any) => {
    const days = calculateDays(
      new Date(document.paymentDate),
      new Date(e.target.value),
    );
    if (!isDateAWeekDay(new Date(e.target.value))) {
      alert('La fecha de vencimiento debe ser un día hábil');
      return;
    }
    if (days <= 0) {
      alert(
        'La fecha de vencimiento no puede ser menor a la fecha de liberación',
      );
      return;
    } else {
      setDays(days);
      setExtension({
        ...extension,
        dueDate: e.target.value,
        rateCost:
          (document.documentRemainingAmount *
            parseFloat(extension.rate) *
            days) /
          30 /
          100,
      });
    }
  };

  const handleChangeRate = (e: any) => {
    if (isNaN(e.target.value)) return;
    const numbered = parseFloat(e.target.value);
    if (numbered > 100 || numbered < 0) return;
    setExtension({
      ...extension,
      rate: e.target.value,
      rateCost: (document.documentRemainingAmount * numbered * days) / 30 / 100,
    });
  };

  const handleChangeVariableCost = (e: any) => {
    if (isNaN(e.target.value)) return;
    const numbered = parseFloat(e.target.value);
    if (numbered > 100 || numbered < 0) return;

    setExtension({
      ...extension,
      operationCostVariable:
        (document.documentRemainingAmount * numbered) / 100,
      operationCostRateVariable: e.target.value,
    });
  };

  return (
    <div style={{ margin: '8px 0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '16px 0px 0px',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Datos de la prórroga
        </Typography>
        {extension.status === 'En revisión' ? (
          <div>
            <IconButton
              onClick={() => setCanModifyParams(!canModifiyParams)}
              style={{ marginLeft: 'auto' }}
            >
              {canModifiyParams ? <Cancel /> : <Edit />}
            </IconButton>
          </div>
        ) : null}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
            Parámetros
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Tasa"
                value={extension.rate}
                onChange={(e) => handleChangeRate(e)}
                disabled={!canModifiyParams}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Fecha de vencimiento"
                type="date"
                value={extension.dueDate}
                disabled={!canModifiyParams}
                onChange={(e) => {
                  handleChangeDate(e);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Costo fijo"
                value={formatMoney(extension.operationCostFixed)}
                disabled={!canModifiyParams}
                onChange={(e) =>
                  setExtension({
                    ...extension,
                    operationCostFixed: formatMoneyBack(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Costo variable"
                disabled={!canModifiyParams}
                value={extension.operationCostRateVariable}
                onChange={(e) => handleChangeVariableCost(e)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ borderLeft: '0.5px solid #000', paddingLeft: 16 }}>
            <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
              Resumen
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Costo tasa ({days} Días)
              </Typography>
              <Typography variant="body2">
                {formatMoney(extension.rateCost)}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Costo operación + IVA
              </Typography>
              <Typography variant="body2">
                {formatMoney(
                  (extension.operationCostFixed +
                    extension.operationCostVariable) *
                    1.19,
                )}
              </Typography>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Total
              </Typography>
              <Typography variant="body2">
                {formatMoney(
                  (extension.operationCostFixed +
                    extension.operationCostVariable) *
                    1.19 +
                    extension.rateCost,
                )}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
          Observaciones
        </Typography>
        <TextField
          fullWidth
          disabled={!canModifiyParams}
          multiline
          rows={4}
          value={extension.observation}
          onChange={(e) =>
            setExtension({ ...extension, observation: e.target.value })
          }
        />
      </div>
    </div>
  );
};

const DocuemntPreviewData = (props: any) => {
  const { document, setSearched, edit } = props;
  return (
    <div style={{ margin: '0px 0px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold" margin={'8px 0px 0px'}>
          Datos del documento
        </Typography>
        {!edit && (
          <Typography
            variant="caption"
            fontWeight="bold"
            padding={'8px 8px'}
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => setSearched(false)}
          >
            Cambiar
          </Typography>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Folio</Typography>
          <Typography variant="body2">{document.folio}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Liberación Original</Typography>
          <Typography variant="body2">
            {formatDate(document.startingPaymentDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Liberación actual</Typography>
          <Typography variant="body2">
            {formatDate(document.paymentDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Monto documento</Typography>
          <Typography variant="body2">
            {formatMoney(document.amount)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Abonos</Typography>
          <Typography variant="body2">
            {formatMoney(document.amountConciliated)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Saldo deuda</Typography>
          <Typography variant="body2">
            {' '}
            {formatMoney(document.totalRemaining)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const ExtensionMain = (props: any) => {
  const {
    extension,
    setExtension,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    edit,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [document, setDocument] = useState<any>([]);
  const [searched, setSearched] = useState(false);
  const [canModifyParams, setCanModifyParams] = useState(!edit);

  const validParams = () => {
    return true;
  };

  const handleChangeDocument = (event: any, newValue: any) => {
    setDocument(newValue);
    setExtension({ ...extension, documentId: newValue.id });
  };

  const handleSearchData = async () => {
    setLoading(true);
    try {
      const response = await getDocumentWithExtensionData(
        dispatch,
        document.id,
      );
      setDocument(response);
      setExtension({
        ...extension,
        dueDate: formatDate(response.paymentDate),
      });
      setSearched(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      console.log(extension);
      if (edit) {
        await updateFactoringExtensionParams(dispatch, extension.id, {
          ...extension,
          rate: parseFloat(extension.rate),
          dueDate: new Date(extension.dueDate),
          operationCostRateVariable: parseFloat(
            extension.operationCostRateVariable,
          ),
        });
        setOpen(false);
        setSuccess('Prórroga actualizada correctamente');
        await setUp();
        return;
      } else {
        await createFactoringExtension(dispatch, {
          ...extension,
          rate: parseFloat(extension.rate),
          dueDate: new Date(extension.dueDate),
          startDate: new Date(document.startingPaymentDate),
          operationCostRateVariable: parseFloat(
            extension.operationCostRateVariable,
          ),
        });
      }
      setOpen(false);
      setSuccess('Prórroga creada correctamente');
      await setUp();
    } catch (error) {
      console.log(error);
      setFailure('Error al crear la prórroga');
    }
    setLoading(false);
  };

  const setUpSuggestions = async () => {
    setLoading(true);
    try {
      const response =
        await getAvailableDocumentsForFactoringExtension(dispatch);
      console.log(response);
      setSuggestions(response.documentsProcessed);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpDialog = async () => {
    if (edit) {
      setLoading(true);
      try {
        const response = await getFactoringExtension(dispatch, extension.id);
        setExtension({
          ...response.extension,
          dueDate: formatDate(response.extension.dueDate),
        });
        setDocument(response.document);
        setSearched(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    } else {
      await setUpSuggestions();
    }
  };

  useEffect(() => {
    setUpDialog();
  }, []);

  return (
    <div>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <div>
          {!searched && !edit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Autocomplete
                style={{ margin: '8px 0px' }}
                options={suggestions}
                getOptionLabel={(option: any) =>
                  `${option.folio} | ${option.businessName} | ${option.stakeholderName}`
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Busca por folio, cliente o deudor"
                  />
                )}
                onChange={handleChangeDocument}
                fullWidth
              />
              <div style={{ padding: '0px 16px' }}>
                <CustomButton
                  onClick={() => handleSearchData()}
                  color="primary"
                >
                  Seleccionar
                </CustomButton>
              </div>
            </div>
          )}
          {searched && (
            <div>
              <DocuemntPreviewData
                document={document}
                setSearched={setSearched}
                edit={edit}
              />
              <DocuemntFormParameters
                document={document}
                extension={extension}
                setExtension={setExtension}
                canModifiyParams={canModifyParams}
                setCanModifyParams={setCanModifyParams}
                edit={edit}
              />
            </div>
          )}
          <DialogActions>
            <CustomButton onClick={() => setOpen(false)} color="secondary">
              Salir
            </CustomButton>
            {!edit || extension.status === 'En revisión' ? (
              <CustomButton
                onClick={() => handleSubmit()}
                color="primary"
                disabled={!canModifyParams}
              >
                Guardar
              </CustomButton>
            ) : null}
          </DialogActions>
        </div>
      )}
    </div>
  );
};

export default ExtensionMain;
