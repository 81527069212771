import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import BusinessProfile from './pages/BusinessProfile';

import Layout from './layouts/layout';
import Settings from './pages/Settings';
import { useSelector } from 'react-redux';
import { AuthState } from './services/redux/authSlice';
import UserProfile from './pages/UserProfile';
import EnrolationForm from './pages/PublicLandings/EnrolationForm';
import FinancialStatementsAssign from './pages/FinancialStatementsAssign';
import {
  validateCompanyTypes,
  validatePermissions,
} from './lib/functions/utils';
import Home from './pages/Home';
import FactoringRequests from './pages/Finance/Components/Financing';
import Assistant from './pages/Assistant';
import ConfirmQuote from './pages/PublicLandings/ConfirmQuote';
import FinancialStatementsEmptyer from './pages/MyFinancialStatements';
import MyAccounts from './pages/MyAccounts';
import SIIProfile from './pages/BusinessProfile/SII';
import ConfirmUserRegistrationAdmin from './pages/PublicLandings/ConfirmUseRegistrationAdmin';
import Summary from './pages/BusinessProfile/FinancialStatements/Components/Summary';
import DecisionTreeCreate from './pages/Settings/Components/Risk/Components/DecisionEngines/components/Create';
import Notifications from './pages/Notifications';
import Contacts from './pages/Network';
import Finance from './pages/Finance';
import PaymentLink from './pages/PublicLandings/PaymentLink';
import InvoiceInformation from './pages/PublicLandings/PaymentLink/components/InvoiceInformation';
import CreditHubProEvaluations from './pages/Evaluations';
import Consultor from './pages/Consultor';
import Integrations from './pages/Integrations';
import { checkFinancingComplete } from './lib/permissionChecks';
import PruffRushSyncs from './pages/PruffRush';

const AppRoutes: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const openSettings = () => {
    if (validatePermissions(user, 'settings')) {
      return true;
    } else if (
      validatePermissions(user, 'settings_users') ||
      validatePermissions(user, 'settings_risk') ||
      validatePermissions(user, 'settings_products') ||
      validatePermissions(user, 'settings_finance')
    ) {
      return true;
    }
    return false;
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route path="/factoring/simulation/:code" element={<ConfirmQuote />} />
        <Route path="/submitCredentials/:id" element={<EnrolationForm />} />
        <Route
          path="/home/:page"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/profile"
          element={
            <Layout>
              <UserProfile />
            </Layout>
          }
        />
        <Route
          path="/companies/:page"
          element={
            <Layout>
              <MyAccounts />
            </Layout>
          }
        />
        <Route
          path="/consultor"
          element={
            <Layout>
              <Consultor />
            </Layout>
          }
        />
        {validateCompanyTypes(user, 'Integrations') &&
          validatePermissions(user, 'integrations_manage') && (
            <Route
              path="/integrations/"
              element={
                <Layout>
                  <Integrations />
                </Layout>
              }
            />
          )}
        {validateCompanyTypes(user, 'Integrations') &&
          validatePermissions(user, 'integrations_manage') && (
            <Route
              path="/integrations/:integration"
              element={
                <Layout>
                  <Integrations />
                </Layout>
              }
            />
          )}
        <Route
          path="/confirmRegistration/createPassword/:code"
          element={<ConfirmUserRegistrationAdmin />}
        />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier"
          element={<PaymentLink />}
        />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier/:invoiceId"
          element={<InvoiceInformation />}
        />
        {(validatePermissions(user, 'financing_visibility_create') ||
          validatePermissions(user, 'financing_visibility_recieve') ||
          validatePermissions(user, 'financing_factoring_recieve') ||
          validatePermissions(user, 'financing_factoring_review') ||
          validatePermissions(user, 'financing_factoring_sent')) && (
          <Route
            path="/financing/:page"
            element={
              <Layout>
                <FactoringRequests />
              </Layout>
            }
          />
        )}
        <Route
          path="/companies/:id/:mainPage/:page"
          element={
            <Layout>
              <BusinessProfile />
            </Layout>
          }
        />
        <Route
          path="/companies/temporary/:id/:mainPage/:page"
          element={
            <Layout>
              <SIIProfile />
            </Layout>
          }
        />
        {validatePermissions(user, 'financial_states_read') && (
          <Route
            path="/financialStatements/business/:id/financial/assginations/:financialStatementId/edit"
            element={
              <Layout>
                <FinancialStatementsAssign />
              </Layout>
            }
          />
        )}
        {validatePermissions(user, 'financial_states_read') && (
          <Route
            path="/financialStatements/business/:id/financial/assginations/results"
            element={
              <Layout>
                <Summary emptyer />
              </Layout>
            }
          />
        )}
        {(validateCompanyTypes(user, 'FinancingHub') ||
          validateCompanyTypes(user, 'AccountantCollections') ||
          validateCompanyTypes(user, 'FactoringQuoting')) &&
          checkFinancingComplete(user) && (
            <Route
              path="/finance/:mainPage/:subPage"
              element={
                <Layout>
                  <Finance />
                </Layout>
              }
            />
          )}
        {validatePermissions(user, 'financial_states_create_assignment') && (
          <Route
            path="/financialStatements/:page"
            element={
              <Layout>
                <FinancialStatementsEmptyer />
              </Layout>
            }
          />
        )}
        {validateCompanyTypes(user, 'CreditHubPro') &&
          validatePermissions(user, 'settings_risk') && (
            <Route
              path="/settings/engines/create"
              element={
                <Layout>
                  <DecisionTreeCreate />
                </Layout>
              }
            />
          )}
        {validateCompanyTypes(user, 'CreditHubPro') &&
          validatePermissions(user, 'settings_risk') && (
            <Route
              path="/settings/engines/edit/:id"
              element={
                <Layout>
                  <DecisionTreeCreate />
                </Layout>
              }
            />
          )}
        {openSettings() && (
          <Route
            path="/settings/:mainPage/:subPage"
            element={
              <Layout>
                <Settings />
              </Layout>
            }
          />
        )}
        {(validateCompanyTypes(user, 'FinancingHub') ||
          validateCompanyTypes(user, 'Accountant')) &&
          (validatePermissions(user, 'sii_read_profile') ||
            validatePermissions(user, 'tgr_read')) && (
            <Route
              path="/network/:page"
              element={
                <Layout>
                  <Contacts />
                </Layout>
              }
            />
          )}
        {validateCompanyTypes(user, 'CreditHubPro') &&
          validatePermissions(user, 'creditHubPro_evaluation_view') && (
            <Route
              path="/creditHubPro/:mainPage/:subPage"
              element={
                <Layout>
                  <CreditHubProEvaluations />
                </Layout>
              }
            />
          )}
        {validateCompanyTypes(user, 'PruffRush') &&
          validatePermissions(user, 'pruffRush_execute') && (
            <Route
              path="/sync"
              element={
                <Layout>
                  <PruffRushSyncs />
                </Layout>
              }
            />
          )}
        {
          <Route
            path="/asisstant"
            element={
              <Layout>
                <Assistant />
              </Layout>
            }
          />
        }
        {
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />
              </Layout>
            }
          />
        }

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
