import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../lib/designSystem/Table';
import PublicationForm from './components/PublicationForm';
import { getIntegrationStatusByInternalName } from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomAlert from '../../../../lib/designSystem/Alert';
import BreadCrumb from '../../../../lib/designSystem/BreadCrumb';
import { useNavigate } from 'react-router-dom';
import {
  getInvoicePublications,
  unpublishInvoiceDebt,
} from '../../../../lib/api/documentPublication';

const headers = [
  { key: 'businessIdentifier', label: 'RUT emisor' },
  { key: 'stakeholderIdentifier', label: 'RUT deudor' },
  { key: 'folio', label: 'Folio' },
  { key: 'docType', label: 'Tipo de documento' },
  { key: 'docCode', label: 'Código de documento' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'createdAt', label: 'Fecha de publicación', type: 'date' },
];

const InvoicePublication: React.FC = () => {
  const [openNewInvoice, setOpenNewInvoice] = React.useState(false);
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [customAlert, setCustomAlert] = useState({
    open: false,
    severity: 'error',
    message: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateIntegrations = async () => {
    try {
      const response = await getIntegrationStatusByInternalName(
        dispatch,
        'cobranza-online',
      );
      return response;
    } catch (error) {
      navigate('/integrations');
    }
  };

  const getInvoicesPublished = async () => {
    setLoading(true);
    const validated = await validateIntegrations();
    if (!validated.status) {
      navigate('/integrations');
    }
    const response = await getInvoicePublications(dispatch);
    setInvoices(response || []);
    setLoading(false);
  };

  const handleUnpublish = async (id: number) => {
    setLoading(true);
    try {
      await unpublishInvoiceDebt(dispatch, id);
      setReload(reload + 1);
      setCustomAlert({
        open: true,
        severity: 'success',
        message: 'Factura despublicada correctamente',
      });
    } catch (error) {
      setCustomAlert({
        open: true,
        severity: 'error',
        message: 'Hubo un problema al despublicar la factura, intente de nuevo',
      });
    }
    setLoading(false);
  };

  const actions = [
    {
      action: handleUnpublish,
      actionLabel: 'Despublicar',
    },
  ];

  useEffect(() => {
    getInvoicesPublished();
  }, [reload]);

  return (
    <div>
      <BreadCrumb
        links={[
          {
            title: 'Integraciones',
            link: '/integrations',
          },
          {
            title: 'Cobranza Online',
            link: '/integrations/cobranza-online',
          },
        ]}
      />
      <HeaderWithActions
        title="Documentos publicados en servicios"
        variant={'h6'}
        buttons={[
          {
            name: 'Publicar',
            action: () => {
              setOpenNewInvoice(true);
            },
            color: 'primary',
          },
        ]}
      />
      {loading && <CenteredCircularProgress />}
      {!loading && (
        <EnhancedTable
          dataHeaders={headers}
          data={invoices}
          showActions
          rowsPerPageDefault={50}
          actions={actions}
        />
      )}
      <PublicationForm
        open={openNewInvoice}
        setOpen={setOpenNewInvoice}
        setReload={setReload}
        reload={reload}
        setCustomAlert={setCustomAlert}
      />
      <CustomAlert
        open={customAlert.open}
        setOpen={() =>
          setCustomAlert({ open: false, severity: 'error', message: '' })
        }
        severity={customAlert.severity}
        title={customAlert.message}
      />
    </div>
  );
};

export default InvoicePublication;
