import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';

const ShoppingCartFactoringRejected = (props: any) => {
  const { setOpenShoppingcart, openRequest } = props;

  return (
    <div>
      <div>
        <CreditLineStatus openRequest={openRequest} audienceType="sent" />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingcart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringRejected;
