import React, { useEffect, useState } from 'react';

import { DialogActions, TextField, Typography } from '@mui/material';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import {
  approveExtension,
  getExtensionFactoringMissingApprovals,
  rejectExtension,
} from '../../../../../../../../../lib/api/extension/factoring';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';

const FactoringExtensionMissingApprovalContainer = (props: any) => {
  const { extension, setOpen, setSuccess, setFailure, setUp } = props;
  const [missingApprovals, setMissingApprovals] = useState<any[]>([]);
  const [comitee, setComitee] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [canApprove, setCanApprove] = useState(false);
  const [reason, setReason] = useState('');

  const dispatch = useDispatch();

  const setUpApprovals = async () => {
    setLoading(true);
    try {
      const response = await getExtensionFactoringMissingApprovals(
        dispatch,
        extension.id,
      );
      setMissingApprovals(response.rolesToApprove);
      setComitee(response.comitee);
      if (response.rolesToApprove.some((role: any) => role.userCanApprove)) {
        setCanApprove(true);
      }
    } catch (error) {
      alert('Error al cargar las aprobaciones faltantes');
    }
    setLoading(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      await approveExtension(dispatch, extension.id, { reason });
      setOpen(false);
      await setUpApprovals();
      await setUp();
      setSuccess('Solicitud aprobada');
    } catch (error) {
      setFailure('Error al aprobar la solicitud');
    }
  };

  const handleReject = async () => {
    setLoading(true);
    try {
      await rejectExtension(dispatch, extension.id, { reason });
      await setUpApprovals();
      await setUp();
      setOpen(false);
      setSuccess('Solicitud rechazada');
    } catch (error) {
      setFailure('Error al rechazar la solicitud');
    }
  };

  useEffect(() => {
    setUpApprovals();
  }, []);

  return (
    <div>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <div>
          <div style={{ padding: '10px 0px' }}>
            <Typography variant="body1">
              <b>
                {comitee
                  ? `Aprobaciones necesarias por ${comitee.name}`
                  : '(Comité pendiente)'}
              </b>
            </Typography>
            <Typography variant="body1">
              {comitee
                ? 'Estas son las aprobaciones que faltan para terminar la revisión de la solicitud.'
                : ' Debes esperar a que se le asigne un comité para poder revisar esta solicitud.'}
            </Typography>
          </div>
          {comitee && (
            <EnhancedTable
              data={missingApprovals}
              dataHeaders={[
                { key: 'name', label: 'Nombre' },
                { key: 'approvedCountRequired', label: 'Requeridas' },
                {
                  key: 'approved',
                  label: 'Revisado',
                  type: 'vission',
                },
                {
                  key: 'namesThatApprovedThisRole',
                  label: 'Aprobado por',
                },
              ]}
              rowsPerPageDefault={5}
            />
          )}
          {canApprove && (
            <div style={{ padding: '10px 0px' }}>
              <Typography variant="body1">
                <b>Observaciones</b>
              </Typography>
              <TextField
                label="Por qué está tomando esta decisión?"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <DialogActions>
                <CustomButton onClick={() => setOpen(false)} color="secondary">
                  Salir
                </CustomButton>
                <CustomButton
                  onClick={() => handleApprove()}
                  color="success"
                  disabled={!reason}
                >
                  Aprobar
                </CustomButton>
                <CustomButton
                  onClick={() => handleReject()}
                  color="error"
                  disabled={!reason}
                >
                  Rechazar
                </CustomButton>
              </DialogActions>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FactoringExtensionMissingApprovalContainer;
