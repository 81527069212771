import React from 'react';
import { Search } from '@mui/icons-material';

import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomButton from '../../../../../../lib/designSystem/Button';

export const DateSearcher = (props: any) => {
  const { startDate, setStartDate, endDate, setEndDate, setUp } = props;

  const handleSearch = async () => {
    // Maximum 30 days
    const days = endDate.diff(startDate, 'days');
    if (days > 31) {
      alert('El rango de fechas no puede ser mayor a 30 días');
      return;
    }
    if (days < 0) {
      alert('La fecha de inicio no puede ser mayor a la fecha de fin');
      return;
    }
    endDate.set('hour', 23);
    await setUp();
  };
  return (
    <Grid container justifyContent="left" spacing={2}>
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
          <DatePicker
            label="Fecha inicio búsqueda"
            value={startDate}
            onChange={(e) => setStartDate(dayjs(e))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
          <DatePicker
            label="Fecha fin de búsqueda"
            value={endDate}
            onChange={(e) => setEndDate(dayjs(e))}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <CustomButton
          onClick={handleSearch}
          color="secondary"
          startIcon={<Search />}
          style={{ marginTop: '10px' }}
        >
          Buscar
        </CustomButton>
      </Grid>
    </Grid>
  );
};
