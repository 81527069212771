import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DialogActions, Typography, Grid } from '@mui/material';
import { formatMoney } from '../../../../../../../../../../lib/functions/utils';
import { Check } from '@mui/icons-material';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { selectFundAsDefault } from '../../../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';

const FundSummary = (props: any) => {
  const { setOpen, fund, handelOpenEditModal, setUp, setSuccess } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSelectFund = async () => {
    setLoading(true);
    try {
      const response = await selectFundAsDefault(dispatch, fund.id);
      if (response) {
        setSuccess('Fondo seleccionado correctamente');
        setOpen(false);
        await setUp();
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <div>
          <Grid container spacing={2} padding={'10px 0px'}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Nombre
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1">{fund.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Rut
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1">{fund.id}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Costo
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1">{fund.cost}%</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Monto
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1">
                {formatMoney(fund.amount)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold">
                Default
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body1">
                {fund.default ? <Check /> : 'No'}
              </Typography>
            </Grid>
          </Grid>{' '}
          <DialogActions>
            {!fund.default && (
              <CustomButton onClick={() => handleSelectFund()} color="success">
                Seleccionar
              </CustomButton>
            )}
            <CustomButton onClick={() => setOpen(false)} color="secondary">
              Salir
            </CustomButton>
            <CustomButton
              onClick={() => handelOpenEditModal(true)}
              color="primary"
            >
              Editar
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </div>
  );
};

export default FundSummary;
