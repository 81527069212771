import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import {
  createSheetAssignation,
  editSheetAssignation,
  getScoringAssgination,
  getScoringMatrix,
  getScoringSheets,
} from '../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../../lib/designSystem/Button';
import { PanoramaFishEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';

interface ScoreAssginationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  scoringSheetAssignationId?: number | null;
  openSuccess: any;
  openFailure: any;
  setUp: any;
  processVariablesToListFormat: any;
}

const CustomTooltip = (tableData: any) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Descripción</TableCell>
        <TableCell>Valor</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map((row: any) => (
        <TableRow key={row.key}>
          <TableCell>{row.description || row.score}</TableCell>
          <TableCell>{row.valueMax}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const ScoreAssgination: React.FC<ScoreAssginationProps> = ({
  open,
  setOpen,
  scoringSheetAssignationId,
  openSuccess,
  openFailure,
  setUp,
  processVariablesToListFormat,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [sheets, setSheets] = React.useState<any[]>([]);
  const [sheetSelectedId, setSheetSelectedId] = React.useState<any>(null);
  const [sheetSelected, setSheetSelected] = React.useState<any>(null);
  const [sheetAssignationVariables, setSheetAssignationVariables] =
    React.useState<any>(null);
  const [editing, setEditing] = React.useState<any>(false);
  const [rating, setRating] = React.useState<any>(null);
  const [score, setScore] = React.useState<any>(null);
  const [ratingMatrix, setRatingMatrix] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [editedAssignations, setEditedAssignations] = React.useState<any>([]);

  const handleSubmit = async () => {
    console.log(sheetAssignationVariables);
    setLoading(true);
    try {
      if (scoringSheetAssignationId) {
        const scoringSheetAssginations = sheetAssignationVariables
          .filter((x: any) => editedAssignations.includes(x.id))
          .map((x: any) => {
            return {
              id: x.id,
              inputScore: Number(x.inputScore),
              observation: x.observation,
            };
          });
        await editSheetAssignation(dispatch, { scoringSheetAssginations });
        await setUp();
        openSuccess('Se ha editado la asignación de score exitosamente');
        setOpen(false);
      } else {
        const scoringSheetAssginations = sheetAssignationVariables.map(
          (x: any) => {
            return {
              scoringSheetVariableId: x.id,
              inputScore: Number(x.inputScore),
              observation: x.observation,
            };
          },
        );
        const payload = {
          scoringSheetId: sheetSelected.id,
          scoringSheetAssginations,
          businessId: id,
        };
        await createSheetAssignation(dispatch, payload);
        await setUp();
        openSuccess('Se ha creado la asignación de score exitosamente');
        setOpen(false);
      }
    } catch (error) {
      alert('Ha ocurrido un error');
      openFailure('Ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleUseSheet = () => {
    generateScoreAssignation();
    setEditing(true);
  };

  const generateScoreAssignation = () => {
    const variables = sheetSelected.variables.map((x: any) => {
      return {
        id: x.id,
        name: x.name,
        weight: x.weight,
        options: x.matrix,
        inputScore: 0,
        observation: '',
      };
    });
    setSheetAssignationVariables(variables);
  };

  const handleSelectSheet = (e: any) => {
    setSheetSelectedId(e.target.value);
    const sheet = sheets.find((x: any) => x.id === e.target.value);
    setSheetSelected(sheet);
  };

  const setUpScoreAssgination = async () => {
    setLoading(true);
    try {
      if (scoringSheetAssignationId) {
        const variablesInEditFormat = await processVariablesToListFormat(
          scoringSheetAssignationId,
        );
        console.log(variablesInEditFormat);
        setEditing(true);
        setSheetAssignationVariables(variablesInEditFormat);
      } else {
        const sheets = await getScoringSheets(dispatch);
        setSheets(sheets);
      }
      const ratingMatrix = await getScoringMatrix(dispatch);
      console.log(ratingMatrix);
      setRatingMatrix(ratingMatrix);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleVariableChange = (e: any, index: number, type: string) => {
    const value = e.target.value;
    const variables = sheetAssignationVariables.map((x: any, i: number) => {
      if (i === index) {
        const isInsideTheRange = x.options.find(
          (y: any) => y.valueMin <= value && y.valueMax >= value,
        );
        if (!isInsideTheRange && type === 'inputScore') return x;
        setEditedAssignations((prev: any) => {
          if (!prev.includes(x.id)) {
            return [...prev, x.id];
          } else {
            return prev;
          }
        });

        return {
          ...x,
          [type]: value,
        };
      } else {
        return x;
      }
    });
    if (type === 'inputScore') {
      const score = variables
        .reduce((acc: any, x: any) => acc + x.inputScore * x.weight, 0)
        .toFixed(2);
      setScore(score);
      const rating = ratingMatrix.find(
        (x: any) => x.valueMin <= score && x.valueMax >= score,
      );

      setRating(rating.score);
    }
    setSheetAssignationVariables(variables);
  };

  useEffect(() => {
    setUpScoreAssgination();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <HeaderWithActions
          type="dialogHeader"
          title={
            scoringSheetAssignationId
              ? 'Editar asignación de score'
              : 'Asignar score'
          }
          item={
            editing &&
            score && (
              <Tooltip title={CustomTooltip(ratingMatrix)}>
                <Typography variant="h4">
                  {' '}
                  <b>Score: </b>
                  {`${rating} (${score})`}
                </Typography>
              </Tooltip>
            )
          }
        />
      </DialogTitle>
      <DialogContent style={{ minHeight: 100 }}>
        {!editing && (
          <div>
            <Typography variant="body1" style={{ marginBottom: 8 }}>
              Primero deberás seleccionar una plantilla para hacer la asignación
              del score.
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="filter-label">
                Selecciona una plantilla
              </InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={sheetSelectedId}
                onChange={(e) => handleSelectSheet(e)}
                label="Selecciona una plantilla"
              >
                {sheets.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {sheetSelected && (
              <div>
                <div>
                  <Typography
                    variant="body1"
                    style={{ marginBottom: 8, marginTop: 16 }}
                  >
                    Estás son variables a asignar
                  </Typography>
                  <div
                    style={{ justifyContent: 'space-between', display: 'flex' }}
                  >
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      <b>Nombre</b>
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      <b>Peso</b>
                    </Typography>
                  </div>
                  {sheetSelected.variables.map((x: any, index: any) => (
                    <div
                      key={index}
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <Typography variant="body1" style={{ marginBottom: 8 }}>
                        {x.name}
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 8 }}>
                        {x.weight}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {editing && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" style={{ marginBottom: 8 }}>
                Ahora deberás asignar un valor a cada variable.
              </Typography>
            </div>
            <div>
              <div>
                <Grid container rowSpacing={2}>
                  <Grid item xs={1}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      <b>Detalle</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      <b>Nombre</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography textAlign="center" variant="body1">
                      <b>Peso</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      <b>Input</b>
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography style={{ marginLeft: 12 }} variant="body1">
                      <b>Observaciones</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" textAlign="center">
                      <b>Valor</b>
                    </Typography>
                  </Grid>

                  {sheetAssignationVariables &&
                    sheetAssignationVariables.map((x: any, index: any) => (
                      <Grid item xs={12} key={index}>
                        <Grid container alignItems="center">
                          <Grid item xs={1}>
                            <Tooltip title={CustomTooltip(x.options)}>
                              <IconButton>
                                <RemoveRedEyeOutlined />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body1">{x.name}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography
                              textAlign="center"
                              variant="body1"
                            >{`${(x.weight * 100).toFixed(0)}%`}</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <TextField
                              type="number"
                              variant="outlined"
                              label="Score"
                              value={
                                sheetAssignationVariables[index].inputScore
                              }
                              onChange={(e) =>
                                handleVariableChange(e, index, 'inputScore')
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              style={{ marginLeft: 12 }}
                              variant="outlined"
                              fullWidth
                              label="Observaciones"
                              value={
                                sheetAssignationVariables[index].observation
                              }
                              onChange={(e) =>
                                handleVariableChange(e, index, 'observation')
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="body1" textAlign="center">
                              {(
                                sheetAssignationVariables[index].inputScore *
                                x.weight
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Cancelar
        </CustomButton>
        {editing ? (
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Guardar
          </CustomButton>
        ) : (
          <CustomButton onClick={() => handleUseSheet()} color="primary">
            Ocupar
          </CustomButton>
        )}
      </DialogActions>
      {/* Add your dialog content here */}
    </Dialog>
  );
};

export default ScoreAssgination;
