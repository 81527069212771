import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import {
  createUsersRelations,
  deleteUsersRelations,
  getActiveBusinessRelationsForUser,
  getUsersRelations,
} from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { CompanyRelations } from '../../index';

export interface UserRelations {
  id?: number;
  businessName: string;
}

export interface UserInterface {
  id: number;
  name: string;
  email?: string;
  permissionPolicy: string;
  password?: string;
  roles?: string[];
  active?: boolean;
  permissionPolicyCode?: number;
}

const EditUserBusinesses = (props: any) => {
  const {
    handleCloseDialog,
    setFailed,
    openCompanyDialog,
    selectedUser,
    setCompanyRelations,
    companyRelations,
    setSuccessText,
    setFailedMessage,
    setSuccess,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [businessId, setBusinessId] = React.useState('');
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const [userRelations, setUserRelations] = useState<UserRelations[]>([]);
  const handleChange = (event: SelectChangeEvent) => {
    setBusinessId(event.target.value as string);
  };

  const handleOpenAddCompany = async () => {
    setOpenAddCompany(true);
    setLoading(true);
    const relations = await getActiveBusinessRelationsForUser(dispatch);
    console.log(userRelations);
    const businessIdentifiers = userRelations.map((x: any) => x.id);
    console.log(businessIdentifiers);
    const filteredRelations = relations.relations.filter(
      (x: CompanyRelations) => !businessIdentifiers.includes(x.id),
    );
    console.log(filteredRelations);
    setCompanyRelations(filteredRelations);
    setLoading(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        id: selectedUser.id,
        businessId: businessId,
      };
      await createUsersRelations(dispatch, data);
      setSuccess(true);
      setSuccessText('Se ha agregado la asociación');
    } catch (error) {
      setFailed(true);
      setFailedMessage('Ha ocurrido un error');
    }
    await closeAdding();
    setLoading(false);
  };

  const handleDeleteRelation = async (id: number) => {
    setLoading(true);
    console.log(userRelations, id);
    const relations = userRelations.find((x: UserRelations) => x.id === id);
    if (!relations) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }

    const data = {
      id: selectedUser.id,
      businessId: relations.id,
    };
    await deleteUsersRelations(dispatch, data);
    setSuccess(true);
    setSuccessText('Se ha borrado la asociación');
    setLoading(false);
    await closeAdding();
  };

  const setUpRelations = async () => {
    setLoading(true);
    try {
      const relations = await getUsersRelations(dispatch, {
        id: selectedUser.id,
      });
      console.log(relations);
      setUserRelations(relations.relations);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const closeAdding = async () => {
    setOpenAddCompany(false);
    await setUpRelations();
  };

  useEffect(() => {
    setUpRelations();
  }, []);

  const actionsUserRelations = [
    { action: handleDeleteRelation, actionLabel: 'Desasociar' },
  ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleOpenAddCompany(),
      name: 'Asociar Cliente',
      color: 'primary',
    },
  ];

  return (
    <div>
      <Dialog
        fullWidth
        open={openCompanyDialog}
        onClose={() => handleCloseDialog()}
      >
        {loading && <CenteredCircularProgress type="dialog" />}
        {!loading && (
          <div>
            <DialogContent>
              <HeaderWithActions
                type="subHeader"
                buttons={
                  selectedUser.permissionPolicy === 'Administrador'
                    ? []
                    : !openAddCompany
                      ? buttonsData
                      : []
                }
                title="Clientes visibles"
              />
              <Typography margin="8px 0px">
                Aqui puedes ver los clientes asociados a {selectedUser.name} (
                {selectedUser.email})
              </Typography>
              {!openAddCompany ? (
                <EnhancedTable
                  dataHeaders={[{ key: 'businessName', label: 'Cliente' }]}
                  data={userRelations}
                  showActions={
                    !(selectedUser.permissionPolicy === 'Administrador')
                  }
                  rowsPerPageDefault={5}
                  actions={
                    selectedUser.permissionPolicy === 'Administrador'
                      ? []
                      : actionsUserRelations
                  }
                />
              ) : (
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Clientes disponibles
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={businessId}
                      label="Clientes disponibles"
                      onChange={(e) => handleChange(e)}
                    >
                      {companyRelations.map(
                        (companyRelation: CompanyRelations, index: number) => (
                          <MenuItem key={index} value={companyRelation.id}>
                            {companyRelation.businessName}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                  <div
                    style={{ textAlign: 'center', padding: '30px 0px 0px' }}
                  ></div>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <CustomButton
                onClick={() => handleCloseDialog()}
                color="secondary"
              >
                Salir
              </CustomButton>
              {openAddCompany && (
                <CustomButton onClick={closeAdding} color="secondary">
                  Cancelar
                </CustomButton>
              )}
              {openAddCompany && (
                <CustomButton
                  color="primary"
                  disabled={!businessId}
                  onClick={handleSave}
                >
                  Guardar
                </CustomButton>
              )}
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default EditUserBusinesses;
