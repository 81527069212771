import { Check, Delete, Person } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import {
  assignSubordinate,
  getSubordinates,
} from '../../../../../../lib/api/users';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';

interface subordinateProps {
  open: boolean;
  setClose: () => void;
  user: any;
  users: any[];
}

const Subordinate: React.FC<subordinateProps> = (props) => {
  const { open, setClose, user, users } = props;
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const [newUser, setNewUser] = useState(0);
  const [usersToShow, setUsersToShow] = useState<any[]>([]);
  const [subordinatedUsers, setSubordinatedUsers] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAdd = () => {
    filterUsers();
    setShowAdd(true);
  };

  const handleConfirmAdd = () => {
    const newUserToAdd = users.find((u) => u.id === newUser);
    const newSubordinatedUsers = [...subordinatedUsers, newUserToAdd];
    setSubordinatedUsers(newSubordinatedUsers);
    setShowAdd(false);
  };

  const handleConfirmDelete = (id: number) => {
    const newSubordinatedUsers = subordinatedUsers.filter((u) => u.id !== id);
    setSubordinatedUsers(newSubordinatedUsers);
  };

  const filterUsers = () => {
    const filteredUsers = users.filter((u) => {
      return (
        !subordinatedUsers.some((su: any) => su.id === u.id) && u.id !== user.id
      );
    });
    setUsersToShow(filteredUsers);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await assignSubordinate(
        dispatch,
        user.id,
        subordinatedUsers.map((u) => u.id),
      );
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
    setClose();
    setLoading(false);
  };

  const retrieveSubordinatedUsers = async () => {
    setLoading(true);
    const response = await getSubordinates(dispatch, user.id);
    console.log(response);
    if (response) {
      setSubordinatedUsers(response.data);
      const filteredUsers = users.filter((u) => {
        return (
          !response.data.some((su: any) => su.id === u.id) && u.id !== user.id
        );
      });
      setUsersToShow(filteredUsers);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setShowAdd(false);
    if (user.id !== 0 && open) retrieveSubordinatedUsers();
  }, [user]);

  if (loading) return <CenteredCircularProgress />;
  return (
    <>
      <Dialog open={open} onClose={setClose} fullWidth>
        <DialogContent>
          <HeaderWithActions
            type="subHeader"
            title="Usuarios subordinados"
            buttons={[
              {
                name: 'Agregar',
                action: handleAdd,
                color: 'primary',
              },
            ]}
          />
          <Typography margin="8px 0px">
            Estos son los usuarios que son subordinados de{' '}
            <b>
              {user.name} ({user.email}){' '}
            </b>
            .
          </Typography>

          <List>
            {subordinatedUsers.map((subordinatedUser: any, index: number) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleConfirmDelete(subordinatedUser.id)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText
                  primary={`${subordinatedUser.name} (${subordinatedUser.email})`}
                />
              </ListItem>
            ))}
            {subordinatedUsers.length === 0 && !showAdd && (
              <ListItem>
                <ListItemText primary="No hay subordinados asociados" />
              </ListItem>
            )}
            {showAdd && (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleConfirmAdd()}
                  >
                    <Check />
                  </IconButton>
                }
              >
                <FormControl sx={{ m: 1, width: '300px' }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Usuario"
                    value={newUser}
                    onChange={(e) => setNewUser(e.target.value as number)}
                    fullWidth
                  >
                    {usersToShow.map((option) => (
                      <MenuItem key={option.id} value={parseInt(option.id)}>
                        {option.name} ({option.email})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={setClose} color="secondary">
            Cerrar
          </CustomButton>
          <CustomButton
            onClick={() => handleSave()}
            color="primary"
            disabled={loading}
          >
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text="Usuarios subordinados guardados correctamente"
        handleClose={() => setSuccess(false)}
      />
      <CustomAlert
        open={error}
        setOpen={setError}
        severity="error"
        text="Ha ocurrido un error al guardar los usuarios subordinados"
        handleClose={() => setError(false)}
      />
    </>
  );
};

export default Subordinate;
