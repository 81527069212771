import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import { confirmInvoices } from '../../../../../lib/api/sii';
import { getInvoiceNotConfirmed } from '../../../../../lib/api/dashboard';

const InvoicesNotConfirmed: React.FC = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [selectedInvoices, setSelectedInvoices] = React.useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [bussinesses, setBussinesses] = useState<string[]>([]);

  const fetchInvoices = async () => {
    setLoading(true);
    const invoicesResponse = await getInvoiceNotConfirmed(dispatch);

    const businessesArray: string[] = invoicesResponse.table.map(
      (x: any) => x.stakeholderName,
    );
    const uniqueBusinesses: string[] = [...new Set(businessesArray)];
    setBussinesses(uniqueBusinesses);
    console.log(invoicesResponse);
    setInvoices(invoicesResponse.table || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const handleConfirm = () => {
    const selectedInvoices = invoices.filter((x: any) =>
      selected.includes(x.id),
    );
    setSelectedInvoices(selectedInvoices);
    setConfirmationModal(true);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await confirmInvoices(dispatch, {
        invoices: selectedInvoices,
      });
      console.log(response);
      setSelectedInvoices([]);
      setSelected([]);
      setConfirmationModal(false);
      console.log(selectedInvoices);
      await fetchInvoices();
      setSuccess(true);
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleConfirm(),
      name: 'Confrimar',
      color: 'primary',
      disabled: !selected.length,
    },
    {
      action: () => setOpenFilters(!openFilters),
      name: 'Filtrar',
      color: 'primary',
      disabled: false,
    },
  ];

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      {/* <Typography variant='h5'>Facturas por confirmar</Typography>  */}
      <HeaderWithActions title="Facturas sin confirmar" buttons={buttonsData} />
      <EnhancedTable
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'stakeholderName', label: 'Nombre receptor' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
        ]}
        data={invoices}
        filters={[
          {
            key: 'stakeholderName',
            label: 'Nombre receptor',
            options: bussinesses,
          },
        ]}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        rowsPerPageDefault={100}
        canSelectAll
        selected={selected}
        setSelected={setSelected}
      />
      <Dialog
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
      >
        <DialogTitle>Confirmar facturas</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que quieres confirmar estas facturas? Una vez
            confirmadas, no podrás deshacer esta acción.
          </Typography>
          <Typography variant="body1">
            Facturas seleccionadas: {selected.length}
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={() => setConfirmationModal(false)}
          >
            Cancelar
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Confirmar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text="Facturas confirmadas correctamente"
        handleClose={() => setSuccess(false)}
      />
      <CustomAlert
        open={failed}
        setOpen={setFailed}
        severity="error"
        text="Error al confirmar facturas"
        handleClose={() => setFailed(false)}
      />
    </div>
  );
};

export default InvoicesNotConfirmed;
