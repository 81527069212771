import { requestHandler } from '../../../api';

export const expressGetFileStatusForDocuments = async (
  dispatch: any,
  companyRecieverId: string,
  documents: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/factoring/documents/fileStatus/add`,
    data: { documents },
    dispatch,
  });
  if (response) return response.data;
};

export const expressUploadFilesFromRequest = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/express/${companyRecieverId}/factoring/documents/file/upload`,
    data,
    headers: {},
    dispatch,
  });
  if (response) return response.data;
};

export const expresGetDocumentFile = async (
  dispatch: any,
  companyRecieverId: string,
  fileName: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/factoring/documents/file/${fileName}`,
    dispatch,
  });
  if (response) return response.data;
};
