import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import HeaderWithActions from '../../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';
const MultiResellDocumentSelector = (props: any) => {
  const { suggestions, documents, setDocuments, handleConfirm, open, setOpen } =
    props;
  const [filteredSuggestions, setFilteredSuggestions] = useState<any>([]);
  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredSuggestions(suggestions);
    console.log(newValue);
    const filtered = suggestions.filter((x: any) => x.id === newValue.id);
    setFilteredSuggestions(filtered);
  };
  console.log(suggestions);

  const autoCompleteFilter = (
    <Autocomplete
      options={suggestions}
      getOptionLabel={(option: any) =>
        `${option.folio} | ${option.businessName} | ${option.stakeholderName}`
      }
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  useEffect(() => {
    console.log(suggestions);
    setFilteredSuggestions(suggestions);
  }, [suggestions]);

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle>
        <HeaderWithActions
          title="Seleccionar documentos"
          variant={'h6'}
          type="subHeader"
          item={autoCompleteFilter}
        />
      </DialogTitle>

      <DialogContent>
        <EnhancedTable
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'factoringRequestId', label: 'Solicitud' },
            { key: 'businessName', label: 'Cliente' },
            { key: 'stakeholderName', label: 'Deudor' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'paymentDate', label: 'Fecha vencimiento', type: 'date' },
          ]}
          data={filteredSuggestions}
          selected={documents}
          setSelected={setDocuments}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
        <CustomButton
          onClick={() => handleConfirm()}
          color="primary"
          disabled={documents.length === 0}
        >
          Confirmar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default MultiResellDocumentSelector;
