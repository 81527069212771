import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getBankrruptcyPosts } from '../../../../../lib/api/dashboard';

const LastBaknrruptcyPosts = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRelations = async () => {
    setLoading(true);
    const relations = await getBankrruptcyPosts(dispatch);
    setInvoices(relations || []);
    console.log(loading);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      {/* <HeaderWithActions variant='h6' subHeader buttons={[]} title='Facturas transables' /> */}
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Cuenta' },
          { key: 'role', label: 'Rol' },
          { key: 'process', label: 'Procedimiento ' },
          { key: 'watcher', label: 'Liquidador' },
          { key: 'name', label: 'Publicación' },
          { key: 'court', label: 'Tribunal' },
          { key: 'date', label: 'Publicación', type: 'date' },
        ]}
        data={invoices}
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
    </div>
  );
};

export default LastBaknrruptcyPosts;
