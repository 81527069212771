import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Add, Delete } from '@mui/icons-material';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

const SubProductAssign = (props: any) => {
  const {
    index,
    concentration,
    concentrations,
    setConcentrations,
    deleteConcentration,
    handleSelectSubProduct,
    availableSubProducts,
  } = props;

  const handleChangeAmount = (e: any, index: number) => {
    const value = e.target.value.split('.').join('').split('$').join('');
    const name = e.target.name;

    if (isNaN(value)) return;
    const newArray = [...concentrations];
    newArray[index][name] = Number(value) || 0;
    setConcentrations(newArray);
  };

  const handleChangeRate = (e: any, index: number) => {
    const value = e.target.value;
    console.log('value', value);

    const name = e.target.name;
    if (isNaN(value)) return;
    if (value < 0) return;
    if (value > 100) return;
    console.log('value', value);
    const newArray = [...concentrations];
    newArray[index][name] = value;
    setConcentrations(newArray);
  };

  const handleChangeDays = (e: any, index: number) => {
    const value = e.target.value;
    const name = e.target.name;
    if (isNaN(value)) return;
    if (value < 0) return;
    const newArray = [...concentrations];
    newArray[index][name] = Number(value) || 0;
    setConcentrations(newArray);
  };

  return (
    <div key={index} style={{ display: 'flex' }}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={2}>
          <FormControl key={index} variant="outlined" fullWidth>
            <InputLabel id="filter-label">Seleccionar</InputLabel>
            <Select
              labelId="filter-label"
              fullWidth
              id="Filtrar por estado"
              value={concentration.id}
              onChange={(e) => handleSelectSubProduct(e, index)}
              label="Seleccionar"
            >
              {availableSubProducts(index).map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                label="Anticipo (%)"
                name="advance"
                value={concentration.advance}
                onChange={(e) => handleChangeRate(e, index)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                label="Tasa Mora (%)"
                name="arrearRate"
                value={concentration.arrearRate}
                onChange={(e) => handleChangeRate(e, index)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                label="Max días"
                name="maxFinancingDays"
                value={concentration.maxFinancingDays}
                onChange={(e) => handleChangeDays(e, index)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                label="Spread (%)"
                name="spread"
                value={concentration.spread}
                onChange={(e) => handleChangeRate(e, index)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={3}>
              <TextField
                autoFocus
                margin="dense"
                label="C. Variable (%)"
                name="operationCostVariable"
                value={concentration.operationCostVariable}
                onChange={(e) => handleChangeRate(e, index)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                label="C. Fija ($)"
                name="operationCostFixed"
                value={formatMoney(concentration.operationCostFixed)}
                onChange={(e) => handleChangeAmount(e, index)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                label="Monto ($)"
                name="amount"
                value={formatMoney(concentration.amount)}
                onChange={(e) => handleChangeAmount(e, index)}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton onClick={() => deleteConcentration(index)}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const SubPrductAssingnation = (props: any) => {
  const { concentrations, setConcentrations, amount, subProducts } = props;
  console.log('AssignationModule rendered');

  const addEmptyConcentration = () => {
    setConcentrations([...concentrations, {}]);
  };

  const deleteConcentration = (index: number) => {
    const newArray = [...concentrations];
    newArray.splice(index, 1);
    setConcentrations(newArray);
  };

  const handleSelectSubProduct = (e: any, index: number) => {
    const newArray = [...concentrations];
    const subProduct = subProducts.find((x: any) => x.id === e.target.value);
    if (!subProduct) return;
    newArray[index].subProductName = subProduct.name;
    newArray[index].id = subProduct.id;
    setConcentrations(newArray);
  };

  const availableSubProducts = (index: number) =>
    subProducts.filter(
      (x: any) =>
        !concentrations.map((x: any) => x.id).includes(x.id) ||
        concentrations[index].id === x.id,
    );

  // if (loading) {
  //   return <CenteredCircularProgress type="dialog" />;
  // }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          Acá podrás asignar los detalles de tus subProductos
        </Typography>
        <div style={{ padding: '10px 0px', textAlign: 'right' }}>
          <CustomButton
            color="secondary"
            onClick={() => addEmptyConcentration()}
          >
            <Add /> Sub producto
          </CustomButton>
        </div>
      </div>
      <div style={{ padding: '10px 0px' }}>
        {concentrations.map((concentration: any, index: any) => (
          <div key={index}>
            <SubProductAssign
              key={index}
              index={index}
              concentration={concentration}
              concentrations={concentrations}
              setConcentrations={setConcentrations}
              deleteConcentration={deleteConcentration}
              handleSelectSubProduct={handleSelectSubProduct}
              availableSubProducts={availableSubProducts}
              amount={amount}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubPrductAssingnation;
