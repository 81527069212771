import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import FilesDialog from '../../../../../common/filesView';
import EnhancedTab from '../../../../../../../../../lib/designSystem/TabSimulation';
import Summary from './components/Summary';
import ChatWindow from '../../../../../common/chat';
import DocumentCessionModule from './components/CessionModule';

const ShoppingDialogSent = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    setUp,
    openRequest,
    setOpenRequest,
    setSuccessOpen,
    setFailureOpen,
  } = props;
  const [openFiles, setOpenFiles] = useState(false);
  const startValue = 0;

  useEffect(() => {}, []);

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <Summary
          shoppingCartType={shoppingCartType}
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          setUp={setUp}
          openRequest={openRequest}
          setOpenRequest={setOpenRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      ),
    },
    {
      index: 1,
      label: 'Archivos ',
      component: (
        <FilesDialog
          openRequest={openRequest}
          openFiles={openFiles}
          requestType="factoring"
          setOpenFiles={setOpenFiles}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      ),
      path: 'recieved',
    },
    // {
    //   index: 2,
    //   label: 'Chat',
    //   component: (
    //     <ChatWindow
    //       requestType="factoring"
    //       selectedRequest={openRequest}
    //       setSuccessOpen={setSuccessOpen}
    //       setFailureOpen={setFailureOpen}
    //     />
    //   ),
    //   path: 'recieved',
    // },
    {
      index: 3,
      label: 'Cesión',
      component: (
        <DocumentCessionModule
          selectedRequest={openRequest}
          setSelectedRequest={setOpenRequest}
          setOpenShoppingCart={setOpenShoppingCart}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      ),
      disabled: openRequest?.status === 'Creada',
      path: 'cession',
    },
  ];

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openShoppingCart}
        onClose={() => setOpenShoppingCart(false)}
      >
        <div style={{ padding: 20, minHeight: 200 }}>
          <EnhancedTab
            title={
              openRequest ? `Solicitud #${openRequest?.id}` : 'Nueva solicitud'
            }
            startValue={startValue}
            tabs={tabs}
            closeAction={() => setOpenShoppingCart(false)}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ShoppingDialogSent;
