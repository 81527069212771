import { requestHandler } from '../../../api';

export const expressUploadFilesFromRequest = async (
  dispatch: any,
  data: any,
  requesData: any,
  clientId: string,
) => {
  const { requestId, requestType } = requesData;
  console.log('data', data);
  const response = await requestHandler({
    method: 'patch',
    url: `internal/express/${clientId}/files/requestType/${requestType}/requestId/${requestId}`,
    data,
    headers: {},
    dispatch,
  });
  if (response) return response.data;
};

export const expressDownloadFilesFromRequest = async (
  dispatch: any,
  data: any,
  clientId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${clientId}/files/requestType/${data.requestType}/requestId/${data.requestId}/file/${data.fileName}`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressGetFilesFromRequest = async (
  dispatch: any,
  data: any,
  clientId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${clientId}/files/requestType/${data.requestType}/requestId/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressUpdateFileNameFromRequest = async (
  dispatch: any,
  data: any,
  clientId: string,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/express/${clientId}/files/requestType/${data.requestType}/requestId/${data.requestId}/name`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const expressGetRequiredFiles = async (
  dispatch: any,
  requestType: string,
  requestId: string,
  names: string[],
  clientId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${clientId}/files/requestType/${requestType}/requestId/${requestId}/requiredFiles/${names.join(',')}`,
    dispatch,
  });
  if (response) return response.data;
};
