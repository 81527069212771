import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  createBusiness,
  startEnrolation,
  uploadIVA,
  validateCredentials,
} from '../../../../../../lib/api';
import {
  validaRut,
  validateParams,
} from '../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { handleError } from '../../../../../../lib/functions/errors';
import Uploader from '../../../../../../lib/designSystem/Uploader';
import pdfParse from 'pdf-parse';
import { useParams } from 'react-router-dom';

function IvaEmptyer(props: any) {
  const {
    setLoading,
    dispatch,
    activateSuccess,
    setFailed,
    setOpenIVADialog,
    openIVADialog,
    fetchRelations,
  } = props;
  const [formErrorMessage, setFormErrorMessage] = useState(''); // ... your state setup for formErrorMessage
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [file, setFile] = useState(null);
  const { id } = useParams<{ id: string }>();

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setFile(uploadedFile);
    } else {
      setFormErrorMessage('Por favor, sube un archivo PDF válido.');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    if (!file) {
      setFormErrorMessage('Por favor, sube un archivo PDF.');
      return;
    }
    const data = new FormData();
    data.append('file', file);
    try {
      const response = await uploadIVA(dispatch, id, { data });
      console.log(response.data);
      await fetchRelations();
      activateSuccess('Se ha terminado de vaciar el documento tributario.');
    } catch (error: any) {
      handleError(error);
    }
    setOpenIVADialog(false);
    setFile(null);
    setLoading(false);
  };

  return (
    <Dialog
      open={openIVADialog}
      onClose={() => {
        setOpenIVADialog(false);
        setFile(null);
      }}
    >
      <DialogTitle>
        <b>Vaciado IVA</b>
      </DialogTitle>
      <DialogContent style={{ maxWidth: 300 }}>
        {/* <p><b>Cliente:</b> {selectedBusiness.businessName}</p> */}
        <Uploader
          handleFileChange={handleFileChange}
          buttonText="Agregar PDF"
          uploadedFileName={uploadedFileName}
        />
        <Typography>
          <b>Importante</b> Si este cliente ya tiene un vaciado, este va a
          reemplazarlo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => {
            setOpenIVADialog(false);
            setFile(null);
          }}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={handleSubmit} color="primary">
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default IvaEmptyer;
