import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Typography,
} from '@mui/material';
import {
  deleteCreditLineApproval,
  getCreditLineApprovals,
} from '../../../../../../../../../../../../../lib/api/creditLine';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';

const DeleteComiteRevision = (props: any) => {
  const { openRequest, open, setOpen, setFailure, setSuccess, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [reviewToDelete, setReviewToDelete] = useState<any>({});
  const dispatch = useDispatch();

  const validateAttributes = () => {
    if (!reviewToDelete) return alert('Seleccione una revisión');
    return true;
  };

  const handleSave = async () => {
    if (!validateAttributes()) {
      return;
    }
    setLoading(true);
    try {
      await deleteCreditLineApproval(dispatch, openRequest.id, reviewToDelete);
      setSuccess('Se ha borrado la revisión de la solicitud.');
    } catch (error) {
      setFailure('Error al borrar la revisión');
    }
    await setUp();
    setOpen(false);
    setLoading(false);
  };

  const setUpComitees = async () => {
    setLoading(true);
    try {
      const response = await getCreditLineApprovals(dispatch, openRequest.id);
      setReviews(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpComitees();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Editar Comité</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ paddingBottom: '10px' }}>
            <Typography variant="body1">
              Acá podrás borrar las revisiones que ya hayan sido realizadas.
            </Typography>
          </div>
          {reviews.length === 0 ? (
            <Typography variant="body1">
              No hay revisiones realizadas en esta solicitud.
            </Typography>
          ) : (
            <FormControl margin="dense" fullWidth>
              <InputLabel id="filter-label">Revisión a eliminar</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={reviewToDelete}
                onChange={(e) => setReviewToDelete(e.target.value)}
                label="Selecciona una revisión"
              >
                {reviews.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.userName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={handleSave}
          disabled={!reviews.length}
        >
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteComiteRevision;
