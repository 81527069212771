import React from 'react';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';

const CessionConfirmationDialog = (props: any) => {
  const {
    open,
    setOpen,
    selectedCertificate,
    passphrase,
    setPassphrase,
    selected,
    handleCession,
    loading,
    companyDetails,
  } = props;

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant="h5">Ceder Documentos</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container marginBottom={2} justifyContent="center" spacing={3}>
          <Grid item xs={6}>
            <div
              style={{
                width: 'auto',
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Certificado:
              </Typography>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">Nombre:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {selectedCertificate.taxName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">Rut:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {selectedCertificate.taxIdentifier}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">Rut empresa:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {selectedCertificate.businessIdentifier}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                width: 'auto',
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                Cesionario
              </Typography>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">Nombre:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{companyDetails.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="spaceBetween" spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">Rut:</Typography>
                </Grid>
                <Grid item xs={6}>
                  {' '}
                  <Typography variant="body1">
                    {companyDetails.taxIdentifier}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Typography variant="body1">
          Para confirmar la cesión de {selected.length}{' '}
          {selected.length > 1 ? 'documentos' : 'documento'}, debes ingresar la
          constraseña del certificado.
        </Typography>
        <TextField
          margin="dense"
          label="Contraseña del certificado"
          variant="outlined"
          type="password"
          fullWidth
          value={passphrase}
          onChange={(e) => setPassphrase(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => handleCession()}
          color="primary"
          disabled={!passphrase.length}
        >
          Confirmar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CessionConfirmationDialog;
