import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  createCompanyPayIn,
  updateCompanyPayIn,
} from '../../../../../../../../lib/api/conciliations';
import { getAllBankAccounts } from '../../../../../../../../lib/api/bankAccounts';
import BankSelectionContainer from './PaymentContainer';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';

const Payment = (props: any) => {
  const {
    open,
    setOpen,
    payIn,
    setPayIn,
    setOpenBankAccountModal,
    setSuccessOpen,
    setFailureOpen,
    setUp,
    setOpenConciliation,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ownBankAccounts, setOwnBankAccounts] = useState<any>([]);
  const [otherBankAccounts, setOtherBankAccounts] = useState<any>([]);
  const [originAccountData, setOriginAccountData] = useState<any>({});
  const [destinationAccountData, setDestinationAccountData] = useState<any>({});

  console.log(payIn);

  const setUpAccounts = async () => {
    setLoading(true);
    try {
      const response = await getAllBankAccounts(dispatch);
      if (response) {
        const { ownBankAccounts, otherBankAccounts } = response;
        setOwnBankAccounts(ownBankAccounts);
        setOtherBankAccounts(otherBankAccounts);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const submitPayIn = async () => {
    try {
      setLoading(true);
      const payload = {
        ...payIn,
        amount: Number(payIn.amount),
        createdAt: new Date(payIn.createdAt),
      };
      console.log(payload);
      if (payIn.id) {
        await updateCompanyPayIn(dispatch, payload);
        setOpenConciliation(false);
      } else {
        await createCompanyPayIn(dispatch, payload);
      }
      setSuccessOpen(`Pago ${payIn.id ? 'editado' : 'creado'} correctamente`);
      setOpen(false);
      setLoading(false);
      await setUp();
    } catch (error) {
      console.log(error);
      setFailureOpen(`Error al ${payIn.id ? 'editar' : 'crear'} el pago`);
    }
  };

  const canSave = () => {
    if (!payIn.amount) return false;
    if (!payIn.bankAccountDestinationId) return false;
    if (!payIn.bankAccountOriginId) return false;
    return true;
  };

  const handleSelectAmount = (e: any) => {
    const value = e.target.value.split('.').join('').split('$').join('');
    setPayIn({ ...payIn, amount: value });
  };

  useEffect(() => {
    setUpAccounts();
  }, []);

  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <HeaderWithActions
          title={payIn.id ? `Editar pago #${payIn.id}` : 'Nuevo pago'}
          type="dialog"
        />
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <BankSelectionContainer
              setPayIn={setPayIn}
              payIn={payIn}
              setOpenBankAccountModal={setOpenBankAccountModal}
              setOpen={setOpen}
              accounts={otherBankAccounts}
              setAccountData={setOriginAccountData}
              accountData={originAccountData}
              type="bankAccountOriginId"
            />
            <BankSelectionContainer
              setPayIn={setPayIn}
              payIn={payIn}
              setOpenBankAccountModal={setOpenBankAccountModal}
              setOpen={setOpen}
              accounts={ownBankAccounts}
              setAccountData={setDestinationAccountData}
              accountData={destinationAccountData}
              type="bankAccountDestinationId"
            />
            {payIn.bankAccountDestinationId ? (
              <div style={{ padding: '10px 0px' }}>
                <Divider />
                <div style={{ padding: '10px 0px' }} />
                <Typography variant="h6" fontWeight={600}>
                  Detalles
                </Typography>
                <TextField
                  label="Monto"
                  value={formatMoney(payIn.amount)}
                  fullWidth
                  margin="dense"
                  onChange={handleSelectAmount}
                  name="amount"
                />
                <TextField
                  label="Fecha"
                  value={
                    payIn.createdAt.includes('T')
                      ? payIn.createdAt.split('T')[0]
                      : payIn.createdAt
                  }
                  fullWidth
                  margin="dense"
                  type="date"
                  onChange={(e) => {
                    setPayIn({ ...payIn, createdAt: e.target.value });
                    console.log(e.target.value);
                  }}
                  name="createdAt"
                />
              </div>
            ) : null}
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          <CustomButton
            onClick={() => submitPayIn()}
            color="primary"
            disabled={!canSave()}
          >
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Payment;
