import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  deletePendingAccountsCategory,
  getPendingAccountsCategories,
  getPendingAccountsCategory,
} from '../../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CreateAccountCategoryDialog from './components/CreateDialog';
import { Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';

const PendingAccountsCategories = (props: any) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [companyCategories, setCompanyProducts] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const emptyPendingAccountCategory = {
    name: '',
    description: '',
  };
  const [pendingAccountCategory, setPendingAccountCategory] = useState(
    emptyPendingAccountCategory,
  );

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const fetchPendingAccountsCategories = async () => {
    setLoading(true);
    try {
      const response = await getPendingAccountsCategories(dispatch);
      setCompanyProducts(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deletePendingAccountsCategory(dispatch, id);
      await fetchPendingAccountsCategories();
      setSuccess(true);
      setSuccessMessage('Categoría eliminada');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
  };

  const handleOpenCreateDialog = async () => {
    setLoading(true);
    try {
      setOpenCreateDialog(true);
      setEdit(false);
      setPendingAccountCategory(emptyPendingAccountCategory);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert('Hubo un error buscando los productos disponibles');
    }
  };

  const handleOpenEditDialog = async (id: number) => {
    setLoading(true);
    try {
      const response = await getPendingAccountsCategory(dispatch, id);
      setEdit(true);
      setPendingAccountCategory(response);
      setLoading(false);
      setOpenCreateDialog(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert('Hubo un error buscando los productos disponibles');
    }
  };

  const canEdit = (id: number) => {
    const category: any = companyCategories.find((x: any) => x.id === id);
    console.log(category);
    if (category) {
      return category.companyId;
    }
  };

  const startButtons: CustomButtonProps[] = [
    {
      action: () => handleOpenCreateDialog(),
      name: 'Agregar',
      color: 'primary',
    },
  ];

  const actions = [
    { action: handleOpenEditDialog, actionLabel: 'Editar', canShow: canEdit },
    { action: handleDelete, actionLabel: 'Eliminar', canShow: canEdit },
  ];

  useEffect(() => {
    fetchPendingAccountsCategories();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={startButtons}
        title="Acá encontrarás todas los tipos de cuentas pagar/cobrar que tienes en tu empresa."
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'description', label: 'Descripción' },
        ]}
        data={companyCategories}
        actions={actions}
        showActions
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
      <CreateAccountCategoryDialog
        openDialog={openCreateDialog}
        setOpenDialog={setOpenCreateDialog}
        pendingAccountCategory={pendingAccountCategory}
        setPendingAccountCategory={setPendingAccountCategory}
        setLoading={setLoading}
        setUp={fetchPendingAccountsCategories}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        setFailed={setFailed}
        edit={edit}
      />
      <CustomAlert
        open={success}
        title="Éxito"
        text={successMessage}
        setOpen={setSuccess}
      />
      <CustomAlert
        open={failed}
        title="Error"
        severity="error"
        text="Hubo un error"
        setOpen={setFailed}
      />
    </div>
  );
};

export default PendingAccountsCategories;
