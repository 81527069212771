import { requestHandler } from '../../api';

export const getNotificationsProfile = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/notifications/config`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateNotificationsProfile = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/users/notifications/config`,
    dispatch,
    data,
  });
  if (response) return response.data;
};
