import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CobranzaOnlineIntegration from './components/CobranzaOnlineIntegration';
import { getIntegrationsCatalog } from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import InvoicePublication from '../Debt/components/InvoicePublication';
import HeaderWithActions from '../../lib/designSystem/HeaderWithActions';
import SuperDebtIntegration from './components/SuperDebt';

const Integrations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const { integration } = useParams<{ integration: string }>();
  const dispatch = useDispatch();

  const getCatalog = async () => {
    setLoading(true);
    const catalogResponse = await getIntegrationsCatalog(dispatch);
    setCatalog(catalogResponse || []);
    setLoading(false);
  };

  useEffect(() => {
    getCatalog();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  if (integration === 'cobranza-online') {
    return <InvoicePublication />;
  }
  return (
    <div>
      <HeaderWithActions title="Integraciones" />
      <Grid container spacing={2}>
        {catalog.map((integration: any) => (
          <Grid item xs={4} key={integration.id}>
            {integration.internalName === 'cobranza-online' && (
              <CobranzaOnlineIntegration
                packages={integration.packages}
                id={integration.id}
                name={integration.name}
                description={integration.description}
              />
            )}
            {integration.internalName === 'super-debt' && (
              <SuperDebtIntegration
                packages={integration.packages}
                id={integration.id}
                name={integration.name}
                description={integration.description}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Integrations;
