import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { getAllDecisionEngines } from '../../../../../../../../lib/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { selectDecisionEngineAsDefault } from '../../../../../../../../lib/api/decisionEngine';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import DefaultDialog from './components/DefaultDialog';
import DefaultDecisionEngineDialog from './components/DefaultDialog';

const DecisionEnginesShow = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [decisionEngines, setDecisionEngines] = useState([]);
  const [openDefault, setOpenDefault] = useState(false);
  const [selectedEngine, setSelectedEngine] = useState<any>(null);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const startButtons: CustomButtonProps[] = [
    {
      action: () => goToCreate(),
      name: 'Crear motor',
      color: 'primary',
    },
  ];

  const goToCreate = () => {
    window.location.href = '/settings/engines/create';
  };

  const fetchAllDecisionEngines = async () => {
    setLoading(true);
    try {
      const response = await getAllDecisionEngines(dispatch);
      setDecisionEngines(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDefault = (id: any) => {
    const engine = decisionEngines.find((engine: any) => engine.id === id);
    setSelectedEngine(engine);
    setOpenDefault(true);
  };

  const confirmDefault = async () => {
    try {
      await selectDecisionEngineAsDefault(dispatch, selectedEngine.id);
      setOpenDefault(false);
      setSuccess('Motor seleccionado como predeterminado');
      await fetchAllDecisionEngines();
    } catch (error) {
      setError('Error al seleccionar motor como predeterminado');
      console.log(error);
    }
  };

  const canDefault = (id: any) => {
    const engine: any = decisionEngines.find((engine: any) => engine.id === id);
    if (!engine) return false;
    return !engine.selected;
  };

  useEffect(() => {
    fetchAllDecisionEngines();
  }, []);

  const handleEdit = (id: any) => {
    window.location.href = `/settings/engines/edit/${id}`;
  };

  const actions: any = [
    { action: handleEdit, actionLabel: 'Editar' },
    { action: handleDefault, actionLabel: 'Default', canShow: canDefault },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los motores de decisión de tu empresa."
        variant="body1"
        buttons={startButtons}
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre del motor' },
          { key: 'revisionObjective', label: 'Elemento a revisar' },
          { key: 'selected', label: 'Default', type: 'vission' },
          { key: 'engineType', label: 'Tipo de revisión' },
        ]}
        data={decisionEngines}
        actions={actions}
        showActions
        rowsPerPageDefault={10}
      />
      {openDefault && (
        <DefaultDecisionEngineDialog
          selectedEngine={selectedEngine}
          openDefault={openDefault}
          setOpenDefault={setOpenDefault}
          setUp={fetchAllDecisionEngines}
          setSuccess={setSuccess}
          setError={setError}
        />
      )}
      <CustomAlert
        open={!!success}
        onClose={() => setSuccess('')}
        severity="success"
        text={success}
      />
      <CustomAlert
        open={!!error}
        onClose={() => setError('')}
        severity="error"
        text={error}
      />
    </div>
  );
};

export default DecisionEnginesShow;
