import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import {
  createCollectionManagementStates,
  updateCollectionManagementState,
} from '../../../../../../../../lib/api/collectionManagementStates';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import {
  Add,
  ArrowRight,
  Delete,
  Directions,
  Search,
} from '@mui/icons-material';
import {
  createCollectionManagementOptions,
  deleteCollectionManagementOption,
} from '../../../../../../../../lib/api/collectionManagementOptions';

interface CreateStateProps {
  open: boolean;
  setOpen: any;
  reload: number;
  setReload: any;
}

const CreateState: React.FC<CreateStateProps> = (props) => {
  const { open, reload, setReload, setOpen } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCollectionOption, setNewCollectionOption] = useState('');
  const [managementOptionsDisplay, setManagementOptionsDisplay] = useState<
    { name: string; id: string }[]
  >([]);

  const handleCreate = async () => {
    setLoading(true);
    try {
      await createCollectionManagementStates(dispatch, {
        name,
        collectionManagementOptions: managementOptionsDisplay,
      });
      setOpenSuccess(true);
      setReload(reload + 1);
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setOpenError(true);
      setOpen(false);
      setLoading(false);
    }
  };

  const addNewCollectionOption = async () => {
    setManagementOptionsDisplay([
      ...managementOptionsDisplay,
      { name: newCollectionOption, id: crypto.randomUUID() },
    ]);
    setNewCollectionOption('');
  };

  const handleDeleteCollectionOption = async (id: string) => {
    const newOptions = managementOptionsDisplay.filter(
      (option) => option.id !== id,
    );
    setManagementOptionsDisplay(newOptions);
  };

  useEffect(() => {
    setName('');
    setManagementOptionsDisplay([]);
    setLoading(false);
  }, [open]);

  return (
    <div>
      <CustomAlert
        open={openSuccess}
        title="Éxito"
        severity="success"
        text="Estado creado con éxito"
        setOpen={setOpenSuccess}
      />
      <CustomAlert
        open={openError}
        title="Error"
        severity="error"
        text="Problemas creando el estado de cobranza."
        setOpen={setOpenError}
      />
      <Dialog open={open}>
        <DialogTitle>Crear estado de cobranza</DialogTitle>
        <DialogContent>
          <>
            <DialogContentText>
              <div style={{ padding: '5px', minWidth: '500px' }}>
                <TextField
                  label="Nombre de estado de cobranza"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />
              </div>
              <div style={{ padding: '5px', minWidth: '500px' }}>
                <List
                  subheader={
                    <Typography variant="h6">Opciones asociadas</Typography>
                  }
                >
                  {managementOptionsDisplay.map((option) => (
                    <ListItem
                      key={option.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            handleDeleteCollectionOption(option.id)
                          }
                          disabled={loading}
                        >
                          <Delete />
                        </IconButton>
                      }
                    >
                      <ListItemIcon>
                        <ArrowRight />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </ListItem>
                  ))}
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={() => addNewCollectionOption()}
                        disabled={loading || newCollectionOption === ''}
                      >
                        <Add />
                      </IconButton>
                    }
                  >
                    <TextField
                      label="Agregar nueva opción de cobranza asociada"
                      variant="standard"
                      fullWidth
                      value={newCollectionOption}
                      onChange={(e) => setNewCollectionOption(e.target.value)}
                    />
                  </ListItem>
                </List>
              </div>
            </DialogContentText>
            <DialogActions>
              <CustomButton color="secondary" onClick={() => setOpen(false)}>
                {' '}
                Cerrar{' '}
              </CustomButton>
              <CustomButton
                color="primary"
                onClick={() => handleCreate()}
                disabled={loading}
              >
                {' '}
                Crear{' '}
              </CustomButton>
            </DialogActions>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateState;
