import React, { useEffect, useState } from 'react';
import AddCertificate from './components/AddCertificate';
import { useDispatch } from 'react-redux';
import {
  deleteCertificate,
  getCertificates,
  selectCertificate,
} from '../../../../../../lib/api/SIICertificates';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';

const SettingsCertificates = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ownCertificates, setOwnCertificates] = useState<any[]>([]);
  const [otherCertificates, setOtherCertificates] = useState<any[]>([]);
  const [addCertificate, setAddCertificate] = useState<boolean>(false);
  const [failed, setFailed] = useState<string>('');
  const [showingOwn, setShowingOwn] = useState<boolean>(true);
  const [success, setSuccess] = useState<string>('');
  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const allCertificate = await getCertificates(dispatch);
      const ownCertificatesResponse = allCertificate.filter(
        (certificate: any) => certificate.addedByCompany,
      );
      const otherCertificatesResponse = allCertificate.filter(
        (certificate: any) => !certificate.addedByCompany,
      );
      setOwnCertificates(ownCertificatesResponse);
      setOtherCertificates(otherCertificatesResponse);
    } catch (error) {
      alert('No se pudieron obtener los certificados');
    }
    setLoading(false);
  };

  const handleCreate = () => {
    setAddCertificate(true);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      name: showingOwn ? 'Ver de clientes' : 'Ver propios',
      action: () => setShowingOwn(!showingOwn),
      color: 'secondary',
    },
    {
      name: 'Agregar',
      action: handleCreate,
      color: 'primary',
    },
  ];

  const handleSelect = async (id: number) => {
    setLoading(true);
    try {
      await selectCertificate(dispatch, id);
      setSuccess('Certificado seleccionado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo seleccionar el certificado');
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteCertificate(dispatch, id);
      setSuccess('Certificado eliminado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo eliminadar el certificado');
    }
  };

  const canShowSelect = (id: number) => {
    const certificate = ownCertificates.find(
      (certificate) => certificate.id === id,
    );
    if (!certificate) return false;
    return !certificate?.selected;
  };

  const canShowDelete = (id: number) => {
    const certificate = ownCertificates.find(
      (certificate) => certificate.id === id,
    );
    if (!certificate) return false;
    return !certificate?.selected;
  };

  const actions = [
    {
      action: handleSelect,
      actionLabel: 'Seleccionar',
      canShow: canShowSelect,
    },
    {
      action: handleDelete,
      actionLabel: 'Eliminar',
      canShow: canShowDelete,
    },
  ];

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        title={
          showingOwn
            ? 'Estos son los certificados agregados por ti'
            : 'Estos son los certificados que han sido compartidos contigo'
        }
        buttons={buttons}
      />
      <EnhancedTable
        data={showingOwn ? ownCertificates : otherCertificates}
        dataHeaders={[
          { key: 'businessName', label: 'Nombre cuenta' },
          { key: 'taxName', label: 'Nombre firmante' },
          { key: 'taxIdentifier', label: 'Rut firmante' },
          { key: 'valid', label: 'Válido', type: 'boolean-active' },
          { key: 'selected', label: 'Seleccionado', type: 'vission' },
        ]}
        actions={showingOwn ? actions : []}
      />
      {addCertificate && (
        <AddCertificate
          open={addCertificate}
          setOpen={setAddCertificate}
          setFailed={setFailed}
          setSuccess={setSuccess}
          setUp={setUp}
        />
      )}
      <CustomAlert
        open={!!success}
        setOpen={setSuccess}
        severity="success"
        text={success}
        handleClose={() => setSuccess('')}
      />
      <CustomAlert
        open={!!failed}
        setOpen={setFailed}
        severity="warning"
        text={failed}
        handleClose={() => setFailed('')}
      />
    </div>
  );
};

export default SettingsCertificates;
