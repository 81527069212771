import { requestHandler } from '../../api';

export const getAutoEnrollmentStatus = async (
  dispatch: any,
  clientId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${clientId}/getAutoEnrollmentStatus`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompanyDetails = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/details`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCertificates = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/settings/certificates`,
    dispatch,
  });
  if (response) return response.data;
};

export const getSelectedCertificate = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/settings/certificates/selected`,
    dispatch,
  });
  if (response) return response.data;
};

export const addCertificates = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    headers,
    data,
    url: `internal/express/${companyRecieverId}/settings/certificates`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCertificate = async (
  dispatch: any,
  companyRecieverId: string,
  id: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/express/${companyRecieverId}/settings/certificates/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const selectCertificate = async (
  dispatch: any,
  companyRecieverId: string,
  id: number,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/express/${companyRecieverId}/settings/certificates/${id}/select`,
    dispatch,
  });
  if (response) return response.data;
};

export const getExpressFactoringRequestsSent = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/factoring/request`,
    dispatch,
  });
  if (response) return response.data;
};

export const getExpressFactoringRequestsSentDetails = async (
  dispatch: any,
  companyRecieverId: string,
  id: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/factoring/request/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createExpressFactoringRequest = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/factoring/request`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const approveExpressFactoringRequest = async (
  dispatch: any,
  companyRecieverId: string,
  id: number,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/factoring/request/${id}/approve`,
    dispatch,
  });
  if (response) return response.data;
};

export const executeCession = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/factoring/request/cession/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getStatusOfRelationBetweenClientAndReceiver = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/enrollment/status`,
    dispatch,
  });
  if (response) return response.data;
};

export const activateRelationBetweenReceiverAndReceiver = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/enrollment/activate`,
    dispatch,
  });
  if (response) return response.data;
};

export const deactivateRelationBetweenReceiverAndReceiver = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/enrollment/deactivate`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBlockedInvoices = async (
  dispatch: any,
  companyRecieverId: string,
  folios: string[],
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/factoring/blockedInvoices?folios=${folios.join(',')}`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressGetCompanyLogo = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/factoring/companyLogo`,
    dispatch,
  });
  if (response) return response.data;
};
