import React from 'react';
import { Typography } from '@mui/material';
import UploadList from '../../../../../lib/common/UploadList';
import { useParams } from 'react-router-dom';

const ProfileCompliance = () => {
  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  return (
    <div>
      <div
        style={{
          paddingTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant="body1">
            Estos son los documentos que ha subido el cliente
          </Typography>
        </div>
      </div>
      <UploadList
        products={['UAF', 'PEP']}
        requestType={`Clients,${id},Compliance`}
      />
    </div>
  );
};

export default ProfileCompliance;
