import React from 'react';
import { DialogActions } from '@mui/material';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import InforPreview from '../../../../../../../../../common/InforPreview';
import DialogActionsWrapper from '../DialogActionWrapper';

const ShoppingCartFactoringAccepted = (props: any) => {
  const {
    setOpenShoppingCart,
    selectedRequest,
    setSelectedRequest,
    setUp,
    handleOpenDeleteConfirmModal,
  } = props;

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      type="Recieved"
      status="Aceptada"
      selectedRequest={selectedRequest}
      setSelectedRequest={setSelectedRequest}
      setUp={setUp}
    />
  );

  console.log(selectedRequest);

  return (
    <div>
      <SimulationDoneFrozen
        selectedRequest={selectedRequest}
        infoDiv={infoDiv}
      />
      <DialogActionsWrapper
        onClose={() => setOpenShoppingCart(false)}
        handleOpenDeleteConfirmModal={() =>
          handleOpenDeleteConfirmModal(selectedRequest.id)
        }
      />
    </div>
  );
};

export default ShoppingCartFactoringAccepted;
