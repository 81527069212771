import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { handleExportSantanderXLSX } from './banks/37';
import CustomButton from '../../designSystem/Button';
import { handleExportGenericPDF } from './banks/0';

const availableBanks = [
  { id: 37, name: 'Banco Santander', export: handleExportSantanderXLSX },
  { id: 0, name: 'PDF Genérico', export: handleExportGenericPDF },
];

const NomineeExporter = (props: any) => {
  const { payments, open, setOpen, id } = props;
  const [message, setMessage] = useState(`Operación ${id}`);
  const [bankCode, setBankCode] = useState(0);
  if (open === false) return null;

  const handleExport = () => {
    const bank = availableBanks.find((x) => x.id === bankCode);
    if (bank) {
      bank.export(payments, id);
    }
  };

  const handleClose = () => {
    if (setOpen) setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Exportar</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Estos son los bancos disponibles para exportar nóminas.
          </Typography>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginBottom: '10px' }}
          >
            <InputLabel id="bank">Banco</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={bankCode}
              onChange={(e) => setBankCode(e.target.value as number)}
              label="Banco"
            >
              {availableBanks.map((bank) => (
                <MenuItem key={bank.id} value={bank.id}>
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Mensaje en glosa"
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton color="secondary" onClick={handleClose}>
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => handleExport()}
            variant="contained"
            color="primary"
            size="small"
          >
            Exportar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NomineeExporter;
