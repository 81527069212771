import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CobranzaOnline from './components/Configure';
import DetailModal from './components/DetailModal';
import { useNavigate } from 'react-router-dom';
import { getIntegrationStatus } from '../../../../lib/api';
import CobranzaOnlineDialogDetails from './components/DialogData';
import GenericCard from '../common/CardComponent';

const CobranzaOnlineDetails = {
  title: 'Cobranza Online',
  description:
    'Cobranza Online es una plataforma de cobranza que te permite gestionar tus cobranzas de forma rápida y segura.Puedes publicar en línea tus facturas y gestionar el cobro de tus clientes de forma más digital. Estás facturas serán publicadas en 3 Burós de crédito: Dicom, Transunion y Experian.',
};

interface IntegrationProps {
  id: number;
  name: string;
  description: string;
  packages: any[];
}

interface IntegrationStatus {
  integrationPackageId: number;
  createdAt: Date;
  integrationPackage: {
    id: number;
    fixedCost: number;
    description: string;
  };
  status: string;
  token: string;
  username: string;
}

const CobranzaOnlineIntegration: React.FC<IntegrationProps> = (
  props: IntegrationProps,
) => {
  const { id, description, packages } = props;
  const [loading, setLoading] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<IntegrationStatus | null>();
  const [openCobranzaOnline, setOpenCobranzaOnline] = useState(false);
  const [reload, setReload] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [integrationDetails, setIntegrationDetails] = useState({
    title: '',
    description: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openCobranzaOnlineApp = () => {
    navigate('/integrations/cobranza-online');
  };

  const getIntegrationSwitch = async () => {
    try {
      setLoading(true);
      const response = await getIntegrationStatus(dispatch, id);
      setIntegrationStatus(response.status);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDetailClick = () => {
    setOpenDetailModal(true);
    setIntegrationDetails({
      title: CobranzaOnlineDetails.title,
      description: CobranzaOnlineDetails.description,
    });
  };

  const handleConfigClick = () => {
    setOpenCobranzaOnline(true);
  };

  const handleOpenClick = () => {
    openCobranzaOnlineApp();
  };

  useEffect(() => {
    getIntegrationSwitch();
  }, [reload]);

  return (
    <>
      <GenericCard
        imageSrc="https://pruff-public.s3.amazonaws.com/Integrations/cobranzaOnline.svg"
        imageAlt="cobranzaOnlineLogo"
        description={description}
        loading={loading}
        onDetailClick={handleDetailClick}
        onConfigClick={handleConfigClick}
        onOpenClick={handleOpenClick}
        integrationStatus={integrationStatus}
      />
      <CobranzaOnline
        id={id}
        open={openCobranzaOnline}
        setOpen={setOpenCobranzaOnline}
        setReload={setReload}
        reload={reload}
        packages={packages}
      />
      <DetailModal
        open={openDetailModal}
        setOpen={setOpenDetailModal}
        title={integrationDetails.title}
        description={integrationDetails.description}
        customComponent={
          <CobranzaOnlineDialogDetails
            packages={packages}
            integrationStatus={integrationStatus}
          />
        }
      />
    </>
  );
};

export default CobranzaOnlineIntegration;
