import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import DocumentSearcher from '../../../../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../../../../lib/designSystem/TableWithPagination';
import dayjs from 'dayjs';
import { getAllInvoices } from '../../../../../../../lib/api/dashboard';

const LastInvoices = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const startDateDefault = dayjs().subtract(1, 'month');
  const endDateDefault = dayjs();
  const [endDate, setEndDate] = useState(endDateDefault);
  const [startDate, setStartDate] = useState(startDateDefault);
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchInvoices = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getAllInvoices(dispatch, query);
      setInvoices(response.data);
      setPagination(response.pagination);
    } catch (error) {
      alert('No tienes permisos para ver las facturas');
    }
    setLoading(false);
  };

  useEffect(() => {
    searchInvoices({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes buscar todas las facturas de tus enrolados"
        variant="body1"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                searchData={searchInvoices}
                setData={setInvoices}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                searchBusiness
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                pagination={pagination}
              />
            ),
          },
        ]}
      />
      <TableWithPagination
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'businessIdentifier', label: 'Rut Emisor' },
          { key: 'stakeholderName', label: 'Nombre receptor' },
          { key: 'stakeholderIdentifier', label: 'Rut receptor' },
          { key: 'issuedDate', label: 'Emisión', type: 'date' },
          {
            key: 'confirmDate',
            label: 'Confirmación',
            type: 'date-colored-ok',
          },
          { key: 'cancelDate', label: 'Reclamo', type: 'date-colored' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'status', label: 'Estado', type: 'cession' },

          { key: 'cessionary', label: 'Cesionario' },
        ]}
        exportToFile="xlsx"
        data={invoices}
        loading={loading}
        setData={setInvoices}
        searchData={searchInvoices}
        setPagination={setPagination}
        pagination={pagination}
      />
    </div>
  );
};

export default LastInvoices;
