import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../lib/designSystem/Button';
import { useParams } from 'react-router-dom';
import {
  createPaymentNotification,
  getInvoicesByEncriptionBusinessIdentifier,
} from '../../../../../lib/api';
import {
  formatMoney,
  formatMoneyBack,
} from '../../../../../lib/functions/utils';
import { format } from 'path';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';

interface NotifyPaymentProps {
  openModal: boolean;
  setOpenModal: any;
  handleClose: any;
  originInvoice?: any;
  setSnackBar: any;
  amount: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NotifyPayment: React.FC<NotifyPaymentProps> = (props) => {
  const { encryptedCompanyId, encryptedBusinessIdentifier } = useParams<{
    encryptedCompanyId: string;
    encryptedBusinessIdentifier: string;
  }>();
  console.log(props);
  const [amount, setAmount] = useState(props.amount);
  const [message, setMessage] = useState('');
  const [checkFields, setCheckField] = useState(false);
  const [invoicesIds, setInvoicesIds] = useState<string[]>(
    props.originInvoice ? [props.originInvoice] : [],
  );
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const getInvoices = async () => {
    if (
      encryptedBusinessIdentifier !== undefined &&
      encryptedCompanyId !== undefined
    ) {
      const result = await getInvoicesByEncriptionBusinessIdentifier(
        encryptedCompanyId,
        encryptedBusinessIdentifier,
      );
      setInvoices(result.invoices);
      console.log(result);
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent<typeof invoices>) => {
    const {
      target: { value },
    } = event;
    setInvoicesIds(typeof value === 'string' ? value.split(',') : value);
  };

  const postPaymentNotification = async () => {
    setCheckField(true);
    console.log(amount, message, invoicesIds);
    if (invoicesIds.length === 0) {
      return;
    }
    setSending(true);
    await createPaymentNotification({
      amount: formatMoneyBack(amount.toString()),
      message: message,
      invoices: invoicesIds,
      businessEncryptedIdentifier: encryptedBusinessIdentifier,
      companyEncryptedId: encryptedCompanyId,
    });
    setAmount(0);
    setMessage('');
    setInvoicesIds([]);
    setCheckField(false);
    setSending(false);
    props.setSnackBar({
      open: true,
      message: 'La notificación de pago ha sido enviada correctamente',
    });
    props.handleClose();
  };

  useEffect(() => {
    getInvoices();
  }, []);

  console.log(amount, invoicesIds);

  return (
    <Dialog open={props.openModal} onClose={() => props.handleClose()}>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <>
          <DialogTitle> 📢 Informar nuevo pago</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Para informar un nuevo pago debes completar los siguientes campos
            </DialogContentText>
            <div style={{ paddingTop: '2%' }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={checkFields && !amount}
                onChange={(e) => {
                  const value = formatMoneyBack(e.target.value);
                  setAmount(value);
                }}
                id="outlined-basic"
                label="Monto pagado"
                variant="outlined"
                value={formatMoney(amount).replace('$', '')}
                fullWidth
              />
            </div>
            <div style={{ paddingTop: '2%' }}>
              <FormControl fullWidth>
                <InputLabel id="select-invoices">Documentos</InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  error={checkFields && invoicesIds.length === 0}
                  multiple
                  fullWidth
                  value={invoicesIds}
                  label="Docuementos"
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  labelId="select-invoices"
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) =>
                          `#${invoices.find((invoice) => invoice.id === value)?.folio}`,
                      )
                      .join(', ')
                  }
                >
                  {invoices.map((invoice) => (
                    <MenuItem key={invoice.folio} value={invoice.id}>
                      <Checkbox checked={invoicesIds.includes(invoice.id)} />
                      <ListItemText primary={`# ${invoice.folio}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ paddingTop: '2%' }}>
              <TextField
                id="outlined-basic"
                label="Mensaje"
                variant="outlined"
                fullWidth
                multiline
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => props.handleClose()}>
              {' '}
              Cancelar{' '}
            </CustomButton>
            <CustomButton
              disabled={sending || loading}
              color="primary"
              onClick={() => {
                postPaymentNotification();
              }}
            >
              {' '}
              Notificar
            </CustomButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default NotifyPayment;
