import React, { useState } from 'react';
import { Grid } from '@mui/material';
import TreeVisualization from './Visualization';
import NodesEditables from './Configuration';
import NewNodeDialog from './NewNodeDialog';
import HeaderWithActions from '../../../../../../../../../../lib/designSystem/HeaderWithActions';

const TreeEngine = (props: any) => {
  const {
    revisionObjective,
    decisionNodesAssignation,
    setDecisionNodesAssignation,
  } = props;
  const [openNodeEditables, setOpenNodeEditables] = useState(false);
  const [openAddNode, setOpenAddNode] = useState(false);
  const [fatherNodeIdDefault, setFatherNodeIdDefault] = useState(null);
  const [counter, setCounter] = useState(
    decisionNodesAssignation.length ? decisionNodesAssignation.length + 1 : 1,
  );

  return (
    <div>
      <HeaderWithActions
        title="Evaluaciones"
        type="pageHeader"
        buttons={[
          {
            action: () => setOpenNodeEditables(!openNodeEditables),
            name: !openNodeEditables ? 'Ver Lista' : 'Cerrar Lista',
            color: 'primary',
          },
        ]}
      />
      <Grid container style={{ paddingTop: '20px' }}>
        <Grid item xs={!openNodeEditables ? 12 : 8}>
          <TreeVisualization
            nodes={decisionNodesAssignation}
            setOpenAddNode={setOpenAddNode}
            setFatherNodeIdDefault={setFatherNodeIdDefault}
          />
        </Grid>
        {openNodeEditables && (
          <Grid item xs={4}>
            <NodesEditables
              setCounter={setCounter}
              counter={counter}
              revisionObjective={revisionObjective}
              decisionNodesAssignation={decisionNodesAssignation}
              setDecisionNodesAssignation={setDecisionNodesAssignation}
              setOpenAddNode={setOpenAddNode}
            />
          </Grid>
        )}
        {openAddNode && (
          <NewNodeDialog
            setCounter={setCounter}
            counter={counter}
            openAddNode={openAddNode}
            setOpenAddNode={setOpenAddNode}
            setDecisionNodesAssignation={setDecisionNodesAssignation}
            decisionNodesAssignation={decisionNodesAssignation}
            revisionObjective={revisionObjective}
            fatherNodeIdDefault={fatherNodeIdDefault}
          />
        )}
      </Grid>
    </div>
  );
};

export default TreeEngine;
