import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { formatMoney } from '../../functions/utils';
interface DataProps {
  name: string;
  Compras: number;
  Ventas: number;
  amt: number;
}

interface BarChartProps {
  data: DataProps[];
  aspect: number;
  xKey?: string;
  xName?: string;
  yKey?: string;
  yName?: string;
  zName?: string;
  zKey?: string;
  handleSelectData?: any;
  selectedDate?: any;
  noMoney?: boolean;
  barArray?: any;
}

export const hexToRGBA = (hex: string, opacity: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const colorsArray = [
  '#0F23DF',
  '#2970FF',
  '#38A0FF',
  '#07D2FE',
  '#FFC400',
  '#07D2FE',
  '#FF3D00',
  '#FF1744',
  '#D500F9',
  '#651FFF',
];

const SimpleBarChart: React.FC<BarChartProps> = ({
  data,
  aspect,
  handleSelectData,
  noMoney,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [finalData, setFinalData] = useState<any>([]);

  const handleBarClick = (data: any, index: number) => {
    // Handle the bar click event here
    console.log(`Bar clicked at index ${index}`);
    console.log('Data:', data);
    if (!Object.keys(data).length) return;
    if (handleSelectData) {
      handleSelectData(data.activeLabel);
      if (selectedIndex === data.activeLabel) {
        setSelectedIndex(null);
      } else {
        setSelectedIndex(data.activeLabel);
      }
    }
  };

  const renderCustomBarShape = (props: any) => {
    const { fill, x, y, width, height, payload } = props;
    const isSelected = payload.name === selectedIndex;
    const newFill = !selectedIndex
      ? fill
      : isSelected
        ? fill
        : hexToRGBA(fill, 0.5); // Change color if selected

    return (
      <Rectangle
        fill={newFill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[10, 10, 0, 0]} // You can adjust radius for desired roundness
      />
    );
  };

  const barArrayDefault = createBarArray(data);

  const formatBarMoney = (value: number) => {
    if (value > 1000000000) return `${formatMoney(value / 1000000000)}MM`;
    if (value > 1000000) return `${formatMoney(value / 1000000)}M`;
    if (value > 1000) return `${formatMoney(value / 1000)}K`;
    return noMoney ? `${value}` : formatMoney(value);
  };

  console.log(data);

  const headers = new Set();
  const allVariablesSet = data.map((x: any) => {
    Object.keys(x).forEach((key: any) => {
      headers.add(key);
    });
    return x;
  });

  useEffect(() => {
    // Function to fill missing keys with default values
    const dataWithFilledBlanks = data.map((item: any) => {
      headers.forEach((header: any) => {
        if (item[header] === undefined) {
          item[header] = 0; // Default value for missing key
        }
      });
      return item;
    });

    // Update finalData state
    setFinalData(dataWithFilledBlanks);
  }, [data]);

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer aspect={aspect}>
        <BarChart
          data={finalData.length ? finalData : data}
          margin={{
            top: 5,
            right: 10,
            left: 4,
            bottom: 5,
          }}
          onClick={handleBarClick}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatBarMoney} />
          <Tooltip formatter={noMoney ? undefined : formatMoney} />
          <Legend />
          {barArrayDefault.map((bar: any, index: number) => (
            <Bar
              key={bar.name}
              name={graphNameTranslation(bar.name)}
              dataKey={bar.dataKey}
              fill={colorsArray[index]}
              shape={renderCustomBarShape}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const graphNameTranslation = (name: string) => {
  const hash: any = {
    ASSIGNED: 'Asignados',
    ANALIZED: 'Analizados',
    ASSIGNATED: 'Asignados',
    INVOICED: 'Facturados',
    CANCELED: 'Cancelados',
    PENDING: 'Pendientes',
    REJECTED: 'Rechazados',
    ACCEPTED: 'Aceptados',
    REVIEW: 'En revisión',
    buyAmount: 'Compras',
    salesAmount: 'Ventas',
    amount: 'Monto',
    buyAmountAVG: 'Compras promedio',
    salesAmountAVG: 'Ventas promedio',
    companyTotal: 'Total empresa',
    total: 'Total',
  };
  if (hash[name]) return hash[name];
  return name;
};

const createBarArray = (data: any) => {
  if (!data || !data.length) return [];

  const barArray = Object.keys(data[0])
    .filter((x: any) => x !== 'name')
    .map((x: any) => ({ name: x, dataKey: x }));
  return barArray;
};

export default SimpleBarChart;
