import React, { useState, useEffect } from 'react';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import { Typography } from '@mui/material';
import CustomPieChart from '../../../../../lib/designSystem/PieChart';
import { useDispatch } from 'react-redux';
import { getInvoiceNotConfirmed } from '../../../../../lib/api/dashboard';

const InvoicesNotConfirmedNumber: React.FC = () => {
  const dispatch = useDispatch();
  const [invoicesPieChart, setInvoicesPieChart] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await getInvoiceNotConfirmed(dispatch);
      console.log(relations.pieChart);
      setInvoicesPieChart(relations.pieChart);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  const handleClick = () => {
    window.location.href = `/home/notConfirmed`;
  };

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhacedBox>
        <Typography>Facturas por confirmar</Typography>
        <CustomPieChart onClick={handleClick} data={invoicesPieChart} />
      </EnhacedBox>
    </div>
  );
};

export default InvoicesNotConfirmedNumber;
