import React from 'react';

import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { formatMoney } from '../../../../../../../../../lib/functions/utils';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { createNotRecognizedPayment } from '../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';

const FNCDialog = (props: any) => {
  const { payIn, setOpen, setBigOpen, open, setSuccessOpen, setUp } = props;
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const submitFNC = async () => {
    try {
      setLoading(true);
      const payload = {
        amount: payIn.amount - payIn.amountConciliated,
        payInId: payIn.id,
      };
      await createNotRecognizedPayment(dispatch, payload);
      await setUp();
      setSuccessOpen('FNC generado correctamente');
      setOpen(false);
      setBigOpen(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Generar FNC</DialogTitle>
      {loading ? (
        <div style={{ minHeight: 200 }}>
          <CenteredCircularProgress type="dialog" />
        </div>
      ) : (
        <DialogContent>
          <Typography margin={'8px 0px'}>
            Aquí podrás crear un FNC por el monto restante de la conciliación.
            Esto significará que el pago recibido quedará conciliado y luego
            podrás ir a la pestaña de FNC y asignar las cuentas pendientes.
          </Typography>
          <Typography>
            ¿Estás seguro que deseas generar un FNC por el monto de{' '}
            <b>{formatMoney(payIn.amount - payIn.amountConciliated)}?</b>
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
        <CustomButton onClick={() => submitFNC()} color="primary">
          Generar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default FNCDialog;
