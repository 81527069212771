// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { confirmUserRegistrationCode } from '../../../lib/api';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

const ConfirmUserRegistration = () => {
  const [loading, setLoading] = useState(true);
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState(false);
  const confirmCodeUser = async () => {
    try {
      setLoading(true);
      const userResponse = await confirmUserRegistrationCode({ code });
      if (!userResponse.success) {
        setError(true);
      }
      setTimeout(() => {
        window.location.href = `/`;
      }, 2000);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  useEffect(() => {
    confirmCodeUser();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  if (error) {
    return (
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <Typography variant="h4">Error</Typography>
        <Typography variant="h6">
          El código ingresado no tiene validez
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
        <EnhancedBox>
          <Typography variant="h5">¡Bienvenido a nuestro portal!</Typography>
          <Typography variant="body1">
            Tu registro ha sido confirmado
          </Typography>
          <Typography variant="body1">
            Te estamos redirigiendo a la página de inicio o si prefieres ya
            puedes <a href={`/`}>iniciar sesión</a>
          </Typography>
        </EnhancedBox>
      </div>
    </div>
  );
};

export default ConfirmUserRegistration;
