import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ClientConfigState = {
  clientId: string;
  configs: any;
};

const initialState: ClientConfigState = {
  clientId: '',
  configs: {},
};

export const clientConfigSlice = createSlice({
  name: 'clientConfig',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<any>) => {
      state.configs = action.payload;
    },
    // You can add more reducers here if needed for other types of notifications
  },
});

export const { setConfig } = clientConfigSlice.actions;

export default clientConfigSlice.reducer;
