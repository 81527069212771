import React from 'react';
import CustomButton from '../Button';
import { exportToExcel } from './utils/exportToExcel';
import { exportToCsv } from './utils/exportToCsv';
import { Download } from '@mui/icons-material';
import { Header } from '../Table';

interface DataExporterProps {
  buttonText?: string;
  buttonColor?: 'primary' | 'secondary' | 'warning' | 'success' | 'transparent';
  data: any[];
  fileName?: string;
  fileType: 'csv' | 'xlsx';
  headers: Header[];
}

const DataExporter: React.FC<DataExporterProps> = (props) => {
  const { data, fileName, fileType, headers, buttonColor, buttonText } = props;

  const exportData = () => {
    switch (fileType) {
      case 'csv':
        exportToCsv(data, headers, fileName);
        break;
      case 'xlsx':
        exportToExcel(data, headers, fileName);
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => exportData()}
    >
      <Download fontSize="medium" />
      {/* <Typography style={{ marginLeft: '4px' }} onClick={() => exportData()}>
        {buttonText ?? 'Exportar'}
      </Typography> */}
    </div>
  );
};

export default DataExporter;
