export const CHILEAN_HOLIDAYS = [
  new Date('2024-01-01'),
  new Date('2024-03-29'),
  new Date('2024-03-30'),
  new Date('2024-05-01'),
  new Date('2024-05-21'),
  new Date('2024-06-20'),
  new Date('2024-06-29'),
  new Date('2024-07-16'),
  new Date('2024-08-15'),
  new Date('2024-09-18'),
  new Date('2024-09-19'),
  new Date('2024-09-20'),
  new Date('2024-10-12'),
  new Date('2024-10-31'),
  new Date('2024-11-01'),
  new Date('2024-12-08'),
  new Date('2024-12-25'),
];
