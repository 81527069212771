import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import {
  assignDocumentToFund,
  getDocumentForAssignation,
} from '../../../../../../../../lib/api';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Stakeholder' },
  { key: 'amount', label: 'Monto', type: 'money' },
];

const FundAssignation = (props: any) => {
  const { open, setOpen, fund, setSuccess, setUp } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);

  const fetchDocumentsForAssignation = async () => {
    setLoading(true);
    try {
      const response = await getDocumentForAssignation(dispatch, {
        id: fund.id,
      });
      setDocuments(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleAssignDocumentsToFund = async () => {
    setLoading(true);
    try {
      const documentToAssgin = documents.filter((document) =>
        selected.includes(document.id),
      );
      const response = await assignDocumentToFund(dispatch, {
        id: fund.id,
        documents: documentToAssgin,
      });
      setDocuments(response.actualDocuments);
      setSuccess('Documentos asignados correctamente');
      setOpen(false);
      await setUp();
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDocumentsForAssignation();
  }, []);

  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <HeaderWithActions title={`Asignar facturas`} type="dialog" />
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Typography variant="body1">
              Seleccione las facturas que desea asignar al fondo{' '}
              <b>{fund.name}</b>
            </Typography>
            <EnhancedTable
              dataHeaders={headers}
              data={documents}
              rowsPerPageDefault={50}
              selected={selected}
              setSelected={setSelected}
            />
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          <CustomButton
            onClick={() => handleAssignDocumentsToFund()}
            color="primary"
            disabled={selected.length === 0}
          >
            Asignar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FundAssignation;
