import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../services/redux/authSlice';
import DecisionTreeConfig from './Components/DecisionEngines/components/Show';
import WhiteList from './Components/Whitelist';
import Blacklist from './Components/BlackList';
import DecisonNodesFinal from './Components/DecisionNodes';
import CompanyComitees from './Components/Comitee';

const companyTabs = [
  {
    label: 'Motores',
    component: <DecisionTreeConfig />,
    path: 'risk/engines',
    subPage: 'engines',
    index: 0,
  },
  {
    label: 'Nodos decisión',
    component: <DecisonNodesFinal />,
    path: 'risk/decisionNodes',
    subPage: 'decisionNodes',
    index: 1,
  },
  {
    label: 'Comités',
    component: <CompanyComitees />,
    path: 'risk/comitees',
    subPage: 'comitees',
    index: 2,
  },
  {
    label: 'WhiteList',
    component: <WhiteList />,
    path: 'risk/whitelists',
    subPage: 'whitelists',
    index: 3,
  },
  {
    label: 'BlackList',
    component: <Blacklist />,
    path: 'risk/blacklist',
    subPage: 'blacklist',
    index: 4,
  },
];

const RiskTab: React.FC = () => {
  const { subPage } = useParams<{
    subPage: string;
  }>();
  const navigate = useNavigate();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [startValue, setStartValue] = useState(0);

  const setUpTabs = () => {
    const startValueFind = companyTabs.findIndex(
      (x: any) => x.subPage === subPage,
    );
    console.log('startValueFind', startValueFind);
    console.log('subPage', subPage);
    if (startValueFind !== -1) return setStartValue(startValueFind);
    setStartValue(0);
  };

  const handleNavigation = (index: number) => {
    navigate(`/settings/${companyTabs[index].path}`);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  return (
    <EnhancedTab
      startValue={startValue}
      tabs={companyTabs}
      onTabChange={handleNavigation}
    />
  );
};

export default RiskTab;
