import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';

import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import {
  getAvailableSubProductsForFactoring,
  getDocumentsAvailablesForFactoring,
} from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CompanyBusinessSearcher from '../../../../../../lib/common/CompanyBusinessSearcher';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';

const DocumentsSelectorDialog = (props: any) => {
  const {
    open,
    setOpen,
    setSelectedDocuments,
    selectedDocuments,
    setNewOpenShoppingCart,
    business,
    setBusiness,
    setDocumentAdder,
    setOpenInvoicesAdder,
    subProductSelected,
    setSubProductSelected,
    setSubProduct,
    backwards,
    setBackwards,
  } = props;
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<any>([]);
  const [searchedBySubProduct, setSearchedBySubProduct] = useState<any>(false);
  const [documents, setDocuments] = useState<any>([]);
  const [subProducts, setSubProducts] = useState<any>([]);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [createWithoutCreditline, setCreateWithoutCreditline] = useState(false);

  const handleSearchSuggestedDocuments = async () => {
    setLoading(true);
    try {
      const documentsResponse = await getDocumentsAvailablesForFactoring(
        dispatch,
        business.id,
        subProductSelected,
      );
      setDocuments(documentsResponse.documents);
      setIsEnrolled(documentsResponse.isEnrolled);
      setSearchedBySubProduct(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setAvailableSubProducts = async () => {
    setLoading(true);
    try {
      const { subProducts, canCreateWithoutCreditLine } =
        await getAvailableSubProductsForFactoring(dispatch, business.id);
      setCreateWithoutCreditline(canCreateWithoutCreditLine);
      setSubProducts(subProducts);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSelectProduct = (id: any) => {
    const subProduct = subProducts.find(
      (subProduct: any) => subProduct.id === id,
    );
    setSubProductSelected(id);
    setSubProduct(subProduct);
    if (searchedBySubProduct) {
      setSearchedBySubProduct(false);
    }
  };

  const handleOpenDialogAdder = () => {
    const subProduct = subProducts.find(
      (subProduct: any) => subProduct.id === subProductSelected,
    );
    if (subProduct.category === 'invoice') {
      setOpenInvoicesAdder(true);
    } else {
      setDocumentAdder(true);
    }
    setOpen(false);
  };

  const handleSelectDocuments = async () => {
    setLoading(true);
    const documentselected: any[] = documents.filter((document: any) =>
      selected.includes(document.id),
    );
    if (!documentselected) {
      return;
    }
    setLoading(false);
    setSelectedDocuments(documentselected);
    setNewOpenShoppingCart(true);
    setOpen(false);
  };

  const handleBackwardsPrefill = async () => {
    setSearched(true);
    setSearchedBySubProduct(true);
    await handleSearchSuggestedDocuments();
    await setAvailableSubProducts();
    setSelected(selectedDocuments.map((document: any) => document.id));
  };

  useEffect(() => {
    if (backwards && !searched) {
      handleBackwardsPrefill();
    } else if (searched && !backwards) {
      setAvailableSubProducts();
    }
  }, [searched]);

  return (
    <Dialog open={open} fullWidth maxWidth={searched ? 'md' : 'sm'}>
      <DialogTitle>
        <HeaderWithActions title="Nueva solicitud" type="dialogHeader" />
      </DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          {searched ? (
            <div>
              <div
                style={{
                  padding: '10px 0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1">
                  <b>Cliente:</b> {business.businessName}
                </Typography>
                <Typography
                  variant="caption"
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSearched(false);
                    setSearchedBySubProduct(false);
                    setBackwards(false);
                    setSelectedDocuments([]);
                    setSelected([]);
                  }}
                >
                  Cambiar
                </Typography>
              </div>
              {subProducts.length === 0 ? (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  padding="8px 0px"
                  color="#E8C547"
                >
                  Este cliente no tiene subproductos disponibles para
                  factorizar. Por favor revise si su línea de crédito está
                  activa
                </Typography>
              ) : (
                <div>
                  <Typography variant="body1">
                    Selecciona un subproducto disponible:
                  </Typography>
                  {createWithoutCreditline && (
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      padding="8px 0px"
                      color="#E8C547"
                    >
                      Este cliente puede no tener una línea de crédito activa
                    </Typography>
                  )}
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="filter-label">Subproducto</InputLabel>
                        <Select
                          labelId="filter-label"
                          id="Filtrar por estado"
                          fullWidth
                          value={subProductSelected}
                          onChange={(e) => handleSelectProduct(e.target.value)}
                          label="Subproducto"
                        >
                          {subProducts.map((x: any, index: any) => (
                            <MenuItem key={index} value={x.id}>
                              {x.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} textAlign={'right' as any}>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleSearchSuggestedDocuments()}
                        disabled={!subProductSelected || searchedBySubProduct}
                      >
                        {' '}
                        Ver documentos
                      </CustomButton>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          ) : (
            <div>
              <CompanyBusinessSearcher
                setBusiness={setBusiness}
                setSearched={setSearched}
              />
            </div>
          )}
          {searchedBySubProduct && (
            <div style={{ padding: '20px 0px' }}>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Typography variant="h6" gutterBottom>
                    Documentos disponibles{' '}
                    {isEnrolled ? '(Conectado SII)' : '(No conectado SII)'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Estas son los documentos disponibles para factorizar en este
                    subproducto
                  </Typography>
                </div>
                <div>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialogAdder()}
                    disabled={isEnrolled}
                  >
                    {' '}
                    Agregar documentos
                  </CustomButton>
                </div>
              </div>
              <div>
                <EnhancedTable
                  dataHeaders={[
                    { key: 'folio', label: 'Folio' },
                    { key: 'amount', label: 'Monto', type: 'money' },
                    {
                      key: 'amountAvailable',
                      label: 'Monto disponible',
                      type: 'money',
                    },
                    {
                      key: 'stakeholderName',
                      label: 'Nombre receptor',
                    },
                    {
                      key: 'issuedDate',
                      label: 'Fecha de emisión',
                      type: 'date',
                    },
                    {
                      key: 'confirmDate',
                      label: 'Fecha de confirmación',
                      type: 'date',
                    },
                  ]}
                  data={documents}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </div>
          )}
          <DialogActions>
            <CustomButton
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              {' '}
              Salir
            </CustomButton>
            {}
            {searchedBySubProduct && (
              <CustomButton
                variant="contained"
                color="primary"
                disabled={!selected.length}
                onClick={handleSelectDocuments}
              >
                {' '}
                Seleccionar
              </CustomButton>
            )}
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DocumentsSelectorDialog;
