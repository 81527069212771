import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import {
  formatDate,
  validatePermissions,
} from '../../../../../lib/functions/utils';
import { getAllFactoringReSelling } from '../../../../../lib/api/reSell/factoring';
import ResellDetails from './components/ResellDetails';
import { Autocomplete, TextField } from '@mui/material';
import { AuthState } from '../../../../../services/redux/authSlice';
import MultiResellMain from './components/NewMultiResell';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'id', label: 'Folio' },
  { key: 'folio', label: 'Folio documento' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'fundName', label: 'Fondo' },
  { key: 'amount', label: 'Monto documento', type: 'money' },
  { key: 'dueDate', label: 'Fecha vencimiento', type: 'date' },
  { key: 'status', label: 'Estado', type: 'status' },
];

const FactoringResells: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [resells, setResells] = useState<any>([]);
  const [filteredResell, setFilteredResell] = useState<any[]>([]);
  const [openNewResell, setOpenNewResell] = useState(false);
  const [openMultiSales, setOpenMultiSales] = useState(false);
  const [edit, setEdit] = useState(false);
  const newResell = {
    rate: '',
    operationCostFixed: 0,
    operationCostVariable: 0,
    rateCost: 0,
    operationCostRateVariable: '',
    startDate: formatDate(new Date()),
    endDate: formatDate(new Date()),
    documentId: null,
    observation: '',
  };
  const [resell, setResell] = useState(newResell);
  const fetchResellings = async () => {
    setLoading(true);
    try {
      const response = await getAllFactoringReSelling(dispatch);
      setResells(response);
      setFilteredResell(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleNewResell = () => {
    setEdit(false);
    setResell(newResell);
    setOpenNewResell(true);
  };

  const handleNewMultiSales = () => {
    setOpenMultiSales(true);
  };

  const handleDetails = async (id: string) => {
    const resellSearched = resells.find((resell: any) => resell.id === id);
    if (!resellSearched) return;
    setResell(resellSearched);
    setOpenNewResell(true);
    setEdit(true);
  };

  const actions: any = [
    {
      actionLabel: 'Ver',
      action: handleDetails,
    },
  ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleNewMultiSales(),
      name: 'Multi venta',
      color: 'secondary',
      disabled: !validatePermissions(user, 'financing_fundSale_new'),
    },
    {
      action: () => handleNewResell(),
      name: 'Nueva venta',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_fundSale_new'),
    },
  ];

  useEffect(() => {
    fetchResellings();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredResell(resells);
    console.log(newValue);
    const filtered = resells.filter((x: any) => x.fundName === newValue);
    setFilteredResell(filtered);
  };

  const filter = (
    <Autocomplete
      options={[...new Set(resells.map((resell: any) => resell.fundName))]}
      getOptionLabel={(option: any) => `${option}`}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ minWidth: 250 }}
          label="Filtrar por fondo"
        />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las ventas a fondos."
        variant="body1"
        buttons={buttonsData}
        item={filter}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={filteredResell}
        rowsPerPageDefault={50}
        actions={actions}
        showActions
        exportToFile="xlsx"
      />
      {openNewResell && (
        <ResellDetails
          resell={resell}
          setResell={setResell}
          open={openNewResell}
          setOpen={setOpenNewResell}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchResellings}
          edit={edit}
          setEdit={setEdit}
        />
      )}
      {openMultiSales && (
        <MultiResellMain
          open={openMultiSales}
          setOpen={setOpenMultiSales}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchResellings}
        />
      )}

      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title={failure}
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default FactoringResells;
