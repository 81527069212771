import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import ExecutedCreditLineEvaluations from './CreditLine';

const companyTabs = [
  {
    label: 'Línea de crédito',
    component: <ExecutedCreditLineEvaluations />,
    path: 'executed/creditLine',
    subPage: 'creditLine',
    index: 0,
  },
];

const RiskTab: React.FC = () => {
  const { subPage } = useParams<{
    subPage: string;
  }>();
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);

  const setUpTabs = () => {
    const startValueFind = companyTabs.findIndex(
      (x: any) => x.subPage === subPage,
    );
    console.log('startValueFind', startValueFind);
    console.log('subPage', subPage);
    if (startValueFind !== -1) return setStartValue(startValueFind);
    setStartValue(0);
  };

  const handleNavigation = (index: number) => {
    navigate(`/creditHubPro/executed/${companyTabs[index].path}`);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  return (
    <EnhancedTab
      startValue={startValue}
      tabs={companyTabs}
      onTabChange={handleNavigation}
    />
  );
};

export default RiskTab;
