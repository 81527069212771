import {
  DialogContent,
  Grid,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentsTable from './components/DocumentsTable';
import PopperPayers from './components/PopperPayers';
import PopperUniqueDate from './components/PopperUniqueDate';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { expressGetFileStatusForDocuments } from '../../../../../../../../../lib/api/express/factoring';
import { APP_DOMAINS } from '../../../../../../../../../App';

const Step1 = (props: any) => {
  const {
    selectedDocuments,
    requestPayers,
    setRequestPayers,
    setSelectedDocuments,
    exchangeDay,
    setTempPaymentDateRows,
    tempPaymentDateRows,
    setTempSubDocumentPaymentDateRows,
    tempSubDocumentPaymentDateRows,
    backwards,
    canForcePaymentDate,
    minimumDays,
    operationDate,
  } = props;
  const [loading, setLoading] = useState(false);
  const [showPayers, setShowPayers] = useState(false);
  const [showUniqueDate, setShowUniqueDate] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const domain = window.location.hostname; // Obtiene 'banpro.pruff.com'
  const subdomain = domain.split('.')[0]; // Obtiene 'banpro'
  let isExpress = false;
  if (!APP_DOMAINS.includes(subdomain)) isExpress = true;

  const [placement, setPlacement] =
    React.useState<PopperPlacementType>('right');

  const handleClickPopper =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setShowPayers((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleClickPopperUniqueDate =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setShowUniqueDate((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const dispatch = useDispatch();

  const setUp = async () => {
    try {
      setLoading(true);
      if (isExpress) await fetchFileStatusPerDocumentForExpress();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFileStatusPerDocumentForExpress = async () => {
    try {
      const response = await expressGetFileStatusForDocuments(
        dispatch,
        subdomain,
        selectedDocuments,
      );
      setSelectedDocuments(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography padding="0px 0px 8px" variant="h6">
          Documentos seleccionados:
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                padding="4px 0px"
                minWidth={160}
                variant="body1"
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={handleClickPopper('right-start')}
                color="primary"
              >
                {showPayers ? 'Ocultar' : 'Fecha por pagador'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                padding="4px 0px 0px 10px"
                variant="body1"
                textAlign={'right' as any}
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={handleClickPopperUniqueDate('right-start')}
                color="primary"
              >
                Fecha única
              </Typography>
            </Grid>
          </Grid>
        </div>
        <PopperPayers
          selectedDocuments={selectedDocuments}
          requestPayers={requestPayers}
          setRequestPayers={setRequestPayers}
          setSelectedDocuments={setSelectedDocuments}
          showPayers={showPayers}
          setShowPayers={setShowPayers}
          anchorEl={anchorEl}
          handleClickPopper={handleClickPopper}
          placement={placement}
          setTempPaymentDateRows={setTempPaymentDateRows}
          setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
          canForcePaymentDate={canForcePaymentDate}
          minimumDays={minimumDays}
          operationDate={operationDate}
        />
        <PopperUniqueDate
          open={showUniqueDate}
          setOpen={setShowUniqueDate}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          exchangeDay={exchangeDay}
          anchorEl={anchorEl}
          handleClickPopper={handleClickPopper}
          placement={placement}
          setTempPaymentDateRows={setTempPaymentDateRows}
          setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
          canForcePaymentDate={canForcePaymentDate}
          minimumDays={minimumDays}
          operationDate={operationDate}
        />
      </div>
      <DocumentsTable
        selectedDocuments={selectedDocuments}
        requestPayers={requestPayers}
        setRequestPayers={setRequestPayers}
        setSelectedDocuments={setSelectedDocuments}
        exchangeDay={exchangeDay}
        setTempPaymentDateRows={setTempPaymentDateRows}
        tempPaymentDateRows={tempPaymentDateRows}
        setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
        tempSubDocumentPaymentDateRows={tempSubDocumentPaymentDateRows}
        backwards={backwards}
        canForcePaymentDate={canForcePaymentDate}
        minimumDays={minimumDays}
        isExpress={isExpress}
        clientId={subdomain}
        operationDate={operationDate}
      />
    </DialogContent>
  );
};

export default Step1;
