import { CardActions, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { renderSelectedNames } from '../../../../../../lib/functions/utils';
import { editTemporaryView, getAllSiiViews } from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const EditConnection = (props:any) => {
  const {
    open,
    setOpen,
    setFailed,
    activateSuccess,
    activateFailed,
    setUp,
    connection
  } = props
  const [views, setViews] = useState([])
  const [loading, setLoading] = useState(false)
  const [factorings, setFactorings] = useState([])
  const dispatch = useDispatch();
  console.log(connection)


  const [formData, setFormData] = useState<any>({
    visibilityDuration: null,
    visibilityAvailable: connection.siiViews.map((x: any) => x.id),
    validUntil: null,
  });

  const handleSubmitRequest = async() => {
    setLoading(true)
    try {
      const payload = {
        businessId: connection.businessId,
        recipientId: connection.recipientId,
        ...formData,

      }
      console.log(payload)
      await editTemporaryView(dispatch, payload)
      activateSuccess(<p>La vista compartida fue editada.</p>)
    } catch (error) {
      setFailed(true)
      console.log(error)
      activateFailed(<p>La vista compartida no pudo ser editada.</p>)
    }
    await setUp()
    setLoading(false)
    setOpen(false)
  }

  const handleChange = (e: any) => {
    const value = e.target.value;
    // Check if the target has the 'options' property, which is true for multiple selects
    const isMultiple = e.target.options;
  
    console.log(e.target.name,  value)
    if (isMultiple) {
      // If it's a multiple select, create an array of the selected values
      const selectedValues = Array.from(e.target.options)
                                  .filter((option: any) => option.selected)
                                  .map((option: any) => option.value);
      setFormData({
        ...formData,
        [e.target.name]: selectedValues
      });
    } else {
      // If it's a single select, set the value as it is
      setFormData({
        ...formData,
        [e.target.name]: value
      });
    }
  };

  useEffect(() => {
    const setUp = async () => {
      setLoading(true)
      const viewsResults = await getAllSiiViews(dispatch)
      setViews(viewsResults || [])
      setLoading(false)
    }
    setUp();
  }, [])

  const validateFactoringsConfig = () => {
    if (!formData.visibilityAvailable.length 
      || !formData.visibilityDuration
    ) return true
    return false
  }

  const handleDateChange = (id: string, newValue: any) => {
    setFormData({
      ...formData,
      [id]: newValue,
    });
  };
  
  return (
    <Dialog fullWidth open={open} onClose={() => setOpen()}>
     <DialogTitle ><span style={{fontWeight: 'bold'}}>Editar conexión</span> ({connection.businessName})</DialogTitle>
     <DialogContent>
       {loading ? <CenteredCircularProgress type='dialog'/> : 
       <div>
        <div style={{padding: 10}}>
        <Typography><b>Empresa: </b>{connection.companyName}</Typography>
        </div>
      <div style={{ padding: 10 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Seleccione las pestañas a mostrar</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.visibilityAvailable}
            multiple
            label="Seleccione las pestañas a mostrar"
            onChange={(e) => handleChange(e)}
            name='visibilityAvailable'
            renderValue={(selected) => renderSelectedNames(selected, views)}
            >
            {views.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                <Checkbox checked={formData.visibilityAvailable.indexOf(x.id) > -1} />
                <ListItemText primary={x.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{padding: 10}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Duración visibilidad</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.visibilityDuration}
            label="Clientes disponibles"
            onChange={(e) => handleChange(e)}
            name='visibilityDuration'
          >
            <MenuItem value='always_visible'>Sin límite</MenuItem>
            <MenuItem value='temporary_visible'>Fecha</MenuItem>
          </Select>
        </FormControl>
      </div>
      { formData.visibilityDuration === 'temporary_visible' && 
       <LocalizationProvider dateAdapter={AdapterDayjs}>
       <div style={{padding:10, display:'flex'}}>
         <DatePicker
           value={formData.validUntil}
           onChange={(newValue: any) => handleDateChange('validUntil', newValue)}
         />
       </div>
     </LocalizationProvider>
      }
       </div>}
     </DialogContent>
     <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
        <CustomButton color='primary' disabled={validateFactoringsConfig()} onClick={() => handleSubmitRequest()}>
          Agregar
        </CustomButton>
      </DialogActions>
     </Dialog>
  );
}

export default EditConnection;