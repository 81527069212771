import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import DiscountDetails from './components/DiscountDetails';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { getAllDocumentDiscounts } from '../../../../../../lib/api/documentDiscount';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { validatePermissions } from '../../../../../../lib/functions/utils';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'id', label: 'Folio' },
  { key: 'folio', label: 'Folio documento' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'amount', label: 'Monto documento', type: 'money' },
  { key: 'executedAt', label: 'Válido desde', type: 'date' },
];

const Discounts: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [discounts, setDiscounts] = useState<any>([]);
  const [openNewDiscount, setOpenNewDiscount] = useState(false);
  const [edit, setEdit] = useState(false);
  const newDiscount = {
    documentId: '',
    amount: 0,
  };
  const [discount, setDiscount] = useState(newDiscount);
  const fetchDocumentDiscounts = async () => {
    setLoading(true);
    try {
      const response = await getAllDocumentDiscounts(dispatch);
      setDiscounts(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleNewDiscount = () => {
    setEdit(false);
    setDiscount(newDiscount);
    setOpenNewDiscount(true);
  };

  // const handleDetails = async (id: string) => {
  //   const discountSearched = discounts.find(
  //     (discount: any) => discount.id === id,
  //   );
  //   if (!discountSearched) return;
  //   setDiscount(discountSearched);
  //   setOpenNewDiscount(true);
  //   setEdit(true);
  // };

  // const actions: any = [
  //   {
  //     actionLabel: 'Ver',
  //     action: handleDetails,
  //   },
  // ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleNewDiscount(),
      name: 'Nuevo descuento',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_discount_new'),
    },
  ];

  useEffect(() => {
    fetchDocumentDiscounts();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todos los descuentos que hayas realizado."
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={discounts}
        rowsPerPageDefault={50}
        exportToFile="xlsx"

        // actions={actions}
        // showActions
      />
      {openNewDiscount && (
        <DiscountDetails
          discount={discount}
          setDiscount={setDiscount}
          open={openNewDiscount}
          setOpen={setOpenNewDiscount}
          setSuccess={setSuccess}
          setFailure={setFailure}
          setUp={fetchDocumentDiscounts}
          edit={edit}
        />
      )}

      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title={failure}
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Discounts;
