// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserEnrollment } from '../../services/redux/authSlice';  // Import the action
import { getAssistantResponseADC, getUser, startEnrolation, validateCredentials } from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/store';
import { Checkbox, FormControlLabel, Grid, IconButton, TextField, Theme, Typography } from '@mui/material';
import EnhancedBox from '../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CustomButton from '../../lib/designSystem/Button';
import CustomAlert from '../../lib/designSystem/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HeaderWithActions from '../../lib/designSystem/HeaderWithActions';
import { get } from 'http';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',  // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },    
    userInfoSection: {
      padding: '32px',
      textAlign: 'center'
    },
    pairSection: {
      padding: '10px'
    }
  })
);


const Assistant = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [termsAgree, setTermsAgree] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userProfile, setUserProfile] = useState({name: '', email: '', encryptedId: '', company: {taxIdentifier: '', name: ''}})
  const auth = useSelector((state: RootState) => state.auth);
  const [textPrompt, setTextPrompt] = useState('')
  const [textResponse, setTextResponse] = useState('')

  const [loadingMessage, setLoadingMessage] = useState('')


  const handleSubmit = async () => {
    try {
      setLoading(true)
      const response = await getAssistantResponseADC(dispatch, {textPrompt})
      console.log(response)
      setTextResponse(response)
    } catch (error) {
      
    }

    setLoading(false)
  }


  if (loading) return <CenteredCircularProgress type='layout' text={loadingMessage}/>

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh'}}>
      <HeaderWithActions title='Asistente' />
      <EnhancedBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' align='center'>
              Hola, soy tu asistente virtual, ¿En qué puedo ayudarte?
            </Typography>
          </Grid>
          <Grid item xs={10}>
          <TextField
            label="Escribe tu pregunta aqui"
            variant="outlined"
            fullWidth
            value={textPrompt}
            onChange={(e) => setTextPrompt(e.target.value)}
          />
          </Grid>
          <Grid item xs={2}>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!textPrompt}
          >
          Enviar
          </CustomButton>
        </Grid>
        </Grid>       
      </EnhancedBox>
      <div style={{paddingTop: 10}}>
      <EnhancedBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' align='center'>
              Respuesta
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography dangerouslySetInnerHTML={{ __html: textResponse }} variant='body1' />
            </Grid>
        </Grid>
      </EnhancedBox>
      </div>
    </div>)
};

export default Assistant;
