import React from 'react';
import { useSelector } from 'react-redux';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import InforPreview from '../../../../../../../../../common/InforPreview';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';
import { validateCompanyTypes } from '../../../../../../../../../../../../../lib/functions/utils';
import DialogActionsWrapper from '../DialogActionWrapper';

const ShoppingCartFactoringQuoted2 = (props: any) => {
  const {
    setOpenShoppingCart,
    selectedRequest,
    handleRejectQuote,
    handleQuote,
    handleOpenDeleteConfirmModal,
  } = props;

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      type="Recieved"
      status="Cotizada"
      selectedRequest={selectedRequest}
    />
  );
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const canAccept = validateCompanyTypes(user, 'FinancingHub');

  return (
    <div>
      <SimulationDoneFrozen
        infoDiv={infoDiv}
        selectedRequest={selectedRequest}
      />
      <DialogActionsWrapper
        onClose={() => setOpenShoppingCart(false)}
        handleOpenDeleteConfirmModal={() =>
          handleOpenDeleteConfirmModal(selectedRequest.id)
        }
      >
        <CustomButton color="error" onClick={() => handleRejectQuote()}>
          Cancelar solicitud
        </CustomButton>
        {canAccept && (
          <CustomButton color="warning" onClick={() => handleQuote('ACCEPTED')}>
            Aceptar
          </CustomButton>
        )}
      </DialogActionsWrapper>
    </div>
  );
};

export default ShoppingCartFactoringQuoted2;
