import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Dialog,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAndAssignPendingAccountsToNotRecognizedPayment } from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { validaRut } from '../../../../../../../../lib/functions/utils';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import AddInvoicesForm from '../../../../../Financing/common/invoicesAdder/invoicesForm';

const AddPendingAccountToFNC = (props: any) => {
  const {
    notRecognizedPayment,
    setSuccessOpen,
    setFailureOpen,
    setOpen,
    open,
    setInvoices,
    invoices,
    setUp,
    setBigOpen,
  } = props;
  const [loading, setLoading] = useState(false);

  const onChangePayTo = (e: any) => {
    const { value, name } = e.target;
    setInvoices((prev: any) =>
      prev.map((invoice: any) => {
        if (invoice.id === name) {
          return {
            ...invoice,
            payTo: value,
          };
        }
        return invoice;
      }),
    );
  };

  const validatePayTo = () => {
    if (invoices.length === 0) return false;
    return invoices.every(
      (invoice: any) => invoice.payTo && validaRut(invoice.payTo),
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!validatePayTo()) {
      setFailureOpen(
        'Debes completar el campo de "Pagar al RUT" correctamente. El rut no debe contener puntos pero debe contener guión.',
      );
      setLoading(false);
      return;
    }
    try {
      await createAndAssignPendingAccountsToNotRecognizedPayment(
        dispatch,
        notRecognizedPayment.id,
        { pendingAccounts: invoices },
      );
      setSuccessOpen('Se han creado las cuentas pendientes correctamente');
      await setUp();
      setBigOpen(false);
      setOpen(false);
    } catch (error) {
      setFailureOpen(
        'Ha ocurrido un error al intentar crear las cuentas pendientes',
      );
    }

    setLoading(false);
  };

  const dispatch = useDispatch();

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      style={{ minHeight: 300 }}
    >
      <DialogTitle>Agregar cuentas pendientes - FNC</DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          <AddInvoicesForm
            setInvoices={setInvoices}
            invoices={invoices}
            stakeholderIdentifier={notRecognizedPayment.stakeholderIdentifier}
          />
          {invoices.length > 0 && (
            <div>
              <Divider />
              <Typography variant="body1" fontWeight="bold" margin={'4px 0px'}>
                Facturas verificadas
              </Typography>
              <EnhancedTable
                data={invoices}
                dataHeaders={[
                  { key: 'folio', label: 'Folio' },
                  { key: 'businessIdentifier', label: 'Rut emisor' },
                  { key: 'stakeholderIdentifier', label: 'Rut receptor' },
                  { key: 'issuedDate', label: 'Fecha emisión' },
                  {
                    key: 'amount',
                    label: 'Monto',
                    type: 'money',
                  },
                  {
                    key: 'payTo',
                    label: 'Pagar al RUT',
                    handleChange: onChangePayTo,
                  },
                ]}
              />
              <Typography variant="body1" fontWeight="bold" margin={'4px 0px'}>
                Al terminar se{' '}
                {invoices.length === 1 ? 'generará' : 'generarán'}{' '}
                {invoices.length}{' '}
                {invoices.length === 1
                  ? 'cuenta pendiente'
                  : 'cuentas pendientes'}
                .
              </Typography>
            </div>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => {
            setOpen(false);
          }}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => handleSubmit()}
          disabled={!invoices.length || loading}
        >
          Crear
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddPendingAccountToFNC;
