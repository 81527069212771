import { requestHandler } from '../../api';

export const checkSIIDocumentForUploadingInvoices = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/sii/checkDocuments`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const confirmInvoices = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/sii/confirmInvoices`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const executeInvoiceGeneration = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/sii/invoicing/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessWithCertificates = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/sii/invoicing/certificates/availables`,
    dispatch,
  });
  if (response) return response.data;
};
