// LoginForm.tsx
import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CustomButton from '../../../../lib/designSystem/Button';

const Login = (props: any) => {
  const {
    open,
    setOpen,
    userData,
    companyName,
    setCompanyName,
    identifier,
    setIdentifier,
    password,
    setPassword,
    termsAgree,
    setTermsAgree,
    newSubmit,
    companyConfig,
  } = props;

  return (
    <div>
      <Grid>
        <Grid>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className="div-container">
                <img
                  width={200}
                  src={`https://pruff-public.s3.amazonaws.com/company-logos/${[userData.company.id]}.png`}
                  alt="Logo"
                />
              </div>
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: companyConfig?.pruffConnect_styles_mainColor
                    ? companyConfig?.pruffConnect_styles_mainColor
                    : '#0F23DF',
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="p">
                Ingresa tus credenciales del SII
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre Cliente"
                      variant="outlined"
                      id="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="identifier"
                      label="RUT Cliente"
                      placeholder="Ej: 12345678-9"
                      variant="outlined"
                      value={identifier}
                      onChange={(e) =>
                        setIdentifier(e.target.value.toUpperCase())
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Contraseña SII"
                      fullWidth
                      type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Checkbox
                        onChange={() => setTermsAgree(!termsAgree)}
                        value="allowExtraEmails"
                        style={{
                          color: companyConfig?.pruffConnect_styles_mainColor
                            ? companyConfig?.pruffConnect_styles_mainColor
                            : '#0F23DF',
                        }}
                      />

                      <Typography variant="body1">
                        He leído y estoy de acuerdo con los{' '}
                        <span
                          onClick={() => {
                            window.open(
                              'https://pruff-public.s3.amazonaws.com/documents/Terminos%2By%2BCondiciones.pdf',
                            );
                          }}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          términos y condiciones
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ margin: '16px 0px' }}>
                  <CustomButton
                    color="primary"
                    expressConfig={companyConfig}
                    onClick={() => newSubmit()}
                    fullWidth
                    variant="contained"
                  >
                    Guardar
                  </CustomButton>
                </div>
                <Typography fontSize={12}>
                  <b>Importante</b> Este form fue solicitado por el ejecutivo{' '}
                  <b>{userData.name}</b>. Si no conoces a esta persona, verifica
                  con tu ejeuctivo que este sea el sitio correcto.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {open && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999 /* Ensure it's above all other elements */,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor:
              'rgba(0, 0, 0, 0.3)' /* Semi-transparent background overlay */,
          }}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            <AlertTitle>Éxito</AlertTitle>
            Se ha enviado tu información con éxito. Tu ejecutivo se pondrá en
            contacto contigo a la brevedad.
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Login;
