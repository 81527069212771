import React, { useEffect, useState } from 'react';
import {
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import InforPreview from '../../../../../../../../../common/InforPreview';
import {
  getRequirementsForProduct,
  rejectCreditLine,
  reviewCreditLine,
} from '../../../../../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { Add, Check, Delete } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import { validatePermissions } from '../../../../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';

interface Concentration {
  stakeholderName: string;
  percentage: number;
}

const ShoppingCartFactoringInReview = (props: any) => {
  const { setOpenShoppingCart, openRequest, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [inAssignation, setInAssignation] = useState(false);
  const { clientId } = useParams<{ clientId: string }>();
  const [reason, setReason] = useState('');
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();

  const canAnalize = () => {
    return validatePermissions(user, 'financing_creditLine_review');
  };

  const validate = () => {
    return reason.length > 0;
  };

  const handleReview = async () => {
    if (!validate()) return alert('Completa todos los campos');
    setLoading(true);
    try {
      await reviewCreditLine(dispatch, {
        id: openRequest.id,
        message: reason,
      });
      setLoading(false);
      setOpenShoppingCart(false);
      await setUp();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleReject = async () => {
    if (!validate()) return alert('Completa todos los campos');
    setLoading(true);
    try {
      await rejectCreditLine(dispatch, {
        id: openRequest.id,
        message: reason,
      });
      setLoading(false);
      setOpenShoppingCart(false);
      await setUp();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <CreditLineStatus openRequest={openRequest} audienceType="received" />
      {/* <TextField
        fullWidth
        label="Motivo"
        value={reason}
        multiline
        onChange={(e) => setReason(e.target.value)}
      /> */}
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Salir
        </CustomButton>
        {/* <CustomButton onClick={() => handleReject()} color="error">
          Rechazar
        </CustomButton>
        <CustomButton onClick={() => handleReview()} color="success">
          Revisada
        </CustomButton> */}
      </DialogActions>
    </div>
  );
};

export default ShoppingCartFactoringInReview;
