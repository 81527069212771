import { CircularProgress, Divider, Grid, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { formatDate } from '../../../../../../lib/functions/utils';

import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../../../services/redux/authSlice';
import {
  addMessageToRequest,
  getMessagesFromRequest,
} from '../../../../../../lib/api';
import { buildRequestId } from '../utils';

const colorsForUsernames = [
  'rgb(41, 125, 255, 0.7)',
  'rgb(255, 125, 41, 0.7)',
  'rgb(125, 255, 41, 0.7)',
  'rgb(41, 255, 125, 0.7)',
  'rgb(255, 41, 125, 0.7)',
  'rgb(125, 41, 255, 0.7)',
  'rgb(255, 255, 41, 0.7)',
  'rgb(41, 255, 255, 0.7)',
  'rgb(255, 41, 255, 0.7)',
  'rgb(255, 255, 255, 0.7)',
];

const ChatWindow = (props: any) => {
  const { selectedRequest, requestType } = props;
  const [messages, setMessages] = useState<any[]>([]);
  const [mesageToSend, setMessageToSend] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [usersIdsColorHash, setUsersIdsColorHash] = useState<{
    [key: string]: string;
  }>({});
  const chatWindowRef = useRef<any>(null);
  const [pollingInterval, setPollingInterval] = useState(10000); // Start with a base interval, e.g., 5000ms
  const [lastMessageCount, setLastMessageCount] = useState(0); // To track if new messages have been fetched

  const makeUserIdsColorHash = (usersIds: string[]) => {
    const hash: { [key: string]: string } = {};
    usersIds.forEach((userId, index) => {
      hash[userId] = colorsForUsernames[index % 10];
    });
    return hash;
  };

  const dispatch = useDispatch();

  const addTemporalMessage = (message: any) => {
    const role =
      user && user.roles && user?.roles[0] ? user?.roles[0].name : '';
    const messageBody = {
      userId: user?.id,
      userName: user?.name,
      userRole: role,
      message,
      timestamp: new Date().toISOString(),
      loading: true,
    };
    setMessages([...messages, messageBody]);
  };

  const sendMessage = async () => {
    try {
      setMessageToSend('');
      addTemporalMessage(mesageToSend);
      await addMessageToRequest(dispatch, {
        requestId: buildRequestId(selectedRequest, requestType),
        requestType,
        message: mesageToSend,
      });
      await fetchMessages(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMessages = async (canLoad = true) => {
    console.log('fetching messages');
    if (canLoad) setLoading(true);
    try {
      const response = await getMessagesFromRequest(dispatch, {
        requestId: buildRequestId(selectedRequest, requestType),
        requestType,
      });
      console.log(response.messages);
      setMessages(response.messages);
      setUsersIdsColorHash(makeUserIdsColorHash(response.usersIds));
      if (canLoad) setLoading(false);
      return response.messages;
    } catch (error) {
      console.log(error);
      if (canLoad) setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const asyncPolling = async () => {
    const response = await fetchMessages(false);
    if (response.length === lastMessageCount) {
      // If they're the same, double the interval
      setPollingInterval((prevInterval: any) =>
        Math.min(prevInterval * 2, 300000),
      );
    } else {
      // If there are new messages, reset the interval
      setPollingInterval(5000);
      setLastMessageCount(response.length); // Update the last message count
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      asyncPolling();
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [pollingInterval]);

  useEffect(() => {
    if (chatWindowRef.current) {
      const { scrollHeight, clientHeight } = chatWindowRef.current;
      chatWindowRef.current.scrollTo(0, scrollHeight - clientHeight);
    }
  }, [messages]);

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }

  return (
    <div style={{}}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          ref={chatWindowRef}
          style={{ flex: 1, overflow: 'auto', minHeight: 400, maxHeight: 400 }}
        >
          {messages.map((message, index) => {
            // Assuming you have access to a `userID` variable that holds the current user's ID
            // Adjust the condition as necessary to match your actual data structure
            const alignToRight = message.userId === user?.id;

            return (
              <div
                key={index}
                style={{
                  margin: alignToRight
                    ? '10px 0 10px auto'
                    : '10px auto 10px 0',
                  maxWidth: '80%',
                  padding: '10px',
                  borderRadius: '10px',
                  backgroundColor: alignToRight
                    ? 'rgb(41, 125, 255, 0.7)'
                    : '#f0f0f0',
                }}
              >
                <Typography
                  style={{
                    fontSize: 12,
                    color: alignToRight
                      ? 'black'
                      : usersIdsColorHash[message.userId],
                  }}
                  variant="body1"
                >
                  <b>
                    {message.userName} | {message.userRole}
                  </b>
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: alignToRight ? 'white' : 'black',
                      fontWeight: 'normal',
                    }}
                  >
                    {message.message}
                  </Typography>
                  <Typography
                    style={{
                      marginLeft: '6px',
                      minWidth: '120px',
                      alignItems: 'end',
                    }}
                    variant="caption"
                  >
                    {message.loading ? (
                      <CircularProgress
                        style={{
                          height: 12,
                          width: 12,
                          margin: '0px 12px -2px 0px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {formatDate(new Date(message.timestamp), 'large')}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>

        {messages.length === 0 && (
          <div style={{ padding: '10px 0px' }}>
            <Typography variant="body1">No hay mensajes todavía</Typography>
          </div>
        )}
        <Grid container style={{ textAlign: 'right', alignItems: 'center' }}>
          <Grid item xs={11}>
            <TextField
              value={mesageToSend}
              onChange={(e) => setMessageToSend(e.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                  e.preventDefault(); // Prevent the default action to avoid form submission if it's part of a form
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <CustomButton onClick={sendMessage} color="primary">
              Enviar
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const MultiChatWindowContainer = (props: any) => {
  const { selectedRequest, requestType } = props;

  if (requestType === 'creditLine') {
    return (
      <ChatWindow selectedRequest={selectedRequest} requestType={requestType} />
    );
  }
  if (requestType === 'factoring') {
    if (selectedRequest.recipientCompanies.length > 1) {
      return (
        <div>
          {selectedRequest.recipientCompanies.map(
            (company: number, index: number) => {
              return (
                <div key={index}>
                  <Typography variant="h6">
                    {selectedRequest.recipientCompanies[index].name}
                  </Typography>
                  <ChatWindow
                    selectedRequest={{
                      ...selectedRequest,
                      recipientCompany: company,
                    }}
                    requestType={requestType}
                  />
                  <Divider />
                </div>
              );
            },
          )}
        </div>
      );
    } else if (selectedRequest.recipientCompanies.length === 1) {
      return (
        <ChatWindow
          selectedRequest={{
            ...selectedRequest,
            recipientCompany: selectedRequest.recipientCompanies[0],
          }}
          requestType={requestType}
        />
      );
    }
  }
};

export default MultiChatWindowContainer;
