import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Dialog,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';

import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import { Add } from '@mui/icons-material';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import AddPendingAccountToFNC from '../AddPendingAccounts';

const NotRecognizedPaymentDetails = (props: any) => {
  const {
    notRecognizedPayment,
    setOpen,
    open,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const [invoices, setInvoices] = useState<any[]>([]);
  const [openAddInvoices, setOpenAddInvoices] = useState(false);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Pago no reconocido # {notRecognizedPayment.id}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0px',
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Estado
          </Typography>
          <Typography variant="body1">{notRecognizedPayment.status}</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0px',
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Monto
          </Typography>
          <Typography variant="body1">
            {formatMoney(notRecognizedPayment.amount)}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0px',
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Monto conciliado
          </Typography>
          <Typography variant="body1">
            {formatMoney(notRecognizedPayment.amountConciliated)}
          </Typography>
        </div>
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 0px',
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            FNCs dentro de este pago
          </Typography>
          <IconButton
            onClick={() => {
              setOpenAddInvoices(true);
            }}
            disabled={notRecognizedPayment.status !== 'Pendiente'}
          >
            <Add />
          </IconButton>
        </div>
        <div>
          {!notRecognizedPayment.pendingAccounts.length ? (
            <Typography variant="body1">
              No hay cuentas asociadas a este FNC
            </Typography>
          ) : (
            <EnhancedTable
              data={notRecognizedPayment.pendingAccounts}
              dataHeaders={[
                { key: 'id', label: 'Folio' },
                { key: 'businessName', label: 'Acreedor' },
                { key: 'createdAt', label: 'Fecha', type: 'date' },
                { key: 'amount', label: 'Monto', type: 'money' },
              ]}
            />
          )}
        </div>
      </DialogContent>
      <AddPendingAccountToFNC
        open={openAddInvoices}
        setOpen={setOpenAddInvoices}
        setBigOpen={setOpen}
        invoices={invoices}
        setInvoices={setInvoices}
        notRecognizedPayment={notRecognizedPayment}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
        setUp={setUp}
      />
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NotRecognizedPaymentDetails;
