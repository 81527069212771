import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PriceCheck from '@mui/icons-material/PriceCheck';
import Groups from '@mui/icons-material/Groups';
import OfflineBolt from '@mui/icons-material/OfflineBolt';
import QueryStatsOutlined from '@mui/icons-material/QueryStatsOutlined';
import ExtensionIcon from '@mui/icons-material/Extension';
import SettingsIcon from '@mui/icons-material/Settings';
import BoltIcon from '@mui/icons-material/Bolt';
export const buttonsAvailable: any = {
  home: {
    label: 'Inicio',
    id: 'home/myWallet',
    icon: <HomeIcon style={{ fontSize: '19px' }} />,
  },
  companies: {
    label: 'Mis cuentas',
    id: 'companies/added',
    icon: <BusinessIcon style={{ fontSize: '19px' }} />,
  },
  financialStatements: {
    label: 'Mis balances',
    id: 'financialStatements/pending',
    icon: <RequestQuoteIcon style={{ fontSize: '19px' }} />,
  },
  financeCreditLine: {
    label: 'Finanzas',
    id: 'finance/financing/creditLine',
    icon: <PriceCheck style={{ fontSize: '19px' }} />,
  },
  financeFactoring: {
    label: 'Finanzas',
    id: 'finance/financing/factoring',
    icon: <PriceCheck style={{ fontSize: '19px' }} />,
  },
  financeCollections: {
    label: 'Finanzas',
    id: 'finance/collections/documents',
    icon: <PriceCheck style={{ fontSize: '19px' }} />,
  },
  network: {
    label: 'Mi red',
    id: 'network/businesses',
    icon: <Groups style={{ fontSize: '19px' }} />,
  },
  creditHubPro: {
    label: 'Evaluaciones',
    id: 'creditHubPro/executed/creditLine',
    icon: <OfflineBolt style={{ fontSize: '19px' }} />,
  },
  consultor: {
    label: 'Consultas',
    id: 'consultor',
    icon: <QueryStatsOutlined style={{ fontSize: '19px' }} />,
  },
  integrations: {
    label: 'Integraciones',
    id: 'integrations',
    icon: <ExtensionIcon style={{ fontSize: '19px' }} />,
  },
  settings: {
    label: 'Configuración',
    id: 'settings/users/summary',
    icon: <SettingsIcon style={{ fontSize: '19px' }} />,
  },
  pruffRush: {
    label: 'Sincronizador',
    id: 'sync',
    icon: <BoltIcon style={{ fontSize: '19px' }} />,
  },
};
