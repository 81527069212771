import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { getDocumentVerifications } from '../../../../../../../lib/api/documentConfirmation';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { formatMoney } from '../../../../../../../lib/functions/utils';
import { formatDateToLocal } from '../../../../../../../lib/designSystem/Table/cellFormat';

interface DocumentConfirmationDetailsProps {
  id: string;
  stakeholderName: string;
  businessName: string;
  amount: number;
  folio: string;
  documentConfirmations: {
    createdAt: string;
    message: string;
    type: string;
    confirmedBy: string;
  }[];
}

const DocumentConfirmationDetailsModule = (props: any) => {
  const { document, open, setOpen } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [documentDetails, setDocumentDetails] =
    useState<DocumentConfirmationDetailsProps>({
      id: '',
      stakeholderName: '',
      businessName: '',
      amount: 0,
      folio: '',
      documentConfirmations: [],
    });

  const setUpDocument = async () => {
    try {
      setLoading(true);
      const response = await getDocumentVerifications(dispatch, {
        documentId: document.id,
      });
      setDocumentDetails(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUpDocument();
  }, []);

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <div>
          <DialogContent style={{ minWidth: '800px' }}>
            <Typography variant="h6" padding="16px 0px" fontWeight={600}>
              Verificación de documento
            </Typography>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body1" fontWeight={600}>
                  Folio
                </Typography>
                <Typography variant="body1">{document.folio}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight={600}>
                  Cliente
                </Typography>
                <Typography variant="body1">{document.businessName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" fontWeight={600}>
                  Deudor
                </Typography>
                <Typography variant="body1">
                  {document.stakeholderName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" fontWeight={600}>
                  Monto
                </Typography>
                <Typography variant="body1">
                  {formatMoney(document.amount)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1" fontWeight={600}>
                  Confirmada SII
                </Typography>
                <Typography variant="body1">
                  {document.confirmDate
                    ? formatDateToLocal(document.confirmDate)
                    : 'No'}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ padding: '30px 0px' }}>
              <Typography
                variant="body1"
                fontWeight={600}
                style={{ margin: '10px 0px' }}
              >
                Historial de verificaciones manuales
              </Typography>
              <EnhancedTable
                data={documentDetails.documentConfirmations}
                dataHeaders={[
                  {
                    key: 'type',
                    label: 'Tipo',
                  },
                  {
                    key: 'message',
                    label: 'Comentario',
                  },
                  {
                    key: 'confirmedBy',
                    label: 'Confirmada por',
                  },
                  { key: 'createdAt', label: 'Fecha', type: 'date' },
                ]}
                rowsPerPageDefault={5}
                exportToFile="xlsx"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => setOpen(false)}>
              Salir
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default DocumentConfirmationDetailsModule;
