import { formatDate } from '../../../functions/utils';
import { Header } from '../../Table';

export const exportToCsv = (
  data: { [key: string]: any }[],
  headers: Header[],
  fileName: string = `${new Date().toDateString()}.csv`,
) => {
  const headersLabels = headers.map((header) => header.label);
  let parsedData: any = headersLabels.join(',') + '\n';
  for (const row of data) {
    const rowValues = headers.map((header) =>
      header.type === 'date' ? formatDate(row[header.key]) : row[header.key],
    );
    parsedData += rowValues.join(',') + '\n';
  }

  const csvContent = `data:text/csv;charset=utf-8,${parsedData}`;
  const encodedURI = encodeURI(csvContent);

  const a = document.createElement('a');
  a.href = encodedURI;
  a.download = fileName;
  a.click();
};
