import { requestHandler } from '../../../api';

export const getAllFactoringReSelling = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/reSell/factoring`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentsAvailableForReSelling = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/reSell/factoring/documents`,
    dispatch,
  });
  if (response) return response.data;
};

export const createFactoringReSell = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/reSell/factoring`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createFactoringReSellBatch = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/reSell/factoring/batch`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringReSell = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/reSell/factoring/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFactoringReSell = async (
  dispatch: any,
  id: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/reSell/factoring/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getResellFactoringMissingApprovals = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/reSell/factoring/${id}/approvals`,
    dispatch,
  });
  if (response) return response.data;
};

export const approveResell = async (dispatch: any, id: number, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/reSell/factoring/${id}/approve`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const rejectResell = async (dispatch: any, id: number, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/reSell/factoring/${id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getResellPayments = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/reSell/factoring/${id}/payments`,
    dispatch,
  });
  if (response) return response.data;
};
