import {
  CardActions,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  deleteTemporaryView,
  getTemporaryConnectionsUserProviderBusiness,
} from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddRelation from '../AddRelation';
import CustomButton from '../../../../../../lib/designSystem/Button';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';

const ShowConnections = (props: any) => {
  const {
    open,
    business,
    setOpen,
    handleOpenAdd,
    setConnection,
    handleOpenEdit,
    setUp,
    activateSuccess,
    activateFailed,
  } = props;
  const [views, setViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState<any>([]);
  const [addOpen, setAddOpen] = useState(false);

  const dispatch = useDispatch();

  const setUp2 = async () => {
    setLoading(true);
    const connections = await getTemporaryConnectionsUserProviderBusiness(
      dispatch,
      business.businessIdentifier,
    );
    console.log(connections);
    setConnections(connections.relations || []);
    setLoading(false);
  };

  const deleteConnection = async (id: number) => {
    const connectionSelected = connections.find((x: any) => x.id === id);
    if (!connectionSelected) return alert('Ha ocurrido un error');

    setLoading(true);
    try {
      await deleteTemporaryView(dispatch, connectionSelected.businessId, {
        recipientId: connectionSelected.recipientId,
      });
      activateSuccess(
        `Se ha dejado de compartir con ${connectionSelected.recipientName}`,
      );
    } catch (error) {
      activateFailed(`Hubo un error con ${connectionSelected.recipientName}`);
    }
    await setUp();
    setLoading(false);
  };

  const editConnection = async (id: number) => {
    console.log(id);
    const connectionSelected = connections.find((x: any) => x.id === id);
    if (!connectionSelected) return alert('Ha ocurrido un error');

    setConnection(connectionSelected);
    handleOpenEdit();
  };

  useEffect(() => {
    setUp2();
  }, []);

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleOpenAdd(),
      name: 'Compartir',
      color: 'primary',
    },
  ];

  const actions = [
    { action: deleteConnection, actionLabel: 'Borrar' },
    { action: editConnection, actionLabel: 'Editar' },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <HeaderWithActions
          type="dialogHeader"
          title={`Compartiendo`}
          buttons={buttonsData}
        />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <EnhancedTable
            dataHeaders={[
              { key: 'recipientName', label: 'Ejecutivo' },
              { key: 'recipientEmail', label: 'Email' },
              { key: 'siiViewsNames', label: 'Vistas Compartidas' },
              { key: 'validUntil', label: 'Fecha límite', type: 'date' },
            ]}
            data={connections}
            rowsPerPageDefault={25}
            showActions
            actions={actions}
          />
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShowConnections;
