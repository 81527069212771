import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import {
  createPendingAccountsCategory,
  createProduct,
  editPendingAccountsCategory,
} from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

const CreateAccountCategoryDialog = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    pendingAccountCategory,
    setPendingAccountCategory,
    setSuccess,
    setSuccessMessage,
    setFailed,
    setLoading,
    setUp,
    edit,
  } = props;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const validateBody = () => {
    if (
      pendingAccountCategory.name === '' ||
      pendingAccountCategory.description === ''
    ) {
      setChecked(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateBody()) return;
    setLoading(true);
    try {
      if (edit) {
        await editPendingAccountsCategory(dispatch, pendingAccountCategory.id, {
          name: pendingAccountCategory.name,
          description: pendingAccountCategory.description,
          income: pendingAccountCategory.income,
        });
      } else {
        await createPendingAccountsCategory(dispatch, {
          ...pendingAccountCategory,
        });
      }
      await setUp();
      setSuccess(true);
      setSuccessMessage('Producto creado correctamente');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Agregar cuenta</DialogTitle>
      <DialogContent>
        <div>
          <Typography style={{ padding: '10px 0px' }} variant="body1">
            Agrega la información de tu cuenta
          </Typography>
        </div>
        <div style={{ padding: '16px 0px' }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre de la cuenta"
            type="text"
            error={checked && pendingAccountCategory.name === ''}
            fullWidth
            value={pendingAccountCategory.name}
            onChange={(e) => {
              setPendingAccountCategory({
                ...pendingAccountCategory,
                name: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Descripción"
            error={checked && pendingAccountCategory.description === ''}
            type="text"
            fullWidth
            value={pendingAccountCategory.description}
            onChange={(e) => {
              setPendingAccountCategory({
                ...pendingAccountCategory,
                description: e.target.value,
              });
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color="primary"
              checked={pendingAccountCategory.income}
              onClick={() => {
                setPendingAccountCategory({
                  ...pendingAccountCategory,
                  income: !pendingAccountCategory.income,
                });
              }}
            />
            <Typography variant="body1">
              Quieres que se considere como ingreso?
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          {edit ? 'Editar' : 'Agregar'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAccountCategoryDialog;
