import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBusinessBankAccount,
  getBusinessBankAccounts,
  selectBankAccount,
} from '../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../lib/designSystem/Button';
import BankAccountDialog from '../../../../../lib/common/BankAccountDialog';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import { validatePermissions } from '../../../../../lib/functions/utils';
import { AuthState } from '../../../../../services/redux/authSlice';
import {
  expressGetBankAccounts,
  expressDeleteBankAccount,
  expressSelectBankAccount,
} from '../../../../../lib/api/express/bankAccounts';

const BankAccounts = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { businessId } = props;

  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [failed, setFailed] = useState(false);
  const [failedText, setFailedText] = useState('');
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<any>([]);
  const mockBankAccount = {
    id: null,
    bankName: '',
    accountType: '',
    accountNumber: '',
    mainAccount: false,
    holderName: '',
    holderIdentifier: '',
    currency: '',
  };
  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const pageBusinessId = businessId || id || user?.businessIdentifier;

  const handleGetBusinessBankAccounts = () => {
    if (user?.express) {
      return expressGetBankAccounts(dispatch, user.clientId as string);
    }
    return getBusinessBankAccounts(dispatch, pageBusinessId);
  };

  const handleSelectBankAccount = async (bankAccountId: number) => {
    if (user?.express) {
      return expressSelectBankAccount(dispatch, user.clientId as string, {
        bankAccountId,
      });
    }
    return selectBankAccount(dispatch, pageBusinessId, {
      bankAccountId,
    });
  };

  const handleDeleteBusinessBankAccount = (bankAccountId: number) => {
    if (user?.express) {
      return expressDeleteBankAccount(
        dispatch,
        user.clientId as string,
        bankAccountId,
      );
    }
    return deleteBusinessBankAccount(dispatch, pageBusinessId, {
      bankAccountId,
    });
  };

  const setSuccessOpen = (text: string) => {
    setSuccessText(text);
    setSuccess(true);
  };

  const setFailureOpen = (text: string) => {
    setFailedText(text);
    setFailed(true);
  };

  const setUp = async () => {
    setLoading(true);
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    const bankAccountsResponse = await handleGetBusinessBankAccounts();
    setBankAccounts(bankAccountsResponse || []);
    console.log(loading);
    setLoading(false);
  };

  const handleCreate = () => {
    setOpenEdit(true);
    setSelectedBankAccount(mockBankAccount);
  };

  const handleEdit = (bankAccountId: any) => {
    const bankAccount = bankAccounts.find((c: any) => c.id === bankAccountId);
    if (!bankAccount) return;

    console.log(bankAccount);

    setOpenEdit(true);
    setSelectedBankAccount(bankAccount);
  };

  const handleSelectMain = async (bankAccountId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await handleSelectBankAccount(bankAccountId);
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  const handleConfirmDelete = (bankAccountId: number) => {
    const bankAccount = bankAccounts.find((b: any) => b.id === bankAccountId);

    if (!bankAccount) return;
    setSelectedBankAccount(bankAccount);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = async (bankAccountId: number) => {
    if (!pageBusinessId) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const response = await handleDeleteBusinessBankAccount(bankAccountId);
      if (response) {
        setSuccess(true);
        await setUp();
        setOpenEdit(false);
      }
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  const canEdit = () => validatePermissions(user, 'businesses_create_new');

  const canSelectMain = (id: number) => {
    const bankAccount = bankAccounts.find((b: any) => b.id === id);
    if (!bankAccount) return false;
    return !bankAccount.selected;
  };

  const actions = [
    {
      action: handleSelectMain,
      actionLabel: 'Seleccionar como principal',
      canShow: canSelectMain,
    },
    {
      action: handleEdit,
      actionLabel: 'Editar',
      canShow: canEdit,
    },
    {
      action: handleConfirmDelete,
      actionLabel: 'Eliminar',
      canShow: canEdit,
    },
  ];

  const buttons: CustomButtonProps[] = [
    {
      name: 'Crear',
      action: handleCreate,
      color: 'primary',
      disabled: !canEdit(),
    },
  ];

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={buttons}
        title="Acá podrás ver y editar las cuentas bancarias de esta empresa"
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'bankName', label: 'Banco' },
          { key: 'accountType', label: 'Tipo' },
          { key: 'accountNumber', label: 'Número' },
          { key: 'currency', label: 'Moneda ' },
          { key: 'email', label: 'Correo' },
          { key: 'selected', label: 'Principal', type: 'boolean-active-null' },
        ]}
        data={bankAccounts}
        actions={actions}
        showActions
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
      <BankAccountDialog
        open={openEdit}
        setOpen={setOpenEdit}
        businessId={pageBusinessId}
        selectedBankAccount={selectedBankAccount}
        setSelectedBankAccount={setSelectedBankAccount}
        handleSuccessExit={setUp}
        setSuccess={setSuccessOpen}
        setFailure={setFailureOpen}
        clientId={user?.clientId as string}
      />
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Eliminar cuenta</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro que deseas eliminar a la cuenta{' '}
            {selectedBankAccount.bankName} {selectedBankAccount.accountNumber}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setConfirmDeleteOpen(false);
            }}
          >
            Cancelar
          </CustomButton>
          <CustomButton
            color="error"
            onClick={() => {
              handleDelete(selectedBankAccount.id);
              setConfirmDeleteOpen(false);
            }}
          >
            Eliminar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text={successText}
        handleClose={() => setSuccess(false)}
      />
      <CustomAlert
        open={failed}
        setOpen={setFailed}
        severity="error"
        text={failedText}
        handleClose={() => setFailed(false)}
      />
    </div>
  );
};

export default BankAccounts;
