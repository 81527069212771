import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import Uploader from '../../../../../../../../lib/designSystem/Uploader';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import {
  addCertificates,
  addOwnCertificates,
} from '../../../../../../../../lib/api/SIICertificates';
import { BusinessRelation } from '../../../../../../../MyAccounts/components/Businesses';
import { getActiveBusinessRelationsForUser } from '../../../../../../../../lib/api';

const AddCertificate = (props: any) => {
  const { open, setOpen, setFailed, setSuccess, setUp, failed } = props;
  const [taxName, setTaxName] = useState('');
  const [taxIdentifier, setTaxIdentifier] = useState('');
  const [taxPassword, setTaxPassword] = useState('');
  const [termsAgree, setTermsAgree] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [dataCompanies, setDataCompanies] = useState<{
    relations: BusinessRelation[];
    ivaContainers: any[];
  }>({ relations: [], ivaContainers: [] });
  const dispatch = useDispatch();
  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const validateInputs = () => {
    if (!taxName) {
      alert('Por favor ingrese el nombre del firmante del certificado');
      return false;
    }
    if (!taxIdentifier) {
      alert('Por favor ingrese el RUT del firmante del certificado');
      return false;
    }
    if (!taxPassword) {
      alert('Por favor ingrese la clave del certificado');
      return false;
    }
    if (!file) {
      alert('Por favor seleccione un archivo');
      return false;
    }
    if (!termsAgree) {
      alert('Por favor acepte los términos y condiciones');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateInputs()) return;
    setLoading(true);
    const data = new FormData();
    if (!file) return;
    data.append('file', file);
    data.append('taxPassword', taxPassword);
    data.append('taxIdentifier', taxIdentifier);
    data.append('taxName', taxName);
    data.append('businessIdentifier', selectedBusiness.identifier);

    try {
      const response = await addCertificates(dispatch, data);
      if (response.status === 'failed') {
        alert(response.error);
      } else {
        setSuccess('Certificado agregado correctamente');
        await setUp();
        setOpen(false);
      }
    } catch (error: any) {
      setFailed('Error al agregar el certificado');
    }
    setUploadedFileName(null);
    setLoading(false);
  };

  const fetchRelations = async () => {
    setLoading(true);
    const results = await getActiveBusinessRelationsForUser(dispatch);
    setDataCompanies(results || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return;

    setSelectedBusiness(newValue);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant="h5">Agregar Certificado</Typography>
      </DialogTitle>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={dataCompanies.relations}
                getOptionLabel={(option) =>
                  `${option.businessName} - ${option.identifier}`
                }
                renderInput={(params) => (
                  <TextField
                    style={{ minWidth: 250 }}
                    {...params}
                    label="Seleccionar cuenta"
                  />
                )}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre Firmante"
                variant="outlined"
                id="companyName"
                value={taxName}
                onChange={(e) => setTaxName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="identifier"
                label="RUT Firmante"
                variant="outlined"
                value={taxIdentifier}
                onChange={(e) => setTaxIdentifier(e.target.value.toUpperCase())}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Clave certificado digital"
                fullWidth
                type="password"
                variant="outlined"
                value={taxPassword}
                onChange={(e) => setTaxPassword(e.target.value)}
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Uploader
                handleFileChange={handleFileChange}
                buttonText="Agregar PFX"
                accept=".pfx"
                uploadedFileName={uploadedFileName}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={() => setTermsAgree(!termsAgree)}
                  value="allowExtraEmails"
                  color="primary"
                />

                <Typography variant="body1">
                  He leído y estoy de acuerdo con los{' '}
                  <span
                    onClick={() => {
                      window.open(
                        'https://pruff-public.s3.amazonaws.com/documents/Terminos%2By%2BCondiciones.pdf',
                      );
                    }}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    términos y condiciones
                  </span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Salir{' '}
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} variant="contained">
          Guardar
        </CustomButton>
      </DialogActions>
      <CustomAlert
        open={!!failed}
        setOpen={setFailed}
        severity="warning"
        text={failed}
        handleClose={() => setFailed('')}
      />
    </Dialog>
  );
};

export default AddCertificate;
