import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const CustomAlert: React.FC<any> = ({
  open,
  title,
  onClose,
  setOpen,
  text,
  severity,
}) => {
  const handleClose = () => {
    if (setOpen) setOpen(false);

    if (onClose) onClose();
  };
  return open ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999 /* Ensure it's above all other elements */,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor:
          'rgba(0, 0, 0, 0.3)' /* Semi-transparent background overlay */,
      }}
    >
      <Alert onClose={handleClose} severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {text}
      </Alert>
    </div>
  ) : (
    <div />
  );
};

export default CustomAlert;
