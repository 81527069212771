import React, { useState } from 'react';

import { Dialog } from '@mui/material';
import EnhancedTab from '../../../../../../../lib/designSystem/TabSimulation';
import ExtensionMain from './components/NewExtension';
import { cellFormat } from '../../../../../../../lib/designSystem/Table/cellFormat';
import FactoringExtensionMissingApprovalContainer from './components/Approval';

const ExtensionDetails = (props: any) => {
  const {
    extension,
    setExtension,
    open,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    edit,
  } = props;
  const [startValue, setStartValue] = useState(0);

  const newExtension = !extension.status;

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <ExtensionMain
          extension={extension}
          setExtension={setExtension}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
          edit={edit}
        />
      ),
    },
    {
      index: 0,
      label: 'Aprobación',
      disabled: newExtension,
      component: (
        <FactoringExtensionMissingApprovalContainer
          extension={extension}
          setExtension={setExtension}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
          edit={edit}
        />
      ),
    },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={extension.id ? `Prórroga #${extension.id}` : 'Nueva prórroga'}
          startValue={startValue}
          tabs={tabs}
          chip={cellFormat(extension.status || 'Nuevo', 'status')}
        />
      </div>
    </Dialog>
  );
};

export default ExtensionDetails;
