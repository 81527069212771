// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/store';
import { Typography } from '@mui/material';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { Copyright } from '../EnrolationForm';
import EnhancedTable from '../../../lib/designSystem/Table';
import { publicAcceptQuote } from '../../../lib/api/factoring/updateStatus';

const useStyles = makeStyles(() =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const ConfirmQuote = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState('');
  const [quote, setQuote] = useState<any>({});

  const confirmCodeUser = async () => {
    try {
      setLoading(true);
      const userResponse = await publicAcceptQuote({ code });
      console.log(userResponse);
      if (!userResponse.success) {
        setError(true);
      }
      console.log(userResponse);
      setQuote(userResponse.quote);
      setClientId(userResponse.clientId);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  useEffect(() => {
    confirmCodeUser();
  }, []);

  if (loading) return <CenteredCircularProgress type="noLayout" />;

  // if (error) {
  //   return (
  //     <div style={{margin: 'auto', maxWidth: '40%', padding: 40}}>
  //       <Typography variant='h4'>Error</Typography>
  //       <Typography variant='h6'>El código ingresado no tiene validez</Typography>
  //     </div>
  //   )
  // }

  if (error)
    return (
      <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
        <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
          {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
          <EnhancedBox>
            <Typography style={{ padding: '6px 0px' }} variant="h3">
              Simulación inválida
            </Typography>
            <Typography variant="body1">
              Esta simulación no es válida o ya fue aceptada.
            </Typography>
          </EnhancedBox>
        </div>
        <div style={{ paddingTop: 40 }}>
          <Copyright />
        </div>
      </div>
    );

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
        <EnhancedBox>
          <Typography style={{ padding: '6px 0px' }} variant="h3">
            Simulación en proceso
          </Typography>
          <Typography variant="body1">
            Hemos notificado a {quote.recipient.name} ({quote.recipient.email})
            tu interés por la factorización de las siguientes facturas:
          </Typography>
          <div style={{ paddingTop: 20 }}>
            <EnhancedTable
              dataHeaders={[
                { key: 'folio', label: 'Folio' },
                { key: 'stakeholder', label: 'Pagador' },
                { key: 'amount', label: 'Monto', type: 'money' },
              ]}
              data={quote.documents}
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <Typography variant="body1">
              Dentro de unos minutos, se pondrá en contacto contigo.
            </Typography>
          </div>
        </EnhancedBox>
      </div>
      <div style={{ paddingTop: 40 }}>
        <Copyright />
      </div>
    </div>
  );
};

export default ConfirmQuote;
