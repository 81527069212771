import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { formatMoney } from '../../../../../../../../../lib/functions/utils';
import { Typography } from '@mui/material';

interface DebtorChartDataItem {
  name: string;
  value: number;
}

type DebtorDataType = {
  byCompany: DebtorChartDataItem[];
  bySector: DebtorChartDataItem[];
  bySalesSegment: DebtorChartDataItem[];
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

type DebtorChartProps = {
  type: 'byCompany' | 'bySector' | 'bySalesSegment';
  data: DebtorDataType;
};

const DebtorChart = ({ type, data }: DebtorChartProps) => {
  if (data[type].length === 0)
    return (
      <Typography variant="h6" textAlign="center">
        No hay datos para mostrar
      </Typography>
    );

  return (
    <PieChart width={400} height={400} style={{ margin: '0' }}>
      <Pie
        data={data[type]}
        cx={200}
        cy={200}
        label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
        outerRadius={150}
        fill="#8884d8"
        dataKey="value"
      >
        {data[type].map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip formatter={(value) => formatMoney(Number(value))} />
    </PieChart>
  );
};

export default DebtorChart;
