import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  DialogActions,
} from '@mui/material';
import {
  createBusiness,
  startEnrolation,
  validateCredentials,
} from '../../../../../../lib/api';
import { validateParams } from '../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { handleError } from '../../../../../../lib/functions/errors';
import {
  pauseBusinessCompanyRelation,
  resumeBusinessCompanyRelation,
} from '../../../../../../lib/api/enrollment';

function EditBusiness(props: any) {
  const {
    setLoading,
    dispatch,
    activateSuccess,
    setFailed,
    setOpenEditDialog,
    openEditDialog,
    handleInputChange,
    fetchRelations,
    selectedBusiness,
    business,
  } = props;
  const [formErrorMessage, setFormErrorMessage] = useState(''); // ... your state setup for formErrorMessage
  console.log(selectedBusiness);
  const handleEditDialog = async () => {
    try {
      setLoading(true);
      if (
        !validateParams(
          selectedBusiness,
          ['id', 'identifier', 'password'],
          setFormErrorMessage,
        )
      )
        return setLoading(false);
      await validateCredentials(dispatch, {
        taxPassword: selectedBusiness.password,
        taxIdentifier: selectedBusiness.identifier,
      });
      if (!business.active) {
        await startEnrolation(dispatch, {
          taxPassword: selectedBusiness.password,
          taxIdentifier: selectedBusiness.identifier,
          businessId: selectedBusiness.id,
        });
        await createBusiness(dispatch, {
          identifier: selectedBusiness.identifier,
          taxPassword: selectedBusiness.password,
        });
      }

      await fetchRelations();
      activateSuccess(
        `Se ha actualizado el cliente. ${!business.active ? 'Estamos ingestando las nuevas facturas. Estas estarán disponibles en algunos minutos' : ''}`,
      );
    } catch (error: any) {
      console.log(error);
      setFailed(true);
      handleError(error);
    }
    setLoading(false);
    setOpenEditDialog(false);
  };

  const handlePauseBR = async () => {
    try {
      setLoading(true);
      await pauseBusinessCompanyRelation(dispatch, {
        businessIdentifier: selectedBusiness.identifier,
      });
      activateSuccess(
        'El cliente quedó en proceso de desactivación. Aún puedes reactivarlo en cualquier momento.',
      );
      await fetchRelations();
    } catch (error: any) {
      console.log(error);
      setFailed(true);
      handleError(error);
    }
    setLoading(false);
    setOpenEditDialog(false);
  };

  const handleResumeBR = async () => {
    try {
      setLoading(true);
      await resumeBusinessCompanyRelation(dispatch, {
        businessIdentifier: selectedBusiness.identifier,
      });
      activateSuccess('El cliente ha sido reactivado.');
      await fetchRelations();
    } catch (error: any) {
      console.log(error);
      setFailed(true);
      handleError(error);
    }
    setLoading(false);
    setOpenEditDialog(false);
  };

  return (
    <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
      <DialogTitle>Editar cliente</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="businessName"
          disabled
          label="Nombre"
          fullWidth
          value={selectedBusiness.businessName}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="identifier"
          disabled
          label="RUT"
          fullWidth
          value={selectedBusiness.identifier}
          onChange={handleInputChange}
        />
        {!business.active && (
          <TextField
            margin="dense"
            name="password"
            label="Contraseña"
            value={selectedBusiness.password}
            onChange={handleInputChange}
            type="password"
            fullWidth
          />
        )}
        <Typography variant="body1">{formErrorMessage}</Typography>
        {business.pausedAt && (
          <Typography variant="body1">
            El cliente se encuentra desactivado. A final de mes se eliminará
            definitivamente. Puedes reactivarlo en cualquier momento.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenEditDialog(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        {!business.pausedAt && (
          <CustomButton
            onClick={() => {
              // Handle update logic here
              handlePauseBR();
            }}
            color="error"
          >
            Eliminar
          </CustomButton>
        )}
        {business.pausedAt && (
          <CustomButton
            onClick={() => {
              // Handle update logic here
              handleResumeBR();
            }}
            color="success"
          >
            Reactivar
          </CustomButton>
        )}
        {!business.active && (
          <CustomButton
            onClick={() => {
              // Handle update logic here
              handleEditDialog();
            }}
            color="primary"
          >
            Actualizar
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EditBusiness;
