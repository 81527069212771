import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  createComitee,
  deleteComitee,
  getAllDecisionEngines,
  getAllFinalNodes,
  getComitee,
  getComitees,
  updateComitee,
} from '../../../../../../lib/api';
import { Dialog } from '@mui/material';
import FinalNodeDialog from './components/comiteeDialog';
import ComiteeDialog from './components/comiteeDialog';

export interface Role {
  id: string;
  name: string;
  description: string;
}

interface Comitee {
  id?: string;
  name: string;
  severityLevel: number;
  rolesMapped?: string;
  roles: Role[];
}

const CompanyComitees = (props: any) => {
  const { setCreating } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [comitees, setComitees] = useState<Comitee[]>([]);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [severityLevel, setSeverityLevel] = useState(1);
  const newComitee = {
    id: '',
    name: `Comite ${severityLevel}`,
    severityLevel: severityLevel,
    rolesMapped: '',
    roles: [],
  };
  const [selectedComitee, setSelectedComitee] = useState<Comitee>(newComitee);
  const [openAddFinalNodeDialog, setOpenAddFinalNodeDialog] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const fetchComitees = async () => {
    setLoading(true);
    try {
      const response = await getComitees(dispatch);
      setSeverityLevel(response.length + 1);
      setComitees(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const validateSelectedComitee = () => {
    if (!selectedComitee.name) {
      alert('El nombre del comité es obligatorio');
      return false;
    }
    const existingName = comitees.find((x) => x.name === selectedComitee.name);
    if (existingName && existingName.id !== selectedComitee.id) {
      alert('El nombre del comité ya existe');
      return false;
    }
    if (!selectedComitee.roles.length) {
      alert('Debes agregar al menos un rol');
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    if (!validateSelectedComitee()) return;
    setLoading(true);
    try {
      delete selectedComitee.rolesMapped;
      if (isNew) {
        delete selectedComitee.id;
        await createComitee(dispatch, selectedComitee);
        setSuccessMessage('Comité creado con éxito');
      } else {
        await updateComitee(dispatch, selectedComitee);
        setSuccessMessage('Comité actualizado con éxito');
      }
      setOpenAddFinalNodeDialog(false);
      setSuccess(true);
      await fetchComitees();
    } catch (error) {
      setFailed(true);
      setFailedMessage('Error con el comité');
      console.log(error);
    }
    setLoading(false);
  };

  const startButtons: CustomButtonProps[] = [
    {
      action: () => hanldeCreateNode(),
      name: 'Agregar comité',
      color: 'primary',
    },
  ];

  const hanldeCreateNode = () => {
    setSelectedComitee(newComitee);
    setIsNew(true);
    setOpenAddFinalNodeDialog(true);
  };

  const handleEdit = async (id: any) => {
    setIsNew(false);
    try {
      const response = await getComitee(dispatch, { id });
      setSelectedComitee(response);
      setOpenAddFinalNodeDialog(true);
    } catch (error) {
      alert('Error al obtener el comité');
      console.log(error);
    }
  };

  const handleDelete = async (id: any) => {
    setLoading(true);
    try {
      await deleteComitee(dispatch, { id });
      setSuccessMessage('Comité eliminado con éxito');
      setSuccess(true);
      await fetchComitees();
    } catch (error) {
      setFailed(true);
      setFailedMessage('Error al eliminar el comité');
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchComitees();
  }, []);

  const actions: any = [
    { action: handleEdit, actionLabel: 'Editar' },
    //
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los comités que hayas creado. Estos son las aprobaciones que se necesitan para tomar una decisión."
        variant="body1"
        buttons={startButtons}
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'rolesMapped', label: 'Roles' },
        ]}
        data={comitees}
        actions={actions}
        showActions
        rowsPerPageDefault={10}
      />
      {openAddFinalNodeDialog && (
        <ComiteeDialog
          open={openAddFinalNodeDialog}
          setOpen={setOpenAddFinalNodeDialog}
          comitee={selectedComitee}
          setComitee={setSelectedComitee}
          newComitee={isNew}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default CompanyComitees;
