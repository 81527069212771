// A function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number): string {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) =>
    l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);

  const r = Math.round(255 * f(0));
  const g = Math.round(255 * f(8));
  const b = Math.round(255 * f(4));

  return `rgb(${r}, ${g}, ${b})`;
}

// A function to generate visually related colors
export function generateRelatedColors(
  baseHue: number,
  baseSaturation: number,
  baseLightness: number,
  count: number,
): string[] {
  const colors: string[] = [];

  for (let i = 0; i < count; i++) {
    // Rotate hue and adjust saturation/lightness for variation
    const hue = (baseHue + i * 30) % 360; // Rotate hue by 30 degrees
    const saturation = baseSaturation + i * 5; // Slight variation in saturation
    const lightness = baseLightness - i * 5; // Slight variation in lightness

    const color = hslToRgb(hue, saturation, lightness);
    colors.push(color);
  }

  return colors;
}
