import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTable from '../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoicesWithStatus } from '../../../../lib/api';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import { Grid, Typography } from '@mui/material';
import { AuthState } from '../../../../services/redux/authSlice';
import SummaryFactoring from '../common/SummaryFactoring';
import InvoicesClaimed from '../common/InvoicesClaimed';
import InvoicesNotConfirmed from '../common/InvoicesNotConfirmed';
import LastInvoicesADC from '../common/LastInvoicesADC';
import SiiDebt from '../common/SIIDebt';
import InvoicesNotConfirmedNumber from '../common/InvoicesNotConfirmedNumber';
import SiiMessages from '../common/SiiMessages';

const DashboardADC: React.FC = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  useEffect(() => {}, []);

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setOpenFilters(!openFilters),
      name: !openFilters ? 'Filtros' : 'Cerrar filtros',
      color: 'primary',
    },
  ];

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Typography variant="h5">Pendientes</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SiiDebt />
            </Grid>
            <Grid item xs={6}>
              <InvoicesNotConfirmedNumber />
            </Grid>
            <Grid item xs={12}>
              <InvoicesClaimed />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SiiMessages />
            </Grid>
            <Grid item xs={12}>
              <LastInvoicesADC />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
    //    <Grid item xs={6}>
    //    <InvoicesClaimed />
    //  </Grid>
    //  <Grid item xs={6}>
    //    <InvoicesNotConfirmed />
    //  </Grid>
    //  <Grid item xs={6}>
    //    <LastInvoicesADC />
    //  </Grid>
    //  <Grid item xs={12}>
    //    <LastInvoices />
    //  </Grid>
  );
};

export default DashboardADC;
