import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { automaticCollectionEnrollment } from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs';
import {
  fetchInvoicingContacts,
  fetchPaymentDates,
} from '../../../../../../../../lib/api/contacts';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import ContactFormDialog from '../../../../../../../../lib/common/ContactFormDialog';

interface Props {
  id: number;
  stakeholderIdentifier: string;
  open: boolean;
  invoiceData: any;
  setModal: any;
  setReload: any;
  reload: number;
  setSnackbar: any;
  setLoading: any;
}

const NewEnrollment: React.FC<Props> = (props) => {
  const { id, stakeholderIdentifier } = props;
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(dayjs('23/03/2024'));
  const [openContactForm, setOpenContactForm] = useState(false);
  const [invoicingContacts, setInvoicingContacts] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const handleAutomaticEnroll = async () => {
    try {
      props.setLoading(true);
      await automaticCollectionEnrollment(dispatch, {
        id,
        type: props.invoiceData.invoiceType,
        paymentDate: selectedDate.format(),
      });
      props.setSnackbar({ message: 'Factura enrolada con éxito', open: true });
      props.setReload(props.reload + 1);
    } catch (error) {
      alert('Ha ocurrido un error');
    }
  };
  const setUpInvoicingContacts = async () => {
    setLoading(true);
    try {
      const contactsResponse = await fetchInvoicingContacts(
        dispatch,
        stakeholderIdentifier,
      );
      const paymentDatesResponse = await fetchPaymentDates(
        dispatch,
        stakeholderIdentifier,
      );
      setInvoicingContacts(contactsResponse);
      if (paymentDatesResponse) {
        setSelectedDate(dayjs(paymentDatesResponse[0].date));
      }
    } catch (error) {
      console.log(error);
      alert('Ha ocurrido un error');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpInvoicingContacts();
  }, []);

  return (
    <div>
      <Dialog open={props.open} onClose={() => props.setModal({ open: false })}>
        <DialogTitle>
          🤖 Enrolar factura <strong>#{props.invoiceData?.folio}</strong> en
          cobranza automática
        </DialogTitle>
        {loading && (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <div>
            <DialogContent>
              <DialogContentText>
                <p>
                  Confirma la siguiente información para enrolar la factura en
                  cobranza automática.
                </p>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="es-mx"
                >
                  <DatePicker
                    label="Fecha de pago de factura"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(dayjs(e))}
                  />
                </LocalizationProvider>
                <p>
                  {' '}
                  Contactos a los que se enviaran correos de cobranza:{' '}
                  {invoicingContacts
                    .map((element: any) => element.email)
                    .toString()}
                  {!invoicingContacts.length && (
                    <Typography>
                      No hay contacto de facturación asociado, prueba asignando{' '}
                      <span
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => setOpenContactForm(true)}
                      >
                        uno
                      </span>
                    </Typography>
                  )}
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton
                color="secondary"
                onClick={() => {
                  props.setModal({ open: false, id: 0 });
                }}
              >
                Cerrar
              </CustomButton>
              <CustomButton
                color="primary"
                disabled={
                  invoicingContacts.length === 0 || selectedDate === undefined
                }
                onClick={() => {
                  handleAutomaticEnroll();
                  props.setModal({ open: false, id: 0 });
                }}
              >
                Enrolar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <ContactFormDialog
        open={openContactForm}
        onClose={() => setOpenContactForm(false)}
        setOpen={setOpenContactForm}
        businessId={stakeholderIdentifier}
        setUp={setUpInvoicingContacts}
        invoicingOption
      />
    </div>
  );
};

export default NewEnrollment;
