import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { getAllRoles } from '../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import { Form } from 'react-router-dom';
import { Role } from '../..';

const ComiteeDialog = (props: any) => {
  const { open, setOpen, comitee, setComitee, newComitee, handleSubmit } =
    props;
  const [nodeData, setNodeData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [usagesExpanded, setUsagesExpanded] = useState(true);
  const [decisionEngines, setDecisionEngines] = useState([]);
  const [expanded, setExpanded] = useState<string>('');
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRoles = () => {
    setComitee({
      ...comitee,
      roles: [...comitee.roles, { name: '', id: null }],
    });
  };

  const setUpAvailableRoles = async () => {
    try {
      const response = await getAllRoles(dispatch);
      setAvailableRoles(response);
    } catch (error) {
      console.log(error);
      alert('Error al obtener los roles');
    }
  };

  const handleChangeRole = (e: any, index: number) => {
    const roles = [...comitee.roles];
    roles[index].id = e.target.value;
    const rolesObject = availableRoles.find(
      (x: Role) => x.id === e.target.value,
    );
    roles[index].name = rolesObject ? rolesObject.name : '';
    setComitee({ ...comitee, roles });
  };

  const handleChangeCount = (e: any, index: number) => {
    const roles = [...comitee.roles];
    roles[index].count = Number(e.target.value);
    setComitee({ ...comitee, roles });
  };

  const handleDeleteRole = (index: number) => {
    setComitee({
      ...comitee,
      roles: comitee.roles.filter((x: any, i: number) => i !== index),
    });
  };

  const showAvailableNodesForSelect = (role: any) => {
    const rolesSelectedIds = comitee.roles.map((x: any) => x.id);
    const rolesNotSelected = availableRoles.filter(
      (x: any) => !rolesSelectedIds.includes(x.id),
    );
    return [...rolesNotSelected, role];
  };

  useEffect(() => {
    setUpAvailableRoles();
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{newComitee ? 'Nuevo comité' : 'Editar comité'}</DialogTitle>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <DialogContent>
          <Typography variant="body1" fontWeight={600}>
            Información general
          </Typography>
          <Typography variant="body1">
            Acá podrás asignar la información del comité.
          </Typography>
          <div style={{ padding: '10px 0px' }}>
            <div style={{ padding: '10px 0px' }}>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                value={comitee.name}
                onChange={(e) =>
                  setComitee({ ...comitee, name: e.target.value })
                }
              />
            </div>
          </div>

          <div>
            <div style={{ padding: '10px 0px' }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1" fontWeight={600}>
                    Roles
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleAddRoles}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography>
                Acá podrás asignar los roles que deberán tomar decisiones en
                este comité.
              </Typography>
            </div>
            {comitee.roles.length === 0 && (
              <Typography variant="body1">
                No hay roles asignados a este comité.
              </Typography>
            )}
            {comitee.roles.map((role: any, index: number) => {
              console.log('role', role);
              return (
                <Grid
                  container
                  key={index}
                  spacing={2}
                  alignItems="center"
                  rowSpacing={2}
                  style={{ padding: '10px 0px' }}
                >
                  <Grid item xs={6}>
                    <FormControl key={index} variant="outlined" fullWidth>
                      <InputLabel id="filter-label">
                        Roles Disponibles
                      </InputLabel>
                      <Select
                        labelId="filter-label"
                        id="Filtrar por estado"
                        value={role.id}
                        fullWidth
                        onChange={(e) => handleChangeRole(e, index)}
                        label="Roles Disponibles"
                      >
                        {showAvailableNodesForSelect(role).map(
                          (role: any, index: any) => (
                            <MenuItem key={index} value={role.id}>
                              {role.name}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Cantidad"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={role.count}
                      onChange={(e) => handleChangeCount(e, index)}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton onClick={() => handleDeleteRole(index)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => {
            setOpen(false);
          }}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => {
            handleSubmit();
          }}
          color="primary"
        >
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ComiteeDialog;
