import React from 'react';

import { Typography, Grid } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../lib/functions/utils';

const PaymentConciliationSummary = (props: any) => {
  const { payIn } = props;

  return (
    <div style={{ padding: '0px 0px 20px 0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" fontWeight={600}>
          Conciliación del pago
        </Typography>
        <Typography variant="body1">
          <b>Fecha de pago:</b> {formatDate(payIn.createdAt)}
        </Typography>
      </div>

      <Grid padding="10px 0px" container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Cuenta
          </Typography>
          <Typography variant="body1">{payIn.businessOriginName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Banco
          </Typography>
          <Typography variant="body1">{payIn.bankAccountOriginName}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Cuenta
          </Typography>
          <Typography variant="body1">
            {payIn.businessDestinationName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Banco
          </Typography>
          <Typography variant="body1">
            {payIn.bankAccountDestinationName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Monto
          </Typography>
          <Typography variant="body1">{formatMoney(payIn.amount)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" fontWeight={600}>
            Monto conciliado
          </Typography>
          <Typography variant="body1">
            {formatMoney(payIn.amountConciliated)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentConciliationSummary;
