import React, { useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Theme } from '@mui/material/styles';
import { Save } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: '500px',
      height: 260, // or any desired fixed width
    },
    input: {
      display: 'none',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    uploadText: {
      borderRadius: '5px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

function UploaderInLine(props: any) {
  const { handleFileChange, uploadedFileName, buttonText, accept, handleSave } =
    props;
  const classes = useStyles();

  return (
    <div>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {uploadedFileName && (
          <div className={classes.uploadText}>
            <Typography>{uploadedFileName}</Typography>
            <IconButton onClick={handleSave}>
              <Save color="primary" />
            </IconButton>
          </div>
        )}
        <IconButton>
          <label
            htmlFor="contained-button-file"
            style={{ cursor: 'pointer', paddingTop: 4 }}
          >
            <span>
              <CloudUploadIcon />
            </span>
          </label>
        </IconButton>
      </div>
    </div>
  );
}

export default UploaderInLine;
