import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles, styled } from '@mui/styles';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import { formatMoney } from '../../../../../../../lib/functions/utils';
import { Info } from '@mui/icons-material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    padding: 0,
    maxWidth: '1200px',
    borderRadius: '10px',
  },
}));

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '2px 12px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
    tooltip: {
      '&:hover': {
        background: '#f00',
      },
    },
  }),
);

const DocumentsTable = (props: any) => {
  const { row } = props;
  return (
    <div style={{ overflowX: 'scroll', padding: '8px' }}>
      <Typography variant="h6" style={{ margin: '8px' }} fontWeight={'bold'}>
        {row.key}
      </Typography>
      <EnhancedTable
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre empresa' },
          { key: 'type', label: 'Tipo' },
          { key: 'lendAmount', label: 'Monto colocado', type: 'money' },
          {
            key: 'lendAmountNet',
            label: 'Monto colocado neto',
            type: 'money',
          },
          { key: 'rateAmount', label: 'Interés devengado', type: 'money' },
          { key: 'arrearAmount', label: 'Interés mora', type: 'money' },
          { key: 'commissionAmount', label: 'Monto comisión', type: 'money' },
          { key: 'otherIncome', label: 'Otros ingresos', type: 'money' },
          { key: 'totalIncome', label: 'Ingreso total', type: 'money' },
        ]}
        data={row.documents}
      />
    </div>
  );
};

// const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasse.tooltip}`]: {
//     maxWidth: 'none',
//   },
// });

const ReportIncomeDailyTable = (props: any) => {
  const { rows } = props;
  // const [page, setPage] = useState(0);
  const classes = useStyles();
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  // Handlers for opening and closing the tooltip.

  // const handleChange = (id: string, newValue: string) => {
  //   // Parse the input as a float
  //   const numericValue = parseFloat(newValue);

  //   console.log(id);

  //   // Validate the parsed number
  //   setRequestPayers({
  //     ...requestPayers,
  //     [id]: { ...requestPayers[id], paymentDays: numericValue },
  //   });
  // };

  // Handlers for opening and closing the tooltip.

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead style={{ background: '#efefef' }}>
          <TableCell style={{ textAlign: 'right' }}></TableCell>

          <TableCell>Fecha</TableCell>
          <TableCell style={{ textAlign: 'right' }}>Monto colocado</TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            Monto colocado neto
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            Interés devengado
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>Interés mora</TableCell>
          {/* <TableCell style={{ textAlign: 'right' }}>Interés prórroga</TableCell> */}

          <TableCell style={{ textAlign: 'right' }}>Monto comisión</TableCell>
          <TableCell style={{ textAlign: 'right' }}>Otros ingresos</TableCell>

          <TableCell style={{ textAlign: 'right' }}>Ingreso total</TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rows.map((row: any, rowIndex: any) => (
            // Color on hover
            <TableRow key={rowIndex}>
              <TableCell style={{ padding: 0 }}>
                <LightTooltip
                  placement="bottom-end"
                  style={{ maxWidth: 'none' }}
                  title={<DocumentsTable row={row} />}
                >
                  <IconButton>
                    <Info />
                  </IconButton>
                </LightTooltip>
              </TableCell>
              <TableCell className={classes.tableCell}>{row.key}</TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.lendAmount)}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.lendAmountNet)}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.rateAmount)}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.arrearAmount)}
              </TableCell>
              {/* <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.extensionAmount)}
              </TableCell> */}
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.commissionAmount)}
              </TableCell>

              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.otherIncome)}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                {formatMoney(row.totalIncome)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportIncomeDailyTable;
