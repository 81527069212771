import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0F23DF', // replace with your desired primary color
    },
    secondary: {
      main: '#ffffff', // replace with your desired secondary color
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: '#0F23DF',
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
      },
    },
  },
  typography: {
    // fontFamily: "'Lato', sans-serif;",
    // fontFamily: "'Montserrat', sans-serif",
    // fontFamily:" 'Nunito Sans', sans-serif",
    fontFamily: " 'Nunito', sans-serif",

    h1: {
      fontSize: '1.8rem',
      fontWeight: 500,
      margin: 0,
      // You can add more styles or modify the current ones as needed
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      margin: '0px 0px',
      // ...
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 400,
      // ...
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
      margin: '15px 0px',
      // ...
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 200,
      margin: '15px 0px',
      // ...
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 200,
      margin: '5px 0px',
      // ...
    },
    body1: {
      // Typically maps to the 'p' tag
      fontSize: '1rem',
      fontWeight: 200,
      // ...
    },
    // Add more styles or modify the current ones as needed
  },
});

export default theme;
