import React, { useEffect, useState } from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import {
  assginCreditLine,
  getBusinessPayers,
  getCreditLineAssignations,
  getProductSubProducts,
} from '../../../../../../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import AssignationModule from '../../../../../../../../../common/assignationModule';

const ShoppingCartFactoringReviewed = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setUp,
    inAssignationDefault,
    updateStatus,
  } = props;
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(openRequest.amount);
  const [validUntil, setValidUntil] = useState(openRequest.validUntil);
  const [stakeholderConcentrations, setStakeholderConcentrations] = useState(
    [],
  );
  const [subProductConcentrations, setSubProductConcentrations] = useState([]);
  const [inAssignation, setInAssignation] = useState(
    inAssignationDefault ? true : false,
  );
  const [payers, setPayers] = useState<any[]>([]);
  const [subProducts, setSubProducts] = useState<any[]>([]);
  console.log('ShoppingCartFactoringReviewed ');

  const dispatch = useDispatch();

  const hanldeAssignation = async () => {
    setInAssignation(true);
  };

  const validateConcentrationsAmount = () => {
    if (stakeholderConcentrations.length === 0) {
      alert(
        'Debes seleccionar al menos un pagador. Si no quieres agregar una concentración especififica, selecciona "Otros"',
      );
      return false;
    }
    const total = stakeholderConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los pagadores no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const validateSubProductsConcentrationsAmount = () => {
    if (subProductConcentrations.length === 0) {
      alert('Debes seleccionar al menos un subproducto');
      return false;
    }
    const total = subProductConcentrations.every(
      (x: any) => x.amount <= amount,
    );

    if (!total) {
      alert(
        'El monto de las concentraciones de los subproductos no puede ser mayor al total de la línea.',
      );
      return false;
    }
    return true;
  };

  const validateAmount = () => {
    if (amount <= 0) {
      alert('El monto del total de la línea debe ser mayor a 0');
      return false;
    }
    return true;
  };

  const cleanStakeholderConcentrations = () => {
    return stakeholderConcentrations.map((x: any) => {
      return {
        businessIdentifier: x.stakeholderName === 'Otros' ? null : x.identifier,
        businessName: x.stakeholderName,
        amount: x.amount,
      };
    });
  };

  const cleanSubProductsConcentrations = () => {
    const newConcentrations = subProductConcentrations.map((x: any) => {
      const newConcentration = {
        subProductId: x.id,
        advance: Number(x.advance),
        arrearRate: Number(x.arrearRate),
        maxFinancingDays: Number(x.maxFinancingDays),
        spread: Number(x.spread),
        operationCostVariable: Number(x.operationCostVariable),
        operationCostFixed: Number(x.operationCostFixed),
        amount: Number(x.amount),
      };
      delete x.id;
      return newConcentration;
    });
    return newConcentrations;
  };
  const handleSubmit = async () => {
    if (!validateAmount()) return;
    if (!validateConcentrationsAmount()) return;
    if (!validateSubProductsConcentrationsAmount()) return;
    if (!validUntil)
      return alert('Por favor, selecciona una fecha de vencimiento');

    setLoading(true);
    try {
      const cleannedStakeholdersData = cleanStakeholderConcentrations();
      const cleannedSubProductsData = cleanSubProductsConcentrations();

      console.log('cleannedStakeholdersData', cleannedStakeholdersData);
      console.log('cleannedSubProductsData', cleannedSubProductsData);
      await assginCreditLine(dispatch, {
        id: openRequest.id,
        amount,
        stakeholderConcentrations: cleannedStakeholdersData,
        subProductConcentrations: cleannedSubProductsData,
        validUntil,
        updateStatus,
      });
      setInAssignation(false);
      setSuccessOpen('Se ha asignado la línea con éxito.');
      await setUp();
      setOpenShoppingCart(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getConcentrations = async () => {
    setLoading(true);
    try {
      const response = await getCreditLineAssignations(dispatch, {
        id: openRequest.id,
      });
      const { stakeholderAssignations, subProductAssignations } = response;

      const parsedConcentrations = stakeholderAssignations.map(
        (concentration: any) => ({
          identifier: concentration.businessIdentifier,
          stakeholderName: concentration.businessName,
          amount: concentration.amount,
          new: false,
        }),
      );
      setStakeholderConcentrations(parsedConcentrations);
      setSubProductConcentrations(subProductAssignations);

      console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const needToSetup = async () => {
    console.log('updateStatus', updateStatus);
    await setUpBusinessPayers();
    await setUpBusinessSubProducts();
    if (updateStatus) {
      return false;
    } else {
      await getConcentrations();
      setValidUntil(openRequest.validUntil);
    }
  };

  const setUpBusinessSubProducts = async () => {
    setLoading(true);
    try {
      const response = await getProductSubProducts(dispatch, openRequest.id);
      setSubProducts(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const setUpBusinessPayers = async () => {
    setLoading(true);
    try {
      const response = await getBusinessPayers(
        dispatch,
        openRequest.businessIdentifier,
      );
      setPayers(response);
      console.log(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    needToSetup();
  }, []);

  if (loading) {
    return <CenteredCircularProgress type="dialog" />;
  }

  return (
    <div>
      <div>
        {inAssignation ? (
          <div>
            <AssignationModule
              stakeholderConcentrations={stakeholderConcentrations}
              setStakeholderConcentrations={setStakeholderConcentrations}
              subProductConcentrations={subProductConcentrations}
              setSubProductConcentrations={setSubProductConcentrations}
              amount={amount}
              setAmount={setAmount}
              validUntil={validUntil}
              setValidUntil={setValidUntil}
              handleSubmit={handleSubmit}
              payers={payers}
              subProducts={subProducts}
              setOpenShoppingCart={setOpenShoppingCart}
            />
          </div>
        ) : (
          <div>
            <CreditLineStatus
              openRequest={openRequest}
              audienceType="received"
            />
            <DialogActions>
              <CustomButton onClick={() => hanldeAssignation()} color="primary">
                Asignar
              </CustomButton>
              <CustomButton
                onClick={() => setOpenShoppingCart(false)}
                color="secondary"
              >
                Salir
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartFactoringReviewed;
