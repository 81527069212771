import { requestHandler, requestHandlerPublic } from '../../../api';

export const getCompanyStyles = async (companyRecieverId: string) => {
  const response = await requestHandlerPublic({
    method: 'get',
    url: `public/express/${companyRecieverId}/companyStyleConfig`,
  });
  if (response) return response.data;
};

export const expressGetCompanyConfig = async (
  dispatch: any,
  companyRecieverId: string,
  configs: string[],
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/configuration/${configs.join(',')}`,
    dispatch,
  });
  if (response) return response.data;
};
