import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getBankAccount } from '../../../../../lib/api';
import { ContentCopy } from '@mui/icons-material';

interface WireInformationProps {
  encryptedCompanyId: string;
  encryptedBusinessIdentifier: string;
}

const WireInformation: React.FC<WireInformationProps> = (props) => {
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [legalName, setLegalName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const data = await getBankAccount(
      props.encryptedCompanyId,
      props.encryptedBusinessIdentifier,
    );
    setBankName(data.bankName);
    setAccountNumber(data.accountNumber);
    setAccountType(data.accountType);
    setLegalName(data.holderName);
    setIdentifier(data.holderIdentifier);
    setEmail(data.email);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{
        border: 'solid 1px ',
        borderColor: '#e0e0e0',
        borderRadius: '7px',
        backgroundColor: '#FFFF',
        marginTop: '46px',
        width: '100%',
      }}
    >
      {!loading && (
        <div style={{ padding: '8px 24px' }}>
          <Typography
            variant="h4"
            style={{ textAlign: 'center' }}
            fontWeight="bold"
          >
            🏦 Datos bancarios
          </Typography>

          <div style={{ padding: '24px' }}>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {legalName}
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {identifier}
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {accountNumber}
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {accountType}
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {bankName}
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'center' }}>
              {email}
            </Typography>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant="text"
              startIcon={<ContentCopy />}
              onClick={() =>
                navigator.clipboard.writeText(
                  `${legalName}\n${identifier}\n${bankName}\n${accountNumber}\n${accountType}`,
                )
              }
            >
              {' '}
              Copiar datos{' '}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WireInformation;
