import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { createProduct } from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

const CreateProductDialog = (props: any) => {
  const {
    openDialog,
    setOpenDialog,
    setSuccess,
    setSuccessMessage,
    setFailed,
    setLoading,
    fetchProducts,
    productsAvailable,
  } = props;
  const dispatch = useDispatch();
  const [typeId, setTypeId] = useState<string | null>(null);
  const [productTypeSelected, setProductTypeSelected] = useState<any>(null);
  const [requirements, setRequirements] = useState<
    { name: string; description: string }[]
  >([]);
  const addEmptyRequirement = () => {
    setRequirements([...requirements, { name: '', description: '' }]);
  };

  const handleSelectType = (value: any) => {
    const productType = productsAvailable.find((x: any) => x.id === value);
    setProductTypeSelected(productType);
    setTypeId(value);
  };

  const deleteRequirement = (index: number) => {
    const newArray = [...requirements];
    newArray.splice(index, 1);
    setRequirements(newArray);
  };

  const validateBody = () => {
    if (!typeId) {
      alert('Selecciona un producto');
      return false;
    }
    if (requirements.length === 0) {
      alert('Agrega al menos un requerimiento');
      return false;
    } else {
      for (let i = 0; i < requirements.length; i++) {
        if (!requirements[i].name || !requirements[i].description) {
          alert('Completa todos los campos de los requerimientos');
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateBody()) return;
    setLoading(true);
    try {
      await createProduct(dispatch, { productData: { typeId }, requirements });
      await fetchProducts();
      setSuccess(true);
      setSuccessMessage('Producto creado correctamente');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Agregar producto</DialogTitle>
      <DialogContent>
        {productsAvailable.length === 0 && (
          <Typography style={{ padding: '10px 0px' }} variant="body1">
            Ya tienes todos los productos disponibles
          </Typography>
        )}
        {productsAvailable.length > 0 && (
          <div>
            <div>
              <Typography style={{ padding: '10px 0px' }} variant="body1">
                Selecciona un producto dentro de los disponibles
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="filter-label">Productos disponibles</InputLabel>
                <Select
                  fullWidth
                  labelId="filter-label"
                  id="Filtrar por estado"
                  value={typeId}
                  onChange={(e) => handleSelectType(e.target.value)}
                  label={'Productos disponibles'}
                >
                  {productsAvailable.map((x: any, index: any) => (
                    <MenuItem key={index} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {productTypeSelected && (
                <div style={{ padding: 10 }}>
                  <Typography variant="body1" fontWeight="bold">
                    Subproductos
                  </Typography>

                  {productTypeSelected.subProducts.map((x: any, index: any) => (
                    <div key={index}>
                      <Typography variant="body1">- {x.name}</Typography>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div style={{ padding: '16px 0px' }}>
              <Typography variant="body1">
                Agrega los requerimientos que tiene que subir el cliente para
                presentar una revisión de línea de crédito para este producto.
              </Typography>
              {requirements.map((x: any, index: any) => (
                <div key={index} style={{ display: 'flex' }}>
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={5}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nombre del requerimiento"
                        type="text"
                        fullWidth
                        value={x.name}
                        onChange={(e) => {
                          const newArray = [...requirements];
                          newArray[index].name = e.target.value;
                          setRequirements(newArray);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Descripción del requerimiento"
                        type="text"
                        fullWidth
                        value={x.description}
                        onChange={(e) => {
                          const newArray = [...requirements];
                          newArray[index].description = e.target.value;
                          setRequirements(newArray);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'right' }}>
                      <IconButton onClick={() => deleteRequirement(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={11}></Grid>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <IconButton onClick={() => addEmptyRequirement()}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
          Cancelar
        </CustomButton>
        {productsAvailable.length > 0 && (
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Agregar
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateProductDialog;
