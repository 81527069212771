import React from 'react';

interface BoxProps {
  children?: React.ReactNode;
  centerText?: boolean;
  small?: boolean;
  props?: any;
  margin?: string;
}

const boxStyle = {
  border: '1px solid #e0e0e0',
  margin: '0px 12px 0px 0px',
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  padding: '18px', // I added padding for visual clarity. You can adjust or remove this as needed.
};

const EnhacedBox: React.FC<BoxProps> = ({
  children,
  centerText,
  small,
  margin,
  props,
}) => {
  return (
    <div
      {...props}
      style={{
        ...boxStyle,
        ...(small ? {} : { minHeight: 215 }),
        ...(margin ? { margin } : {}), // I added margin for visual clarity. You can adjust or remove this as needed.
        textAlign: centerText ? 'center' : 'left',
      }}
    >
      {children}
    </div>
  );
};

export default EnhacedBox;
