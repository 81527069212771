import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { lastSiiMessages } from '../../../../../lib/api/dashboard';

const SiiMessages: React.FC = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await lastSiiMessages(dispatch);
      console.log(relations);
      setInvoices(relations || []);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h5">Notificaciones SII</Typography>
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Cuenta' },
          { key: 'message', label: 'Mensaje' },
          { key: 'createdAt', label: 'Fecha', type: 'date' },
        ]}
        data={invoices}
        rowsPerPageDefault={5}
        completeTable
      />
    </div>
  );
};

export default SiiMessages;
