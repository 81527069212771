import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  getDocumentAlerts,
  getDocumentAlertsByCompany,
} from '../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

interface AdminAlert {
  alertType: string;
  alertDate: string;
  businessIdentifier: string;
  businessName: string;
  folio: string;
  users: string[];
}

const AdminAlerts: React.FC = () => {
  const [data, setData] = useState<AdminAlert[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState({ id: 0, open: false });
  const dispatch = useDispatch();
  const tableHeaders = [
    { key: 'alertType', label: 'Tipo de alerta', type: 'documentAlert' },
    { key: 'alertDate', label: 'Fecha de alerta', type: 'date-large' },
    { key: 'businessIdentifier', label: 'Rut cliente', type: 'report' },
    { key: 'businessName', label: 'Nombre cliente', type: 'report' },
    { key: 'folio', label: 'Folio documento' },
  ];

  const retrieveData = async () => {
    setLoading(true);
    const response = await getDocumentAlertsByCompany(dispatch);
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las alertas relacionadas a documentos en operación."
        variant="body1"
        buttons={[]}
      />
      <EnhancedTable
        dataHeaders={tableHeaders}
        exportToFile="xlsx"
        data={data}
        actions={[
          {
            action: (id: number) => setOpenDrawer({ id, open: true }),
            actionLabel: 'Ver destinatarios',
          },
        ]}
        showActions
        rowsPerPageDefault={100}
      />
      <Drawer
        anchor="right"
        open={openDrawer.open}
        onClose={() => setOpenDrawer({ id: 0, open: false })}
      >
        <Box sx={{ width: 250, backgroundColor: '#F8FAFC', height: '100%' }}>
          <div style={{ padding: '10px' }}>
            <HeaderWithActions
              type="pageHeader"
              title="Destinatarios"
              variant="h3"
            />
          </div>
          <List>
            {data.length === 0 && (
              <ListItem>
                <ListItemText primary="Alerta sin destinatarios" />
              </ListItem>
            )}
            {data.length !== 0 &&
              data[openDrawer.id].users.map((item, k) => (
                <div key={k}>
                  <ListItem>
                    <ListItemText primary={item} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              ))}
          </List>
          <div style={{ padding: '15px' }}>
            <CustomButton
              color="secondary"
              onClick={() => setOpenDrawer({ id: 0, open: false })}
            >
              {' '}
              Cerrar{' '}
            </CustomButton>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default AdminAlerts;
