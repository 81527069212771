import React from 'react';
import { DialogActions, Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import InforPreview from '../../../../../../../../../common/InforPreview';

const ShoppingCartFactoringActive = (props: any) => {
  const { setOpenShoppingcart, selectedRequest } = props;
  const infoDiv = (
    <InforPreview
      requestType="factoring"
      type="Sent"
      status="Activa"
      selectedRequest={selectedRequest}
    />
  );
  return (
    <div>
      <div>
        <SimulationDoneFrozen
          infoDiv={infoDiv}
          selectedRequest={selectedRequest}
        />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingcart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringActive;
