import React from 'react';
import { useSelector } from 'react-redux';
import { validatePermissions } from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import AdminAlerts from './components/AdminAlerts';
import ExecutiveAlerts from './components/ExecutiveAlerts';

const Alerts: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  if (validatePermissions(user, 'admin')) return <AdminAlerts />;
  return <ExecutiveAlerts />;
};

export default Alerts;
