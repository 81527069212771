import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import InvoicesTable from '../../../../../SII/components/TheBiller/components/InvoicesTable';
import { executeInvoiceGeneration } from '../../../../../../../../lib/api/sii';

const ReportInvoicingConfirmDialog = (props: any) => {
  const {
    confirmOpen,
    setConfirmOpen,
    selectedInvoices,
    setSelectedInvoices,
    startDate,
    endDate,
    selectedCertificate,
    setUp,
    setSuccessInvoices,
    setResultInvoices,
    setFinalizedModal,
    setFailed,
  } = props;
  console.log(selectedCertificate);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [passphrase, setPassphrase] = useState('');
  const [date, setDate] = useState(endDate.format('YYYY-MM-DD'));
  const handleCreateInvoices = async () => {
    setLoading(true);
    try {
      const response = await executeInvoiceGeneration(dispatch, {
        invoices: selectedInvoices.map((invoice: any) => ({
          ...invoice,
          code: '33',
        })),
        certificateId: selectedCertificate.id,
        passphrase,
      });
      if (response.status === 'failed') {
        setFailed(response.message);
        setLoading(false);
        return;
      }
      const erroredInvoices = response.error.map((invoice: any) => ({
        ...invoice,
        status: false,
      }));
      const successInvoices = response.success.map((invoice: any) => ({
        ...invoice,
        status: true,
      }));
      setSuccessInvoices(successInvoices);
      setResultInvoices([...successInvoices, ...erroredInvoices]);
      setFinalizedModal(true);
      if (successInvoices.length > 0) {
        await setUp();
      }
      setConfirmOpen(false);
    } catch (error) {
      console.log('error', error);
      alert(error);
    }
    setLoading(false);
  };

  const handleChangeDate = (date: any) => {
    const invoicesWithNewDate = selectedInvoices.map((invoice: any) => ({
      ...invoice,
      issuedDate: date,
    }));
    setSelectedInvoices(invoicesWithNewDate);
    setDate(date);
  };

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <Dialog
      maxWidth="xl"
      open={confirmOpen}
      onClose={() => setConfirmOpen(false)}
    >
      <DialogTitle>Confirmar facturación</DialogTitle>
      <DialogContent>
        <div
          style={{
            padding: '10px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>
            Este detalle corresponde a las comisiones a sus clientes del{' '}
            {startDate.format('DD/MM/YYYY')} al {endDate.format('DD/MM/YYYY')}.
            Se realizará la facturación para el rut empresa{' '}
            <b>{selectedCertificate?.businessIdentifier}</b>
          </Typography>
          <TextField
            margin="dense"
            label="Fecha de facturación"
            type="date"
            value={date}
            onChange={(e) => handleChangeDate(e.target.value)}
          />
        </div>
        <div style={{ padding: '10px 0px' }}>
          <InvoicesTable
            invoices={selectedInvoices}
            setInvoices={setSelectedInvoices}
          />
        </div>
        <div
          style={{
            padding: '10px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>
            Para la facturación se debe ingresar la contraseña del certificado
            de{' '}
            <b>
              {selectedCertificate?.taxName} (
              {selectedCertificate?.taxIdentifier})
            </b>
          </Typography>
          <TextField
            margin="dense"
            label="Contraseña del certificado"
            type="password"
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => {
            setConfirmOpen(false);
          }}
        >
          Cancelar
        </CustomButton>
        <CustomButton
          disabled={!passphrase.length}
          onClick={() => {
            handleCreateInvoices();
          }}
        >
          Facturar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReportInvoicingConfirmDialog;
