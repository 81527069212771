import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { selectDecisionEngineAsDefault } from '../../../../../../../../../../lib/api/decisionEngine';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';

const DefaultDecisionEngineDialog = (props: any) => {
  const {
    selectedEngine,
    openDefault,
    setOpenDefault,
    setUp,
    setSuccess,
    setError,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const confirmDefault = async () => {
    setLoading(true);
    try {
      await selectDecisionEngineAsDefault(dispatch, selectedEngine.id);
      setOpenDefault(false);
      setSuccess('Motor seleccionado como predeterminado');
      await setUp();
    } catch (error) {
      setError('Error al seleccionar motor como predeterminado');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={openDefault} onClose={() => setOpenDefault(false)}>
      <DialogTitle>Seleccionar motor</DialogTitle>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <DialogContent>
          <Typography>
            Para todas las decisiones de{' '}
            <b>{selectedEngine?.revisionObjective}</b> se utilizará este motor
            como predeterminado.
          </Typography>
          <Typography>
            ¿Estás seguro de que deseas seleccionar el motor{' '}
            {selectedEngine?.name} como predeterminado?
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton onClick={() => setOpenDefault(false)} color="secondary">
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => confirmDefault()} color="primary">
          Seleccionar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultDecisionEngineDialog;
