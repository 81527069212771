import React from 'react';
import { Typography } from '@mui/material';
import HeaderWithActions from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'category', label: 'Categoría' },
  { key: 'observation', label: 'Observación' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'issuedDate', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto documento', type: 'money' },
];

const PayOutDetaiPendingAccounts = (props: any) => {
  const { pendingAccounts } = props;

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes ver los pagos realizados."
        variant="body1"
      />
      {pendingAccounts.length === 0 ? (
        <Typography variant="body1">
          No hay cuentas pendientes en este pago
        </Typography>
      ) : (
        <EnhancedTable dataHeaders={headers} data={pendingAccounts} />
      )}
    </div>
  );
};

export default PayOutDetaiPendingAccounts;
