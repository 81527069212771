import React from 'react';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate, useParams } from 'react-router-dom';
import Main from './components/Contacts';
import NetworkBusinesses from './components/Businesses';

const Network: React.FC = () => {
  const { page } = useParams<{ page: string }>();
  const tabs: { label: string; component: any; path: string; index: number }[] =
    [
      //{ label: 'Dashboard', component: <div></div>, path: 'summary', index: 0},
      {
        label: 'Empresas',
        component: <NetworkBusinesses />,
        path: 'businesses',
        index: 0,
      },
      { label: 'Contactos', component: <Main />, path: 'contacts', index: 0 },
    ];
  const startValue = tabs.findIndex((x: any) => x.path === page);
  const navigate = useNavigate();

  const handleNavigation = (index: number) => {
    navigate(`/network/${tabs[index].path}`);
  };
  return (
    <div>
      <EnhancedTab
        title={'Network'}
        startValue={startValue}
        tabs={tabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Network;
