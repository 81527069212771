import { requestHandler } from '../../../api';

export const getAllFactoringExtensions = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/extension/factoring`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFactoringExtension = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/extension/factoring/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFactoringExtensionParams = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/extension/factoring/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const createFactoringExtension = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/extension/factoring`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentWithExtensionData = async (
  dispatch: any,
  id: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/extension/factoring/documents/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAvailableDocumentsForFactoringExtension = async (
  dispatch: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/extension/factoring/documents`,
    dispatch,
  });
  if (response) return response.data;
};

export const approveFactoringExtension = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/extension/factoring/${id}/approve`,
    dispatch,
  });
  if (response) return response.data;
};

export const getExtensionFactoringMissingApprovals = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/extension/factoring/${id}/approvals`,
    dispatch,
  });
  if (response) return response.data;
};

export const approveExtension = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/extension/factoring/${id}/approve`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const rejectExtension = async (dispatch: any, id: number, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/extension/factoring/${id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
