import { requestHandler } from '../../api';

export const fetchInvoicingContacts = async (
  dispatch: any,
  businessId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/invoicingContacts`,
    dispatch,
  });
  if (response) return response.data;
};

export const fetchPaymentDates = async (dispatch: any, businessId: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${businessId}/open/paymentDates`,
    dispatch,
  });
  if (response) return response.data;
};
