import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { getNodesForDecisionAvailables } from '../../../../../../../../../../../lib/api';
import { Info, Light } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../../../../../../lib/designSystem/WhiteTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    addContainer: {
      height: '100vh', // Set the sidebar to full height
      backgroundColor: 'ffffff', // Change the background color
      alignItems: 'center',
      width: '100%',
    },
    container: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const typeNames: any = {
  boolean: 'Si o no',
  amount: 'Monto',
  money: 'Monto',
  percentage: 'Porcentaje',
  days: 'Días',
  string: 'Texto',
};

export const operatorTypes: any = [
  {
    id: 'eq',
    name: 'Igual a',
  },
  {
    id: 'gt',
    name: 'Mayor a',
  },
  {
    id: 'lt',
    name: 'Menor a',
  },
];

const FatherIntroDiv = (props: any) => {
  const { newNode, decisionNodesAssignation, operatorTypes } = props;
  const fatherNode = decisionNodesAssignation.find(
    (x: any) => x.id === newNode.fatherNodeId,
  );
  if (!fatherNode) {
    return null;
  }
  const operationName = operatorTypes.find(
    (x: any) => x.id === fatherNode.operation,
  );
  const typeName = typeNames[fatherNode.type];

  return (
    <div>
      <Typography variant="body1">
        <b>Tipo de operación:</b> {operationName?.name}
      </Typography>
      <Typography variant="body1">
        <b>Tipo de valor:</b> {typeName}
      </Typography>
    </div>
  );
};

export const explanationText = (
  newNode: any,
  decisionNodesAssignation: any,
) => {
  const fatherNode = decisionNodesAssignation.find(
    (x: any) => x.id === newNode.fatherNodeId,
  );
  if (!fatherNode) {
    return null;
  }
  console.log(fatherNode, newNode);
  switch (fatherNode.operation) {
    case 'eq':
      if (newNode.value === '') {
        return `El nodo será ejecutado si el valor es diferente a todos los otros nodos.`;
      } else {
        return `El nodo será ejecutado si el valor es igual a ${newNode.value}.`;
      }
    case 'gt':
      if (newNode.value === '') {
        return `El nodo será ejecutado si el valor es menor a todos los otros nodos.`;
      } else {
        return `El nodo será ejecutado si el valor es mayor a ${newNode.value} y menor que el siguiente nodo. Si no hay otro nodo mayor, este nodo será ejecutado.`;
      }
    case 'lt':
      if (newNode.value === '') {
        return `El nodo será ejecutado si el valor es mayor a todos los otros nodos.`;
      } else {
        return `El nodo será ejecutado si el valor es menor a ${newNode.value} y mayor que el siguiente nodo. Si no hay otro nodo menor, este nodo será ejecutado.`;
      }
    default:
      return '';
  }
};

export const NodeValueChangeDiv = (props: any) => {
  const {
    newNode,
    setNewNode,
    decisionNodesAssignation,
    setCanSave,
    canSave,
    baseCase,
  } = props;
  const [isNodeBaseCase, setIsNodeBaseCase] = useState<any>(baseCase);

  const handleChangeNodeType = (e: any) => {
    const { value } = e.target;
    setIsNodeBaseCase(value);
    if (value === 'baseCase') {
      setNewNode({ ...newNode, value: '' });
    }
  };

  const canBaseCase = () => {
    const fatherNode = decisionNodesAssignation.find(
      (x: any) => x.id === newNode.fatherNodeId,
    );
    if (!fatherNode) {
      return false;
    }
    const haveBaseCaseAlready = fatherNode.ranges.find(
      (x: any) => x.value === '',
    );
    if (!haveBaseCaseAlready) {
      return true;
    }
    if (haveBaseCaseAlready.nodeId === newNode.id) {
      return true;
    }
    return false;
  };

  const handleChangeNodeValue = (e: any) => {
    // Check father ranges
    const nodes = [...decisionNodesAssignation];
    let { value } = e.target;
    value = value.toUpperCase();
    const fatherNode = nodes.find((x: any) => x.id === newNode.fatherNodeId);
    if (fatherNode) {
      const range = fatherNode.ranges.find((x: any) => x.value === value);
      console.log('Rango:', range);
      if (range) {
        setCanSave(false);
      } else {
        setCanSave(true);
      }
    }
    setNewNode({ ...newNode, value });
  };

  return (
    <div>
      <Typography gutterBottom>
        Selecciona si es caso base o un nodo con valor.
      </Typography>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="filter-label">Naturaleza del nodo</InputLabel>
        <Select
          labelId="filter-label"
          id="Filtrar por estado"
          name="originNodeId"
          value={isNodeBaseCase}
          onChange={(e: any) => handleChangeNodeType(e)}
          label="Naturaleza del nodo"
        >
          {canBaseCase() && <MenuItem value={'baseCase'}>Caso base</MenuItem>}
          <MenuItem value={'newValue'}>Nodo con valor</MenuItem>
        </Select>
      </FormControl>
      {isNodeBaseCase !== 'baseCase' && (
        <div style={{ padding: '10px 0px' }}>
          <Typography gutterBottom>
            Escribe el valor con el que se ejecutará este nodo.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Valor"
            value={newNode.value}
            onChange={(e: any) => handleChangeNodeValue(e)}
          />
        </div>
      )}
      {!canSave && (
        <Typography color="error" variant="body1" gutterBottom>
          Ya existe un nodo con el mismo valor, debese cambiarlo.
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        <b>Explicación:</b> {explanationText(newNode, decisionNodesAssignation)}
      </Typography>
    </div>
  );
};

const NewNodeDialog = (props: any) => {
  const {
    setDecisionNodesAssignation,
    decisionNodesAssignation,
    revisionObjective,
    fatherNodeIdDefault,
    openAddNode,
    setOpenAddNode,
    setCounter,
    counter,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [decisionNodes, setDecisionNodes] = useState<any>([]);
  const [isNodeBaseCase, setIsNodeBaseCase] = useState<any>(null);
  const [canSave, setCanSave] = useState(true);
  const [newNode, setNewNode] = useState({
    id: 0,
    fatherNodeId: '',
    originNodeId: '',
    value: '',
    type: '',
    operation: '',
    description: '',
    name: '',
    fatherNodeDecision: null,
    ranges: [],
  });

  const dispatch = useDispatch();

  const addRangeToFatherNode = (nodes: any, node: any) => {
    const fatherNode = nodes.find((x: any) => x.id === node.fatherNodeId);
    if (fatherNode) {
      fatherNode.ranges.push({
        nodeId: counter,
        value: node.value,
      });
    }
    return nodes;
  };

  const addNode = () => {
    console.log(newNode);
    if (newNode.originNodeId === '' || newNode.fatherNodeId === '') {
      return alert('Debes completar todos los campos');
    }
    const nodes = [...decisionNodesAssignation];
    nodes.push({ ...newNode, id: counter });
    addRangeToFatherNode(nodes, newNode);
    const nextCounter = counter + 1;
    setCounter(nextCounter);
    setDecisionNodesAssignation(nodes);
    setNewNode({
      id: 0,
      fatherNodeId: '',
      originNodeId: '',
      value: '',
      type: '',
      operation: '',
      description: '',
      name: '',
      fatherNodeDecision: null,
      ranges: [],
    });
    setOpenAddNode(false);
  };

  const handleChangeFatherNodeId = (e: any) => {
    const { value } = e.target;
    setNewNode({ ...newNode, fatherNodeId: value });
  };

  const handleChangeOriginId = (e: any) => {
    const { value } = e.target;
    const node = decisionNodes.find((x: any) => x.id === value);
    if (!node) {
      return;
    }
    if (node.type === 'boolean' || node.type === 'final') {
      node.value = 1;
    } else {
      node.value = '';
    }
    setNewNode({
      ...newNode,
      originNodeId: value,
      type: node.type,
      description: node.description,
      name: node.name,
    });
  };

  const fatherCanHaveOnlyOneTypeOfPassDecission = (
    node: any,
    nodeTypeId: number,
  ) => {
    // Nos entrega si está disponible un nodo.
    const haveChildren = decisionNodesAssignation.find(
      (x: any) =>
        x.fatherNodeId === node.fatherNodeId &&
        x.fatherNodeDecision === nodeTypeId,
    );
    if (haveChildren) {
      return false;
    }
    return true;
  };

  const canHaveMax2Children = (node: any) => {
    const haveChildren = decisionNodesAssignation.filter(
      (x: any) => x.fatherNodeId === node.id,
    );
    if (haveChildren.length >= 2) {
      return false;
    }
    return true;
  };

  const getNodes = async () => {
    setLoading(true);
    const nodes = await getNodesForDecisionAvailables(dispatch, {
      revisionObjective,
    });
    if (nodes) {
      setDecisionNodes(nodes.decisionNodes);
    }
    setLoading(false);
  };

  const availableNodeForSelection = () => {
    if (decisionNodesAssignation.length === 0) {
      return decisionNodes.filter((x: any) => x.type !== 'final');
    } else {
      return decisionNodes;
    }
  };

  useEffect(() => {
    getNodes();
  }, []);

  const handleChangeNodeType = (e: any) => {
    const { value } = e.target;
    setIsNodeBaseCase(value);
    if (value === 'baseCase') {
      setNewNode({ ...newNode, value: '' });
    }
  };
  const canBaseCase = () => {
    const fatherNode = decisionNodesAssignation.find(
      (x: any) => x.id === newNode.fatherNodeId,
    );
    if (!fatherNode) {
      return false;
    }
    const haveBaseCaseAlready = fatherNode.ranges.find(
      (x: any) => x.value === '',
    );
    if (haveBaseCaseAlready) {
      return false;
    }
    return true;
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div className={classes.container}>
      <Dialog
        maxWidth="sm"
        open={openAddNode}
        onClose={() => setOpenAddNode(false)}
      >
        <DialogTitle>Agregar nodo</DialogTitle>
        <DialogContent style={{ width: '500px' }}>
          <div style={{ display: 'flex', alignItems: 'top' }}>
            <Typography variant="h6" gutterBottom fontWeight={400}>
              Nodo padre
            </Typography>
            <LightTooltip
              title={
                <div style={{ padding: '10px' }}>
                  {' '}
                  <Typography variant="body1">
                    El nodo que estás creando se ejecutará cuando el nodo padre
                    (nodo previo) tenga el valor que designes aquí.
                  </Typography>
                </div>
              }
              placement="top"
            >
              <Info style={{ marginLeft: '6px', fontSize: 18 }} />
            </LightTooltip>
          </div>
          <div style={{ padding: '10px 0px' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="filter-label">Padre del nodo</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="fatherNodeId"
                value={newNode.fatherNodeId}
                onChange={(e: any) => handleChangeFatherNodeId(e)}
                label="Padre del nodo"
              >
                {decisionNodesAssignation.length === 0 && (
                  <MenuItem value={0}>Sin padre (Solo el primer nodo)</MenuItem>
                )}
                {decisionNodesAssignation
                  .filter((x: any) => x.type !== 'final')
                  // .filter((x: any) => canHaveMax2Children(x))
                  .map((x: any, index: any) => (
                    <MenuItem key={index} value={x.id}>
                      {x.id} | {x.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {String(newNode.fatherNodeId) !== '0' && (
            <div style={{ padding: '10px 0px' }}>
              <FatherIntroDiv
                newNode={newNode}
                decisionNodesAssignation={decisionNodesAssignation}
                operatorTypes={operatorTypes}
              />
              <NodeValueChangeDiv
                newNode={newNode}
                setNewNode={setNewNode}
                decisionNodesAssignation={decisionNodesAssignation}
                setCanSave={setCanSave}
                canSave={canSave}
              />
            </div>
          )}
          {decisionNodesAssignation.length !== 0 && (
            <div>
              <div style={{ display: 'flex' }}>
                <Typography variant="h6" gutterBottom fontWeight={400}>
                  Nuevo nodo
                </Typography>
              </div>
            </div>
          )}

          <div style={{ padding: '10px 0px' }}>
            <Typography variant="body1" gutterBottom>
              Selecciona el nodo que se ejecutará en este paso.
            </Typography>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="filter-label">Nodos disponibles</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="originNodeId"
                value={newNode.originNodeId}
                onChange={(e: any) => handleChangeOriginId(e)}
                label="Nodos disponibles"
              >
                {availableNodeForSelection().map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {newNode.originNodeId !== '' && (
            <div style={{ padding: '20ox' }}>
              <Typography variant="body1" gutterBottom>
                <b>Descripción del nodo</b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {newNode.description}
              </Typography>
            </div>
          )}
          {['amount', 'percentage', 'days', 'money', 'string'].includes(
            newNode.type,
          ) && (
            <div>
              <div style={{ padding: '10px 0px' }}>
                <FormControl fullWidth>
                  <InputLabel id="filter-label">{'Operador'}</InputLabel>
                  <Select
                    labelId="filter-label"
                    id="Filtrar por estado"
                    fullWidth
                    value={newNode.operation}
                    onChange={(e: any) =>
                      setNewNode({ ...newNode, operation: e.target.value })
                    }
                    label={'Operador'}
                  >
                    {operatorTypes.map((x: any, index: any) => (
                      <MenuItem key={index} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => setOpenAddNode(false)}
            color="secondary"
            variant="contained"
          >
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => addNode()}
            color="primary"
            variant="contained"
            disabled={!canSave}
          >
            Agregar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewNodeDialog;
