import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FinancialStatementsSummary from './Components/Assignments';
import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import Summary from './Components/Summary';
import { AuthState } from '../../../services/redux/authSlice';
import { validatePermissions } from '../../../lib/functions/utils';

const BusinessBalanceProfile: React.FC = () => {
  const { id, page } = useParams<{ id: string; page: string }>();
  const [loading, setLoading] = useState(true);
  const [startValue, setStartValue] = useState(0);
  const [companyTabs, setCompanyTabs] = useState([
    { label: 'Resumen', component: <Summary />, path: 'summary' },
  ]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const navigate = useNavigate();

  const handleNavigation = (index: number) => {
    navigate(`/companies/${id}/financial/${companyTabs[index].path}`);
  };

  const setTabs = async () => {
    setLoading(true);
    if (!id) return;

    const newCompanyTabs = [...companyTabs];
    if (validatePermissions(user, 'financial_states_create_new')) {
      newCompanyTabs.push({
        label: 'Asignaciones',
        component: <FinancialStatementsSummary />,
        path: 'assignations',
      });
    }
    const startValueDefault = newCompanyTabs.findIndex(
      (x: any) => x.path === page,
    );
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setCompanyTabs(newCompanyTabs);
    setLoading(false);
  };

  useEffect(() => {
    setTabs();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        tabs={companyTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default BusinessBalanceProfile;
