import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getInvoiceNotFinanciable } from '../../../../../lib/api/dashboard';

const LastInvoicesADC: React.FC = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await getInvoiceNotFinanciable(dispatch);
      console.log(relations);
      setInvoices(relations || []);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h5">Facturas transables</Typography>
      <EnhancedTable
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre Emisor' },
          // {key: 'businessIdentifier', label: 'Rut Emisor'},
          { key: 'stakeholderName', label: 'Nombre receptor' },
          // {key: 'stakeholderIdentifier', label: 'Rut receptor'},
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
          // {key: 'confirmDate', label: 'Fecha confirmación', type: 'date'},
          // {key: 'cancelDate', label: 'Fecha de reclamo', type: 'date-colored'},
          // {key: 'status', label: 'Estado', type: 'cession'}
        ]}
        data={invoices}
        rowsPerPageDefault={5}
      />
    </div>
  );
};

export default LastInvoicesADC;
