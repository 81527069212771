export const setInitialTab = (
  setAllTabs: any,
  setStartValue: any,
  mainPage: any,
  newTabs: any,
  defaultPage: any,
) => {
  if (newTabs.length === 0) {
    alert('No tiene permisos para acceder a esta página');
    return null;
  }
  setAllTabs(newTabs);
  const startValue = newTabs.findIndex(
    (x: any) => x.mainPage === `${mainPage}`,
  );
  console.log(startValue);
  if (startValue === -1) {
    if (newTabs[0].subPage) {
      window.location.href = `/${defaultPage}/${newTabs[0].mainPage}/${newTabs[0].subPage}`;
    } else {
      window.location.href = `/${defaultPage}/${newTabs[0].mainPage}`;
    }
  }
  setStartValue(startValue);
};
