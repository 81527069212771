import { cellFormat } from '../../../../../../lib/designSystem/Table/cellFormat';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getImageDimensionsFromBase64 } from '../../../../../Finance/Components/Financing/common/exportSimulation';
import { formatDate } from '../../../../../../lib/functions/utils';
const writeContinueText = (text: string, doc: any, x: number, y: number) => {
  const textLines = doc.splitTextToSize(text, 160);
  doc.text(textLines, x, y);
};

export const generateBalancePDF = async (
  summaryData: any,
  bussinesName: string,
  businessIdentifier: string,
  imageBase64: string,
  userName: string,
  commentary?: string,
) => {
  const doc = new jsPDF();
  // Add the image
  const imgX = 150; // X position for the image
  const imgY = 10; // Y position for the image
  const imgHeight = 15; // Height of the image

  const { width: naturalWidth, height: naturalHeight } =
    await getImageDimensionsFromBase64(imageBase64);
  const aspectRatio = naturalWidth / naturalHeight;
  const imgWidth = imgHeight * aspectRatio;

  // Load and add the image
  doc.addImage(imageBase64 as any, 'PNG', imgX, imgY, imgWidth, imgHeight);

  // Add the title text
  doc.setFontSize(16);
  doc.text('Resumen balance', 12, 15);

  // Add the date and user
  doc.setFontSize(10);
  doc.text(`Fecha   : ${formatDate(new Date())}`, 150, 40);
  doc.text(`Usuario : ${userName}`, 150, 45);

  doc.setFontSize(10);
  doc.text('Empresa       :  ' + bussinesName, 12, 40);
  doc.text('Rut                :  ' + businessIdentifier, 12, 45);
  if (commentary && commentary !== '') {
    doc.text('Comentarios : ', 12, 50);
    writeContinueText(commentary, doc, 36, 50);
  }
  doc.setFontSize(12);
  let startY = 70; // Initial Y position for the first table
  const orderedCategories = [
    'Activos',
    'Pasivos',
    'Estado de resultados',
    'Métricas',
  ];
  orderedCategories.forEach((category: any) => {
    // Add a title for the category
    doc.text(category, 12, startY); // Adjust the Y position for the title
    // Define the columns for the table
    const columns = summaryData.years.map((year: any) => ({
      title: year,
      dataKey: year,
    }));
    columns.unshift({ title: 'Cuenta', dataKey: 'key' });

    // Prepare the rows for the table for the current category
    const rows = summaryData.data[category].map((item: any) => {
      for (const key of Object.keys(item)) {
        if (key !== 'key') {
          if (Object.keys(item).includes('rowType')) {
            if (item.rowType === 'isFather') {
              item[key] = cellFormat(item[key], 'money');
            } else {
              item[key] = cellFormat(item[key], item.rowType);
            }
          } else {
            item[key] = cellFormat(item[key], 'money');
          }
        }
      }
      return item;
    });

    // Create the table in the PDF
    autoTable(doc, {
      columns: columns,
      startY: startY + 5,
      body: rows,
      theme: 'grid',
      headStyles: {
        fillColor: '#efefef', // or '#2980b9' in hex format
        textColor: '#1b1b1b',
        fontStyle: 'bold',
        halign: 'left', // horizontal alignment
        valign: 'middle', // vertical alignment
        lineColor: [44, 62, 80],
        fontSize: 10,
      },
      bodyStyles: {
        textColor: [50, 50, 50],
        fontSize: 8,
      },
      styles: {
        font: 'helvetica', // choose the font
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      // For each column, you can define individual styles as well, for example:
      columnStyles: {
        0: { fontStyle: 'bold' }, // Specific styles for the first column (index 0)
      },
      // Add other options as needed
      // New page
    });

    // Update startY for the next table
    // You need to calculate the height of the current table and add some space
    if (category === 'Métricas') {
      return;
    } else if (category === 'Estado de resultados') {
      startY = (doc as any).lastAutoTable.finalY + 15; // 10 is the margin space
    } else {
      startY = 20;
      doc.addPage();
      console.log(category, startY);
    }

    // Check if the next table would exceed the page's height
    // if (startY > 425) {
    //   // 275 is near the bottom of a standard PDF page
    //   doc.addPage();
    //   startY = 20; // Reset startY for the new page
    // }
  });

  // Save the PDF
  doc.save(`Resumen Balance ${bussinesName}.pdf`);
};
