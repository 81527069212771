import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Dialog,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createPendingBusinessAccount,
  editPendingBusinessAccount,
  getPendingAccountsCategories,
} from '../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import CompanyBusinessSearcher from '../../../../../../../../lib/common/CompanyBusinessSearcher';
import {
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../lib/functions/utils';

const ACCOUNT_TYPES = [
  { id: 'receivable', name: 'Por cobrar' },
  { id: 'payable', name: 'Por pagar' },
];

const NewAccount = (props: any) => {
  const {
    account,
    setAccount,
    setOpen,
    open,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<any>(null);
  const [searched, setSearched] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);

  const validateAttributes = () => {
    if (
      account.category === '' ||
      account.observation === '' ||
      account.type === '' ||
      !account.amount ||
      !account.createdAt
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateAttributes()) return;
    console.log(account);

    const payload = {
      amount: account.amount,
      createdAt: new Date(account.createdAt),
      categoryId: account.category,
      observation: account.observation,
      type: account.type,
      businessIdentifier: business.identifier,
    };
    setLoading(true);
    try {
      if (account.id) {
        await editPendingBusinessAccount(dispatch, account.id, {
          ...payload,
        });
        setSuccessOpen('Cuenta editada con éxito');
      } else {
        await createPendingBusinessAccount(dispatch, {
          ...payload,
        });
        setSuccessOpen('Cuenta creada con éxito');
      }
      setOpen(false);
      await setUp();
    } catch (error) {
      console.log(error);
      setFailureOpen('Ha ocurrido un error');
    }
    setLoading(false);
  };

  const categoriesSetUp = async () => {
    try {
      setLoading(true);
      const response = await getPendingAccountsCategories(dispatch);
      setCategories(response);
    } catch (error) {
      alert('Error al cargar las categorías');
    }
    setLoading(false);
  };
  useEffect(() => {
    categoriesSetUp();
  }, []);

  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        {' '}
        {account.id ? 'Editar cuenta' : 'Nueva cuenta'}{' '}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            {!account.id && (
              <div>
                <CompanyBusinessSearcher
                  setBusiness={setBusiness}
                  setSearched={setSearched}
                />
              </div>
            )}
            {business && business.id && searched && (
              <div>
                <Divider />
                <div>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    margin="4px 0px"
                  >
                    {' '}
                    Datos de la cuenta{' '}
                  </Typography>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="filter-label">Tipo de cuenta</InputLabel>
                    <Select
                      labelId="filter-label"
                      id="Filtrar por estado"
                      fullWidth
                      value={account.type}
                      onChange={(e) =>
                        setAccount({ ...account, type: e.target.value })
                      }
                      label="Tipo de cuenta"
                    >
                      {ACCOUNT_TYPES.map((x: any, index: any) => (
                        <MenuItem key={index} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="filter-label">Categoría</InputLabel>
                    <Select
                      labelId="filter-label"
                      id="Filtrar por estado"
                      fullWidth
                      value={account.category}
                      onChange={(e) =>
                        setAccount({ ...account, category: e.target.value })
                      }
                      label="Categoría"
                    >
                      {categories.map((x: any, index: any) => (
                        <MenuItem key={index} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    margin="4px 0px"
                  >
                    {categories.find((x) => x.id === account.category)
                      ?.income && 'Esta categoría se considera un ingreso'}
                  </Typography>
                  <TextField
                    margin="dense"
                    id="observation"
                    label="Observación"
                    type="text"
                    fullWidth
                    value={account.observation}
                    onChange={(e) =>
                      setAccount({ ...account, observation: e.target.value })
                    }
                  />
                  <TextField
                    margin="dense"
                    id="amount"
                    label="Monto"
                    fullWidth
                    value={formatMoney(account.amount)}
                    onChange={(e) =>
                      setAccount({
                        ...account,
                        amount: formatMoneyBack(e.target.value),
                      })
                    }
                  />
                  <TextField
                    margin="dense"
                    id="createdAt"
                    label="Fecha"
                    type="date"
                    fullWidth
                    value={account.createdAt}
                    onChange={(e) =>
                      setAccount({ ...account, createdAt: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={!business && !account.id}
          color="primary"
          onClick={() => handleSubmit()}
        >
          {' '}
          {account.id ? 'Guardar' : 'Crear'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewAccount;
