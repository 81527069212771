import { LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { formatMoney } from '../../../../../lib/functions/utils';
import CustomButton from '../../../../../lib/designSystem/Button';

const Interested: React.FC<any> = (props) => {
  const handleInterest = () => {
    window.open(
      `https://pruff.com/collections/?utm_source=XSACE232XZ-Z${props.companyId}-1s2daasd&utm_medium=collections-campaing`,
      '_blank',
    );
  };
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ textAlign: 'center' }}>
        ¿Quieres cobrar así de fácil?
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <CustomButton onClick={handleInterest}>Prueba gratis!</CustomButton>
      </div>
    </div>
  );
};

export default Interested;
