import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import { getBusinessCreditNotes } from '../../../../../lib/api/business';
import TableWithPagination from '../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../lib/common/DocumentSearcher';

const BusinessCreditNotes: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [creditNotes, setCreditNotes] = useState([]);
  const { id } = useParams<{ id: string }>();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchCreditNotes = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    if (!id) return;

    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getBusinessCreditNotes(dispatch, id, query);
      setCreditNotes(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchCreditNotes({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <HeaderWithActions
          variant="body1"
          title="Acá encontrarás las notas de crédito que esta empresa se ha emitido"
          buttons={[
            {
              name: 'Filtro',
              action: () => {},
              color: 'primary',
              customButton: (
                <DocumentSearcher
                  searchData={searchCreditNotes}
                  setData={setCreditNotes}
                  setLoading={setLoading}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  stakeholderIdentifier={stakeholderIdentifier}
                  setStakeholderIdentifier={setStakeholderIdentifier}
                  pagination={pagination}
                />
              ),
            },
          ]}
        />
        <TableWithPagination
          finalSumRow
          exportToFile="xlsx"
          tableSize="small"
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'stakeholderName', label: 'Cliente' },
            { key: 'stakeholderIdentifier', label: 'Rut cliente' },

            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'issuedDate', label: 'Emisión', type: 'date' },
            {
              key: 'cancelDate',
              label: 'Reclamo',
              type: 'date-colored',
            },
            {
              key: 'confirmDate',
              label: 'Confirmación',
              type: 'date-colored-ok',
            },
          ]}
          data={creditNotes}
          loading={loading}
          setData={setCreditNotes}
          searchData={searchCreditNotes}
          setPagination={setPagination}
          pagination={pagination}
        />
      </Grid>
    </Grid>
  );
};

export default BusinessCreditNotes;
