import React, { useEffect } from 'react';
import { IconButton, Paper, Popper, Typography } from '@mui/material';
import CustomPieChart from '../../../../../../../../../lib/designSystem/PieChart';
import { Cancel } from '@mui/icons-material';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';

const MiniPieChart = (props: any) => {
  const { openPopper, anchorEl, setAnchorEl, setOpenPopper, data, date, name } =
    props;
  props;

  useEffect(() => {
    if (!data) {
      setAnchorEl(null);
    }
  }, [data]);
  console.log(data);

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={openPopper}
      anchorEl={anchorEl}
      placement={'bottom'}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper
        style={{
          padding: 20,
          minWidth: 400,
          maxWidth: 800,
          maxHeight: 400,
          overflow: 'scroll',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Distribución
            </Typography>
            <Typography variant="body2" gutterBottom fontWeight="bold">
              {name}
            </Typography>
            <Typography variant="body2" gutterBottom fontWeight="bold">
              {date}
            </Typography>
          </div>
          <div>
            <IconButton
              onClick={() => {
                setAnchorEl(null);
                setOpenPopper(false);
              }}
            >
              <Cancel />
            </IconButton>
          </div>
        </div>
        <CustomPieChart data={data} money />
        <EnhancedTable
          data={data}
          dataHeaders={[
            { key: 'name', label: 'Cliente' },
            { key: 'value', label: 'Monto', type: 'money' },
          ]}
          finalSumRow
          exportToFile="xlsx"
        />
      </Paper>
    </Popper>
  );
};

export default MiniPieChart;
