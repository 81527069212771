import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NotificationState = {
  pendingRequests: number;
  pendingNotifications: number;
};

const initialState: NotificationState = {
  pendingRequests: 0,
  pendingNotifications: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setPendingRequests: (state, action: PayloadAction<number>) => {
      state.pendingRequests = action.payload;
    },
    decrementPendingRequests: (state) => {
      if (state.pendingRequests > 0) {
        state.pendingRequests -= 1;
      }
    },
    setPendingNotifications: (state, action: PayloadAction<number>) => {
      state.pendingNotifications = action.payload;
    },
    decrementPendingNotifications: (state) => {
      if (state.pendingNotifications && state.pendingNotifications > 0) {
        state.pendingNotifications -= 1;
      }
    },

    // You can add more reducers here if needed for other types of notifications
  },
});

export const {
  setPendingRequests,
  decrementPendingRequests,
  setPendingNotifications,
  decrementPendingNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
