import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  getBusiness,
  getBusinessRelation,
  getTemporaryBusinessViewsForExecutive,
} from '../../../lib/api';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { Typography } from '@mui/material';
import BusinessSummary from '../SII/Components/BusinessSummary';
import BusinessFactoring from '../SII/Components/BusinessFactoring';
import BusinessCrossInvoices from '../SII/Components/BusinessCrossInvoices';
import BusinessInvoices from '../SII/Components/BusinessInvoices';
import BusinessIVA from '../SII/Components/BusinessIVA';
import BusinessProfileDetails from './Components/Details';
import BusinessCreditNotes from './Components/BusinessCreditNotes';
import { validatePermissions } from '../../../lib/functions/utils';
import { AuthState } from '../../../services/redux/authSlice';

const getUpdatedTabs = (business: any) => {
  if (business.companies && !business.companies[0].active) {
    return [
      {
        id: 5,
        label: 'C. Tributaria',
        component: <BusinessIVA />,
        path: 'taxFolder',
      },
    ];
  }
  return [
    {
      id: 1,
      label: 'Resumen',
      component: <BusinessSummary />,
      path: 'summary',
    },
    {
      id: 2,
      label: 'Facturas',
      component: (
        <BusinessInvoices
          active={business.companies && business.companies[0].active}
          business={business}
        />
      ),
      path: 'invoices',
    },
    {
      id: 3,
      label: 'Notas de crédito',
      component: <BusinessCreditNotes />,
      path: 'creditNotes',
    },
    {
      id: 4,
      label: 'Factoring',
      component: <BusinessFactoring />,
      path: 'factoring',
    },
    {
      id: 5,
      label: 'Facturas cruzadas',
      component: <BusinessCrossInvoices />,
      path: 'cross-invoices',
    },
    {
      id: 6,
      label: 'C. Tributaria',
      component: <BusinessIVA />,
      path: 'taxFolder',
    },
    {
      id: 7,
      label: 'Otros',
      component: <BusinessProfileDetails />,
      path: 'others',
    },
  ];
};

const SIIProfile = (props: any) => {
  const { onlyIVA } = props;
  const { id, page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const currentPath = window.location.pathname;
  const initialPath = currentPath.split('/')[2];
  const temporary = initialPath === 'temporary';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState({ name: '', companies: [] });
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();
  const [recentlyCreated, setRecentlyCreated] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/companies/${id}/sii/${companyTabs[index].path}`);
  };

  const handleNavigationTemporary = (index: number) => {
    navigate(`/companies/temporary/${id}/sii/${companyTabs[index].path}`);
  };
  console.log(page);

  const mainBusiness = async () => {
    if (!id) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    const business = await getBusiness(dispatch, id);
    setBusiness(business);
    const tabs = getUpdatedTabs(business);
    let newProfileTabs: any = [];
    let startValueDefault = 0;
    console.log(onlyIVA, tabs);
    if (business.companies && !business.companies[0].active) {
      newProfileTabs = tabs;
      window.history.replaceState({}, '', `/companies/${id}/sii/taxFolder`);
    } else {
      if (validatePermissions(user, 'sii_summary_read'))
        newProfileTabs.push(tabs[0]);
      if (validatePermissions(user, 'sii_invoices_read'))
        newProfileTabs.push(tabs[1]);
      if (validatePermissions(user, 'sii_credit_notes_read'))
        newProfileTabs.push(tabs[2]);
      if (validatePermissions(user, 'sii_factoring_read'))
        newProfileTabs.push(tabs[3]);
      if (validatePermissions(user, 'sii_cross_read'))
        newProfileTabs.push(tabs[4]);
      if (validatePermissions(user, 'sii_iva_read'))
        newProfileTabs.push(tabs[5]);
      if (validatePermissions(user, 'sii_others_read'))
        newProfileTabs.push(tabs[6]);
      startValueDefault = newProfileTabs.findIndex((x: any) => x.path === page);
    }
    console.log(newProfileTabs);
    setCompanyTabs(newProfileTabs);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
  };

  const temporaryBusiness = async () => {
    if (!id) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    const business = await getBusiness(dispatch, id);
    const tabs = getUpdatedTabs(business);
    const siiViews = await getTemporaryBusinessViewsForExecutive(dispatch, id);
    console.log(siiViews);
    const availableTabsIds = siiViews.map((x: any) => x.id);
    console.log(availableTabsIds);
    setCompanyTabs(tabs.filter((x: any) => availableTabsIds.includes(x.id)));
    console.log(companyTabs);
    setBusiness(business);
  };

  const fetchRelations = async () => {
    console.log(id, temporary);
    setLoading(true);
    if (!id) {
      return setLoading(false);
    }

    if (!temporary) {
      await mainBusiness();
    } else {
      await temporaryBusiness();
    }

    setLoading(false);
  };

  const checkRecentlyCreated = async () => {
    if (!id) {
      alert('Falta el id de la empresa');
      return;
    }
    const businessRelationResponse = await getBusinessRelation(dispatch, id);
    if (
      businessRelationResponse?.createdAt <
      new Date(new Date().getTime() - 300 * 1000)
    ) {
      setRecentlyCreated(true);
    }
  };

  useEffect(() => {
    fetchRelations();
    checkRecentlyCreated();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        title={temporary ? business.name : undefined}
        tabs={companyTabs}
        infoChip={temporary ? 'Temporal' : undefined}
        colorChip={temporary ? '#E8C547' : undefined}
        onTabChange={!temporary ? handleNavigation : handleNavigationTemporary}
        // canShowTab={checkCanAccessView()}
      />
      {recentlyCreated && (
        <Typography variant="body1" color="error">
          *Esta empresa fue conectada hace menos de 5 minutos, algunas facturas
          todavía se están cargando...
        </Typography>
      )}
    </div>
  );
};

export default SIIProfile;
