import React, { useState } from 'react';
import { useEffect } from 'react';
import { payersHeaders } from '../../tableHeaders';
import EnhancedTable from '../../../../../../../../../../../../../lib/designSystem/Table';
import { getFactoringBalanceByPayers } from '../../../../../../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../../../../lib/designSystem/HeaderWithActions';
const STAGES_TO_CONSIDER_NEW_DOCUMENTS = [
  'Creada',
  'En revisión',
  'Cotizada',
  'Aceptada',
];
const PayersConcentration = (props: any) => {
  const { selectedRequest } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [concentrationsByPayers, setConcentrationsByPayers] = useState([]);
  const [proyectedConcentrationsByPayers, setProyectedConcentrationsByPayers] =
    useState<any[]>([]);
  const [showProyectedConcentrations, setShowProyectedConcentrations] =
    useState(false);

  const setUPConcentrationsByPayers = async () => {
    setLoading(true);
    try {
      console.log('selectedRequest', selectedRequest);
      const response = await getFactoringBalanceByPayers(dispatch, {
        identifier: selectedRequest.businessIdentifier,
        quoteId: selectedRequest.quoteId,
      });
      setConcentrationsByPayers(response);
      const proyectedAssignations = proyectActualRequest(response);
      console.log('proyectedAssignations', proyectedAssignations);
      setProyectedConcentrationsByPayers(proyectedAssignations);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const proyectActualRequest = (assignations: any) => {
    const proyectedAssignations = [...assignations];
    console.log(
      'STAGES_TO_CONSIDER_NEW_DOCUMENTS',
      STAGES_TO_CONSIDER_NEW_DOCUMENTS,
      selectedRequest.status,
      selectedRequest.documents,
    );
    if (STAGES_TO_CONSIDER_NEW_DOCUMENTS.includes(selectedRequest.status)) {
      for (const document of selectedRequest.documents) {
        const assignationIndex = proyectedAssignations.findIndex(
          (assignation: any) =>
            assignation.stakeholderIdentifier ===
            document.stakeholderIdentifier,
        );
        console.log('assignationIndex', assignationIndex);
        if (assignationIndex >= 0) {
          proyectedAssignations[assignationIndex] = {
            ...proyectedAssignations[assignationIndex],
            documentsCount:
              proyectedAssignations[assignationIndex].documentsCount + 1,
            totalAmount:
              proyectedAssignations[assignationIndex].totalAmount +
              document.amount,
          };
        } else {
          proyectedAssignations.push({
            stakeholderName: document.stakeholderName,
            stakeholderIdentifier: document.stakeholderIdentifier,
            totalDocumentsCount: 1,
            documentsCount: 1,
            totalAmount: document.amount,
          });
        }
      }
    }
    return proyectedAssignations;
  };

  useEffect(() => {
    setUPConcentrationsByPayers();
  }, []);

  const handleShowProyectedConcentrations = () => {
    setShowProyectedConcentrations(!showProyectedConcentrations);
    console.log('showProyectedConcentrations', showProyectedConcentrations);
    console.log(
      'proyectedConcentrationsByPayers',
      proyectedConcentrationsByPayers,
    );
    console.log('concentrationsByPayers', concentrationsByPayers);
  };

  const buttons: CustomButtonProps[] = [
    {
      name: showProyectedConcentrations
        ? 'Concentración actual'
        : 'Proyectar concentración',
      color: 'primary',
      action: handleShowProyectedConcentrations,
    },
  ];
  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes ver la concentración actual y proyectada de pagadores"
        variant="body1"
        buttons={buttons}
      />
      <EnhancedTable
        dataHeaders={payersHeaders}
        data={
          showProyectedConcentrations
            ? proyectedConcentrationsByPayers
            : concentrationsByPayers
        }
        rowsPerPageDefault={5}
        finalSumRow
        exportToFile="xlsx"
      />
    </div>
  );
};

export default PayersConcentration;
