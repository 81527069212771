import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: '500px',
      height: 260, // or any desired fixed width
    },
    input: {
      display: 'none',
    },
    fileUploadButton: {
      marginTop: '8px',
    },
    centerUpload: {
      textAlign: 'center',
    },
    right: {
      marginLeft: 'auto',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    uploadText: {
      marginTop: 8,
      padding: '4px 8px 8px',
      backgroundColor: '#3bc14a',
      borderRadius: '10px',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    uploadContainer: {
      maxHeight: '150px',
      overflowY: 'scroll',
    },
  }),
);

function MultiUploader(props: any) {
  const {
    handleChange,
    uploadedFileNames, // Assuming this is now an array of file names
    buttonText,
    center,
    textLeft,
  } = props;
  const classes = useStyles();

  // Modify the onChange event handler to handle multiple files

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {textLeft && (
          <Typography margin="6px 0px" variant="body1">
            {textLeft}
          </Typography>
        )}
        <div
          className={`${center && classes.centerUpload} ${textLeft && classes.right}`}
        >
          <input
            className={classes.input}
            id="contained-button-file"
            type="file"
            multiple
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CloudUploadIcon />}
              component="span"
            >
              {buttonText}
            </Button>
          </label>
        </div>
      </div>
      <div className={classes.uploadContainer}>
        {uploadedFileNames.length > 0 && (
          <div>
            {/* <Typography variant='body1'>Archivos subidos:</Typography> */}
            {uploadedFileNames.map((name: any, index: any) => (
              <div className={classes.uploadText}>
                <Typography>{name}</Typography>
                <CheckCircleOutlineIcon className={classes.icon} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MultiUploader;
