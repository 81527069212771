import React from 'react';
import MenuDialog from '../MenuDialog';
import { FilterList } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface FilterButtonProps {
  buttonColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  buttonLabel?: string;
  filters: {
    label: string;
    value: any;
    key: string;
    options?: any[];
    type?: string;
  }[];
  setData: any;
  originalData: any[];
}
const FilterButton: React.FC<FilterButtonProps> = (props) => {
  const { buttonColor, buttonLabel, setData, originalData } = props;
  const [filters, setFilters] = React.useState<
    {
      label: string;
      value: string;
      key: string;
      options?: any[];
      type?: string;
    }[]
  >(props.filters);

  const handleFilterChange = (event: any, newValue: any, filter: any) => {
    const newFilters = filters.map((x: any) => {
      if (x.key === filter.key && x.label === filter.label) {
        return { ...x, value: newValue };
      } else {
        return x;
      }
    });
    let newData = [...originalData];
    for (const filter of newFilters) {
      if (filter.value !== '' && filter.value !== null) {
        if (filter.type === 'startDate') {
          newData = newData.filter(
            (x: any) =>
              dayjs(x[filter.key]) >= dayjs(filter.value).startOf('day'),
          );
        } else if (filter.type === 'endDate') {
          newData = newData.filter(
            (x: any) =>
              dayjs(x[filter.key]) <= dayjs(filter.value).startOf('day'),
          );
        } else {
          newData = newData.filter((x: any) => x[filter.key] === filter.value);
        }
      }
    }
    console.log(newFilters);
    setData([...newData]);
    setFilters(newFilters);
  };

  return (
    <MenuDialog
      buttonColor={buttonColor || 'secondary'}
      buttonLabel={buttonLabel || 'Filtrar'}
      startIcon={<FilterList />}
    >
      {filters.map((x: any, index: any) => (
        <div key={index} style={{ padding: '4px 10px' }}>
          {!['startDate', 'endDate'].includes(x.type) && (
            <Autocomplete
              options={x.options}
              value={x.value}
              getOptionLabel={(option: any) => `${option}`}
              renderInput={(params: any) => (
                <TextField {...params} label={x.label} value={x.value} />
              )}
              onChange={(e: any, newValue: any) =>
                handleFilterChange(e, newValue, x)
              }
              fullWidth
            />
          )}
          {x.type === 'startDate' && (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="es-mx"
            >
              <DatePicker
                label={x.label}
                value={x.value}
                onChange={(e: any) => handleFilterChange(e, e, x)}
                sx={{ minWidth: '100%' }}
                slotProps={{ field: { clearable: true } }}
              />
            </LocalizationProvider>
          )}
          {x.type === 'endDate' && (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="es-mx"
            >
              <DatePicker
                label={x.label}
                value={x.value}
                onChange={(e: any) => handleFilterChange(e, e, x)}
                sx={{ minWidth: '100%' }}
                slotProps={{ field: { clearable: true } }}
              />
            </LocalizationProvider>
          )}
        </div>
      ))}
    </MenuDialog>
  );
};

export default FilterButton;
