import { requestHandler } from '../../../api';

export const expressGetBankAccounts = async (
  dispatch: any,
  companyRecieverId: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/express/${companyRecieverId}/bankAccount`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressCreateBankAccount = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/bankAccount`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const expressUpdateBankAccount = async (
  dispatch: any,
  companyRecieverId: string,
  bankAccountId: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/express/${companyRecieverId}/bankAccount/${bankAccountId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const expressDeleteBankAccount = async (
  dispatch: any,
  companyRecieverId: string,
  bankAccountId: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/express/${companyRecieverId}/bankAccount/${bankAccountId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const expressSelectBankAccount = async (
  dispatch: any,
  companyRecieverId: string,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/express/${companyRecieverId}/bankAccount/select/${data.bankAccountId}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
