import { requestHandler } from '../../../api';

export const getDataHubLightReport = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/datahub/reports/dataHubLightReport/${id}`,
    dispatch,
  });
  if (response) return response.data;
};
