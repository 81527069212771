import {
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
  formatDate,
  formatMoney,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../services/redux/authSlice';

interface ManagementProps {
  managements: {
    id: number;
    description: string;
    type: string;
    createdAt: string;
    invoiceId: string;
    updatedAt: string;
  }[];
  open: boolean;
  setOpen: any;
  setOpenModal: any;
  request: any;
}

const codesWithIcons = [
  'CALL',
  'EMAIL',
  'CLOSED',
  'auto-unenrollment',
  'auto-enrollment',
];

const Management: React.FC<ManagementProps> = (props) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const managements = props.managements.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const transformDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()}/${dateObj.getMonth()}/${dateObj.getFullYear()} - ${dateObj.getHours()}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={props.open}
        onClose={() => {
          props.setOpen(close);
        }}
      >
        <Box sx={{ width: 500, backgroundColor: '#F8FAFC', height: '100%' }}>
          <div style={{ paddingLeft: '23px' }}>
            <Typography variant="h4">Detalles documento</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <b>Folio</b>
                </Typography>
                <Typography variant="body1">
                  {` #${props.request?.folio}`}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  <b>Fecha de emisión</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatDate(props.request?.issuedDate)}`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">
                  <b>Monto</b>
                </Typography>
                <Typography variant="body1">
                  {` ${formatMoney(props.request?.amount)}`}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  <b>Deudor</b>
                </Typography>
                <Typography variant="body1">
                  {` ${props.request?.stakeholderName}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 23px',
            }}
          >
            <div>
              <Typography variant="h4">Gestiones realizadas</Typography>
            </div>
            <div>
              <CustomButton
                color="primary"
                onClick={() => {
                  props.setOpenModal(true);
                }}
                disabled={!validatePermissions(user, 'collections_create')}
              >
                {' '}
                Agregar{' '}
              </CustomButton>
            </div>
          </div>
          <List>
            {managements.length === 0 && (
              <ListItem>
                <ListItemText primary="No hay gestiones realizadas" />
              </ListItem>
            )}
            {managements.map((item) => (
              <div key={item.id}>
                <ListItem>
                  <ListItemIcon>
                    {item.type === 'CALL' && <PhoneIcon />}
                    {item.type === 'EMAIL' && <EmailIcon />}
                    {item.type === 'CLOSED' && <PriceCheckIcon />}
                    {item.type === 'CLOSED' && <PriceCheckIcon />}
                    {item.type.includes('auto-unenrollment') && (
                      <UnsubscribeIcon />
                    )}
                    {item.type.includes('auto-enrollment') && (
                      <MarkEmailReadIcon />
                    )}
                    {!codesWithIcons.includes(item.type) && <TaskAltIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.type} - ${transformDate(item.createdAt)}`}
                    secondary={item.description}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Management;
