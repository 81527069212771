import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  formatMoney,
  formatMoneyBack,
  isValidRate,
  validaRut,
} from '../../../../../../../../lib/functions/utils';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import { createFund, updateFund } from '../../../../../../../../lib/api';

const NewFund = (props: any) => {
  const { open, setOpen, setSuccess, setUp, fund, setFund, edit } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validParams = () => {
    if (fund.name === '' || fund.id === '' || !fund.cost || !fund.amount) {
      return false;
    }
    if (isNaN(Number(fund.cost)) || isNaN(Number(fund.amount))) {
      return false;
    }
    if (!validaRut(fund.id)) {
      alert('Rut inválido');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      const payload = {
        cost: Number(fund.cost),
        amount: Number(fund.amount),
      };
      if (edit) {
        await updateFund(dispatch, fund.id, payload);
      } else {
        const newPayload = {
          ...fund,
          ...payload,
        };
        await createFund(dispatch, newPayload);
      }
      setOpen(false);
      setSuccess('Fondo guardado correctamente');
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <HeaderWithActions
          title={edit ? `Editar ${fund.name}` : 'Nuevo fondo'}
          type="dialog"
        />
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Typography variant="body1">
              Ingrese los datos del nuevo fondo
            </Typography>
            {!edit && (
              <TextField
                label="Nombre"
                fullWidth
                margin="normal"
                value={fund.name}
                onChange={(e) => setFund({ ...fund, name: e.target.value })}
              />
            )}
            {!edit && (
              <TextField
                label="Rut"
                fullWidth
                margin="normal"
                value={fund.id}
                onChange={(e) => setFund({ ...fund, id: e.target.value })}
              />
            )}

            <TextField
              label="Costo"
              fullWidth
              margin="normal"
              value={fund.cost}
              onChange={(e) => {
                let value = e.target.value;
                if (!isValidRate(value)) {
                  return;
                }
                if (value.length > 1) {
                  if (value[0] === '0' && value[1] !== '.') {
                    value = value.slice(1);
                  }
                }
                setFund({ ...fund, cost: value });
              }}
            />
            <TextField
              label="Monto"
              fullWidth
              margin="normal"
              value={formatMoney(fund.amount)}
              onChange={(e) => {
                const nonFomatted = formatMoneyBack(e.target.value);
                setFund({ ...fund, amount: nonFomatted });
              }}
            />
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewFund;
