import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: '500px',
      height: 260, // or any desired fixed width
    },
    input: {
      display: 'none',
    },
    fileUploadButton: {
      marginTop: '8px',
    },
    centerUpload: {
      textAlign: 'center',
      paddingTop: '20px',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    uploadText: {
      marginTop: 8,
      padding: '4px 8px',
      backgroundColor: 'green',
      borderRadius: '5px',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

function Uploader(props: any) {
  const { handleFileChange, uploadedFileName, buttonText, accept } = props;
  const classes = useStyles();

  return (
    <div className={classes.centerUpload}>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        accept={accept ? accept : 'application/pdf'}
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudUploadIcon />}
          component="span"
        >
          {buttonText}
        </Button>
      </label>
      {uploadedFileName && (
        <div className={classes.uploadText}>
          <Typography>{uploadedFileName}</Typography>
          <CheckCircleOutlineIcon className={classes.icon} />
        </div>
      )}
    </div>
  );
}

export default Uploader;
