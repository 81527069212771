import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { getDocumentAlerts } from '../../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';

const ExecutiveAlerts: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const tableHeaders = [
    { key: 'alertType', label: 'Tipo de alerta' },
    { key: 'alertDate', label: 'Fecha de alerta', type: 'date-large' },
    { key: 'businessIdentifier', label: 'Rut cliente', type: 'report' },
    { key: 'businessName', label: 'Nombre cliente', type: 'report' },
    { key: 'folio', label: 'Folio documento' },
  ];

  const retrieveData = async () => {
    setLoading(true);
    const response = await getDocumentAlerts(dispatch);
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todas las alertas relacionadas a documentos en operación."
        variant="body1"
        buttons={[]}
      />
      <EnhancedTable
        exportToFile="xlsx"
        dataHeaders={tableHeaders}
        data={data}
        actions={[]}
        showActions
        rowsPerPageDefault={100}
      />
    </div>
  );
};

export default ExecutiveAlerts;
