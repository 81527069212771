import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { lastCessionMonthly } from '../../../../../../../lib/api/dashboard';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';

const MyWalletCessions = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ownCessions, setOwnCessions] = useState([]);
  const [otherCessions, setOtherCessions] = useState([]);
  const [recessions, setRecessions] = useState([]);
  const [filteredOwnCessions, setFilteredOwnCessions] = useState([]);
  const [filteredOtherCessions, setFilteredOtherCessions] = useState([]);
  const [filteredRecessions, setFilteredRecessions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [allBusinesses, setAllBusinesses] = useState<any>([]);
  const [allCessioners, setAllCessioners] = useState<any>([]);
  const [allPayers, setAllPayers] = useState<any>([]);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [selectedCessioner, setSelectedCessioner] = useState('');
  const [selectedPayer, setSelectedPayer] = useState('');
  const [orderedMonths, setOrderedMonth] = useState([]);

  const buildFilters = (cessionsResponse: any) => {
    const businesses: any = new Set();
    const cessioners: any = new Set();
    const payers: any = new Set();

    cessionsResponse.ownCessions.forEach((cession: any) => {
      businesses.add(cession.businessName);
      cessioners.add(cession.cessionerName);
      payers.add(cession.payerName);
    });

    cessionsResponse.otherCessions.forEach((cession: any) => {
      businesses.add(cession.businessName);
      cessioners.add(cession.cessionerName);
      payers.add(cession.payerName);
    });

    setAllBusinesses([...businesses]);
    setAllCessioners([...cessioners]);
    setAllPayers([...payers]);
  };

  const handleChange = (event: any, value: any, reason: any, field: any) => {
    if (field === 'business') setSelectedBusiness(value);
    if (field === 'cessioner') setSelectedCessioner(value);
    if (field === 'payer') setSelectedPayer(value);

    updateFilters();
  };

  const updateFilters = () => {
    let filteredOwn = [...ownCessions];
    let filteredOther = [...otherCessions];
    let filteredRecessions = [...recessions];

    if (selectedBusiness) {
      filteredOwn = filteredOwn.filter(
        (cession: any) => cession.businessName === selectedBusiness,
      );
      filteredOther = filteredOther.filter(
        (cession: any) => cession.businessName === selectedBusiness,
      );
      filteredRecessions = filteredRecessions.filter(
        (cession: any) => cession.businessName === selectedBusiness,
      );
    }
    if (selectedCessioner) {
      filteredOwn = filteredOwn.filter(
        (cession: any) => cession.cessionerName === selectedCessioner,
      );
      filteredOther = filteredOther.filter(
        (cession: any) => cession.cessionerName === selectedCessioner,
      );
      filteredRecessions = filteredRecessions.filter(
        (cession: any) => cession.cessionerName === selectedCessioner,
      );
    }
    if (selectedPayer) {
      filteredOwn = filteredOwn.filter(
        (cession: any) => cession.payerName === selectedPayer,
      );
      filteredOther = filteredOther.filter(
        (cession: any) => cession.payerName === selectedPayer,
      );
      filteredRecessions = filteredRecessions.filter(
        (cession: any) => cession.payerName === selectedPayer,
      );
    }

    setFilteredOwnCessions(filteredOwn);
    setFilteredOtherCessions(filteredOther);
    setFilteredRecessions(filteredRecessions);

    const businesses = new Set();
    const cessioners = new Set();
    const payers = new Set();

    filteredOwn.forEach((cession: any) => {
      businesses.add(cession.businessName);
      cessioners.add(cession.cessionerName);
      payers.add(cession.payerName);
    });

    filteredOther.forEach((cession: any) => {
      businesses.add(cession.businessName);
      cessioners.add(cession.cessionerName);
      payers.add(cession.payerName);
    });

    filteredRecessions.forEach((cession: any) => {
      businesses.add(cession.businessName);
      cessioners.add(cession.cessionerName);
      payers.add(cession.payerName);
    });

    setAllBusinesses([...businesses]);
    setAllCessioners([...cessioners]);
    setAllPayers([...payers]);
  };

  const updateMonthly = async (month?: any) => {
    try {
      if (!month) return (month = orderedMonths[0]);
      setSelectedMonth(month);
      setLoading(true);
      const cessionsResponse = await lastCessionMonthly(dispatch, month);
      setOwnCessions(cessionsResponse.ownCessions || []);
      setOtherCessions(cessionsResponse.otherCessions || []);
      setRecessions(cessionsResponse.reCessions || []);
      setOrderedMonth(cessionsResponse.orderedMonths);
      buildFilters(cessionsResponse);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchCessions = async () => {
    setLoading(true);
    try {
      const cessionsResponse = await lastCessionMonthly(dispatch);
      setOwnCessions(cessionsResponse.ownCessions || []);
      setOtherCessions(cessionsResponse.otherCessions || []);
      setRecessions(cessionsResponse.reCessions || []);
      cessionsResponse.orderedMonths[0] &&
        setSelectedMonth([cessionsResponse.orderedMonths[0]]);
      setOrderedMonth(cessionsResponse.orderedMonths);
      buildFilters(cessionsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const customTextField = (params: any, label: string) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        style: {},
      }}
      label={label}
    />
  );

  const businessFilter = () => {
    return (
      <Autocomplete
        options={allBusinesses}
        value={selectedBusiness || null}
        getOptionLabel={(option) => option}
        renderInput={(params) => customTextField(params, 'Cliente')}
        onChange={(event, value, reason) =>
          handleChange(event, value, reason, 'business')
        }
        fullWidth
      />
    );
  };

  const payerFilter = () => {
    return (
      <Autocomplete
        options={allPayers}
        value={selectedPayer || null}
        getOptionLabel={(option) => option}
        renderInput={(params) => customTextField(params, 'Deudor')}
        onChange={(event, value, reason) =>
          handleChange(event, value, reason, 'payer')
        }
        fullWidth
      />
    );
  };

  const cessionerFilter = () => {
    return (
      <Autocomplete
        options={allCessioners}
        value={selectedCessioner || null}
        getOptionLabel={(option) => option}
        renderInput={(params) => customTextField(params, 'Factoring')}
        onChange={(event, value, reason) =>
          handleChange(event, value, reason, 'cessioner')
        }
        fullWidth
      />
    );
  };

  useEffect(() => {
    fetchCessions();
  }, []);

  useEffect(() => {
    updateFilters();
  }, [
    ownCessions,
    otherCessions,
    selectedBusiness,
    selectedCessioner,
    selectedPayer,
  ]);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '4px 0px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl margin="dense" style={{ minWidth: '200px' }}>
                <InputLabel id="filter-label">Mes</InputLabel>
                <Select
                  labelId="filter-label"
                  fullWidth
                  value={selectedMonth}
                  onChange={(e) => updateMonthly(e.target.value)}
                  label="MEs"
                >
                  {orderedMonths.map((x: any, index: any) => (
                    <MenuItem key={index} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ minWidth: '50%' }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {cessionerFilter()}
                </Grid>
                <Grid item xs={4}>
                  {businessFilter()}
                </Grid>
                <Grid item xs={4}>
                  {payerFilter()}
                </Grid>
              </Grid>
            </div>
          </div>

          <Typography variant="h5">Mi cartera</Typography>
          <EnhancedTable
            data={filteredOwnCessions}
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'payerName', label: 'Deudor' },
              { key: 'cessionDate', label: 'Fecha', type: 'date' },
              { key: 'amount', label: 'Monto', type: 'money' },
            ]}
            finalSumRow
            exportToFile="xlsx"
          />
          <Typography variant="h5">Cesiones perdidas</Typography>
          <EnhancedTable
            data={filteredOtherCessions}
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'payerName', label: 'Deudor' },
              { key: 'cessionDate', label: 'Fecha', type: 'date' },
              { key: 'amount', label: 'Monto', type: 'money' },
              { key: 'cessionerName', label: 'Factoring' },
            ]}
            finalSumRow
            exportToFile="xlsx"
          />

          <Typography variant="h5">Cesiones ganadas recedidas</Typography>
          <EnhancedTable
            data={filteredRecessions}
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              { key: 'payerName', label: 'Deudor' },
              { key: 'cessionDate', label: 'Fecha', type: 'date' },
              { key: 'amount', label: 'Monto', type: 'money' },
              { key: 'cessionerName', label: 'Fondo' },
            ]}
            finalSumRow
            exportToFile="xlsx"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyWalletCessions;
