import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import {
  getBusiness,
  getBusinessRelation,
  getLastTGRScrapDates,
  getTemporaryBusinessViewsForExecutive,
} from '../../../lib/api';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { Typography } from '@mui/material';
import TGRAgreements from './components/Agreements';
import TGRProceedings from './components/Proceedings';
import TGRDebts from './components/Debts';
import { formatDate } from '../../../lib/functions/utils';
import { formatDateToLocal } from '../../../lib/designSystem/Table/cellFormat';

const tabs = [
  { id: 0, label: 'Cartola Fiscal', component: <TGRDebts />, path: 'debts' },
  {
    id: 1,
    label: 'Convenios',
    component: <TGRAgreements />,
    path: 'agreements',
  },
  {
    id: 2,
    label: 'Cobranzas',
    component: <TGRProceedings />,
    path: 'collections',
  },
];

const TGRProfile = (props: any) => {
  const { id, page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const currentPath = window.location.pathname;
  const initialPath = currentPath.split('/')[2];
  const temporary = initialPath === 'temporary';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState({ name: '', companies: [] });
  const [active, setAcitve] = useState(false);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();
  const [recentlyCreated, setRecentlyCreated] = useState(false);
  const [tgrUpdates, setTgrUpdates] = useState(false);
  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/companies/${id}/tgr/${companyTabs[index].path}`);
  };

  const handleNavigationTemporary = (index: number) => {
    navigate(`/companies/temporary/${id}/tgr/${companyTabs[index].path}`);
  };
  console.log(page);

  const checkCanAccessView = () => {
    const exists = companyTabs.find((x: any) => x.path === page);
    if (exists) return true;

    return false;
  };

  const mainBusiness = async () => {
    if (!id) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }

    const newProfileTabs: any = [...tabs];
    const tgrUpdates = await fetchLastScrapDates();

    const tabsWithUpdates = newProfileTabs.map((tab: any) => {
      const update = tgrUpdates.find(
        (x: any) => `${x.scraperId.split('-')[1]}s` === tab.path,
      );
      return {
        ...tab,
        hoverText:
          update &&
          `Revisado última vez: ${formatDateToLocal(update.lastScrapedAt, false)}`,
      };
    });
    console.log(tabsWithUpdates);
    let startValueDefault = 0;
    startValueDefault = tabsWithUpdates.findIndex((x: any) => x.path === page);
    setCompanyTabs(tabsWithUpdates);
    console.log(tabsWithUpdates);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setBusiness(business);
  };

  const fetchLastScrapDates = async () => {
    if (!id) {
      setLoading(false);
      return alert('Ha ocurrido un error');
    }
    try {
      return await getLastTGRScrapDates(dispatch, id);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRelations = async () => {
    console.log(id, temporary);
    setLoading(true);
    if (!id) {
      return setLoading(false);
    }
    await mainBusiness();

    // if (!temporary) {
    //   await mainBusiness()
    // } else {
    //   await temporaryBusiness()
    // }

    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  const handleNavigationBack = () => {
    navigate(`/companies`);
  };

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        title={temporary ? business.name : undefined}
        tabs={companyTabs}
        onTabChange={!temporary ? handleNavigation : handleNavigationTemporary}
        // canShowTab={checkCanAccessView()}
      />
      {recentlyCreated && (
        <Typography variant="body1" color="error">
          *Esta empresa fue conectada hace menos de 5 minutos, algunas facturas
          todavía se están cargando...
        </Typography>
      )}
    </div>
  );
};

export default TGRProfile;
