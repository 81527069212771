export const buildRequestId = (
  selectedRequest: any,
  requestType: string,
  extra?: string,
) => {
  switch (requestType) {
    case 'invoice':
      if (extra === 'quote') return selectedRequest.firstQuote.id;
      return `${selectedRequest.id}-${selectedRequest.recipientCompany.id}`;
    case 'factoring-request':
      return selectedRequest.firstQuote.id;
    case 'creditLine':
      return selectedRequest.id;
    default:
      return selectedRequest.id;
  }
};
