import { requestHandler } from '../../api';

export const getAllDocumentDiscounts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentDiscount`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentDiscount = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentDiscount/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createDocumentDiscount = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/documentDiscount`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const updateDocumentDiscount = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/documentDiscount/${id}`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
