import { requestHandler } from '../../api';

export const submitVerification = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/invoiceConfirmation/batch`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const fetchAllVerifications = async (
  dispatch: any,
  queryParams: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/invoiceConfirmation?${queryParams}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentVerifications = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/invoiceConfirmation/document/${data.documentId}`,
    dispatch,
  });
  if (response) return response.data;
};
