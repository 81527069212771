import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Configuration from './components/Configure';
import DetailModal from './components/DetailModal';
import { useNavigate } from 'react-router-dom';
import { getIntegrationStatus } from '../../../../lib/api';
import SuperDebtDetails from './components/DialogDetails';
import GenericCard from '../common/CardComponent';

const SuperDebtDetailsText = {
  title: 'Deuda',
  description:
    'Esta deuda es consultada por las empresas para saber si un cliente tiene deudas en otras entidades financieras.',
};

interface IntegrationProps {
  id: number;
  name: string;
  description: string;
  packages: any[];
}

interface IntegrationStatus {
  integrationPackageId: number;
  createdAt: Date;
  integrationPackage: {
    id: number;
    fixedCost: number;
    description: string;
  };
  status: string;
  token: string;
  username: string;
}

const SuperDebtIntegration: React.FC<IntegrationProps> = (
  props: IntegrationProps,
) => {
  const { id, description, packages } = props;
  const [loading, setLoading] = useState(false);
  const [integrationStatus, setIntegrationStatus] =
    useState<IntegrationStatus | null>();
  const [openConfig, setOpenConfig] = useState(false);
  const [reload, setReload] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [integrationDetails, setIntegrationDetails] = useState({
    title: '',
    description: '',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openApp = () => {
    navigate('/consultor?query=superDebt');
  };

  const getIntegrationSwitch = async () => {
    try {
      setLoading(true);
      const response = await getIntegrationStatus(dispatch, id);
      setIntegrationStatus(response.status);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDetailClick = () => {
    setOpenDetailModal(true);
    setIntegrationDetails({
      title: SuperDebtDetailsText.title,
      description: SuperDebtDetailsText.description,
    });
  };

  const handleConfigClick = () => {
    setOpenConfig(true);
  };

  const handleOpenClick = () => {
    openApp();
  };

  useEffect(() => {
    getIntegrationSwitch();
  }, [reload]);

  return (
    <>
      <GenericCard
        imageSrc="http://proriesgo.cl/images/logoProriesgo.jpg"
        imageAlt="proriesgo logo"
        description={description}
        loading={loading}
        onDetailClick={handleDetailClick}
        onConfigClick={handleConfigClick}
        onOpenClick={handleOpenClick}
        integrationStatus={integrationStatus}
      />
      <Configuration
        id={id}
        open={openConfig}
        setOpen={setOpenConfig}
        setReload={setReload}
        reload={reload}
        packages={packages}
      />
      <DetailModal
        open={openDetailModal}
        setOpen={setOpenDetailModal}
        title={integrationDetails.title}
        description={integrationDetails.description}
        customComponent={
          <SuperDebtDetails integrationStatus={integrationStatus} />
        }
      />
    </>
  );
};

export default SuperDebtIntegration;
