import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import { useDispatch } from 'react-redux';
import { Download } from '@mui/icons-material';
import ExcelJS from 'exceljs';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import ReportIncomeDailyTable from './DailyTable';
import { DateSearcher } from '../common/DateSearcher';
import { getIncomeBookReport } from '../../../../../../lib/api/reports';

const IncomeBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').subtract(4, 'hour'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('day').subtract(4, 'hour'),
  );
  const dispatch = useDispatch();

  const exportToExcel = async () => {
    const plainData = data.map((item: any) => {
      return [
        item.key,
        item.lendAmount,
        item.lendAmountNet,
        item.rateAmount,
        item.arrearAmount,
        item.commissionAmount,
        item.otherIncome,
        item.totalIncome,
      ];
    });
    const headers = [
      'Fecha',
      'Monto colocado',
      'Monto colocado neto',
      'Monto tasa',
      'Monto mora',
      'Monto comisión',
      'Otros ingresos',
      'Ingreso total',
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add data to worksheet
    [headers, ...plainData].forEach((rowData, rowIndex) => {
      console.log(rowData);
      const row = worksheet.addRow(rowData);
      if (rowIndex === 0) {
        // Make the first row bold, visible, and add grey background color
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getColumn(cell.col).width = 20;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, // Grey background color
          };
        });
      }
    });
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();

    URL.revokeObjectURL(url);
  };

  const setUp = async () => {
    try {
      setLoading(true);
      const response = await getIncomeBookReport(
        dispatch,
        startDate.toString(),
        endDate.toString(),
      );
      setData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <Grid container justifyContent="left">
        <HeaderWithActions
          variant="body1"
          customTitle={
            <DateSearcher
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setUp={setUp}
            />
          }
          buttons={[
            {
              action: () => exportToExcel(),
              name: 'Descargar XSLX',
              color: 'primary',
              startIcon: <Download />,
            },
          ]}
        />
      </Grid>
      <ReportIncomeDailyTable rows={data} />
      {/* <EnhancedTable
        dataHeaders={[
          { key: 'key', label: 'Fecha' },
          { key: 'arrerAmount', label: 'Monto mora', type: 'money' },
          { key: 'rateAmount', label: 'Monto tasa', type: 'money' },
          { key: 'commissionAmount', label: 'Monto comisión', type: 'money' },
          { key: 'lendAmount', label: 'Monto colocado', type: 'money' },
          { key: 'lendAmountNet', label: 'Monto colocado neto', type: 'money' },
          { key: 'totalIncome', label: 'Ingreso total', type: 'money' },
        ]}
        data={data}
        actions={[]}
        showActions
        rowsPerPageDefault={100}
      /> */}
    </div>
  );
};

export default IncomeBook;
