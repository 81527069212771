import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TablePagination,
  Checkbox,
  TableContainer,
  Tooltip,
  IconButton,
} from '@mui/material';
import { formatMoney } from '../../../../../../../../lib/functions/utils';
import EnhacedBox from '../../../../../../../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '4px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
  }),
);

const firstCellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '4px 16px',
};

const DataTable = (props: any) => {
  const {
    treeData,
    nodesForAssignation,
    setNodesForAssignation,
    handleEditAccount,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const classes = useStyles();

  const handleSelectChange = (
    rowIndex: number,
    selectName:
      | 'treeType'
      | 'financialStatementsTreeNodesId'
      | 'count'
      | 'negative',
    value: any,
  ) => {
    console.log(rowIndex, selectName, value);
    const newArray = [...nodesForAssignation];
    const temp = newArray[rowIndex];
    temp[selectName] = value;
    setNodesForAssignation(newArray);
    console.log(treeData);
    console.log(newArray);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // Handlers for opening and closing the tooltip.
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

  // Handlers for opening and closing the tooltip.

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>Información recolectada</TableCell>
            <TableCell colSpan={4}>Asignación</TableCell>
            <TableCell colSpan={1}>Resultado</TableCell>
          </TableRow>
          <TableRow>
            <TableCell title={'Cuenta'} style={firstCellStyle}></TableCell>
            <TableCell title={'Cuenta'} style={firstCellStyle}>
              Cuenta
            </TableCell>
            <TableCell className={classes.tableCell}>Tipo</TableCell>
            <TableCell className={classes.tableCell}>Valor</TableCell>
            <TableCell className={classes.tableCell}>Invertir valor</TableCell>
            <TableCell className={classes.tableCell}>Tipo de balance</TableCell>
            <TableCell className={classes.tableCell}>
              Materia a asignar
            </TableCell>
            <TableCell className={classes.tableCell}>Contabilizar</TableCell>
            <TableCell className={classes.tableCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {nodesForAssignation
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: any, rowIndex: any) => (
              <TableRow key={rowIndex}>
                <TableCell style={firstCellStyle}>
                  <IconButton onClick={() => handleEditAccount(rowIndex)}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <Tooltip
                  title={row.initialSubject}
                  open={openTooltipIndex === rowIndex}
                  onClose={() => setOpenTooltipIndex(null)}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {},
                      },
                    ],
                  }}
                >
                  <TableCell
                    style={firstCellStyle}
                    onMouseEnter={() => setOpenTooltipIndex(rowIndex)}
                    onMouseLeave={() => setOpenTooltipIndex(null)} // Close the tooltip when the mouse leaves
                    onClick={() => setOpenTooltipIndex(null)}
                  >
                    {row.initialSubject}
                  </TableCell>
                </Tooltip>
                <TableCell className={classes.tableCell}>
                  {row.initialType}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {formatMoney(row.value)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Checkbox
                    checked={nodesForAssignation[rowIndex]?.negative}
                    onChange={(_, checked) =>
                      handleSelectChange(rowIndex, 'negative', checked)
                    }
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Select
                    className={classes.selectSmall} // Use the small-width style
                    value={nodesForAssignation[rowIndex]?.treeType || ''}
                    onChange={(e) =>
                      handleSelectChange(
                        rowIndex,
                        'treeType',
                        e.target.value as string,
                      )
                    }
                  >
                    {treeData.map((x: any) => (
                      <MenuItem value={x.id}>{x.name}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Select
                    className={classes.selectSmall} // Use the small-width style
                    value={
                      nodesForAssignation[rowIndex]
                        ?.financialStatementsTreeNodesId || ''
                    }
                    disabled={
                      nodesForAssignation[rowIndex]?.treeType ? false : true
                    }
                    onChange={(e) =>
                      handleSelectChange(
                        rowIndex,
                        'financialStatementsTreeNodesId',
                        e.target.value as string,
                      )
                    }
                  >
                    {nodesForAssignation[rowIndex].treeType &&
                      treeData
                        .find(
                          (x: any) =>
                            x.id === nodesForAssignation[rowIndex]?.treeType,
                        )
                        ?.financialStatementsTreeNodes.map(
                          (x: any) =>
                            !x.isFather && (
                              <MenuItem value={x.id}>{x.key}</MenuItem>
                            ),
                        )}
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Checkbox
                    checked={nodesForAssignation[rowIndex]?.count}
                    onChange={(_, checked) =>
                      handleSelectChange(rowIndex, 'count', checked)
                    }
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {nodesForAssignation[rowIndex]?.count
                    ? formatMoney(
                        (nodesForAssignation[rowIndex]?.negative ? -1 : 1) *
                          row.value,
                      )
                    : ' No incluído'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TablePagination
          count={nodesForAssignation.length} // total number of rows
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Table>
    </TableContainer>
  );
};

export default DataTable;
