import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Button, Divider, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ClientConfigState } from '../../services/redux/clientConfigSlice';
import { AuthState, logout } from '../../services/redux/authSlice';
import { useSelector } from 'react-redux';
import { logoutUser } from '../../lib/api';
import { useDispatch } from 'react-redux';
import { setConfig } from '../../services/redux/clientConfigSlice';
import { getCompanyStyles } from '../../lib/api/express/company';
import CustomButton from '../../lib/designSystem/Button';
import ExpressSidebar from './SiderBar';
import { createStyles, withStyles } from '@mui/styles';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { ArrowDropDown, KeyboardArrowDown } from '@mui/icons-material';

const styles = createStyles({
  layoutWrapper: {
    display: 'flex',
    height: '100vh', // Covers the entire viewport height
  },
  content: {
    width: '100%',
    flexGrow: 1, // Allows it to grow and consume available space
    display: 'flex', // Added to facilitate flex-based layout
    flexDirection: 'column', // Stack children vertically
  },
  layout: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '0px 20px',
    backgroundColor: '#F8FAFC',
    boxSizing: 'border-box',
  },
  loading: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});

const WhiteAppBar = styled(AppBar)({
  padding: 2,
  backgroundColor: '#fff',
  borderBottom: '1px solid #eaecf0',
});

const ExpressLayout = (props: any) => {
  const { children, classes } = props;
  const auth = useSelector((state: { auth: AuthState }) => state.auth);
  const expressConfig = useSelector(
    (state: { clientConfiguration: ClientConfigState }) =>
      state.clientConfiguration.configs,
  );
  const domain = window.location.hostname; // Obtiene 'banpro.pruff.com'
  const subdomain = domain.split('.')[0]; // Obtiene 'banpro'
  const finalClientId = subdomain;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const handleLogout = async () => {
    const response = await logoutUser(dispatch);
    if (response.success) {
      console.log('logout');
      dispatch(logout());
    }
  };

  const isInCorrectPortal = async () => {
    console.log('auth', auth, finalClientId);
    console.log('auth', auth.isAuthenticated, auth?.user?.clientId);
    if (auth.isAuthenticated && auth?.user?.clientId !== finalClientId) {
      console.log('kick out');
      dispatch(logout());
      return false;
    }
    return true;
  };

  const setUpClientConfiguration = async () => {
    try {
      const correctPortal = await isInCorrectPortal();
      if (!correctPortal) return;

      console.log('clientId', finalClientId);
      if (!finalClientId) return;
      console.log('expressConfig', expressConfig);
      console.log('finalClientId', expressConfig.finalClientId);
      if (!expressConfig || expressConfig.finalClientId !== finalClientId) {
        const response = await getCompanyStyles(finalClientId);
        console.log('executing dispatch');
        dispatch(setConfig({ ...response, finalClientId }));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    setUpClientConfiguration();
  }, []);

  return (
    <div>
      <WhiteAppBar position="static" elevation={0}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 0px',
            width: '96%',
            margin: 'auto',
            alignItems: 'center',
          }}
        >
          <img
            width={160}
            src={`https://pruff-public.s3.amazonaws.com/company-logos/${expressConfig?.companyId}.png`}
            alt="Logo"
          />
          <div>
            {auth.isAuthenticated && (
              <div>
                <Button
                  ref={buttonRef}
                  aria-controls="client-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{
                    color: expressConfig?.pruffConnect_styles_mainColor,
                  }}
                  endIcon={<KeyboardArrowDown />}
                >
                  {auth.user?.name}
                </Button>
                <Menu
                  id="client-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  keepMounted
                  MenuListProps={{
                    style: {
                      minWidth: buttonRef.current
                        ? buttonRef.current.offsetWidth
                        : undefined,
                    },
                  }}
                >
                  <MenuItem disabled>{auth.user?.companyName}</MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleLogout();
                      handleClose();
                    }}
                  >
                    Salir
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </WhiteAppBar>
      <div className={classes.layoutWrapper}>
        {auth.isAuthenticated && auth.user?.autoEnrrolled && <ExpressSidebar />}
        <div className={classes.content}>
          <div className={`${classes.layout}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ExpressLayout);
