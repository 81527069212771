import React from 'react';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate, useParams } from 'react-router-dom';
import ExecutedEvaluations from './components/Executed';

const CreditHubProEvaluations: React.FC = () => {
  const { mainPage } = useParams<{ mainPage: string }>();
  const tabs: any = [
    //{ label: 'Dashboard', component: <div></div>, path: 'summary', index: 0},
    {
      label: 'Realizadas',
      component: <ExecutedEvaluations />,
      mainPage: 'executed',
      subPage: 'creditLine',
      index: 0,
    },
    // { label: 'Contactos', component: <Main />, path: 'contacts', index: 0 },
  ];
  const startValue = tabs.findIndex((x: any) => x.mainPage === mainPage);
  const navigate = useNavigate();

  const handleNavigation = (index: number) => {
    navigate(`/creditHubPro/${tabs[index].mainPage}/${tabs[index].subPage}`);
  };
  return (
    <div>
      <EnhancedTab
        title={'Evaluaciones'}
        startValue={startValue}
        tabs={tabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default CreditHubProEvaluations;
