import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import Companies from './components/Businesses';

const companyTabs = [
  { label: 'Agregadas', component: <Companies />, path: 'added', index: 0 },
  // { label: 'Temporales', component: <TemporaryBusinesses />, path: 'shared', index: 1},
];

const MyAccounts: React.FC = () => {
  const { page } = useParams<{ page: string }>();
  const navigate = useNavigate();
  const [compnayTabs, setCompanyTabs] = useState<any>(companyTabs);

  const handleNavigation = (index: number) => {
    navigate(`/companies/${compnayTabs[index].path}`);
  };
  let startValue = compnayTabs.findIndex((x: any) => x.path === page);
  console.log(startValue);
  if (startValue === -1) startValue = 0;

  const setUp = async () => {
    const newCompanyTabs: any = [...companyTabs];
    // if (validateCompanyTypes(user, 'Factoring')) {
    //   if (validatePermissions(user, 'financial_states_update_configuration'))  {
    //     newCompanyTabs.push({ label: 'Balances ', component: <FinancialStatementsSettings />, path: 'financialStatements', index: 0})
    //   }
    // }
    setCompanyTabs(newCompanyTabs);
  };

  useEffect(() => {
    setUp();
  }, []);

  return (
    <div>
      <EnhancedTab
        title="Mis cuentas"
        startValue={startValue}
        tabs={compnayTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default MyAccounts;
