import React from 'react';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';

const TheBillerFinalizedModal = (props: any) => {
  const {
    finalizedModal,
    setFinalizedModal,
    resultInvoices,
    successInvoices,
    handleClose,
  } = props;
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={finalizedModal}
      onClose={() => handleClose()}
    >
      <DialogTitle>
        <Typography variant="h5">Resultado facturación</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ padding: '10px 0px' }}>
          Se ha realizado la facturación. A continuación se muestra el
          resultado.
        </Typography>
        <EnhancedTable
          data={resultInvoices}
          dataHeaders={[
            { key: 'code', label: 'Tipo de factura' },
            { key: 'name', label: 'Empresa' },
            { key: 'identifier', label: 'Rut' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'status', label: 'Creada', type: 'yes-no-circle' },
            { key: 'error', label: 'Error' },
          ]}
        />
        {successInvoices.length > 0 && (
          <Typography style={{ padding: '10px 0px' }}>
            Se está actualizando el estado de las facturas en el sistema. Dentro
            de unos minutos podrás verlas en la sección de facturas.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => setFinalizedModal(false)}
        >
          {' '}
          Salir{' '}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default TheBillerFinalizedModal;
