// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AuthState,
  authenticate,
  logout,
} from '../../../services/redux/authSlice'; // Import the action
import {
  authenticateUser,
  confirmUserPasswordRecovery,
  confirmUserRegistrationCode,
  getUserProfile,
  updatePasswordRecovery,
  updateUser,
} from '../../../lib/api';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/store';
import HeaderWithActions from '../../../lib/designSystem/HeaderWithActions';
import {
  Divider,
  IconButton,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon
import { useParams } from 'react-router-dom';
import { Copyright } from '../EnrolationForm';
import CustomButton from '../../../lib/designSystem/Button';
import { log } from 'console';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const ConfirmUserRegistrationAdmin = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state: RootState) => state.auth);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState('');
  const [user, setUser] = useState<any>({});
  const userRedux = useSelector(
    (state: { auth: AuthState }) => state.auth.user,
  );

  const confirmCodeUser = async () => {
    try {
      setLoading(true);
      const userResponse = await confirmUserPasswordRecovery({ code });
      console.log(userResponse);
      if (!userResponse.success) {
        setError(true);
        setUser(userResponse.user);
      }
      setClientId(userResponse.clientId);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  useEffect(() => {
    confirmCodeUser();
  }, []);

  const passwordValidations = () => {
    if (!password) return alert('Ingresa una contraseña');
    if (password !== confirmPassword)
      return alert('Las contraseñas no coinciden');
    if (password.length < 8)
      return alert('La contraseña debe tener al menos 8 caracteres');
    if (password.length > 20)
      return alert('La contraseña debe tener máximo 20 caracteres');
    if (!password.match(/[A-Z]/g))
      return alert('La contraseña debe tener al menos una letra mayúscula');
    if (!password.match(/[a-z]/g))
      return alert('La contraseña debe tener al menos una letra minúscula');
    return true;
  };

  const handleSubmit = async () => {
    if (!passwordValidations()) return;
    setLoading(true);
    try {
      const payload = {
        code,
        password,
        name,
      };
      const resposne = await updatePasswordRecovery(payload);
      if (!resposne.success) {
        setLoading(false);
        return alert(resposne.message);
      }
      const userResponse = await confirmUserRegistrationCode({ code });
      if (!userResponse.success) {
        setLoading(false);
        return alert(userResponse.message);
      }
      alert(
        'Se ha actualizado la contraseña, por favor ingresa con tu nueva contraseña',
      );
      if (userRedux) {
        dispatch(logout());
      }
      window.location.href = `/`;
    } catch (error) {
      alert('Usuario no existe');
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="noLayout" />;

  if (error) {
    return (
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <Typography variant="h4">Error</Typography>
        <Typography variant="h6">
          El código ingresado no tiene validez
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
        <EnhancedBox>
          <Typography style={{ padding: '6px 0px' }} variant="h3">
            Confirmación exitosa
          </Typography>
          <Typography variant="body1">
            Hemos verificado tu email. Por favor ingresa la información restante
            para poder ingresar a Pruff.{' '}
          </Typography>
          <div style={{ paddingTop: 20 }}>
            <TextField
              variant="outlined"
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <TextField
              variant="outlined"
              label="Contraseña"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <TextField
              variant="outlined"
              label="Repetir contraseña"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <div style={{ paddingBottom: 20 }}>
              <Typography variant="body1">
                La contraseña debe tener al menos 8 caracteres, una letra
                mayúscula y una minúscula
              </Typography>
            </div>
            <CustomButton
              color="primary"
              onClick={() => handleSubmit()}
              disabled={loading}
              fullWidth
              style={{ marginBottom: 20 }}
            >
              Ingresar
            </CustomButton>
          </div>
        </EnhancedBox>
      </div>
      <div style={{ paddingTop: 40 }}>
        <Copyright />
      </div>
    </div>
  );
};

export default ConfirmUserRegistrationAdmin;
