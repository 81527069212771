import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { uploadFinancialStatement } from '../../../../../../../../../lib/api';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import { handleError } from '../../../../../../../../../lib/functions/errors';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { createStyles, makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Theme } from '@mui/material/styles';
import CustomAlert from '../../../../../../../../../lib/designSystem/Alert';
import { useDispatch } from 'react-redux';
import Uploader from '../../../../../../../../../lib/designSystem/Uploader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      width: '500px',
      height: 260, // or any desired fixed width
    },
    input: {
      display: 'none',
    },
    fileUploadButton: {
      marginTop: '8px',
    },
    centerUpload: {
      textAlign: 'center',
      paddingTop: '20px',
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    uploadText: {
      marginTop: 8,
      padding: '4px 8px',
      backgroundColor: 'green',
      borderRadius: '5px',
      color: theme.palette.primary.contrastText,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

function AddStatement(props: any) {
  const {
    openDialog,
    setOpenDialog,
    financialStatements,
    reloadIndex,
    setOpenAlertSuccess,
    setOpenAlertFailed,
  } = props;
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const classes = useStyles();

  const [year, setYear] = useState('2023'); // Corrected from yaer to year
  const [type, setType] = useState('8 Columnas'); // State for selected company
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [file, setFile] = useState(null);

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];
    setUploadedFileName(uploadedFile.name);
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setFile(uploadedFile);
    } else {
      setFormErrorMessage('Por favor, sube un archivo PDF válido.');
    }
  };

  const validateNames = () => {
    const alreadyExists = financialStatements.find(
      (x: any) => x.year === year && x.fsType === type,
    );
    if (alreadyExists) {
      if (alreadyExists.status === 'CREADO') {
        alert('Este balance ya existe, se reemplazará el existente.');
        return true;
      } else {
        alert(
          'Este balance ya existe y ya fue operado, no puedes reemplazarlo.',
        );
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateNames()) return;
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }

    setLoading(true);
    if (!file) {
      setFormErrorMessage('Por favor, sube un archivo PDF.');
      return;
    }
    const data = new FormData();
    data.append('file', file);
    data.append('year', year);
    data.append('type', type);

    try {
      const response = await uploadFinancialStatement(dispatch, id, { data });
      console.log(response.data);
      setOpenAlertSuccess(true);
      await reloadIndex();
    } catch (error: any) {
      handleError(error);
      setOpenAlertFailed(true);
    }
    setYear('');
    setUploadedFileName(null);

    setOpenDialog(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={() => (!loading ? setOpenDialog(false) : console.log('No'))}
    >
      <DialogTitle>Agregar Balance</DialogTitle>
      <div className={classes.contentContainer}>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Select
              label="Año"
              name="year"
              value={year}
              onChange={(e: any) => setYear(e.target.value)}
              fullWidth
            >
              <MenuItem value={'2024'}>2024</MenuItem>
              <MenuItem value={'2023'}>2023</MenuItem>
              <MenuItem value={'2022'}>2022</MenuItem>
              <MenuItem value={'2021'}>2021</MenuItem>
              <MenuItem value={'2020'}>2020</MenuItem>
              <MenuItem value={'2019'}>2019</MenuItem>
              <MenuItem value={'2018'}>2018</MenuItem>
              <MenuItem value={'2017'}>2017</MenuItem>
              <MenuItem value={'2016'}>2016</MenuItem>
              <MenuItem value={'2015'}>2015</MenuItem>
              <MenuItem value={'2014'}>2014</MenuItem>
              <MenuItem value={'2013'}>2013</MenuItem>

              {/* Agrega más opciones según lo necesites */}
            </Select>
            <Select
              label="Tipo"
              name="type"
              value={type}
              onChange={(e: any) => setType(e.target.value)}
              fullWidth
            >
              <MenuItem value={'8 Columnas'}>8 Columnas</MenuItem>
              {/* Agrega más opciones según lo necesites */}
            </Select>
            <Uploader
              handleFileChange={handleFileChange}
              buttonText="Agregar PDF"
              uploadedFileName={uploadedFileName}
            />
          </DialogContent>
        )}
      </div>
      <DialogActions>
        <CustomButton
          disabled={loading}
          onClick={() => setOpenDialog(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton disabled={loading} onClick={handleSubmit} color="primary">
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddStatement;
