import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../../../lib/designSystem/Button';
import { OpenInNew } from '@mui/icons-material';

interface CobranzaOnlineProps {
  response: any;
  searched: boolean;
}

const CobranzaOnlineCerificateResponse = (props: CobranzaOnlineProps) => {
  const { response, searched } = props;

  if (!response.status)
    return (
      <div style={{ padding: '0px' }}>
        <Typography variant="body1">
          {searched ? 'No se encontraron resultados' : ''}{' '}
        </Typography>
      </div>
    );

  return (
    <div>
      <CustomButton
        variant="contained"
        color="primary"
        onClick={() => {
          window.open(response.data.certificado_url, '_blank');
        }}
        startIcon={<OpenInNew />}
      >
        Descargar certificado
      </CustomButton>
    </div>
  );
};

export default CobranzaOnlineCerificateResponse;
