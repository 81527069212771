import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import { generateBalancePDF } from '../../utils';
import { getBusiness } from '../../../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { getCompanyLogo } from '../../../../../../../lib/api/company';
import { AuthState } from '../../../../../../../services/redux/authSlice';

const ExportFinancialStatementDialog = (props: any) => {
  const { summaryData, open, setOpen } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string; financialStatementId: string }>();
  const [commentary, setCommentary] = useState('');
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const handleDownloadPDF = async () => {
    setLoading(true);
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    const business = await getBusiness(dispatch, id);
    const imageBase64 = await getCompanyLogo(dispatch);

    await generateBalancePDF(
      summaryData,
      business.name,
      business.identifier,
      imageBase64,
      user?.name || '',
      commentary,
    );
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Exportar balance</DialogTitle>
      {loading ? (
        <div style={{ minHeight: 100 }}>
          <CenteredCircularProgress type="dialog" />
        </div>
      ) : (
        <div>
          <DialogContent>
            <Typography>
              ¿Desea agregar un comentario al archivo PDF?
            </Typography>
            <TextField
              fullWidth
              style={{ margin: '10px 0' }}
              label="Comentario"
              value={commentary}
              onChange={(e) => setCommentary(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => setOpen(false)} color="secondary">
              Cancelar
            </CustomButton>
            <CustomButton onClick={() => handleDownloadPDF()} color="primary">
              Exportar
            </CustomButton>
          </DialogActions>{' '}
        </div>
      )}
    </Dialog>
  );
};

export default ExportFinancialStatementDialog;
