import { DialogActions, Paper, Popper, TextField } from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import {
  calculateDays,
  getLiberationDate,
  getRealDaysToCount,
  isDateAWeekDay,
} from '../../../../../../../../../../../lib/functions/dates';
import { formatDate } from '../../../../../../../../../../../lib/functions/utils';

const PopperUniqueDate = (props: any) => {
  const {
    selectedDocuments,
    setSelectedDocuments,
    open,
    setOpen,
    anchorEl,
    placement,
    exchangeDay,
    setTempPaymentDateRows,
    setTempSubDocumentPaymentDateRows,
    canForcePaymentDate,
    minimumDays,
    operationDate,
  } = props;
  const [uniqueDate, setUniqueDate] = React.useState<any>(null);
  const dateValidators = (date: Date) => {
    if (canForcePaymentDate) return true;

    if (!isDateAWeekDay(date)) {
      alert('La fecha de vencimiento debe ser un día hábil');
      return false;
    }
    const minimumDate = new Date(operationDate);
    //
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      alert(
        `La fecha de vencimiento no puede ser menor a ${minimumDays}  días a partir de la fecha actual`,
      );
      return false;
    }
    for (const requestInvoice of selectedDocuments) {
      const days = calculateDays(
        new Date(requestInvoice.issuedDate),
        new Date(date),
      );
      if (days <= 0) {
        alert(
          'La fecha de vencimiento no puede ser menor a la fecha de emisión',
        );
        return false;
      }
    }
    return true;
  };

  const handleChangeDateNormal = () => {
    console.log(uniqueDate);
    const date = new Date(uniqueDate);
    console.log(date?.toUTCString());
    console.log(operationDate);
    const valid = dateValidators(date);
    if (!valid) return;

    const newSelectedDocuments = selectedDocuments.map((doc: any) => {
      const subDocuments = doc.subDocuments?.map((subDoc: any) => {
        return {
          ...subDoc,
          dueDate: date?.toUTCString(),
          paymentDate: date,
          daysToCount: getRealDaysToCount(date, operationDate),
        };
      });
      return {
        ...doc,
        dueDate: date?.toUTCString(),
        paymentDate: date,
        daysToCount: getRealDaysToCount(date, operationDate),
        subDocuments,
      };
    });
    console.log(newSelectedDocuments);

    const dateForTemp = formatDate(date);
    console.log(dateForTemp);
    const newTempPaymentDateRows = selectedDocuments.map(() => {
      return dateForTemp;
    });
    const newTempSubDocumentPaymentDateRows = selectedDocuments.map(
      (doc: any) => {
        if (!doc.subDocuments) return [];
        return doc.subDocuments.map(() => {
          return dateForTemp;
        });
      },
    );
    setTempPaymentDateRows(newTempPaymentDateRows);
    setTempSubDocumentPaymentDateRows(newTempSubDocumentPaymentDateRows);
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeDateExchange = () => {
    const date = new Date(uniqueDate);
    const liberationDate = getLiberationDate(date);
    const daysToCount = getRealDaysToCount(liberationDate);
    const newSelectedDocuments = selectedDocuments.map((doc: any) => {
      const subDocuments = doc.subDocuments?.map((subDoc: any) => {
        return {
          ...subDoc,
          dueDate: date?.toISOString(),
          paymentDate: liberationDate,
          daysToCount,
        };
      });
      return {
        ...doc,
        dueDate: date?.toISOString(),
        paymentDate: liberationDate,
        daysToCount,
        subDocuments,
      };
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleApplyUniqueDate = () => {
    if (exchangeDay) {
      handleChangeDateExchange();
    } else {
      handleChangeDateNormal();
    }
    setOpen(false);
  };

  return (
    <Popper
      sx={{ zIndex: 1300 }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition={false}
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      <Paper style={{ padding: 8 }}>
        <TextField
          value={uniqueDate}
          type="date"
          onChange={(e: any) => setUniqueDate(e.target.value)}
        />
        <DialogActions>
          <CustomButton
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </CustomButton>
          <CustomButton onClick={() => handleApplyUniqueDate()}>
            Aplicar
          </CustomButton>
        </DialogActions>
      </Paper>
    </Popper>
  );
};

export default PopperUniqueDate;
