import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  AlertTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Input,
  Checkbox,
  ListItemText,
  Grid,
  Icon,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { Add } from '@mui/icons-material';
import {
  formatDateBack,
  validaRut,
} from '../../../../../../../../lib/functions/utils';
import axios from 'axios';
import { checkSIIDocumentForUploadingInvoices } from '../../../../../../../../lib/api/sii';

interface User {
  id?: number;
  name: string;
  email?: string;
  permissionPolicy: string;
  password?: string;
  permissionPolicyCode?: number;
}
const AddInvoicesForm = (props: any) => {
  const { handleCloseDialog, open, setOpen, setInvoices, invoices, business } =
    props;
  const dispatch = useDispatch();
  const [failed, setFailed] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [inputType, setInputType] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [newInvoice, setNewInvoice] = useState({
    folio: '',
    businessIdentifier: business ? business.identifier : '',
    stakeholderIdentifier: '',
    stakeholderName: '',
    issuedDate: '',
    amount: '',
  });

  const checkWithSII = async (invoices: any) => {
    try {
      const response = await checkSIIDocumentForUploadingInvoices(dispatch, {
        invoices,
      });
      return response[0];
    } catch (error) {
      console.log(error);
    }
  };

  const existingFolio = (folio: string) => {
    return invoices.some((invoice: any) => invoice.folio === folio);
  };

  const validateRuts = () => {
    if (validaRut(newInvoice.stakeholderIdentifier)) {
      return true;
    }
    return false;
  };

  const checkAllFields = () => {
    if (
      newInvoice.folio === '' ||
      newInvoice.businessIdentifier === '' ||
      newInvoice.stakeholderIdentifier === '' ||
      newInvoice.issuedDate === '' ||
      newInvoice.amount === '' ||
      newInvoice.stakeholderName === ''
    ) {
      alert('Debes llenar todos los campos');
      return false;
    }
    return true;
  };

  const handleChangeDate = (e: any) => {
    const [year, month, day] = e.target.value.split('-');
    setNewInvoice({ ...newInvoice, issuedDate: `${day}${month}${year}` });
  };

  const handleAddInvoice = async () => {
    setLoading(true);
    if (existingFolio(newInvoice.folio)) {
      alert('Ya existe una factura con este folio');
      return;
    }
    if (!checkAllFields()) return;
    if (!validateRuts()) {
      alert('RUT inválido');
      return;
    }
    if (await checkWithSII([newInvoice])) {
      newInvoice.issuedDate = formatDateBack(newInvoice.issuedDate);
      setInvoices((prev: any) => [...prev, newInvoice]);
      setNewInvoice({
        folio: '',
        businessIdentifier: '',
        stakeholderIdentifier: '',
        stakeholderName: '',
        issuedDate: '',
        amount: '',
      });
    } else {
      alert('La factura no existe en el SII');
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <Typography variant="body1">
        A continuación, ingresa los datos de las facturas que quieres ingresar.
      </Typography>
      <div style={{ padding: '20px 0px' }}>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Folio"
              value={newInvoice.folio}
              onChange={(e) =>
                setNewInvoice({ ...newInvoice, folio: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="RUT receptor *"
              value={newInvoice.stakeholderIdentifier}
              onChange={(e) =>
                setNewInvoice({
                  ...newInvoice,
                  stakeholderIdentifier: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Nombre"
              value={newInvoice.stakeholderName}
              onChange={(e) =>
                setNewInvoice({
                  ...newInvoice,
                  stakeholderName: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Fecha de Emisión"
              type="date"
              value={formatDateBack(newInvoice.issuedDate)}
              onChange={(e) => handleChangeDate(e)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Monto"
              value={newInvoice.amount}
              onChange={(e) =>
                setNewInvoice({ ...newInvoice, amount: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={1}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <IconButton
                color="primary"
                onClick={() => {
                  handleAddInvoice();
                }}
              >
                <Add />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Typography variant="caption">* Rut sin puntos y con guión</Typography>
      </div>
    </div>
  );
};

export default AddInvoicesForm;
