import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Divider, Grid, Typography } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../lib/functions/utils';
import EnhacedBox from '../../../../../../../lib/designSystem/BigBox';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { getBusinessFactoringOperativeSummary } from '../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import EnhancedTab from '../../../../../../../lib/designSystem/TabSimulation';
import { ExpandMore } from '../../../../../../Finance/Components/Financing/common/documentsPreview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ActiveDocuments from '../../../../../../Finance/Components/Financing/CreditLine/components/Recieved/components/ShoppingDialog/components/ActiveDocuments';
import Accounts from '../../../../../../Finance/Components/Payments/components/Accounts';
import { Info } from '@mui/icons-material';
import { CustomTooltip } from '../../../../../../../lib/designSystem/TooltipTable';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';

const StakeholderConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre' },
          { key: 'businessIdentifier', label: 'RUT' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'amount', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.stakeholderConcentrationUses
            : []
        }
      />
    </div>
  );
};

const SubProductConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'subProductName', label: 'Subproducto' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'concentrationLimit', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.subProductConcentrationUses
            : []
        }
      />
    </div>
  );
};

const OperativeInfo = (props: any) => {
  const { businessIdentifier } = props;
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = useState(false);
  const [documentsExpanded, setDocumentsExpanded] = useState(false);
  const [accountsExpanded, setAccountsExpanded] = useState(false);
  const [startValue, setStartValue] = useState(0);
  const [creditLineData, setCreditLineData] = useState<any>({});
  const [operativeBusinessDetails, setOperativeBusinessDetails] =
    React.useState<any>({});
  const dispatch = useDispatch();
  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const [businessId, setBusinessId] = useState('');

  const setUp = async () => {
    if (!id && !businessIdentifier) {
      alert('No se ha encontrado el id del cliente');
      return;
    }
    const finalId = businessIdentifier || id;
    setBusinessId(finalId);
    setStartValue(0);
    setLoading(true);
    const operativeBusinessDetailsResponse =
      await getBusinessFactoringOperativeSummary(dispatch, finalId);
    setCreditLineData({
      status: operativeBusinessDetailsResponse.factoringCreditLine.status,
      validUntil:
        operativeBusinessDetailsResponse.factoringCreditLine.validUntil,
      total: operativeBusinessDetailsResponse.factoringCreditLine.amount,
      activeAmount:
        operativeBusinessDetailsResponse.creditLineBalance.activeAmount,
      dueAmount: operativeBusinessDetailsResponse.creditLineBalance.dueAmount,
      amountUsed: operativeBusinessDetailsResponse.creditLineBalance.amountUsed,
      amountArrear:
        operativeBusinessDetailsResponse.creditLineBalance.amountArrear,
      amountTotalArrear:
        operativeBusinessDetailsResponse.creditLineBalance.amountTotalArrear,
      conciliatedByBusiness:
        operativeBusinessDetailsResponse.collections
          .conciliatedByBusinessAmount,
      conciliatedByStakeholderAmount:
        operativeBusinessDetailsResponse.collections
          .conciliatedByStakeholderAmount,
      segmentedActive:
        operativeBusinessDetailsResponse.creditLineBalance.segmentedActive,
      segmentedArrear:
        operativeBusinessDetailsResponse.creditLineBalance.segmentedArrear,
    });
    console.log(operativeBusinessDetailsResponse);
    setOperativeBusinessDetails(operativeBusinessDetailsResponse || {});
    // setOperativeBusinessDetails(operativeBusinessDetailsResponse || {});
    // const businessOperativeDetails = await getBusinessBankruptcyPosts(dispatch, Number(id));
    // console.log(operativeBusinessDetailsResponse);
    setLoading(false);
  };

  const tabs = [
    {
      index: 0,
      label: 'Deudor',
      component: (
        <StakeholderConcentrations
          creditLineAssignation={operativeBusinessDetails.creditLineBalance}
        />
      ),
    },
    {
      index: 0,
      label: 'Sub Producto',
      component: (
        <SubProductConcentrations
          creditLineAssignation={operativeBusinessDetails.creditLineBalance}
        />
      ),
    },
  ];

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h6">Información línea de crédito</Typography>
      <EnhacedBox>
        <Typography variant="body1" fontWeight={600}>
          Resumen
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}></div>
        <div style={{ padding: '20px 0px' }}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Total Línea de crédito
              </Typography>
              {formatMoney(creditLineData.total)}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto disponible
              </Typography>
              {formatMoney(
                creditLineData.total -
                  creditLineData.activeAmount -
                  creditLineData.dueAmount,
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto Vigente
                {creditLineData.segmentedActive && (
                  <LightTooltip
                    title={CustomTooltip(creditLineData.segmentedActive)}
                  >
                    <Info
                      style={{
                        marginLeft: 6,
                        fontSize: 16,
                        cursor: 'pointer',
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.activeAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Monto Vencido
                {creditLineData.segmentedArrear && (
                  <LightTooltip
                    title={CustomTooltip(creditLineData.segmentedArrear)}
                  >
                    <Info
                      style={{
                        marginLeft: 6,
                        fontSize: 16,
                        cursor: 'pointer',
                      }}
                    />
                  </LightTooltip>
                )}
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.dueAmount)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Estado Cliente
              </Typography>
              <Typography variant="body1">{creditLineData.status}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Vencimiento
              </Typography>
              <Typography variant="body1">
                {formatDate(creditLineData.validUntil)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Recaudado por cliente
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.conciliatedByBusiness)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight={600}>
                Recaudado por deudor
              </Typography>
              <Typography variant="body1">
                {formatMoney(creditLineData.conciliatedByStakeholderAmount)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={600}>
              Concentraciones
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <EnhancedTab startValue={startValue} tabs={tabs} />
          </Collapse>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={600}>
              Documentos en cartera
            </Typography>
            <ExpandMore
              expand={documentsExpanded}
              onClick={() => setDocumentsExpanded(!documentsExpanded)}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={documentsExpanded} timeout="auto" unmountOnExit>
            <ActiveDocuments businessIdentifier={businessId} />
          </Collapse>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={600}>
              Cuentas
            </Typography>
            <ExpandMore
              expand={accountsExpanded}
              onClick={() => setAccountsExpanded(!accountsExpanded)}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={accountsExpanded} timeout="auto" unmountOnExit>
            <Accounts businessIdentifier={businessId} />
          </Collapse>
        </div>
      </EnhacedBox>
    </div>
  );
};

export default OperativeInfo;
