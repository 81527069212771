import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  createContact,
  getBusinessContactsByIdentifier,
} from '../../../../../../../lib/api';
import { submitVerification } from '../../../../../../../lib/api/documentConfirmation';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import ConfirmInvoiceContactForm from '../ConfirmContactForm';

const availableTypes = [
  { id: 'mail', name: 'Correo' },
  { id: 'phone', name: 'Teléfono' },
  { id: 'whatsapp', name: 'Whatsapp' },
  { id: 'postVerified', name: 'Post verificado' },
];

const ConfirmationForm = (props: any) => {
  const { type, setType, message, setMessage, selectedDocuments } = props;

  const confirmTypesInDocuments = selectedDocuments.map(
    (document: any) => document.confirmType,
  );
  const availableTypeForThisDocuments = availableTypes.filter(
    (type: any) => !confirmTypesInDocuments.includes(type.name),
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="filter-label">{'Tipo'}</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={type}
              onChange={(e) => setType(e.target.value)}
              label={'Tipo'}
            >
              {availableTypeForThisDocuments.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            label="Comentario"
            value={message}
            fullWidth
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmationModule = (props: any) => {
  const {
    documents,
    stakeholderName,
    stakeholderIdentifier,
    setSuccessOpen,
    setFailureOpen,
    setUp,
    open,
    setOpen,
    pagination,
  } = props;

  const [contact, setContact] = useState<any>({
    name: '',
    email: '',
    role: '',
    phone: '',
  });
  const [newContact, setNewContact] = useState<any>(false);
  const [contacts, setContacts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  // const [stakeholderIdentifier, setStakeholderIdentifier] = useState<string>('');

  const dispatch = useDispatch();

  const setUpContacts = async () => {
    setLoading(true);
    const contactsResponse = await getBusinessContactsByIdentifier(dispatch, {
      identifier: stakeholderIdentifier,
    });
    setContacts(contactsResponse || []);
    if (!contactsResponse.length) {
      setNewContact(true);
    }
    console.log(loading);
    setLoading(false);
  };

  const validateAttributes = () => {
    if (type === 'postVerified') return true;
    if (
      contact.name === '' ||
      contact.email === '' ||
      contact.role === '' ||
      contact.phone === ''
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    return true;
  };

  const validateConfirmation = () => {
    if (type === '') {
      alert('Debes seleccionar un tipo de confirmación');
      return false;
    }
    if (message === '') {
      alert('Debes agregar un comentario');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (stakeholderIdentifier) {
      setUpContacts();
    }
  }, [stakeholderIdentifier]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!validateConfirmation()) {
      setLoading(false);
      return;
    }
    try {
      const contactWithId = contact;
      if (newContact && type !== 'postVerified') {
        console.log(contact);
        if (!validateAttributes()) {
          setLoading(false);
          return;
        }
        const response = await createContact(dispatch, {
          businessIdentifier: stakeholderIdentifier,
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          role: contact.role,
        });
        contactWithId.id = response.id;
        setNewContact(false);
      }
      await submitVerification(dispatch, {
        type,
        message,
        contactId: contactWithId.id,
        documents,
      });
      setLoading(false);
      await setUp({ page: 0, pageSize: pagination.pageSize });
      setSuccessOpen('Facturas confirmadas correctamente');
      setOpen(false);
    } catch (error) {
      setFailureOpen('Error al confirmar facturas');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <div>
          <DialogContent>
            <Typography variant="body1" padding="16px 0px" fontWeight={600}>
              Verificar documentos del deudor {stakeholderName} (
              {stakeholderIdentifier})
            </Typography>
            <div style={{ padding: '10px 0px' }}>
              <EnhancedTable
                data={documents}
                dataHeaders={[
                  { key: 'folio', label: 'Folio' },
                  {
                    key: 'businessName',
                    label: 'Cliente',
                  },
                  {
                    key: 'amount',
                    label: 'Monto',
                    type: 'money',
                  },
                  {
                    key: 'confirmDate',
                    label: 'Confirmada',
                    type: 'confirm-lights',
                    canNull: true,
                  },
                ]}
                rowsPerPageDefault={5}
                exportToFile="xlsx"
              />
            </div>
            <ConfirmationForm
              type={type}
              setType={setType}
              meesage={message}
              setMessage={setMessage}
              selectedDocuments={documents}
            />
            {type !== 'postVerified' && (
              <div>
                <div style={{ padding: '10px 0px' }}>
                  <Divider />
                </div>
                <ConfirmInvoiceContactForm
                  newContact={newContact}
                  setNewContact={setNewContact}
                  contacts={contacts}
                  setContact={setContact}
                  contact={contact}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => setOpen(false)}>
              Salir
            </CustomButton>
            <CustomButton color="primary" onClick={() => handleSubmit()}>
              Confirmar
            </CustomButton>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default ConfirmationModule;
