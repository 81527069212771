import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  getAllNotRecognizedPayments,
  getNotRecognizedPayment,
} from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NotRecognizedPaymentDetails from './components/NotRecognizedPaymentDetails';
import { Autocomplete, TextField } from '@mui/material';
import { validatePermissions } from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'id', label: 'Folio' },
  { key: 'stakeholderName', label: 'Origen' },
  { key: 'createdAt', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'status', label: 'Estado' },
];

const FNC: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [notRecognizedPayments, setNotRecognizedPayments] = useState<any[]>([]);
  const [filteredNotRecognizedPayments, setFilteredNotRecognizedPayments] =
    useState<any[]>([]);
  const [notRecognizedPayment, setNotRecognizedPayment] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [failure, setFailure] = useState('');
  const [value, setValue] = useState(null);

  const fetchFNC = async () => {
    setLoading(true);
    try {
      const response = await getAllNotRecognizedPayments(dispatch);
      setNotRecognizedPayments(response);
      setFilteredNotRecognizedPayments(response);
    } catch (error) {
      alert('Error al cargar los FNCS');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFNC();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    if (!newValue) {
      return setFilteredNotRecognizedPayments(notRecognizedPayments);
    }
    const filtered = notRecognizedPayments.filter(
      (x: any) => x.businessIdentifier === newValue.businessIdentifier,
    );
    setFilteredNotRecognizedPayments(filtered);
  };

  const uniqueAccountForFilter = () => {
    const uniqueAccounts = notRecognizedPayments.reduce(
      (acc: any, account: any) => {
        if (
          !acc.find(
            (x: any) => x.businessIdentifier === account.businessIdentifier,
          )
        ) {
          acc.push(account);
        }
        return acc;
      },
      [],
    );
    return uniqueAccounts;
  };

  const autoCompleteFilter = (
    <Autocomplete
      options={uniqueAccountForFilter()}
      value={value}
      getOptionLabel={(option: any) =>
        `${option.businessIdentifier} - ${option.businessName}`
      }
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  const handleDetails = async (notRecognizedPaymentId: any) => {
    setLoading(true);
    try {
      const response = await getNotRecognizedPayment(
        dispatch,
        notRecognizedPaymentId,
      );
      setNotRecognizedPayment(response);
      setOpen(true);
    } catch (error) {
      console.log(error);
      alert('Error al cargar los FNCs');
    }
    setLoading(false);
  };

  const canShowFNCAssign = () => {
    if (validatePermissions(user, 'financing_fnc_assign')) return true;

    return false;
  };

  const actions: any = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
      canShow: canShowFNCAssign,
    },
    // {action: handleDelete, actionLabel: 'Borrar', canShow: canShowDeleteFS},
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        // buttons={buttons}
        variant="body1"
        title="Acá podrás ver los los pagos no reconocidos generados."
        item={autoCompleteFilter}
        // title="Esto son todos las cuentas pendientes que tienes con tus clientes"
      />
      <EnhancedTable
        dataHeaders={headers}
        data={filteredNotRecognizedPayments}
        rowsPerPageDefault={50}
        actions={actions}
        exportToFile="xlsx"
      />
      {open && (
        <NotRecognizedPaymentDetails
          open={open}
          setOpen={setOpen}
          notRecognizedPayment={notRecognizedPayment}
          setNotRecognizedPayment={setNotRecognizedPayment}
          setUp={fetchFNC}
          setSuccessOpen={setSuccess}
          setFailureOpen={setFailure}
        />
      )}
      <CustomAlert
        open={success.length}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        text={success}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure.length}
        title="Error"
        onClose={() => {
          setFailure('');
        }}
        setOpen={setFailure}
        text={failure}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default FNC;
