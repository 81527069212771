import React, { useState, useEffect } from 'react';
import DataTable from './components/table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../lib/designSystem/HeaderWithActions';
import {
  assignFinancialStatement,
  getFinancialNodesForAssignation,
  getFinancialTrees,
  reportFinancialStamentError,
} from '../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { useParams } from 'react-router-dom';
import { handleError } from '../../lib/functions/errors';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../lib/designSystem/Button';
import CustomAlert from '../../lib/designSystem/Alert';
import { validatePermissions } from '../../lib/functions/utils';
import { AuthState } from '../../services/redux/authSlice';

const NEGATIVE_MAPPER: any = {
  Pérdida: true,
  Perdida: true,
  Pérdidas: true,
  Perdidas: true,
  PERDIDA: true,
  PERDIDAS: true,
};

const FinancialStatementAssignation = (props: any) => {
  const { id, financialStatementId, mode } = useParams<{
    id: string;
    financialStatementId: string;
    mode: string;
  }>();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [nodesForAssignation, setNodesForAssignation] = useState<any>([]);
  const [treeData, setTreeData] = useState<any>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<
    Record<
      string,
      {
        treeType?: string;
        financialStatementsTreeNodesId?: string;
        count: boolean;
        negative: boolean;
      }
    >
  >({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [accountName, setAccountName] = useState('');
  const [accountValue, setAccounValue] = useState<number | null>(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openNodeDialog, setOpenNodeDialog] = useState(false);
  const [editingNodeId, setEditingNodeId] = useState<number | null>(null);
  const [openAnalysis, setOpenAnalysis] = useState<any>({});
  const [openSuccessText, setOpenSuccessText] = useState('');

  const handleOpenSuccess = (text: string) => {
    setOpenSuccessText(text);
    setOpenSuccess(true);
  };

  const mapEditing = (actualState: any, tree: any) => {
    const treeMap: any = {};
    for (const treeType of tree) {
      for (const node of treeType.financialStatementsTreeNodes) {
        treeMap[node.id] = treeType.id;
      }
    }
    for (const node of actualState) {
      node.treeType = treeMap[node.financialStatementsTreeNodesId];
      node.count = node.financialStatementsTreeNodesId ? true : false;
    }
    return actualState;
  };

  const addNodeAssignation = () => {
    const newNodesForAssignation: any = [...nodesForAssignation];
    newNodesForAssignation.push({
      initialSubject: accountName,
      value: accountValue,
      count: true,
      negative: false,
    });
    setNodesForAssignation(newNodesForAssignation);
    setOpenSuccess(true);
    setOpenSuccessText(
      'La nueva cuenta ha sido asignada al final del balance. No te olvides de guardar!',
    );
    setOpenNodeDialog(false);
  };

  const editNodeAssignation = () => {
    if (editingNodeId === null) return;

    const newNodesForAssignation: any = [...nodesForAssignation];
    newNodesForAssignation[editingNodeId].initialSubject = accountName;
    newNodesForAssignation[editingNodeId].value = accountValue;
    setNodesForAssignation(newNodesForAssignation);
    setOpenSuccess(true);
    setOpenSuccessText('La cuenta ha sido editada');
    setOpenNodeDialog(false);
  };

  const mapSuggestions = (actualState: any, financialTreesResponse: any) => {
    const FS_MAPPER: any = financialTreesResponse.reduce((acc: any, x: any) => {
      acc[x.name] = x.id;
      return acc;
    }, {});
    console.log(FS_MAPPER);
    for (const item of actualState) {
      if (
        item.financialStatementsTreeNodesId === null ||
        item.financialStatementsTreeNodesId === undefined
      ) {
        const valueBasedOnType = item.initialType
          ? FS_MAPPER[item.initialType] || FS_MAPPER['Estado de resultados']
          : null;
        const negative = NEGATIVE_MAPPER[item.initialType] || false;
        item.treeType = valueBasedOnType;
        item.negative = negative;
      }
    }
    return actualState;
  };

  const fetchSetupData = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const financialStatementsResponse = await getFinancialNodesForAssignation(
        dispatch,
        id,
        { financialStatementId },
      );
      setOpenAnalysis(financialStatementsResponse);
      console.log('Array de nodos', financialStatementsResponse);
      const financialTreesResponse = await getFinancialTrees(dispatch);
      console.log('financialTreesResponse', financialTreesResponse);

      let newList;
      if (financialStatementsResponse.status === 'ASIGNADO') {
        newList = mapEditing(
          financialStatementsResponse.financialStatementNodeAssignations,
          financialTreesResponse,
        );
      } else {
        newList =
          financialStatementsResponse.financialStatementNodeAssignations.map(
            (node: any) => {
              return {
                ...node,
                count: true,
                negative: false,
              };
            },
          );
      }
      newList = mapSuggestions(newList, financialTreesResponse);
      setTreeData(financialTreesResponse);
      setNodesForAssignation(newList);
    } catch (error) {
      alert('Ha ocurrido un error');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  // const validateCompleteAsignation = () => {
  //   for (const row of Object.keys(selectedAttributes)) {
  //     if (selectedAttributes[row].count){
  //       if (!selectedAttributes[row].financialStatementsTreeNodesId) return false
  //     }
  //   }
  //   return true
  // }

  const handleReportError = async () => {
    try {
      setLoading(true);
      await reportFinancialStamentError(dispatch, { id: financialStatementId });
      handleOpenSuccess('Se ha reportado el error con éxito!');
    } catch (error) {}
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    // if (!validateCompleteAsignation()) {
    //   setLoading(false)
    //   return alert('No está completa la asignación')
    // }
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }

    console.log(nodesForAssignation);
    try {
      await assignFinancialStatement(dispatch, id, {
        financialStatementId: openAnalysis.id,
        assignations: nodesForAssignation,
      });
      setOpenAnalysis(0);
      await fetchSetupData();
      handleOpenSuccess('Se ha realizado tu asignación con éxito!');
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const countAll = () => {
    const newNodesForAssignation: any = [...nodesForAssignation];
    for (const node of newNodesForAssignation) {
      node.count = true;
    }
    setNodesForAssignation(newNodesForAssignation);
  };

  const handleCreateAccount = () => {
    setEditingNodeId(null);
    setAccountName('');
    setAccounValue(null);
    setOpenNodeDialog(true);
  };

  const handleEditAccount = (index: number) => {
    setAccountName(nodesForAssignation[index].initialSubject);
    setAccounValue(nodesForAssignation[index].value);
    setEditingNodeId(index);
    setOpenNodeDialog(true);
  };

  const handleBack = () => {
    // if (validatePermissions(user, 'financial_states_create_assignment')) {
    //   return window.location.href = `financialStatements/pending` }
    window.location.href = `/companies/${id}/financial/assginations`;
  };
  const buttons: CustomButtonProps[] = [
    {
      action: handleBack,
      name: 'Atrás',
      color: 'primary',
    },
  ];

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        buttons={[
          {
            action: () =>
              (window.location.href = `/financialStatements/pending`),
            name: 'Volver',
            color: 'secondary',
          },
        ]}
        title={`Balance - Asignar `}
      />
      <div style={{ padding: '10px 0px 30px' }}>
        <Typography variant="body1">
          <span style={{ fontWeight: 'bold' }}>Empresa:</span>{' '}
          {openAnalysis?.business?.name}
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: 'bold' }}>Año:</span> {openAnalysis.year}
        </Typography>
        {openAnalysis.uploader && (
          <Typography variant="body1">
            <span style={{ fontWeight: 'bold' }}>Subido por: </span>{' '}
            {openAnalysis?.uploader.name}
          </Typography>
        )}
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => handleCreateAccount()}
              style={{ marginBottom: 10 }}
            >
              Agregar cuenta
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => countAll()}
              style={{ marginBottom: 10, marginLeft: 10 }}
            >
              Contabilizar todo
            </CustomButton>
          </div>
          <div style={{ display: 'flex' }}>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              style={{ marginBottom: 10 }}
            >
              Guardar
            </CustomButton>
            <CustomButton
              variant="contained"
              color="error"
              onClick={() => handleReportError()}
              style={{ marginBottom: 10, marginLeft: 10 }}
            >
              Reportar error
            </CustomButton>
          </div>
        </div>
        <Typography variant="body1">
          Si no guardas, todos tus avances se perderán.
        </Typography>
      </div>
      {!openNodeDialog && (
        <DataTable
          treeData={treeData}
          nodesForAssignation={nodesForAssignation}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          setNodesForAssignation={setNodesForAssignation}
          handleEditAccount={handleEditAccount}
        />
      )}
      <Dialog
        open={openNodeDialog}
        onClose={() => setOpenNodeDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Agregar cuenta</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre"
            variant="outlined"
            margin="dense"
            fullWidth
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
          <TextField
            label="Valor"
            variant="outlined"
            margin="dense"
            fullWidth
            type="number"
            value={accountValue}
            onChange={(e) => setAccounValue(Number(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={() => setOpenNodeDialog(false)}
          >
            Cancelar
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() =>
              editingNodeId !== null
                ? editNodeAssignation()
                : addNodeAssignation()
            }
            disabled={!accountName || !accountValue}
          >
            {editingNodeId !== null ? 'Editar' : 'Agregar'}
          </CustomButton>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text={openSuccessText}
        handleClose={() => setOpenSuccess(false)}
      />
    </div>
  );
};

export default FinancialStatementAssignation;
