import React from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const IVAFolderLoader = (props: any) => {
  const { loading } = props;
  if (loading) {
    return (
      <Dialog open={loading}>
        <DialogTitle>Cargando Carpeta tributaria</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
            <Typography>
              Estamos cargando la carpeta tributaria, por favor espere...
            </Typography>
          </div>
          <div style={{ padding: '20px 0px' }}>
            <Typography>Tenga en consideración lo siguiente:</Typography>
            <Typography>
              - Este proceso puede tardar varios segundos.
            </Typography>
            <Typography>- Esta es una versión BETA</Typography>
            <Typography>
              - Si en un mes NO se especifica que no hubo declaración, hubo un
              problema con el SII. Vuelva a intentarlo.
            </Typography>
            <Typography>
              - Su cliente no verá ningún cambio en su SII. Ni tampoco recibirá
              correos.
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default IVAFolderLoader;
