import React from 'react';

interface BoxProps {
  children?: React.ReactNode;
  centerText?: boolean;
  height?: string;
  margin?: string;
}

const boxStyle = {
  border: '1px solid #e0e0e0',
  margin: '0px',
  borderRadius: '10px',
  backgroundColor: '#ffffff',
  padding: '16px', // I added padding for visual clarity. You can adjust or remove this as needed.
};

const EnhacedBox: React.FC<BoxProps> = ({
  children,
  centerText,
  height,
  margin,
}) => {
  return (
    <div
      style={{
        ...boxStyle,
        textAlign: centerText ? 'center' : 'left',
        height: height ? height : 'auto',
        margin: margin ? margin : '0px',
      }}
    >
      {children}
    </div>
  );
};

export default EnhacedBox;
