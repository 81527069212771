import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../lib/designSystem/Table';
import {
  getAllCompanyContacts,
  getCompanyNetwork,
  getInvoicesForCollection,
} from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../lib/designSystem/Alert';
import NewContact from './components/NewBusiness';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import NetworkBusinessDetails from './components/Details';
import { Autocomplete, TextField } from '@mui/material';

const headers = [
  { key: 'name', label: 'Nombre contacto' },
  { key: 'identifier', label: 'Rut' },
  { key: 'source', label: 'Fuente' },
];

const emptyBusiness = {
  name: '',
  identifier: '',
};

const NetworkBusinesses: React.FC = () => {
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [managements, setManagements] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [contact, setContact] = useState(emptyBusiness);
  const [businesses, setBusinesses] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [business, setBusiness] = useState<any>(null);
  const [filteredBusinesses, setFilteredBusinesses] = useState<any[]>([]);

  const setSuccessOpen = (message: string) => {
    setSuccessMessage(message);
    setOpenSuccess(true);
  };

  const setFailureOpen = (message: string) => {
    setFailureMessage(message);
    setOpenFailure(true);
  };

  const handleCreateNewBusiness = async () => {
    setContact(emptyBusiness);
    setOpenModal(true);
  };

  // const handleEdit = (id: number) => {
  //   const contact = businesses.find((x: any) => x.id === id);
  //   if (!contact) return alert('Ha ocurrido un error');
  //   setContact(contact);
  //   setOpenModal(true);
  // };

  // const actions = [
  //   {
  //     action: handleEdit,
  //     actionLabel: 'Editar',
  //   },
  // ];

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nueva empresa',
      action: () => handleCreateNewBusiness(),
      color: 'primary',
    },
  ];

  const setUp = async () => {
    try {
      const businessesResponse = await getCompanyNetwork(dispatch);
      setBusinesses(businessesResponse.allBusinesses);
      setFilteredBusinesses(businessesResponse.allBusinesses);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleDetails = (id: number) => {
    console.log('id', id);
    setBusiness(businesses.find((x: any) => x.id === id));
    setOpenModal(true);
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Ver',
    },
  ];

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredBusinesses(businesses);
    console.log(newValue);
    const filtered = businesses.filter((x: any) => x.id === newValue.id);
    setFilteredBusinesses(filtered);
  };

  const autoCompleteFilter = (
    <Autocomplete
      options={businesses}
      getOptionLabel={(option: any) => `${option.name} - ${option.identifier}`}
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        type="subHeader"
        title="Esta es toda tu red de empresas."
        variant="body1"
        buttons={[]}
        item={autoCompleteFilter}
      />

      <EnhancedTable
        dataHeaders={headers}
        data={filteredBusinesses}
        showActions
        rowsPerPageDefault={50}
        actions={actions}
      />
      {openModal && (
        <NetworkBusinessDetails
          open={openModal}
          setOpen={setOpenModal}
          business={business}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        text={failureMessage}
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default NetworkBusinesses;
