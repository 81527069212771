import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Chip,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import { start } from 'repl';
import { Cancel, Edit, Save } from '@mui/icons-material';
import {
  formatDate,
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../../../lib/functions/utils';
import { getDocumentWithExtensionData } from '../../../../../../../../../../lib/api/extension/factoring';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import { createDocumentDiscount } from '../../../../../../../../../../lib/api/documentDiscount';
import { fetchSuggestedCompanyConcilations } from '../../../../../../../../../../lib/api/conciliations';

const DocuemntFormParameters = (props: any) => {
  const {
    document,
    discount,
    setDiscount,
    setCanModifyParams,
    canModifiyParams,
    edit,
  } = props;

  return (
    <div style={{ margin: '8px 0px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '16px 0px 0px',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Datos del descuento
        </Typography>
        {discount.status === 'En revisión' ? (
          <div>
            <IconButton
              onClick={() => setCanModifyParams(!canModifiyParams)}
              style={{ marginLeft: 'auto' }}
            >
              {canModifiyParams ? <Cancel /> : <Edit />}
            </IconButton>
          </div>
        ) : null}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
            Parámetros
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Valor descuento"
                value={formatMoney(discount.amount)}
                disabled={!canModifiyParams}
                onChange={(e) =>
                  setDiscount({
                    ...discount,
                    amount: formatMoneyBack(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ borderLeft: '0.5px solid #000', paddingLeft: 16 }}>
            <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
              Resumen
            </Typography>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Saldo pendiente
              </Typography>
              <Typography variant="body2">
                {formatMoney(document.documentRemainingAmount)}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Descuento
              </Typography>
              <Typography variant="body2">
                {formatMoney(discount.amount)}
              </Typography>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
                Total remanente
              </Typography>
              <Typography variant="body2">
                {formatMoney(
                  document.documentRemainingAmount - discount.amount,
                )}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <Typography variant="body1" fontWeight="bold" margin={'8px 0px'}>
          Observaciones
        </Typography>
        <TextField
          fullWidth
          disabled={!canModifiyParams}
          multiline
          rows={4}
          value={discount.observation}
          onChange={(e) =>
            setDiscount({ ...discount, observation: e.target.value })
          }
        />
      </div>
    </div>
  );
};

const DocuemntPreviewData = (props: any) => {
  const { document, setSearched, edit } = props;
  return (
    <div style={{ margin: '0px 0px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold" margin={'8px 0px 0px'}>
          Datos del documento
        </Typography>
        {!edit && (
          <Typography
            variant="caption"
            fontWeight="bold"
            padding={'8px 8px'}
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => setSearched(false)}
          >
            Cambiar
          </Typography>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Folio</Typography>
          <Typography variant="body2">{document.folio}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Liberación Original</Typography>
          <Typography variant="body2">
            {formatDate(document.startingPaymentDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Liberación actual</Typography>
          <Typography variant="body2">
            {formatDate(document.paymentDate)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Monto documento</Typography>
          <Typography variant="body2">
            {formatMoney(document.amount)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Abonos</Typography>
          <Typography variant="body2">
            {formatMoney(document.amountConciliated)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2">Saldo deuda</Typography>
          <Typography variant="body2">
            {' '}
            {formatMoney(document.documentRemainingAmount)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const DiscountMain = (props: any) => {
  const {
    discount,
    setDiscount,
    open,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    edit,
  } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [document, setDocument] = useState<any>([]);
  const [searched, setSearched] = useState(false);
  const [canModifyParams, setCanModifyParams] = useState(!edit);

  const validParams = () => {
    return true;
  };

  const handleChangeDocument = (event: any, newValue: any) => {
    setDocument(newValue);
    setDiscount({ ...discount, documentId: newValue.id });
  };

  const handleSearchData = async () => {
    setLoading(true);
    try {
      const response = await getDocumentWithExtensionData(
        dispatch,
        document.id,
      );
      setDocument(response);
      setDiscount({
        ...discount,
        dueDate: formatDate(response.paymentDate),
      });
      setSearched(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      console.log(discount);
      const response = await createDocumentDiscount(dispatch, discount);
      setOpen(false);
      setSuccess('Descuento creado correctamente');
      await setUp();
    } catch (error) {
      console.log(error);
      setFailure('Error al crear el descuento');
    }
    setLoading(false);
  };

  const setUpSuggestions = async () => {
    setLoading(true);
    try {
      const response = await fetchSuggestedCompanyConcilations(dispatch);
      console.log(response);
      setSuggestions(response.documentsProcessed);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpDialog = async () => {
    if (edit) {
    } else {
      await setUpSuggestions();
    }
  };

  useEffect(() => {
    setUpDialog();
  }, []);

  return (
    <div>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <div>
          {!searched && !edit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Autocomplete
                style={{ margin: '8px 0px' }}
                options={suggestions}
                getOptionLabel={(option: any) =>
                  `${option.folio} | ${option.businessName} | ${option.stakeholderName}`
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Busca por folio, cliente o deudor"
                  />
                )}
                onChange={handleChangeDocument}
                fullWidth
              />
              <div style={{ padding: '0px 16px' }}>
                <CustomButton
                  onClick={() => handleSearchData()}
                  color="primary"
                >
                  Seleccionar
                </CustomButton>
              </div>
            </div>
          )}
          {searched && (
            <div>
              <DocuemntPreviewData
                document={document}
                setSearched={setSearched}
                edit={edit}
              />
              <DocuemntFormParameters
                document={document}
                discount={discount}
                setDiscount={setDiscount}
                canModifiyParams={canModifyParams}
                setCanModifyParams={setCanModifyParams}
                edit={edit}
              />
            </div>
          )}
        </div>
      )}
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
        {!edit || discount.status === 'En revisión' ? (
          <CustomButton
            onClick={() => handleSubmit()}
            color="primary"
            disabled={!canModifyParams}
          >
            Guardar
          </CustomButton>
        ) : null}
      </DialogActions>
    </div>
  );
};

export default DiscountMain;
