import {
  CardActions,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  createTemporaryView,
  getAllFactorings,
  getAllSiiViews,
  getTemporaryConnectionsUserProviderBusiness,
  gettUserExecutives,
} from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { renderSelectedNames } from '../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../lib/designSystem/Button';

const AddRelation = (props: any) => {
  const {
    open,
    setOpen,
    setSuccess,
    activateFailed,
    activateSuccess,
    business,
    setUp,
  } = props;
  const [views, setViews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [executives, setExecutives] = useState([]);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<any>({
    visibilityDuration: null,
    visibilityAvailable: [],
    recipientUserId: null,
    validUntil: null,
  });

  const handleSubmitRequest = async () => {
    setLoading(true);
    try {
      const payload = {
        businessId: business.id,
        ...formData,
      };
      console.log(payload);
      await createTemporaryView(dispatch, payload);
      activateSuccess(`Se ha compartido la vista.`);
    } catch (error) {
      console.log(error);
      activateFailed(`No se pudo compartir la vista.`);
    }
    setLoading(false);
    await setUp();
    setOpen(false);
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    // Check if the target has the 'options' property, which is true for multiple selects
    const isMultiple = e.target.options;

    console.log(e.target.name, value);
    if (isMultiple) {
      // If it's a multiple select, create an array of the selected values
      const selectedValues = Array.from(e.target.options)
        .filter((option: any) => option.selected)
        .map((option: any) => option.value);
      setFormData({
        ...formData,
        [e.target.name]: selectedValues,
      });
    } else {
      // If it's a single select, set the value as it is
      setFormData({
        ...formData,
        [e.target.name]: value,
      });
    }
  };

  useEffect(() => {
    const setUp = async () => {
      setLoading(true);
      const executives = await gettUserExecutives(dispatch);
      console.log(executives);
      const connections = await getTemporaryConnectionsUserProviderBusiness(
        dispatch,
        business.identifier,
      );
      console.log(connections);

      const connectionExecutivesIdMap = connections.relations.map(
        (x: any) => x.recipientId,
      );
      const executivesFilttered = executives.filter(
        (x: any) => !connectionExecutivesIdMap.includes(x.executiveId),
      );
      setExecutives(executivesFilttered || []);
      const viewsResults = await getAllSiiViews(dispatch);
      setViews(viewsResults || []);
      setLoading(false);
    };
    setUp();
  }, []);

  const validateFactoringsConfig = () => {
    if (
      !formData.recipientUserId ||
      !formData.visibilityAvailable.length ||
      !formData.visibilityDuration
    )
      return true;
    return false;
  };

  const handleDateChange = (id: string, newValue: any) => {
    setFormData({
      ...formData,
      [id]: newValue,
    });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen()}>
      <DialogTitle>
        <span style={{ fontWeight: 'bold' }}>Compartir a nuevo ejecutivo</span>{' '}
        ({business.businessName})
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <div style={{ padding: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Ejecutivos disponibles
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.factorings}
                  label="Clientes disponibles"
                  onChange={(e) => handleChange(e)}
                  name="recipientUserId"
                >
                  {executives.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <ListItemText primary={`${x.name} (${x.email})`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Seleccione las pestañas a mostrar
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.visibilityAvailable}
                  multiple
                  label="Seleccione las pestañas a mostrar"
                  onChange={(e) => handleChange(e)}
                  name="visibilityAvailable"
                  renderValue={(selected) =>
                    renderSelectedNames(selected, views)
                  }
                >
                  {views.map((x: any) => (
                    <MenuItem key={x.id} value={x.id}>
                      <Checkbox
                        checked={
                          formData.visibilityAvailable.indexOf(x.id) > -1
                        }
                      />
                      <ListItemText primary={x.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: 10 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Duración visibilidad
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.visibilityDuration}
                  label="Clientes disponibles"
                  onChange={(e) => handleChange(e)}
                  name="visibilityDuration"
                >
                  <MenuItem value="always_visible">Sin límite</MenuItem>
                  <MenuItem value="temporary_visible">Fecha</MenuItem>
                </Select>
              </FormControl>
            </div>
            {formData.visibilityDuration === 'temporary_visible' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ padding: 10, display: 'flex' }}>
                  <DatePicker
                    value={formData.validUntil}
                    onChange={(newValue: any) =>
                      handleDateChange('validUntil', newValue)
                    }
                  />
                </div>
              </LocalizationProvider>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setOpen(false)} color="secondary">
          Salir
        </CustomButton>
        <CustomButton
          color="primary"
          disabled={validateFactoringsConfig()}
          onClick={() => handleSubmitRequest()}
        >
          Agregar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddRelation;
