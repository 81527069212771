import { Alert, Grid, Snackbar } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Invoice from './components/Invoices';
import Debt from './components/InterestContainer';
import HeaderWithActions from '../../../lib/designSystem/HeaderWithActions';
import { getInvoicesByEncriptionBusinessIdentifier } from '../../../lib/api';
import { useParams } from 'react-router-dom';
import NotifyPayment from './components/NotifyPayment';
import WireInformation from './components/WireInformation';
import Interested from './components/InterestContainer';

const styles = createStyles({
  layoutWrapper: {
    display: 'flex',
    height: '100vh', // Covers the entire viewport height
  },
  content: {
    width: '100%',
    flexGrow: 1, // Allows it to grow and consume available space
    display: 'flex', // Added to facilitate flex-based layout
    flexDirection: 'column', // Stack children vertically
  },
  layout: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '20px',
    whiteSpace: 'nowrap',
    backgroundColor: '#F8FAFC',
    boxSizing: 'border-box',
    height: '100vh',
  },
  side: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '20px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
  },
  loading: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});

interface LayoutProps extends WithStyles<typeof styles> {}

const PaymentLink: React.FC<LayoutProps> = ({ classes }) => {
  const { encryptedCompanyId, encryptedBusinessIdentifier } = useParams<{
    encryptedCompanyId: string;
    encryptedBusinessIdentifier: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<any>({});
  const [company, setCompany] = useState<any>({});
  const [invoices, setInvoices] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [amount, setAmount] = useState(0);
  const [invoiceId, setInvoiceId] = useState<any>({});

  const getInvoices = async () => {
    if (
      encryptedBusinessIdentifier !== undefined &&
      encryptedCompanyId !== undefined
    ) {
      const result = await getInvoicesByEncriptionBusinessIdentifier(
        encryptedCompanyId,
        encryptedBusinessIdentifier,
      );
      setBusiness(result.business);
      setCompany(result.company);
      setInvoices(result.invoices);
      console.log(result);
    }
  };

  const handleClickFromInvoice = (amount: number, invoiceId: string) => {
    console.log(amount, invoiceId);
    setAmount(amount);
    setInvoiceId(invoiceId);
    setOpenModal(true);
  };

  useEffect(() => {
    getInvoices();
    setLoading(false);
  }, []);

  return (
    <div className={classes.layoutWrapper}>
      {openModal && (
        <NotifyPayment
          amount={amount}
          originInvoice={invoiceId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleClose={() => setOpenModal(false)}
          setSnackBar={setSnackbar}
        />
      )}
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12} md={3}>
            {!loading && (
              <div
                style={{
                  minWidth: 210, // Adjust the width as needed
                  height: '100vh', // Set the sidebar to full height
                  backgroundColor: 'ffffff', // Change the background color
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRight: '1px solid #eaecf0',
                }}
              >
                <div className={classes.side}>
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    {' '}
                    <img
                      width={175}
                      src={`https://pruff-public.s3.amazonaws.com/company-logos/${company.id}.png`}
                    />
                  </div>
                  {/* <Debt invoices={invoices} /> */}
                  <WireInformation
                    encryptedBusinessIdentifier={
                      encryptedBusinessIdentifier || ''
                    }
                    encryptedCompanyId={encryptedCompanyId || ''}
                  />
                </div>
                {/* <div style={{ textAlign: 'center' }}>
                  <CustomButton
                    startIcon={<CampaignIcon />}
                    color="primary"
                    variant="contained"
                    style={{ margin: '20px 20px 20px 20px' }}
                    onClick={() => setOpenModal(true)}
                  >
                    Informar pago
                  </CustomButton>
                </div> */}
                <Interested companyId={company.id} />
              </div>
            )}
          </Grid>
          <Grid item xs={0} md={9}>
            <div className={classes.layout}>
              <HeaderWithActions
                type="pageHeader"
                title="¡Hola 👋!"
                variant="h2"
              />
              <HeaderWithActions
                type="subHeader"
                title={`A continuación encontrarás los documentos emitidos a ${business.name}.`}
                variant="body1"
              />

              {invoices
                .sort((a, b) => {
                  return (
                    new Date(b.issuedDate).getTime() -
                    new Date(a.issuedDate).getTime()
                  );
                })
                .map((invoice: any, index: number) => (
                  <Invoice
                    key={index}
                    uuid={invoice.id}
                    companyOwner={
                      invoice.type === 'cession'
                        ? invoice.newBusiness
                        : invoice.business.name
                    }
                    amount={invoice.amount}
                    handleClickFromInvoice={handleClickFromInvoice}
                    status={invoice.isPaid}
                    issuedDate={invoice.issuedDate}
                    folio={invoice.folio}
                    type={invoice.type}
                    oldBusiness={invoice.business.name}
                  />
                ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        onClose={() => setSnackbar({ message: '', open: false })}
        open={snackbar.open}
      >
        <Alert
          onClose={() => setSnackbar({ message: '', open: false })}
          severity="success"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withStyles(styles)(PaymentLink);
