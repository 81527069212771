import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Disclaimer from '../../../../../../lib/designSystem/Disclaimer';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { publishInvoiceDebt } from '../../../../../../lib/api/documentPublication';

// TODO: Change disclaimer text to a real one
interface PublicationFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setReload: (reload: number) => void;
  reload: number;
  setCustomAlert: ({
    open,
    message,
    severity,
  }: {
    open: boolean;
    message: string;
    severity: string;
  }) => void;
  data?: any;
}

export interface PublishPayloadDto {
  company_rut: string;
  company_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  description: string;
  amount: number;
  emission_date: string;
  due_date: string;
  ndoc: string;
  creditor_rut: string;
  doc_type: string;
  dte_type?: string;
}

const PublicationForm: React.FC<PublicationFormProps> = (props) => {
  const { open, setOpen, reload, setReload, setCustomAlert, data } = props;
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [folio, setFolio] = useState('');
  const [amount, setAmount] = useState('');
  const [issueDate, setIssueDate] = useState<Date | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [debtorLegalName, setDebtorLegalName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [docType, setDocType] = useState('');
  const [invoiceCode, setInvoiceCode] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setBusinessIdentifier('');
    setStakeholderIdentifier('');
    setFolio('');
    setAmount('');
    setIssueDate(null);
    setDueDate(null);
    setDocType('');
    setInvoiceCode('');
    setDebtorLegalName('');
    setContactName('');
    setContactLastName('');
    setContactPhone('');
    setContactEmail('');
    setOpen(false);
  };

  const loadData = () => {
    setBusinessIdentifier(data.businessIdentifier);
    setStakeholderIdentifier(data.stakeholderIdentifier);
    setFolio(data.folio);
    setAmount(data.amount);
    setIssueDate(data.issueDate);
    setDueDate(data.dueDate);
    setDocType(data.docType);
    setInvoiceCode(data.invoiceCode);
    setDebtorLegalName(data.debtorLegalName);
    setContactName(data.contactName);
    setContactLastName(data.contactLastName);
    setContactPhone(data.contactPhone);
    setContactEmail(data.contactEmail);
  };

  const handlePublish = async () => {
    try {
      setLoading(true);
      const payload: PublishPayloadDto = {
        company_rut: stakeholderIdentifier,
        company_name: debtorLegalName,
        first_name: contactName,
        last_name: contactLastName,
        email: contactEmail,
        phone: contactPhone,
        doc_type: docType,
        dte_type: invoiceCode,
        description: 'Description',
        amount: Number(amount),
        emission_date: issueDate ? moment(issueDate).format('YYYY-MM-DD') : '',
        due_date: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
        ndoc: folio,
        creditor_rut: businessIdentifier,
      };
      await publishInvoiceDebt(dispatch, payload);
      setLoading(false);
      setReload(reload + 1);
      setCustomAlert({
        open: true,
        message: 'Documento publicado correctamente',
        severity: 'success',
      });
      handleClose();
    } catch (error) {
      setLoading(false);
      setReload(reload + 1);
      setCustomAlert({
        open: true,
        message:
          'Hubo un problema al publicar el documento, revisa que los campos sean correctos',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (data) {
      loadData();
    }
  }, [data]);

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4"> Publicar</Typography>
            </Grid>
            <Grid item>
              <img
                src="https://pruff-public.s3.amazonaws.com/Integrations/cobranzaOnline.svg"
                alt="cobranzaOnlineLogo"
              />
            </Grid>
          </Grid>
        </DialogTitle>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <DialogContent>
              <Typography variant="body1" marginBottom="8px">
                {' '}
                Ingresa la información del documento que deseas publicar.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={docType === 'Factura' ? 3 : 6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-label">Tipo de documento</InputLabel>
                    <Select
                      labelId="filter-label"
                      id="Filtrar por estado"
                      fullWidth
                      value={docType}
                      onChange={(e) => setDocType(e.target.value as string)}
                      label="Tipo de documento"
                    >
                      {['Factura', 'Contrato Impago', 'Cuota Impaga'].map(
                        (x: any, index: any) => (
                          <MenuItem key={index} value={x}>
                            {x}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                {docType === 'Factura' && (
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="filter-label">
                        Código de factura
                      </InputLabel>
                      <Select
                        labelId="filter-label"
                        id="Filtrar por estado"
                        fullWidth
                        value={invoiceCode}
                        onChange={(e) =>
                          setInvoiceCode(e.target.value as string)
                        }
                        label="Código de factura"
                      >
                        {['33', '34', '43', '110'].map((x: any, index: any) => (
                          <MenuItem key={index} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Folio"
                    variant="outlined"
                    required
                    value={folio}
                    onChange={(e) => setFolio(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="RUT emisor"
                    variant="outlined"
                    required
                    value={businessIdentifier}
                    onChange={(e) => setBusinessIdentifier(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="RUT deudor"
                    variant="outlined"
                    required
                    value={stakeholderIdentifier}
                    onChange={(e) => setStakeholderIdentifier(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Razón social deudor"
                    variant="outlined"
                    required
                    value={debtorLegalName}
                    onChange={(e) => setDebtorLegalName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Monto"
                    variant="outlined"
                    required
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label="Fecha emisión documento"
                      value={issueDate}
                      onChange={(e) => {
                        setIssueDate(e);
                      }}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label="Fecha vencimiento documento"
                      value={dueDate}
                      onChange={(e) => {
                        setDueDate(e);
                      }}
                      slotProps={{ textField: { fullWidth: true } }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Nombre contacto deudor"
                    variant="outlined"
                    required
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Apellido contacto deudor"
                    variant="outlined"
                    required
                    value={contactLastName}
                    onChange={(e) => setContactLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Teléfono contacto deudor"
                    variant="outlined"
                    required
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Correo contacto deudor"
                    variant="outlined"
                    required
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Disclaimer text="Este documento será publicado en distintos burós." />
            </DialogContent>
            <DialogActions>
              <CustomButton
                color="secondary"
                onClick={() => handleClose()}
                disabled={loading}
              >
                Cancelar
              </CustomButton>
              <CustomButton color="primary" onClick={() => handlePublish()}>
                Publicar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default PublicationForm;
