import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { getCompanyFunds } from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewFund from './components/NewFund';
import FundDetails from './components/FundDetails';
import FundAssignation from './components/Assignation';
import NewFundPayment from './components/NewPayment';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'name', label: 'Nombre' },
  { key: 'cost', label: 'Costo' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'default', label: 'Default', type: 'vission' },
];

const Funds: React.FC = () => {
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState('');
  const [funds, setFunds] = useState<any>([]);
  const [openNewFund, setOpenNewFund] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fundPayment, setFundPayment] = useState({});
  const newFund = {
    name: '',
    id: '',
    cost: '',
    amount: 0,
  };
  const [fund, setFund] = useState(newFund);
  const [openAssignationModal, setOpenAssignationModal] = useState(false);
  const [newPaymentModal, setNewPaymentModal] = useState(false);
  const fetchFunds = async () => {
    setLoading(true);
    try {
      const response = await getCompanyFunds(dispatch);
      setFunds(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleNewPaymentModal = () => {
    setNewPaymentModal(true);
  };

  const handleNewFund = () => {
    setEdit(false);
    setFund(newFund);
    setOpenNewFund(true);
  };

  const handelOpenEditModal = () => {
    setEdit(true);
    setOpenModal(false);
    setOpenNewFund(true);
  };

  const handleOpenAssignationModal = () => {
    setOpenAssignationModal(true);
  };

  const handleDetails = (id: string) => {
    const fund = funds.find((fund: any) => fund.id === id);
    if (!fund) return;
    setFund(fund);

    setOpenModal(true);
  };

  // const actions: any = [
  //   {
  //     actionLabel: 'Ver',
  //     action: handleDetails,
  //   },
  // ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleNewFund(),
      name: 'Agregar',
      color: 'primary',
    },
  ];

  useEffect(() => {
    fetchFunds();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todos tus fondos"
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={funds}
        rowsPerPageDefault={50}
        // actions={actions}
        // showActions
      />
      <NewFund
        fund={fund}
        setFund={setFund}
        open={openNewFund}
        setOpen={setOpenNewFund}
        setSuccess={setSuccess}
        setUp={fetchFunds}
        edit={edit}
      />
      {newPaymentModal && (
        <NewFundPayment
          open={newPaymentModal}
          setOpen={setNewPaymentModal}
          fundPayment={fundPayment}
          setFundPayment={setFundPayment}
          setUp={fetchFunds}
          fund={fund}
          setSuccess={setSuccess}
        />
      )}
      {
        <FundDetails
          open={openModal}
          setOpen={setOpenModal}
          setUp={fetchFunds}
          fund={fund}
          setFundPayment={setFundPayment}
          handelOpenEditModal={handelOpenEditModal}
          setSuccess={setSuccess}
          handleOpenAssignationModal={handleOpenAssignationModal}
          handleOpenNewPaymentModal={handleNewPaymentModal}
        />
      }
      {openAssignationModal && (
        <FundAssignation
          open={openAssignationModal}
          setOpen={setOpenAssignationModal}
          setUp={fetchFunds}
          fund={fund}
          setSuccess={setSuccess}
        />
      )}
      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Funds;
