// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../../services/redux/authSlice'; // Import the action
import {
  authenticateUser,
  confirmUserPasswordRecovery,
  confirmUserRegistrationCode,
  getUserProfile,
  updatePasswordRecovery,
} from '../../../lib/api';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/store';
import HeaderWithActions from '../../../lib/designSystem/HeaderWithActions';
import {
  Divider,
  IconButton,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon
import { useParams } from 'react-router-dom';
import CustomButton from '../../../lib/designSystem/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const PasswordRecoveryLanding = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state: RootState) => state.auth);
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [user, setUser] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [registerData, setRgisterData] = useState({
    email: '',
    password: '',
    checkPassword: '',
    name: '',
    identifier: '',
    companyName: '',
  });

  const handleChange = (e: any) => {
    setRgisterData({ ...registerData, [e.target.id]: e.target.value });
  };

  const confirmCodeUser = async () => {
    setLoading(true);
    const userResponse = await confirmUserPasswordRecovery({ code });
    console.log(userResponse);
    if (!userResponse.success) {
      setError(true);
    }
    setClientId(userResponse.clientId);
    setUser(userResponse.user);
    setLoading(false);
  };

  const checkPassword = () => {
    if (registerData.password === registerData.checkPassword) {
      return true;
    }
    return false;
  };

  const checkPasswordLength = () => {
    if (registerData.password.length >= 12) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    const userResponse = await updatePasswordRecovery({
      code,
      password: registerData.password,
    });
    console.log(userResponse);
    if (!userResponse.success) {
      setError(true);
    }
    setClientId(userResponse.clientId);
    setLoading(false);
    if (clientId) {
      window.location.href = `/express/${clientId}`;
    } else {
      window.location.href = `/`;
    }
  };
  useEffect(() => {
    confirmCodeUser();
  }, []);

  if (loading) return <CenteredCircularProgress type="noLayout" />;

  if (error) return <div>Hubo un error</div>;

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
        <EnhancedBox>
          <Typography variant="h4">Recuperar constraseña</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Bienvenido {user.name}, ingresa tu nueva contraseña.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contraseña"
                fullWidth
                type="password"
                variant="outlined"
                value={registerData.password}
                onChange={(e) => handleChange(e)}
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Repetir contraseña"
                fullWidth
                type="password"
                variant="outlined"
                value={registerData.checkPassword}
                onChange={(e) => handleChange(e)}
                id="checkPassword"
                autoComplete="new-password"
              />
              {checkPassword() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas no coinciden
                </Typography>
              )}
              {checkPasswordLength() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas debe ser mayor a 12 caracteres
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <CustomButton
              onClick={(e) => handleSubmit(e)}
              fullWidth
              color="primary"
              variant="contained"
              disabled={!checkPassword() || !checkPasswordLength()}
            >
              Cambiar contraseña
            </CustomButton>
          </Box>
        </EnhancedBox>
      </div>
    </div>
  );
};

export default PasswordRecoveryLanding;
