import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../../../../lib/designSystem/Alert';
import { getFundPayments } from '../../../../../../../../../../lib/api';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Pagador' },
  { key: 'date', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
];

const FundPayments = (props: any) => {
  const { fund, handleOpenNewPaymentModal, setFundPayment } = props;
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [payments, setPayments] = useState<any>([]);

  const fetchFundPayments = async () => {
    setLoading(true);
    try {
      const response = await getFundPayments(dispatch, {
        id: fund.id,
      });
      setPayments(response);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  const handleRegisterPayment = () => {
    setFundPayment({ fundId: fund.id });
    handleOpenNewPaymentModal(true);
    // setOpenModal(true);
  };

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleRegisterPayment(),
      name: 'Nuevo Pago',
      color: 'primary',
    },
  ];

  useEffect(() => {
    fetchFundPayments();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todos los pagos registrados para este fondo"
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={payments}
        rowsPerPageDefault={50}
      />
      {/* <NewFund
        open={openNewFund}
        setOpen={setOpenNewFund}
        setSuccess={setSuccess}
        setUp={fetchFunds}
      /> */}
      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default FundPayments;
