import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import FilesDialog from '../../../../../common/filesView';
import EnhancedTab from '../../../../../../../../../lib/designSystem/TabSimulation';
import Summary from './components/Summary';
// import ChatWindow from '../../../../../common/chat';
import RiskReviews from '../../../../../common/riskReviews';
import MultiChatWindowContainer from '../../../../../common/chat';
import ActiveDocuments from './components/ActiveDocuments';
import CreditLineApprovalContainer from './components/Approval';

const ShoppingDialogRecieved = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    setUp,
    openRequest,
    setSelectedRequest,
    setSuccessOpen,
    setFailureOpen,
    executiveCreating,
  } = props;
  const [openFiles, setOpenFiles] = useState(false);
  const [startValue, setStartValue] = useState(0);
  const [goToRiskTimes, setGoToRiskTimes] = useState(1);

  useEffect(() => {}, []);

  const goToRisk = () => {
    setStartValue(1);
    setGoToRiskTimes(goToRiskTimes + 1);
  };

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <Summary
          shoppingCartType={shoppingCartType}
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          setUp={setUp}
          openRequest={openRequest}
          setSelectedRequest={setSelectedRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          executiveCreating={executiveCreating}
          goToRisk={goToRisk}
        />
      ),
    },
    {
      index: 1,
      label: 'Aprobación',
      disabled: ['Revisada'].includes(openRequest?.status),
      component: (
        <CreditLineApprovalContainer
          setOpenShoppingCart={setOpenShoppingCart}
          setUp={setUp}
          requestType="creditLine"
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      ),
      path: 'recieved',
    },
    {
      index: 1,
      label: 'Activo',
      component: (
        <ActiveDocuments
          requestType="creditLine"
          businessIdentifier={openRequest && openRequest.businessIdentifier}
          setOpenShoppingCart={setOpenShoppingCart}
        />
      ),
      path: 'recieved',
    },
    {
      index: 1,
      label: 'Riesgo',
      component: (
        <RiskReviews requestType="creditLine" selectedRequest={openRequest} />
      ),
      path: 'recieved',
    },
    {
      index: 2,
      label: 'Archivos',
      component: (
        <FilesDialog
          openRequest={openRequest}
          openFiles={openFiles}
          setOpenFiles={setOpenFiles}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          executiveCreating={executiveCreating}
          requestType="creditLine"
        />
      ),
      path: 'recieved',
    },
    {
      index: 3,
      label: 'Chat',
      component: (
        <MultiChatWindowContainer
          selectedRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          requestType="creditLine"
        />
      ),
      path: 'recieved',
    },
  ];

  if (openRequest && openRequest.status === 'REJECTED') {
    tabs.pop();
  }

  if (executiveCreating) {
    tabs.pop();
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openShoppingCart}
      onClose={() => setOpenShoppingCart(false)}
    >
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={
            !executiveCreating ? `Línea #${openRequest?.id}` : 'Nueva solicitud'
          }
          startValue={startValue}
          tabs={tabs}
          updater={goToRiskTimes}
          context={openRequest?.businessName}
          // infoChip={openRequest?.status}
          // colorChip={colors[openRequest?.status]}
        />
      </div>
    </Dialog>
  );
};

export default ShoppingDialogRecieved;
