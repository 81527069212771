import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import ProductsSummary from './components/PendingAccountsCategories';
import Funds from './components/Funds';
import CollectionStates from './components/CollectionStates';

const tabs = [
  {
    index: 0,
    label: 'Categorías de cobranza',
    component: <CollectionStates />,
    path: 'collectionsStates',
  },
  {
    index: 1,
    label: 'Cuentas',
    component: <ProductsSummary />,
    path: 'accounts',
  },
  {
    index: 2,
    label: 'Fondos',
    component: <Funds />,
    path: 'funds',
  },
];

const FinanceTab = () => {
  const { subPage } = useParams<{
    id: string;
    businessId: string;
    subPage: string;
    page: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/settings/finance/${companyTabs[index].path}`);
  };

  const setUpTabs = async () => {
    const newProfileTabs: any = [...tabs];
    let startValueDefault = 0;
    startValueDefault = newProfileTabs.findIndex(
      (x: any) => x.path === subPage,
    );
    setCompanyTabs(newProfileTabs);
    console.log(newProfileTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        tabs={companyTabs}
        onTabChange={handleNavigation}
        // canShowTab={checkCanAccessView()}
      />
    </div>
  );
};

export default FinanceTab;
