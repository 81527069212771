import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  deleteProduct,
  getAvailableProducts,
  getOwnCompanyProducts,
  getProduct,
} from '../../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CreateProductDialog from './components/CreateDialog';
import { Edit } from '@mui/icons-material';
import EditProductDialog from './components/EditDialog';

const ProductsSummary = (props: any) => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [productsAvailable, setProductsAvailable] = useState<
    { id: number; name: string; description: string }[]
  >([]);
  const [product, setProduct] = useState<any>({
    name: '',
    requirements: [],
  });
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await getOwnCompanyProducts(dispatch);
      setCompanyProducts(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteProduct(dispatch, { id });
      await fetchProducts();
      setSuccess(true);
      setSuccessMessage('Producto eliminado whitelist');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setFailed(true);
      setLoading(false);
    }
  };

  const handleOpenCreateDialog = async () => {
    setLoading(true);
    try {
      const response = await getAvailableProducts(dispatch);
      console.log(response);
      setProductsAvailable(response);
      setOpenCreateDialog(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert('Hubo un error buscando los productos disponibles');
    }
  };

  const handleOpenEditDialog = async (id: number) => {
    setLoading(true);
    try {
      const response = await getProduct(dispatch, { id });
      setProduct(response);
      setLoading(false);
      setOpenEditDialog(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert('Hubo un error buscando los productos disponibles');
    }
  };

  const startButtons: CustomButtonProps[] = [
    {
      action: () => handleOpenCreateDialog(),
      name: 'Agregar',
      color: 'primary',
    },
  ];

  const actions = [
    { action: handleOpenEditDialog, actionLabel: 'Editar' },
    { action: handleDelete, actionLabel: 'Eliminar' },
  ];

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={startButtons}
        title="Acá encontrarás todas los productos que estás ofreciendo a tus clientes."
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'productType', label: 'Nombre producto' },
          { key: 'productTypeDescription', label: 'Descripción producto' },
        ]}
        data={companyProducts}
        actions={actions}
        showActions
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
      <CreateProductDialog
        openDialog={openCreateDialog}
        setOpenDialog={setOpenCreateDialog}
        productsAvailable={productsAvailable}
        setLoading={setLoading}
        fetchProducts={fetchProducts}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        setFailed={setFailed}
      />
      <EditProductDialog
        openDialog={openEditDialog}
        setOpenDialog={setOpenEditDialog}
        product={product}
        setLoading={setLoading}
        fetchProducts={fetchProducts}
        setSuccess={setSuccess}
        setSuccessMessage={setSuccessMessage}
        setFailed={setFailed}
      />
      <CustomAlert
        open={success}
        title="Éxito"
        text={successMessage}
        setOpen={setSuccess}
      />
      <CustomAlert
        open={failed}
        title="Error"
        severity="error"
        text="Hubo un error"
        setOpen={setFailed}
      />
    </div>
  );
};

export default ProductsSummary;
