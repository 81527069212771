import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getSiiDebt } from '../../../../../lib/api/dashboard';

interface SiiData {
  contributions: any;
  iva: any;
}

const SiiResponsabilities: React.FC = () => {
  const dispatch = useDispatch();
  const [siiData, setSiiData] = useState<SiiData>({
    contributions: [],
    iva: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await getSiiDebt(dispatch);
      console.log(relations);
      setSiiData(relations);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h5">IVA SII</Typography>

      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Cuenta' },
          { key: 'name', label: 'Fecha' },
          { key: 'status', label: 'Estado' },
          { key: 'amount', label: 'Monto', type: 'money' },
        ]}
        data={siiData.iva}
        rowsPerPageDefault={5}
      />
      <Typography variant="h5">Cuotas contribuciones SII</Typography>
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'status', label: 'Estado' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'dueDate', label: 'Fecha de vencimiento', type: 'date' },
        ]}
        data={siiData.contributions}
        rowsPerPageDefault={5}
      />
    </div>
  );
};

export default SiiResponsabilities;
