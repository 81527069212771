import { InfoOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';

interface DisclaimerProps {
  text: any;
}

const Disclaimer: React.FC<DisclaimerProps> = (props) => {
  const { text } = props;

  return (
    <div
      style={{
        display: 'inline-flex',
        verticalAlign: 'text-bottom',
        boxSizing: 'inherit',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <InfoOutlined />
      <Typography
        variant="body1"
        style={{ alignItems: 'left', textAlign: 'left', padding: '23px' }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default Disclaimer;
