import React from 'react';
import { Typography } from '@mui/material';
import HeaderWithActions from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'issuedDate', label: 'Fecha de emisión', type: 'date' },
  { key: 'appliedAmount', label: 'Monto aplicado', type: 'money' },
  { key: 'documentAmount', label: 'Monto documento', type: 'money' },
];

const PayOutDetailAppliedDocuments = (props: any) => {
  const { documentsApplied } = props;

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes ver los pagos realizados."
        variant="body1"
      />
      {documentsApplied.length === 0 ? (
        <Typography variant="body1">
          No hay documentos aplicados en este pago
        </Typography>
      ) : (
        <EnhancedTable dataHeaders={headers} data={documentsApplied} />
      )}
    </div>
  );
};

export default PayOutDetailAppliedDocuments;
