import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { activateIntegration } from '../../../../../../lib/api';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import DialogTitle from '../../../../../../lib/designSystem/DialogTitle';

// TODO: Change disclaimer text to a real one

const SuperDebtConfiguration = (props: any) => {
  const { open, setOpen, id, reload, setReload } = props;
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const checkElements = () => {
    if (username && password) {
      return true;
    }
    setErrorMessage('Todos los campos son requeridos.');
    setShowError(true);
    setError(true);
    return false;
  };

  const handleActivate = async () => {
    if (checkElements()) {
      await handleConfiguration();
    }
  };

  const handleConfiguration = async () => {
    setLoading(true);
    try {
      const response = await activateIntegration(dispatch, id, {
        username,
        password,
      });
      if (!response.status) {
        setLoading(false);
        setShowError(true);
        setErrorMessage(response.message);
        return;
      }
      setLoading(false);
      setShowAlert(true);
      setReload(reload + 1);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      console.log(error);
      setErrorMessage('Error al activar la integración, intente nuevamente.');
    }
  };
  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle setOpen={setOpen}>
          <img
            height={50}
            src="https://pruff-public.s3.amazonaws.com/Integrations/logoProriesgo.jpg"
            alt="cobranzaOnlineLogo"
          />
        </DialogTitle>
        {loading ? (
          <div style={{ minHeight: '200px' }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        ) : (
          <div>
            <DialogContent>
              <div style={{ padding: '12px 0px' }}>
                <Typography fontWeight={700} variant="body1">
                  Configuración
                </Typography>
                <Typography variant="body1">
                  {' '}
                  Rellena este formulario para verificar que tu cuenta está
                  activa.
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Usuario"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    variant="outlined"
                    margin="none"
                    error={error && !username}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Escribe tu contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    margin="none"
                    type="password"
                    error={error && !password}
                  />
                </Grid>
              </Grid>
              <Typography marginTop={2} variant="body2" color="red">
                Al intentar activar la integración, se realizará una consulta
                para que lo tengas considerado.
              </Typography>
            </DialogContent>
            <DialogActions>
              <CustomButton
                color="secondary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Salir
              </CustomButton>
              <CustomButton
                color="primary"
                onClick={() => handleActivate()}
                disabled={loading}
              >
                Activar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <CustomAlert
        open={showError}
        setOpen={setShowError}
        severity="error"
        text={errorMessage}
        title="Error"
      />
      <CustomAlert
        open={showAlert}
        setOpen={setShowAlert}
        severity="success"
        text="Integración activada correctamente."
        title="Éxito"
      />
    </div>
  );
};

export default SuperDebtConfiguration;
