// LoginForm.tsx
import React, { useState } from 'react';
import { registerUser } from '../../../../lib/api';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../../../lib/designSystem/BigBox';
import { Copyright } from '../../../PublicLandings/EnrolationForm';
import {
  validaRut,
  validateChileanPhone,
} from '../../../../lib/functions/utils';
import CustomAlert from '../../../../lib/designSystem/Alert';
import { executeRutificator } from '../../../../lib/api/express/enrollment';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SigninExpress = () => {
  const [registerData, setRgisterData] = useState({
    email: '',
    password: '',
    checkPassword: '',
    name: '',
    identifier: '',
    phone: '',
    companyName: '',
  });
  const [success, setSucces] = useState(false);
  const [companyNameFound, setCompanyNameFound] = useState(false);
  const domain = window.location.hostname; // Obtiene 'banpro.pruff.com'
  const clientId = domain.split('.')[0]; // Obtiene 'banpro'
  const [loadingName, setLoadingName] = useState(false);
  const [canSeePassword, setCanSeePassword] = useState(false);

  const handleChange = (e: any) => {
    setRgisterData({ ...registerData, [e.target.id]: e.target.value });
  };

  const handleChangeIdentifier = (value: any) => {
    if (value.includes('.')) return;
    if (value.includes(' ')) return;
    if (value.includes(',')) return;
    setRgisterData({
      ...registerData,
      identifier: value.toUpperCase(),
    });
  };

  const [loading, setLoding] = useState(false);

  const checkPassword = () => {
    if (registerData.password === registerData.checkPassword) {
      return true;
    }
    return false;
  };

  const checkPasswordLength = () => {
    if (registerData.password.length >= 12) {
      return true;
    }
    return false;
  };

  const checkParams = () => {
    if (
      registerData.email &&
      registerData.password &&
      registerData.checkPassword &&
      registerData.name &&
      registerData.identifier &&
      registerData.phone
    ) {
      return true;
    }
    return false;
  };
  const validateRut = () => {
    const rutValid = validaRut(registerData.identifier);
    if (!rutValid) {
      if (!registerData.identifier.includes('-')) {
        // add a dash to the rut
        const rut = registerData.identifier;
        const rutArray = rut.split('');
        rutArray.splice(rutArray.length - 1, 0, '-');
        const newRut = rutArray.join('');
        const rutValid2 = validaRut(newRut);
        if (rutValid2) {
          setRgisterData({ ...registerData, identifier: newRut });
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  const getCompanyName = async () => {
    setLoadingName(true);
    if (!validateRut()) {
      return alert('Rut inválido');
    }
    try {
      console.log('clientId', clientId);
      if (!clientId) return;
      const responseConsult = await executeRutificator(clientId, [
        registerData.identifier,
      ]);
      if (responseConsult) {
        console.log(responseConsult);
        setRgisterData({
          ...registerData,
          companyName:
            responseConsult.results[registerData.identifier][0].RAZON_SOCIAL,
        });
        setCompanyNameFound(true);
      } else {
        setCompanyNameFound(false);
      }
    } catch (error) {
      setCompanyNameFound(false);
    }
    setLoadingName(false);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      setLoding(true);
      const response = await registerUser({ ...registerData, clientId });
      if (!response.success) {
        alert(response.message);
        return setLoding(false);
      }
      setSucces(true);
      console.log(response);
    } catch (error) {
      alert('Hubo un error');
    }
    setLoding(false);
  };
  if (loading) return <CenteredCircularProgress type="dialog" />;

  const companyNameDisabled = () => {
    if (companyNameFound) return true;
    if (loadingName) return true;
    if (!registerData.identifier.length) return true;
    return false;
  };
  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <EnhancedBox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">Registrarse</Typography>
            <Typography>
              <a href={`/`}>Volver</a>
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre personal"
                variant="outlined"
                id="name"
                value={registerData.name}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                id="email"
                value={registerData.email}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Teléfono"
                variant="outlined"
                id="phone"
                value={registerData.phone}
                onChange={(e) => handleChange(e)}
              />
              {validateChileanPhone(registerData.phone) &&
              registerData.phone.length ? null : (
                <Typography variant="body1" color="error">
                  Teléfono inválido
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Rut empresa (Sin puntos, con guión)"
                variant="outlined"
                id="identifier"
                value={registerData.identifier}
                onChange={(e) => handleChangeIdentifier(e.target.value)}
                onBlur={() => getCompanyName()}
              />
              {registerData.identifier.length && validateRut() ? null : (
                <Typography variant="body1" color="error">
                  Rut inválido
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre empresa"
                variant="outlined"
                id="companyName"
                value={registerData.companyName}
                onChange={(e) => handleChange(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loadingName && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                }}
                disabled={companyNameDisabled()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contraseña"
                fullWidth
                type={canSeePassword ? 'text' : 'password'}
                variant="outlined"
                value={registerData.password}
                onChange={(e) => handleChange(e)}
                id="password"
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setCanSeePassword(!canSeePassword)}
                      >
                        {canSeePassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Repetir contraseña"
                fullWidth
                type={canSeePassword ? 'text' : 'password'}
                variant="outlined"
                value={registerData.checkPassword}
                onChange={(e) => handleChange(e)}
                id="checkPassword"
                autoComplete="new-password"
              />
              {checkPassword() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas no coinciden
                </Typography>
              )}
              {checkPasswordLength() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas debe ser mayor a 12 caracteres
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <CustomButton
              onClick={(e) => handleSubmit(e)}
              fullWidth
              color="primary"
              variant="contained"
              disabled={
                !checkParams() ||
                !checkPassword() ||
                !validateRut() ||
                !checkPasswordLength() ||
                !validateChileanPhone(registerData.phone)
              }
            >
              Registrarse
            </CustomButton>
          </Box>
        </EnhancedBox>
      </div>
      <CustomAlert
        open={success}
        setOpen={setSucces}
        severity="success"
        text="Se te envió un email para activar tu cuenta"
        onClose={() => (window.location.href = `/`)}
      />
      <Copyright />
    </div>
  );
};

export default SigninExpress;
