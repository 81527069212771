export const payersHeaders = [
  { key: 'stakeholderName', label: 'Pagador' },
  { key: 'stakeholderIdentifier', label: 'Rut' },
  { key: 'documentsCount', label: 'Documentos' },
  { key: 'totalAmount', label: 'Monto', type: 'money' },
  {
    key: 'concentrationLimit',
    label: 'Límite de Concentración',
    type: 'money',
  },
  {
    key: 'amountArrear',
    label: 'Mora',
    type: 'money',
  },
  {
    key: 'conciliatedByBusinessAmount',
    label: 'Recaudado cliente',
    type: 'money',
  },
  {
    key: 'conciliatedByStakeholderAmount',
    label: 'Recaudado pagador',
    type: 'money',
  },
  {
    key: 'conciliatedTotalAmount',
    label: 'Recaudado total',
    type: 'money',
  },
];
export const documentssHeaders = [
  {
    key: 'stakeholderName',
    label: 'Pagador',
  },
  { key: 'stakeholderSegment', label: 'Segmento pagador' },
  {
    key: 'folio',
    label: 'Folio',
  },
  {
    key: 'paymentDate',
    label: 'Fecha pago',
    type: 'date',
  },
  {
    key: 'confirmDate',
    label: 'Confirmada',
    type: 'confirm-lights',
    canNull: true,
  },
  {
    key: 'confirmedBy',
    label: 'Confirmada por',
    canNull: true,
  },
  {
    key: 'confirmType',
    label: 'Tipo Confirmación',
    canNull: true,
  },
  {
    key: 'confirmMessage',
    label: 'Comentario',
  },
  {
    key: 'cancelDate',
    label: 'Reclamos?',
    type: 'cession-lights-false',
    canNull: true,
  },
  {
    key: 'amountDocument',
    label: 'Monto',
    type: 'money',
  },
  {
    key: 'amountFinanced',
    label: 'Monto financiado',
    type: 'money',
  },

  {
    key: 'costRate',
    label: 'Costo tasa',
    type: 'money',
  },
  {
    key: 'amountToDeposit',
    label: 'Montos a depositar',
    type: 'money',
  },
];

export const appliedHeaders = [
  { key: 'folio', label: 'Folio' },
  { key: 'factoringRequestId', label: 'Solicitud' },
  {
    key: 'stakeholderName',
    label: 'Pagador',
  },
  {
    key: 'amount',
    label: 'Monto',
    type: 'money',
  },
  {
    key: 'amountConciliated',
    label: 'Monto aplicado',
    type: 'money',
  },
];
