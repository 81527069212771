import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
} from '@mui/material';
import Launch from '@mui/icons-material/Launch';
import CustomButton from '../../../../lib/designSystem/Button';

const GenericCard = (props: any) => {
  const {
    imageSrc,
    imageAlt,
    description,
    loading,
    onDetailClick,
    onConfigClick,
    onOpenClick,
    integrationStatus,
  } = props;
  return (
    <Card sx={{ borderRadius: 2 }}>
      <CardContent style={{ maxHeight: 200 }}>
        <div style={{ wordWrap: 'break-word' }}>
          <img height={30} src={imageSrc} alt={imageAlt} />
          <Typography variant="body1" sx={{ pt: 3, whiteSpace: 'normal' }}>
            {description}
          </Typography>
        </div>
      </CardContent>
      {loading ? (
        <div style={{ textAlign: 'right', padding: 8 }}>
          <CircularProgress />
        </div>
      ) : (
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <CustomButton
            style={{ marginLeft: 8 }}
            color="secondary"
            onClick={onDetailClick}
          >
            Detalle
          </CustomButton>
          {!loading && (
            <>
              {!integrationStatus && (
                <CustomButton
                  style={{ marginLeft: 8 }}
                  color="primary"
                  onClick={onConfigClick}
                >
                  Configurar
                </CustomButton>
              )}
              {integrationStatus && (
                <CustomButton
                  style={{ marginLeft: 8 }}
                  color="primary"
                  onClick={onOpenClick}
                  startIcon={<Launch />}
                >
                  Abrir
                </CustomButton>
              )}
            </>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default GenericCard;
