import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { validaRut } from '../../functions/utils';
import { getBusinessByIdentifier } from '../../api';
import { useDispatch } from 'react-redux';
import CustomButton from '../../designSystem/Button';
import CenteredCircularProgress from '../../designSystem/CircularProgress';

const BusinessSearcher = (props: any) => {
  const { setBusiness, setSearched } = props;
  const [identifier, setIdentifier] = useState('');
  const [loading, setLoading] = useState(false);
  const [businessSearched, setBusinessSearched] = useState<any>({});

  const dispatch = useDispatch();

  const handleChangeIdentifier = (e: any) => {
    setSearched(false);
    setIdentifier(e.target.value);
  };

  const handleSearchBusiness = async () => {
    if (!validaRut(identifier)) {
      alert('Rut inválido');
      return;
    }
    setLoading(true);
    try {
      const businessResponse = await getBusinessByIdentifier(dispatch, {
        identifier,
      });
      console.log(businessResponse);
      if (businessResponse) {
        setBusiness(businessResponse);
        setBusinessSearched(businessResponse);
      }
      setSearched(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (identifier === '') {
      setSearched(false);
      setBusiness(null);
    }
  }, []);

  const searchOptions = [
    { id: 'identifier', name: 'RUT' },
    { id: 'businessName', name: 'Nombre Empresa' },
  ];

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <Typography padding="10px 0px" variant="body1">
        {' '}
        Ingresa el rut de la empresa
      </Typography>

      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <TextField
              label="RUT"
              placeholder="Sin puntos con guón. Ej: 12345678-K"
              value={identifier}
              fullWidth
              onChange={(e) => handleChangeIdentifier(e)}
            />
          </Grid>
          <Grid item xs={4} textAlign="center">
            <CustomButton
              color="primary"
              onClick={() => handleSearchBusiness()}
            >
              Buscar
            </CustomButton>
          </Grid>
        </Grid>
      </div>
      {businessSearched.name && (
        <Typography variant="caption">
          {' '}
          Empresa encontrada: {businessSearched.name}
        </Typography>
      )}
    </div>
  );
};

export default BusinessSearcher;
