import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../lib/designSystem/HeaderWithActions';
import ScoreAssgination from './components/assignation';
import CustomAlert from '../../../lib/designSystem/Alert';
import {
  getScoringAssgination,
  getScoringAssginations,
  selectAssignationSheet,
} from '../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../lib/designSystem/Table';
import CustomButton from '../../../lib/designSystem/Button';
import { useParams } from 'react-router-dom';
import { generatScorePDF } from '../../../lib/functions/utils';
import { AuthState } from '../../../services/redux/authSlice';

interface SummaryProps {
  summary?: string;
}

const Scoring: React.FC<SummaryProps> = ({ summary }) => {
  const { id } = useParams<{ id: string }>();
  const [openAssgination, setOpenAssgination] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [failedMessage, setFailedMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [assignations, setAssignations] = React.useState<any[]>([]);
  const [openSelectionWarning, setOpenSelectionWarning] = React.useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();

  const selectScoringSheet = async () => {
    setLoading(true);
    try {
      await selectAssignationSheet(dispatch, { scoringSheetId: selectedId });
      await setUp();
      setOpenSelectionWarning(false);
      handleSuccess('Se ha seleccionado la asignación como la actual');
    } catch (error) {
      handleFailed('Ha ocurrido un error');
    }
    setLoading(false);
  };

  const handleSuccess = (text: string) => {
    setSuccessMessage(text);
    setOpenSuccess(true);
  };
  const handleFailed = (text: string) => {
    setFailedMessage(text);
    setOpenFailed(true);
  };

  const setUp = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    try {
      const assignationsResponse = await getScoringAssginations(dispatch, id);
      setAssignations(assignationsResponse);
      console.log(assignationsResponse);
    } catch (error) {}
    setLoading(false);
  };

  const processVariablesToListFormat = async (
    scoringSheetAssignationId: number,
  ) => {
    const sheetAssignated = await getScoringAssgination(dispatch, {
      id: scoringSheetAssignationId,
    });
    const variables = sheetAssignated.scoreAssignations.map(
      (variableAssignated: any) => {
        const variable = variableAssignated.variable;
        return {
          id: variableAssignated.id,
          name: variable.scoringVariable.name,
          weight: variable.weight,
          options: variable.scoringVariable.matrix,
          inputScore: variableAssignated.inputScore,
          observation: variableAssignated.observation,
        };
      },
    );
    return variables;
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleEdit = (id: number) => {
    setSelectedId(id);
    setOpenAssgination(true);
  };

  const handleCreate = () => {
    setSelectedId(null);
    setOpenAssgination(true);
  };
  const handleSelectionWarning = (id: number) => {
    setSelectedId(id);
    setOpenSelectionWarning(true);
  };

  const handleExport = async (id: number) => {
    setLoading(true);
    const variables = await processVariablesToListFormat(id);
    const variablesWithDescription = variables.map((variable: any) => {
      const value = variable.inputScore;
      const option = variable.options.find(
        (option: any) => option.valueMin < value && option.valueMax >= value,
      );
      if (!option) return variable;
      return {
        ...variable,
        description: option.description,
      };
    });
    const assgination = assignations.find((x: any) => x.id === id);
    console.log(variablesWithDescription, assgination);

    generatScorePDF(assgination, variablesWithDescription, user);
    setLoading(false);
  };

  const canShowOpenSelect = (id: number) => {
    const assignation = assignations.find((x: any) => x.id === id);
    if (!assignation) return alert('Ha ocurrido un error');

    if (assignation.selected) return false;
    return true;
  };

  const actions = [
    {
      action: handleSelectionWarning,
      actionLabel: 'Seleccionar',
      canShow: canShowOpenSelect,
    },
    { action: handleEdit, actionLabel: 'Editar' },
    { action: handleExport, actionLabel: 'Exportar' },
  ];

  const buttons: CustomButtonProps[] = [
    {
      action: () => handleCreate(),
      name: 'crear asignación',
      color: 'primary',
    },
  ];

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás las asginaciones de Score que les has dado a la cuenta."
        variant="body1"
        buttons={buttons}
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'rating', label: 'Clasificación' },
          { key: 'score', label: 'Score' },
          { key: 'name', label: 'Plantilla' },
          { key: 'selected', label: 'Activa', type: 'boolean-active-null' },
          { key: 'createdAt', label: 'Fecha de creación', type: 'date-large' },
        ]}
        data={assignations}
        rowsPerPageDefault={10}
        actions={actions}
        showActions
      />
      {openAssgination && (
        <ScoreAssgination
          open={openAssgination}
          setOpen={setOpenAssgination}
          scoringSheetAssignationId={selectedId}
          openSuccess={handleSuccess}
          openFailure={handleFailed}
          setUp={setUp}
          processVariablesToListFormat={processVariablesToListFormat}
        />
      )}
      {openSelectionWarning && (
        <Dialog open={openSelectionWarning}>
          <DialogTitle>
            <HeaderWithActions
              type="dialogHeader"
              title={'Seleccionar asignación'}
            />
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Al cambiar la asignación actual se modificará la clasificación de
              esta empresa.
            </Typography>
            <Typography variant="body1">
              ¿Estás seguro que quieres seleccionar esta asignación?
            </Typography>
          </DialogContent>
          <DialogActions>
            <CustomButton
              onClick={() => setOpenSelectionWarning(false)}
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton onClick={() => selectScoringSheet()} color="primary">
              Seleccionar
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailed}
        setOpen={setOpenFailed}
        severity="error"
        text={failedMessage}
        handleClose={() => setOpenFailed(false)}
      />
    </div>
  );
};

export default Scoring;
