import React, { useState } from 'react';

import { Dialog } from '@mui/material';
import FundSummary from './components/Summary';
import EnhancedTab from '../../../../../../../../lib/designSystem/TabSimulation';
import FundDocuments from './components/Documents';
import FundPayments from './components/Payments';

const FundDetails = (props: any) => {
  const {
    open,
    setOpen,
    fund,
    handelOpenEditModal,
    setSuccess,
    setUp,
    handleOpenAssignationModal,
    handleOpenNewPaymentModal,
    setFundPayment,
  } = props;
  const [startValue, setStartValue] = useState(0);

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <FundSummary
          open={open}
          setOpen={setOpen}
          fund={fund}
          handelOpenEditModal={handelOpenEditModal}
          setUp={setUp}
          setSuccess={setSuccess}
        />
      ),
    },
    {
      index: 0,
      label: 'Documentos',
      component: (
        <FundDocuments
          open={open}
          setOpen={setOpen}
          fund={fund}
          handelOpenEditModal={handelOpenEditModal}
          setUp={setUp}
          setSuccess={setSuccess}
          handleOpenAssignationModal={handleOpenAssignationModal}
          handleOpenNewPaymentModal={handleOpenNewPaymentModal}
          setFundPayment={setFundPayment}
        />
      ),
    },
    {
      index: 0,
      label: 'Pagos',
      component: (
        <FundPayments
          open={open}
          setOpen={setOpen}
          fund={fund}
          handelOpenEditModal={handelOpenEditModal}
          setUp={setUp}
          setSuccess={setSuccess}
          handleOpenAssignationModal={handleOpenAssignationModal}
          setFundPayment={setFundPayment}
          handleOpenNewPaymentModal={handleOpenNewPaymentModal}
        />
      ),
    },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={fund.name || ''}
          startValue={startValue}
          tabs={tabs}
        />
      </div>
    </Dialog>
  );
};

export default FundDetails;
