import { validatePermissions } from '../functions/utils';

export const checkFinancingMainPage = (user: any) => {
  if (validatePermissions(user, 'financing_factoring_receive')) {
    return false;
  } else if (validatePermissions(user, 'financing_creditLine_receive')) {
    return false;
  } else if (validatePermissions(user, 'financing_fundSale_view')) {
    return false;
  } else if (validatePermissions(user, 'financing_extension_view')) {
    return false;
  }
  return true;
};

export const checkPaymentsMainPage = (user: any) => {
  if (validatePermissions(user, 'financing_payout_view')) {
    return false;
  } else if (validatePermissions(user, 'financing_payIn_view')) {
    return false;
  } else if (validatePermissions(user, 'financing_discount_view')) {
    return false;
  } else if (validatePermissions(user, 'financing_fnc_view')) {
    return false;
  }
  return true;
};

export const checkCollectionsMainPage = (user: any) => {
  if (validatePermissions(user, 'collections_view')) {
    return false;
  }
  return true;
};

export const checkFinancingComplete = (user: any) => {
  if (!checkFinancingMainPage(user)) {
    return true;
  }
  if (!checkPaymentsMainPage(user)) {
    return true;
  }
  if (!checkCollectionsMainPage(user)) {
    return true;
  }
  return false;
};
