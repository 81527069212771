import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getCompleteTGR } from '../../../../../lib/api/dashboard';

const TGRGrouped: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState<any>([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState<any>([]);

  const fetchRelations = async () => {
    console.log('data');

    setLoading(true);
    const data = await getCompleteTGR(dispatch);
    console.log(data);
    setBusinesses(data || []);
    setFilteredBusinesses(data || []);
    setLoading(false);
  };
  useEffect(() => {
    fetchRelations();
  }, []);

  const onClickAgreements = (key: string, id: string) => {
    window.location.href = `/companies/${id}/tgr/agreements`;
  };

  const onClickProceedings = (key: string, id: string) => {
    window.location.href = `/companies/${id}/tgr/collections`;
  };

  const onClickDebts = (key: string, id: string) => {
    window.location.href = `/companies/${id}/tgr/debts`;
  };

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredBusinesses(businesses);
    console.log(newValue);
    const filtered = businesses.filter((x: any) => x.id === newValue.id);
    setFilteredBusinesses(filtered);
  };

  const autoCompleteFilter = (
    <Autocomplete
      options={businesses}
      getOptionLabel={(option) =>
        `${option.businessName} - ${option.businessIdentifier}`
      }
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás un resumen de la información de TGR"
        variant="body1"
        type="subHeader"
        item={autoCompleteFilter}
      />
      <EnhancedTable
        exportToFile="xlsx"
        dataHeaders={[
          { key: 'businessName', label: 'Cliente' },
          { key: 'businessIdentifier', label: 'Rut' },
          { key: 'dueDebts', label: 'Folios vencidos', onClick: onClickDebts },
          { key: 'agreements', label: 'Convenios', onClick: onClickAgreements },
          {
            key: 'dueAgreementsCuotas',
            label: 'Cuotas vencidas',
            onClick: onClickAgreements,
          },
          {
            key: 'tgrProceedings',
            label: 'Cobranzas',
            onClick: onClickProceedings,
          },
          {
            key: 'foliosInSuspension',
            label: 'Suspensión',
            onClick: onClickProceedings,
          },
          {
            key: 'foliosInNotification',
            label: 'Notificados',
            onClick: onClickProceedings,
          },
          {
            key: 'foliosInFreeze',
            label: 'Embargo',
            onClick: onClickProceedings,
          },
          { key: 'alert', label: 'Alerta', type: 'alert' },
        ]}
        data={filteredBusinesses}
        rowsPerPageDefault={25}
      />
    </div>
  );
};

export default TGRGrouped;
