import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { AddCircle, CancelOutlined, Edit } from '@mui/icons-material';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import { LightTooltip } from '../../../../../../../../../../../lib/designSystem/WhiteTooltip';
import { circleColor } from '../Visualization';

import { NodeValueChangeDiv, operatorTypes } from '../NewNodeDialog';
import TooltipContent from '../Visualization/components/explanationTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    nodesContainer: {
      height: '600px',
      overflowY: 'auto',
    },
    addContainer: {
      backgroundColor: 'ffffff',
      alignItems: 'center',
      width: '100%',
    },
    container: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const NodesEditables = (props: any) => {
  const {
    setDecisionNodesAssignation,
    decisionNodesAssignation,
    setOpenAddNode,
  } = props;
  const classes = useStyles();
  const [editNode, setEditNode] = useState<any>(null);
  const [canSave, setCanSave] = useState(true);

  const canDeleteNode = (node: any) => node.ranges.length === 0;

  const deleteNode = (node: any) => {
    const father = decisionNodesAssignation.find((x: any) =>
      x.ranges.map((y: any) => y.nodeId).includes(node.id),
    );
    if (!father) {
      alert('No se puede eliminar el nodo');
      return;
    }
    const nodes = decisionNodesAssignation.filter((x: any) => x.id !== node.id);
    const nodesWithoutFatherRangeToNode = nodes.map((x: any) => {
      if (x.id === father.id) {
        x.ranges = x.ranges.filter((y: any) => y.nodeId !== node.id);
      }
      return x;
    });
    setDecisionNodesAssignation(nodesWithoutFatherRangeToNode);
    setEditNode(null);
  };

  const handleEditNode = (node: any) => {
    const fatherNodeId = decisionNodesAssignation.find((x: any) =>
      x.ranges.map((y: any) => y.nodeId).includes(node.id),
    );

    setEditNode({
      ...node,
      fatherNodeId: fatherNodeId ? fatherNodeId.id : 0,
      value: node.value,
      editingOperation: node.operation,
    });
  };

  const handleSaveEditValueNode = () => {
    const nodesUpdated = decisionNodesAssignation.map((x: any) => {
      if (x.id === editNode.id) {
        return {
          ...x,
          value: editNode.value,
          operation: editNode.editingOperation,
        };
      }
      if (x.id === editNode.fatherNodeId) {
        x.ranges = x.ranges.map((y: any) => {
          if (y.nodeId === editNode.id) {
            y.value = editNode.value;
          }
          return y;
        });
      }
      return x;
    });
    setDecisionNodesAssignation(nodesUpdated);
    setEditNode(null);
  };

  return (
    <div className={classes.container}>
      <div className={classes.addContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4">Evaluaciones agregadas</Typography>
          <IconButton onClick={() => setOpenAddNode(true)}>
            <AddCircle color="primary" />
          </IconButton>
        </div>
        <div className={classes.nodesContainer}>
          {decisionNodesAssignation.map((x: any, index: any) => {
            return (
              <LightTooltip
                title={<TooltipContent nodeDatum={x} />}
                placement="left"
                key={index}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#ffffff',
                    padding: '10px 10px',
                    borderRadius: '20px',
                    margin: '10px 0px',
                    border: `1px solid ${circleColor(x)}`,
                  }}
                >
                  <Typography variant="body1" fontWeight="400">
                    {x.id} |{' '}
                    {`${x.name.slice(0, 35)}${x.name.length > 35 ? '...' : ''}`}
                  </Typography>
                  <div>
                    <IconButton
                      onClick={() => handleEditNode(x)}
                      style={{ padding: 0 }}
                    >
                      <Edit />
                    </IconButton>
                    {canDeleteNode(x) && (
                      <IconButton
                        onClick={() => deleteNode(x)}
                        style={{ padding: 0 }}
                      >
                        <CancelOutlined />
                      </IconButton>
                    )}
                  </div>
                </div>
              </LightTooltip>
            );
          })}
          {decisionNodesAssignation.length === 0 && (
            <div style={{ padding: '20px 0px' }}>
              <Typography variant="body1" gutterBottom>
                No hay nodos agregados
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Dialog open={editNode !== null} onClose={() => setEditNode(null)}>
        <div style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Editar nodo
          </Typography>
          <Typography variant="body1" margin="18px 0px">
            {editNode?.id} | {editNode?.name}
          </Typography>
          {editNode?.fatherNodeId !== 0 && editNode && (
            <NodeValueChangeDiv
              newNode={editNode}
              setNewNode={setEditNode}
              decisionNodesAssignation={decisionNodesAssignation}
              canSave={canSave}
              setCanSave={setCanSave}
              baseCase={editNode?.value === '' ? 'baseCase' : 'newValue'}
            />
          )}
          {editNode?.type !== 'final' && (
            <div style={{ margin: '20px 0px' }}>
              <Typography variant="body1" margin="18px 0px">
                Esto modificará el operador con que este nodo ejecutará su
                decisión
              </Typography>

              <FormControl fullWidth>
                <InputLabel id="operation-label">Operador</InputLabel>
                <Select
                  labelId="operation-label"
                  id="operation"
                  label="Operador"
                  value={editNode?.editingOperation || ''}
                  onChange={(e: any) =>
                    setEditNode({
                      ...editNode,
                      editingOperation: e.target.value,
                    })
                  }
                  fullWidth
                >
                  {operatorTypes.map((x: any, index: any) => (
                    <MenuItem key={index} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
        <DialogActions>
          <CustomButton onClick={() => setEditNode(null)} color="secondary">
            Cancelar
          </CustomButton>
          <CustomButton disabled={!canSave} onClick={handleSaveEditValueNode}>
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NodesEditables;
