import { requestHandler } from '../../api';

interface CollectionManagementStates {
  name: string;
  collectionManagementOptions: { name: string }[];
}

export const getCollectionManagementStates = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/states`,
    dispatch,
  });
  if (response) return response.data;
};

export const createCollectionManagementStates = async (
  dispatch: any,
  data: CollectionManagementStates,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/collection/states`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCollectionManagementState = async (
  dispatch: any,
  id: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/collection/states/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCollectionManagementOptionsByStateId = async (
  dispatch: any,
  collectionStateId: number,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/options/state/${collectionStateId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateCollectionManagementState = async (
  dispatch: any,
  id: number,
  name: string,
  collectionManagementOptions: any,
) => {
  const response = await requestHandler({
    method: 'put',
    url: `internal/collection/states/${id}`,
    dispatch,
    data: { name, collectionManagementOptions },
  });
  if (response) return response.data;
};
