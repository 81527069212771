import React from 'react';
import { Divider, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface DialogTitleProps {
  children: React.ReactNode;
  setOpen: (value: boolean) => void;
}

const DialogTitle: React.FC<DialogTitleProps> = ({ children, setOpen }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '4px 20px',
        }}
      >
        <Typography variant="h4">{children}</Typography>
        <IconButton aria-label="close" onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </div>
      <Divider />
    </div>
  );
};

export default DialogTitle;
