import { Cancel, Check, Warning } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { formatMoney } from '../../../../../../../../../../../lib/functions/utils';

const color = (node: any) => {
  switch (node.type) {
    case 'final':
      switch (node.name) {
        case 'Aprobado':
          return <Check style={{ color: '#3bc14a' }} />;
        case 'Rechazado':
          return <Cancel style={{ color: '#D62839' }} />;
        default:
          return <Warning style={{ color: '#E8C547' }} />;
      }
    default:
      return '#0F23DF';
  }
};

function getPreviousRange(
  decisionNodesSortedBySeverity: any,
  node: any,
  index: number,
) {
  console.log('decisionNodesSortedBySeverity', decisionNodesSortedBySeverity);
  // Loop backwards from the given index to find the first valid previous node
  for (let i = index - 1; i >= 0; i--) {
    if (
      decisionNodesSortedBySeverity[i] &&
      decisionNodesSortedBySeverity[i].value !== null
    ) {
      const decisionNode = decisionNodesSortedBySeverity[i];
      const previousRange = node.ranges.find(
        (range: any) => range.nodeId === decisionNode.id,
      );
      if (previousRange) {
        return previousRange;
      }
    }
  }

  // If no valid node is found, return null id object
  return null;
}

const MatrixVisualization = ({
  nodes,
  decisionNodes,
  comiteesAvailables,
}: any) => {
  console.log('nodes', nodes);
  console.log('decisionNodes', decisionNodes);
  console.log('comiteesAvailables', comiteesAvailables);

  const decisionResultsInNodes = nodes
    .map((node: any) => {
      return node.ranges
        .map((range: any) => {
          return range.nodeId;
        })
        .flat();
    })
    .flat();
  const decisionNodesToDisplay = decisionNodes.filter((decisionNode: any) =>
    decisionResultsInNodes.includes(decisionNode.id),
  );

  const decisionNodesSortedBySeverity = decisionNodesToDisplay.sort(
    (a: any, b: any) => {
      return a.severity - b.severity;
    },
  );

  console.log('decisionNodesSortedByComitee', decisionNodesSortedBySeverity);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        overflowX: 'auto',
      }}
    >
      <div style={{ display: 'flex' }}>
        {decisionNodesSortedBySeverity.map((node: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                padding: '20px 0px 0px',
                display: 'flex',
                alignItems: 'center',
                minWidth: 200,
                textAlign: 'center',
              }}
            >
              {color(node)}
              <Typography marginLeft="8px" variant="h6" gutterBottom>
                {node.name}
              </Typography>
            </div>
          );
        })}
      </div>
      <div style={{ padding: '30px 0px' }}>
        {nodes.map((node: any, index: number) => {
          return (
            <div key={index} style={{ display: 'flex' }}>
              {decisionNodesSortedBySeverity.map(
                (decisionNode: any, index: number) => {
                  const range = node.ranges.find(
                    (range: any) => range.nodeId === decisionNode.id,
                  );
                  console.log(index);
                  console.log('range', range);
                  const previousRange = getPreviousRange(
                    decisionNodesSortedBySeverity,
                    node,
                    index,
                  );
                  const formattByType = (value: any) => {
                    switch (node.type) {
                      case 'boolean':
                        return value ? 'Si' : 'No';
                      case 'percentage':
                        return `${value}%`;
                      case 'amount':
                        return value;
                      case 'money':
                        if (value < 1000000) return `${formatMoney(value)}`;
                        return `${formatMoney(value / 1000000)}M`;
                      default:
                        return value;
                    }
                  };
                  return (
                    <div
                      key={index}
                      style={{
                        padding: '18px 0px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: 200,
                        textAlign: 'center',
                      }}
                    >
                      {range && (
                        <div>
                          {node.type === 'boolean' && (
                            <Typography variant="h6" gutterBottom>
                              {String(range.value) === '1' ? 'Si' : 'No'}
                            </Typography>
                          )}
                          {node.type !== 'boolean' && (
                            <Typography variant="h6" gutterBottom>
                              {`${previousRange ? formattByType(previousRange.value) : `${formattByType('0')}`}   a ${
                                range.value !== ''
                                  ? formattByType(range.value)
                                  : '∞'
                              }`}
                            </Typography>
                          )}
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatrixVisualization;
