// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../services/redux/authSlice'; // Import the action
import {
  authenticateUser,
  getNotifications,
  getUserProfile,
  markNotificationsAsRead,
} from '../../lib/api';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/store';
import HeaderWithActions from '../../lib/designSystem/HeaderWithActions';
import {
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon
import {
  Check,
  CheckBoxOutlineBlank,
  Close,
  IosShare,
  RemoveRedEye,
} from '@mui/icons-material';
import { format } from 'path';
import { formatDate } from '../../lib/functions/utils';
import { decrementPendingNotifications } from '../../services/redux/notificactionSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const Notifications = (props: any) => {
  const { drawer, setOpen } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState<any>([]);
  const auth = useSelector((state: RootState) => state.auth);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications(dispatch);
      console.log(response);
      setNotifications(response);
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsReaded = async (timestamp: number) => {
    setLoading(true);
    try {
      await markNotificationsAsRead(dispatch, { timestamps: [timestamp] });
      fetchNotifications();
      dispatch(decrementPendingNotifications());
    } catch (error) {}
    setLoading(false);
  };

  const markAllAsReaded = async () => {
    setLoading(true);
    try {
      const allUnReadTimestamps = notifications
        .filter((x: any) => !x.read)
        .map((x: any) => x.timestamp);
      await markNotificationsAsRead(dispatch, {
        timestamps: allUnReadTimestamps,
      });
      fetchNotifications();
      dispatch(decrementPendingNotifications());
    } catch (error) {}
    setLoading(false);
  };

  if (loading && !drawer) return <CenteredCircularProgress />;

  const shimmerAnimation = `
@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
}`;

  const paperStyle = {
    padding: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background:
      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
    backgroundSize: '80rem 100%', // Large size to ensure smooth animation
    animation: 'shimmer 2s linear infinite',
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant={drawer ? 'h6' : 'h4'}>
            Notificaciones (
            {loading ? '...' : notifications.filter((x: any) => !x.read).length}
            )
          </Typography>
          <Typography
            onClick={() => markAllAsReaded()}
            style={{
              color: '#0F23DF',
              fontWeight: 'bold',
              cursor: 'pointer',
              fontSize: drawer ? 12 : 18,
            }}
          >
            Marcar todas como leídas
          </Typography>
        </div>

        {drawer && (
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <Divider />
      {notifications.map((notification: any) => {
        return (
          <div
            style={{
              backgroundColor: notification.read ? '#f5f5f5' : '#e0e0e0',
            }}
            key={notification.id}
          >
            <div style={{ textAlign: 'right', padding: '4px 8px' }}>
              <Typography style={{ fontSize: drawer ? 12 : 18 }}>
                {formatDate(new Date(notification.timestamp), 'large')}
              </Typography>
            </div>
            <div
              style={{
                padding: '0px 10px 4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography
                  variant={drawer ? 'body1' : 'h6'}
                  style={{ fontSize: drawer ? 16 : 18 }}
                >
                  {notification.message}
                </Typography>
              </div>
              {!notification.read && (
                <div>
                  <IconButton
                    onClick={() => {
                      markAsReaded(notification.timestamp);
                    }}
                  >
                    <Check />
                  </IconButton>
                </div>
              )}
            </div>

            <Divider />
          </div>
        );
      })}

      {loading && drawer && (
        <div>
          <style>{shimmerAnimation}</style>
          <div style={paperStyle} />
        </div>
      )}
    </div>
  );
};

export default Notifications;
