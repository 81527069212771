import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import CreditLineStatus from '../../../../../../../../../common/creditLineStatus';
import { requestCreditLineValidated } from '../../../../../../../../../../../../../lib/api';

const ShoppingCartCreditLineMissingInfo = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setUp,
    setLoading,
  } = props;
  const dispatch = useDispatch();

  const handleValidate = async () => {
    setLoading(true);
    try {
      await requestCreditLineValidated(dispatch, {
        id: openRequest.id,
        async: true,
      });
      setOpenShoppingCart(false);
      setSuccessOpen(
        'Hemos marcado como validada la solicitud, ahora pasará a revisión de riesgos',
      );
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      {/* {missingInfo ? (
        <div>
          <Typography variant="body1">
            Acá podrás indicarle al cliente que archivos necesitas que suba o
            corrija, esto quedará guardado en la pestaña de chat para próximas
            referencias.
          </Typography>
          <TextField
            label="¿Qué más necesitas saber?"
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            value={moreInfo}
            onChange={(e) => setMoreInfo(e.target.value)}
          />
          <DialogActions>
            <CustomButton
              onClick={() => setMissingInfo(false)}
              color="secondary"
            >
              Cancelar
            </CustomButton>
            <CustomButton onClick={() => handleSendInfo()} color="primary">
              Enviar
            </CustomButton>
          </DialogActions>
        </div>
      ) : ( */}
      <div>
        <CreditLineStatus openRequest={openRequest} audienceType="received" />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingCart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
          {/* <CustomButton onClick={() => setMissingInfo(true)} color="warning">
              Falta información
            </CustomButton> */}
          <CustomButton onClick={() => handleValidate()} color="primary">
            Validar
          </CustomButton>
        </DialogActions>
      </div>
      {/* )} */}
    </div>
  );
};

export default ShoppingCartCreditLineMissingInfo;
