import React, { useEffect, useState } from 'react';

import {
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { getDocumentsConciliationSuggestions } from '../../../../../../../../../../../lib/api/conciliations';
import EnhancedTable from '../../../../../../../../../../../lib/designSystem/Table';
import HeaderWithActions from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';

const ActiveDocuments = (props: any) => {
  const { businessIdentifier, setOpenShoppingCart } = props;
  const [loading, setLoading] = useState(false);
  const [documentsSuggested, setDocumentsSuggested] = useState<any[]>([]);
  const [filteredDocumentsSuggested, setFilteredDocumentsSuggested] = useState<
    any[]
  >([]);
  const [filter, setFilter] = useState<any>({ status: '' });

  const dispatch = useDispatch();

  const setUp = async () => {
    if (!businessIdentifier) return;
    setLoading(true);
    try {
      const queryParams = `operationDate=${new Date().toISOString()}`;
      const response = await getDocumentsConciliationSuggestions(
        dispatch,
        {
          businessIdentifier,
        },
        queryParams,
      );
      console.log(response);
      const factoringDocuments = response.documentsPendings.filter(
        (document: any) => document.type === 'Factura electrónica',
      );
      setDocumentsSuggested(factoringDocuments);
      setFilteredDocumentsSuggested(factoringDocuments);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleFilter = (value: string, type: string) => {
    const filterHash = filter;
    filterHash[type] = value;
    const allFiltersAreEmpty = Object.values(filterHash).every(
      (value) => value === '',
    );
    if (allFiltersAreEmpty) {
      setFilter({ status: '' });
      setFilteredDocumentsSuggested(documentsSuggested);
    } else {
      setFilter(filterHash);
      const filtered = documentsSuggested.filter((document) => {
        return Object.entries(filterHash).every(([key, value]) => {
          if (value === '') return true;
          return document[key] === value;
        });
      });
      setFilteredDocumentsSuggested(filtered);
    }
  };

  const Filter = () => (
    <FormControl fullWidth style={{ width: '200px' }}>
      <InputLabel id="filter-label">Filtrar por estado</InputLabel>
      <Select
        fullWidth
        value={filter['status']}
        onChange={(e) => handleFilter(e.target.value as string, 'status')}
        label={'Filtrar por estado'}
      >
        <MenuItem value={''}>Todos</MenuItem>
        <MenuItem value={'Activo'}>Vigente</MenuItem>
        <MenuItem value={'En mora'}>Mora</MenuItem>
      </Select>
    </FormControl>
  );

  // const Filter2 = () => (
  //   <FormControl fullWidth style={{ width: '150px' }}>
  //     <InputLabel id="filter-label">Filtrar por Tipo</InputLabel>
  //     <Select
  //       fullWidth
  //       value={filter['subType']}
  //       onChange={(e) => handleFilter(e.target.value as string, 'subType')}
  //       label={'Filtrar por Tipo'}
  //     >
  //       <MenuItem value={''}>Todos</MenuItem>
  //       {documentsSuggested.map((document: any, index: number) => {
  //         return (
  //           <MenuItem key={index} value={document.subType}>
  //             {document.subType}
  //           </MenuItem>
  //         );
  //       })}
  //     </Select>
  //   </FormControl>
  // );

  if (loading && !businessIdentifier)
    return <CenteredCircularProgress type="layout" />;
  if (loading && businessIdentifier)
    return <CenteredCircularProgress relative />;

  return (
    <div>
      <div>
        <HeaderWithActions
          title="Documentos"
          variant={'body1'}
          buttons={[]}
          item={
            // <div
            //   style={{ display: 'flex', alignItems: 'center', minWidth: 300 }}
            // >
            //   <div style={{ marginRight: 10 }}>
            <Filter />
            //   </div>
            //   <Filter2 />
            // </div>
          }
        />
        <EnhancedTable
          data={filteredDocumentsSuggested}
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'type', label: 'Fuente' },
            { key: 'subType', label: 'Tipo' },
            { key: 'stakeholderName', label: 'Deudor' },
            { key: 'factoringRequestId', label: 'Solicitud' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'arrearAmount', label: 'En mora', type: 'money' },
            { key: 'amountConciliated', label: 'Conciliado', type: 'money' },
            { key: 'totalRemaining', label: 'Pendiente', type: 'money' },
          ]}
          finalSumRow
        />
      </div>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          variant="outlined"
          color="secondary"
        >
          Cerrar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default ActiveDocuments;
