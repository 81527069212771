import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getProspectsData } from '../../../../../lib/api/dashboard';
import SimpleLineChart from './components/LineChart';
import { makeStyles, createStyles } from '@mui/styles';
import { KeyboardArrowLeftOutlined } from '@mui/icons-material';

interface Prospects {
  businessIdentifier: string;
  businessName: string;
  '6M': number;
  '3M': number;
  '1M': number;
}

interface Payers {
  businessIdentifier: string;
  businessName: string;
  businessIdentifiers: string[];
}

interface SummaryBoxProps {
  title?: string;
  content?: React.JSX.Element;
  children?: any;
}

const useStyles: any = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #e0e0e0',
      padding: '10px 0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
    },
    numberBox: {
      border: '1px solid #e0e0e0',
      padding: '0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
      tableLayout: 'fixed', // this can help with column overflow issues
      textAlign: 'center',
    },
  }),
);

const SummaryBox: React.FC<SummaryBoxProps> = ({
  content,
  children,
  title,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <Typography margin={'8px 12px'} variant="body1" fontWeight={'bold'}>
        {title}
      </Typography>
      {content}
      {children}
    </div>
  );
};

const Prospects: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payersMainData, setPayersMainData] = useState<Payers[]>([]);
  const [prospectsMainTable, setProspectsMainTable] = useState<Prospects[]>([]);
  const [filteredProspectsMainTable, setFilteredProspectsMainTable] = useState<
    Prospects[]
  >([]);
  const [monthlyData, setMonthlyData] = useState<any>({});
  const [graphData, setGraphData] = useState<any>({});
  const [factoringTableData, setFactoringTableData] = useState<any>([]);
  const [factoringGraphData, setFactoringGraphData] = useState<any>([]);
  const [payersTableData, setPayersTableData] = useState<any>([]);
  const [payersGraphData, setPayersGraphData] = useState<any>([]);
  const [bigOpenProspects, setBigOpenProspects] = useState(true);
  const [selectedProspect, setSelectedProspect] = useState<any>(null);
  const [selectedFactoringMonths, setSelectedFactoringMonths] = useState<
    string[]
  >([]);
  const [selectedPayersMonths, setSelectedPayersMonths] = useState<string[]>(
    [],
  );

  const fetchRelations = async () => {
    console.log('data');

    setLoading(true);
    const data = await getProspectsData(dispatch);
    setPayersMainData(data.payersMainData);
    setGraphData(data.graphData);
    setProspectsMainTable(data.prospectMainTable);
    setFilteredProspectsMainTable(data.prospectMainTable);
    setMonthlyData(data.monthlyData);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  useEffect(() => {
    if (selectedProspect) {
      const newFactoringTableData = calculateFactoringTableData();
      setFactoringTableData(newFactoringTableData);

      const newPayersTableData = calculatePayersTableData();
      setPayersTableData(newPayersTableData);
    }
  }, [selectedFactoringMonths, selectedPayersMonths, selectedProspect]);

  const calculateFactoringTableData = () => {
    if (!selectedProspect) return [];
    const businessId = selectedProspect.businessIdentifier;

    const monthlyFactoringData = monthlyData[businessId]?.byFactoring || {};

    if (!selectedFactoringMonths.length) return monthlyFactoringData.TOTAL;

    const result: any = {};
    selectedFactoringMonths.forEach((month: string) => {
      if (monthlyFactoringData[month]) {
        monthlyFactoringData[month].forEach((entry: any) => {
          if (!result[entry.identifier]) {
            result[entry.identifier] = { ...entry, amount: 0 };
          }
          result[entry.identifier].amount += entry.amount;
        });
      }
    });

    return Object.values(result);
  };

  const calculatePayersTableData = () => {
    if (!selectedProspect) return [];
    const businessId = selectedProspect.businessIdentifier;

    const monthlyPayersData = monthlyData[businessId]?.byStakeholder || {};

    if (!selectedPayersMonths.length) return monthlyPayersData.TOTAL;

    const result: any = {};
    selectedPayersMonths.forEach((month: string) => {
      if (monthlyPayersData[month]) {
        monthlyPayersData[month].forEach((entry: any) => {
          if (!result[entry.identifier]) {
            result[entry.identifier] = { ...entry, amount: 0 };
          }
          result[entry.identifier].amount += entry.amount;
        });
      }
    });

    return Object.values(result);
  };

  const selectProspect = (key: string, id: string) => {
    const newFactoringBaseData = monthlyData[id].byFactoring;
    setFactoringTableData(newFactoringBaseData.TOTAL);
    const newFactoringBaseDataGraph = graphData[id].byFactoring;
    setFactoringGraphData(newFactoringBaseDataGraph);

    const newPayersBaseData = monthlyData[id].byStakeholder;
    setPayersTableData(newPayersBaseData.TOTAL);
    const newPayersBaseDataGraph = graphData[id].byPayer;
    setPayersGraphData(newPayersBaseDataGraph);

    const prospect = prospectsMainTable.find(
      (x: any) => x.businessIdentifier === id,
    );
    setSelectedProspect(prospect);
    setBigOpenProspects(false);
    setSelectedFactoringMonths([]); // Reset selected months when a new prospect is selected
    setSelectedPayersMonths([]); // Reset selected months when a new prospect is selected
  };

  const handleChange = (event: any, newValue: any) => {
    if (!newValue) return setFilteredProspectsMainTable(prospectsMainTable);

    const filtered = prospectsMainTable.filter((x: any) =>
      newValue.businessIdentifiers.includes(x.businessIdentifier),
    );
    setFilteredProspectsMainTable(filtered);
  };

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá podrás ver dónde ceden los proveedores de tu cartera"
        variant="body"
      />
      {bigOpenProspects ? (
        <div style={{ padding: '0px 0px 4px' }}>
          <Typography variant="body1">
            Para ver detalles, selecciona un prospecto de la tabla
          </Typography>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            padding: '0px 0px 4px',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setBigOpenProspects(!bigOpenProspects)}>
            <KeyboardArrowLeftOutlined />
          </IconButton>
          <Typography margin={'0px 4px 0px'} fontWeight="bold">
            {selectedProspect.businessName} (
            {selectedProspect.businessIdentifier})
          </Typography>
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item xs={bigOpenProspects ? 12 : 2}>
          <Autocomplete
            options={payersMainData}
            getOptionLabel={(option) =>
              `${option.businessName} - ${option.businessIdentifier}`
            }
            renderInput={(params) => (
              <TextField {...params} label="Filtrar por cuenta enrolada" />
            )}
            onChange={handleChange}
            fullWidth
          />
          <div style={{ padding: '8px 0px' }}>
            <EnhancedTable
              exportToFile="xlsx"
              dataHeaders={[
                {
                  key: 'businessName',
                  label: 'Prospecto',
                  onClick: selectProspect,
                },
                ...(bigOpenProspects
                  ? [
                      { key: 'businessIdentifier', label: 'Rut' },
                      { key: '12M', label: '12M', type: 'money' },
                      { key: '6M', label: '6M', type: 'money' },
                      { key: '3M', label: '3M', type: 'money' },
                      { key: '1M', label: '1M', type: 'money' },
                    ]
                  : []),
              ]}
              data={filteredProspectsMainTable}
              rowsPerPageDefault={25}
            />
          </div>
        </Grid>

        {!bigOpenProspects && (
          <Grid item xs={10}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={8}>
                <SummaryBox
                  title={'Factoring'}
                  content={
                    <SimpleLineChart
                      aspect={4.0 / 1}
                      data={factoringGraphData}
                      selectedMonths={selectedFactoringMonths}
                      setSelectedMonths={setSelectedFactoringMonths}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTable
                  exportToFile="xlsx"
                  dataHeaders={[
                    { key: 'name', label: 'Factoring ' },
                    { key: 'amount', label: 'Monto', type: 'money' },
                  ]}
                  data={factoringTableData}
                  rowsPerPageDefault={5}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} paddingTop={2}>
              <Grid item xs={8}>
                <SummaryBox
                  title={'Pagadores'}
                  content={
                    <SimpleLineChart
                      aspect={4.0 / 1}
                      data={payersGraphData}
                      selectedMonths={selectedPayersMonths}
                      setSelectedMonths={setSelectedPayersMonths}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTable
                  exportToFile="xlsx"
                  dataHeaders={[
                    { key: 'name', label: 'Pagador ' },
                    { key: 'amount', label: 'Monto', type: 'money' },
                  ]}
                  data={payersTableData}
                  rowsPerPageDefault={5}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Prospects;
