import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { formatMoney } from '../../../../../../lib/functions/utils';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getCreditLineDetails } from '../../../../../../lib/api';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import EnhancedTab from '../../../../../../lib/designSystem/TabSimulation';

const StakeholderConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'businessName', label: 'Nombre' },
          { key: 'businessIdentifier', label: 'RUT' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'amount', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.stakeholderConcentrationUses
            : []
        }
      />
    </div>
  );
};

const SubProductConcentrations = (props: any) => {
  const { creditLineAssignation } = props;
  return (
    <div style={{ padding: '10px 0px' }}>
      <EnhancedTable
        dataHeaders={[
          { key: 'subProductName', label: 'Subproducto' },
          { key: 'amountUsed', label: 'En uso', type: 'money' },
          { key: 'amountArrear', label: 'Mora', type: 'money' },
          { key: 'concentrationLimit', label: 'Límite', type: 'money' },
        ]}
        data={
          creditLineAssignation
            ? creditLineAssignation.subProductConcentrationUses
            : []
        }
      />
    </div>
  );
};

const CreditLineAssignation = (props: any) => {
  const { openRequest } = props;

  const [loading, setLoading] = useState(false);
  const [creditLineAssignation, setCreditLineAssignation] = useState<any>({
    stakeholderConcentrationUses: [],
    subProductConcentrationUses: [],
  });
  const [startValue, setStartValue] = useState(0);

  const dispatch = useDispatch();

  const setUpCreditLineAssignation = async () => {
    setLoading(true);
    try {
      console.log(openRequest.businessIdentifier);
      const response = await getCreditLineDetails(dispatch, {
        creditLineId: openRequest.id,
      });
      setCreditLineAssignation(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setUpCreditLineAssignation();
  }, []);

  const handleOpen = (id: string) => {};

  const tabs = [
    {
      index: 0,
      label: 'Deudor',
      component: (
        <StakeholderConcentrations
          creditLineAssignation={creditLineAssignation}
        />
      ),
    },
    {
      index: 0,
      label: 'Sub Producto',
      component: (
        <SubProductConcentrations
          creditLineAssignation={creditLineAssignation}
        />
      ),
    },
  ];
  const amountStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 0px',
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div style={{ padding: '0px 0px' }}>
      <div style={{ padding: '8px 0px' }}>
        <Typography variant="h6" fontWeight="bold">
          Asignación
        </Typography>
        <div style={amountStyle}>
          <Typography variant="body1">
            <b>Monto</b>
          </Typography>
          <Typography variant="body1">
            {formatMoney(openRequest.amount)}
          </Typography>
        </div>
        <div style={amountStyle}>
          <Typography variant="body1">
            <b>Utilizado</b>
          </Typography>
          <Typography variant="body1">
            {formatMoney(creditLineAssignation.amountUsed)}
          </Typography>
        </div>
        <div style={amountStyle}>
          <Typography variant="body1">
            <b>Mora</b>
          </Typography>
          <Typography variant="body1">
            {formatMoney(creditLineAssignation.amountArrear)}
          </Typography>
        </div>
      </div>
      <EnhancedTab startValue={startValue} tabs={tabs} />
    </div>
  );
};

export default CreditLineAssignation;
