import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginFormExpress from './pages/LoginForm/Express/LogInExpress';
import SigninExpress from './pages/LoginForm/Express/SignInExpress';
import PasswordRecoveryExpress from './pages/LoginForm/Express/PasswordRecovery';
import ExpressLayout from './layouts/ExpressLayout';
import ConfirmUserRegistration from './pages/PublicLandings/ConfirmUseRegistration';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ExpressLayout>
              <LoginFormExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/confirmRegistration/:code"
          element={
            <ExpressLayout>
              <ConfirmUserRegistration />
            </ExpressLayout>
          }
        />
        <Route
          path="/sign-in"
          element={
            <ExpressLayout>
              <SigninExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/password-recovery"
          element={
            <ExpressLayout>
              <PasswordRecoveryExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/*"
          element={
            <ExpressLayout>
              <LoginFormExpress />
            </ExpressLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
