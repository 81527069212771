import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogActions, Typography, Checkbox, TextField } from '@mui/material';
import {
  getMissingFactoringApprovals,
  saveDecisionEngineAssignation,
} from '../../../../../../../../../../../lib/api';
import { DECISION_NODES } from '../../../../../../../../../../../lib/consts';
import { decrementPendingRequests } from '../../../../../../../../../../../services/redux/notificactionSlice';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
// import { CustomButtonProps } from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import MissingApprovalContainer from './MissingApprovalContainer';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import ComiteeUpdateDialog from './comiteeUpdate';
import DeleteComiteRevision from './DeleteRevision';
import { validatePermissions } from '../../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import { CheckCircle } from '@mui/icons-material';

const FactoringApprovalModule = (props: any) => {
  const {
    setOpenShoppingCart,
    selectedRequest,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const [sure, setSure] = useState(false);
  // const [openMissingInfo, setOpenMissingInfo] = useState(false);
  // const [moreInfo, setMoreInfo] = useState('');
  const [requestReason, setRequestReason] = useState('');
  const [canApprove, setCanApprove] = useState(false);
  const [missingApprovals, setMissingApprovals] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openComiteeUpdate, setOpenComiteeUpdate] = useState(false);
  const [openDeleteComitee, setOpenDeleteComitee] = useState(false);
  const [comitee, setComitee] = useState<any[]>([]);
  const [approvalsFromBusiness, setApprovalsFromBusiness] = useState<any[]>([]);
  const [approvalsFromCompany, setApprovalsFromCompany] = useState<any[]>([]);
  const [table, setTable] = useState<any>('comitee');

  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();
  const setUpStartingInvoiceEvaluation = async () => {
    setLoading(true);
    try {
      const { rolesToApprove, comitee, businessApprovals, approvals } =
        await getMissingFactoringApprovals(dispatch, {
          id: selectedRequest.firstQuote.id,
        });
      setComitee(comitee);
      setApprovalsFromBusiness(businessApprovals);
      setApprovalsFromCompany(approvals);
      setMissingApprovals(rolesToApprove);
      const canApprove = rolesToApprove.filter((approval: any) => {
        return approval.userCanApprove;
      });
      setCanApprove(canApprove.length > 0);
      console.log('missingApprovals', rolesToApprove);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const canSubmit = () => {
    return sure && requestReason.length;
  };

  const createRevisionStory = (value: number, reason: string) => {
    return [
      {
        node: 'RM: ' + reason,
        result: DECISION_NODES.APPROVED.id === value,
        nodeIdDecision: value,
      },
    ];
  };

  const canEdit = selectedRequest.status === 'En revisión';

  const handleSubmit = async (approve: boolean) => {
    if (!canSubmit()) {
      return alert('Favor de revisar los campos vacíos.');
    }
    setLoading(true);
    try {
      const resultsforRequest = {
        metadata: {
          quoteId: selectedRequest.quoteActive.id,
          result: approve,
        },
        results: [
          {
            reason: requestReason,
            quoteId: selectedRequest.quoteActive.id,
            decision: approve
              ? DECISION_NODES.APPROVED.id
              : DECISION_NODES.REJECTED.id,
            approve,
            revisionStory: createRevisionStory(
              approve ? DECISION_NODES.APPROVED.id : DECISION_NODES.REJECTED.id,
              requestReason,
            ),
          },
        ],
        revisionObjectiveId: 'factoring-request',
      };
      await saveDecisionEngineAssignation(dispatch, resultsforRequest);
      dispatch(decrementPendingRequests());
      setSuccessOpen('Se ha revisado la solicitud con éxito.');
      setOpenShoppingCart(false);
      await setUp();
    } catch (error) {
      setFailureOpen(true);
    }
    setLoading(false);
  };
  console.log(
    'disabled',
    !sure,
    !canApprove,
    comitee,
    !sure || !canApprove || !comitee,
  );

  const disabled = !sure || !canApprove || !comitee;

  useEffect(() => {
    setUpStartingInvoiceEvaluation();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <MissingApprovalContainer
        missingApprovals={missingApprovals}
        selectedRequest={selectedRequest}
        comitee={comitee}
        approvals={approvalsFromCompany}
        table={table}
        setTable={setTable}
      />
      {approvalsFromBusiness.length > 0 && (
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            padding: '10px',
          }}
        >
          <CheckCircle style={{ marginRight: '10px', color: 'green' }} />
          <Typography variant="body1">
            El cliente aprobó la solicitud
          </Typography>
        </div>
      )}
      {canApprove && (
        <div>
          <TextField
            label="Observaciones generales"
            variant="outlined"
            margin="dense"
            multiline
            fullWidth
            value={requestReason}
            onChange={(e) => setRequestReason(e.target.value)}
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0px',
            }}
          >
            <Checkbox
              color="primary"
              checked={sure}
              onClick={() => setSure(!sure)}
            />
            <Typography variant="body1">
              He revisado con detención y acepto que está evaluación quedará con
              mi nombre.
            </Typography>
          </div>
        </div>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Salir
        </CustomButton>
        {validatePermissions(user, 'comitee_change') && canEdit && (
          <CustomButton
            onClick={() => setOpenComiteeUpdate(true)}
            color="warning"
          >
            Comité
          </CustomButton>
        )}
        {validatePermissions(user, 'comitee_delete_approval') && canEdit && (
          <CustomButton
            onClick={() => setOpenDeleteComitee(true)}
            color="error"
          >
            Borrar revisión
          </CustomButton>
        )}
        {canApprove && (
          <CustomButton
            onClick={() => handleSubmit(false)}
            color="error"
            disabled={disabled}
          >
            Rechazar
          </CustomButton>
        )}
        {canApprove && (
          <CustomButton
            onClick={() => handleSubmit(true)}
            color="success"
            disabled={disabled}
          >
            Aprobar
          </CustomButton>
        )}
      </DialogActions>

      {openComiteeUpdate && (
        <ComiteeUpdateDialog
          selectedRequest={selectedRequest}
          open={openComiteeUpdate}
          setOpen={setOpenComiteeUpdate}
          setFailure={setFailureOpen}
          setUp={setUpStartingInvoiceEvaluation}
          setSuccess={setSuccessOpen}
          setBigOpen={setOpenShoppingCart}
          comitee={comitee}
          // setUp={setUp}
        />
      )}
      {openDeleteComitee && (
        <DeleteComiteRevision
          selectedRequest={selectedRequest}
          open={openDeleteComitee}
          setOpen={setOpenDeleteComitee}
          setFailure={setFailureOpen}
          setUp={setUpStartingInvoiceEvaluation}
          setSuccess={setSuccessOpen}
          setBigOpen={setOpenShoppingCart}
        />
      )}
    </div>
  );
};

export default FactoringApprovalModule;
