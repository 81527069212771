// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { makeStyles } from '@mui/styles';
import {
  Collapse,
  DialogActions,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import {
  getNotificationsProfile,
  updateNotificationsProfile,
} from '../../../lib/api/notifications';
import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import CustomButton from '../../../lib/designSystem/Button';
import { Cancel, Info, Settings } from '@mui/icons-material';
import { LightTooltip } from '../../../lib/designSystem/WhiteTooltip';
import { formatMoney } from '../../../lib/functions/utils';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '800px',
  },
  noPaddingMargin: {
    padding: '0p !important',
    margin: '0px',
  },
}));

const NotificationParametersConfiguration = (props: any) => {
  const { config, notifications, notificationId, setNotifications } = props;
  const classes = useStyles();

  const handleChange = (e: any) => {
    const newNotifications = notifications.map((notification: any) => {
      if (notification.id === notificationId) {
        const newConfig = notification.config.map((c: any) => {
          if (c.key === config.key) {
            return { ...c, value: e.target.value };
          }
          return c;
        });
        return { ...notification, config: newConfig };
      }
      return notification;
    });
    setNotifications(newNotifications);
  };

  const valueFormatter = (value: any) => {
    switch (config.type) {
      case 'money':
        return formatMoney(value);
      case 'boolean':
        return value === 'true' ? 'Sí' : 'No';
      default:
        return value;
    }
  };

  return (
    <div style={{ padding: '4px 0px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0px 0px 0px 36px',
        }}
      >
        <div>
          <LightTooltip
            title={
              <Typography variant="body1" style={{ padding: '8px' }}>
                {config.description}
              </Typography>
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body1"
                fontWeight={'bold'}
                marginRight={'4px'}
              >
                {config.name}
              </Typography>
              <Info style={{ fontSize: '14px', cursor: 'pointer' }} />
            </div>
          </LightTooltip>
        </div>
        <div>
          <FormControl
            margin="dense"
            fullWidth
            className={classes.noPaddingMargin}
          >
            <InputLabel id="filter-label">Parámetro</InputLabel>
            <Select
              sx={{
                '& .MuiSelect-select': {
                  paddingRight: 4,
                  paddingLeft: 2,
                  paddingTop: 2,
                  paddingBottom: 1,
                  margin: 0,
                  minWidth: '100px',
                },
              }}
              className={classes.noPaddingMargin}
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              margin="dense"
              value={config.value}
              onChange={(e) => handleChange(e)}
              label="Parámetro"
            >
              {config.values.map((x: any, index: any) => (
                <MenuItem key={index} value={x.value}>
                  {valueFormatter(x.value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

const NotificationType = (props: any) => {
  const { notifications, setNotifications, type } = props;
  const typeNotifications = notifications.filter(
    (notification: any) => notification.type === type,
  );

  const handleChangeSwitch = (id: any) => {
    const newNotifications = notifications.map((notification: any) => {
      if (notification.id === id) {
        return { ...notification, active: !notification.active };
      }
      return notification;
    });
    setNotifications(newNotifications);
  };
  return (
    <div style={{ padding: '12px 0px' }}>
      {typeNotifications.map((notification: any) => {
        const [openParamsConfig, setOpenParamsConfig] = useState(false);
        return (
          <div style={{ padding: '8px 0px' }} key={notification.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              key={notification.id}
            >
              <div key={notification.id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {notification.name}
                  </Typography>
                  <div>
                    <LightTooltip
                      title={
                        <Typography variant="body1" style={{ padding: '8px' }}>
                          {notification.config.length
                            ? !notification.active
                              ? 'No se puede configurar una notificación desactivada'
                              : 'Configurar parámetros'
                            : 'Esta notificación no es configurable'}
                        </Typography>
                      }
                    >
                      <div>
                        <IconButton
                          onClick={() => setOpenParamsConfig(!openParamsConfig)}
                          disabled={
                            !(notification.config.length && notification.active)
                          }
                        >
                          {openParamsConfig ? <Cancel /> : <Settings />}
                        </IconButton>
                      </div>
                    </LightTooltip>
                  </div>
                </div>
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={notification.active}
                    onChange={() => handleChangeSwitch(notification.id)}
                  />
                }
                label={notification.active ? 'Activado' : 'Desactivado'}
                labelPlacement="start"
              />
            </div>
            <Typography variant="body1">{notification.description}</Typography>
            <Collapse in={openParamsConfig && notification.active}>
              {notification.config.map((config: any) => (
                <NotificationParametersConfiguration
                  key={config.id}
                  notificationId={notification.id}
                  setNotifications={setNotifications}
                  config={config}
                  notifications={notifications}
                />
              ))}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

const Notifications = (props: any) => {
  const { setSuccess, setFailure } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [types, setTypes] = useState([]);
  const [startValue, setStartValue] = useState(0);

  const simulateTabs = () => {
    const tabs = [];
    for (const type of types) {
      tabs.push({
        id: type,
        label: type,
        component: (
          <NotificationType
            notifications={notifications}
            type={type}
            setNotifications={setNotifications}
          />
        ),
      });
    }
    return tabs;
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const configResponse = await getNotificationsProfile(dispatch);
      setNotifications(configResponse.notificationsProfile || []);
      setTypes(configResponse.typesAvailable || []);
      setStartValue(0);
    } catch (error) {
      setFailure('Error al cargar las notificaciones');
    }

    setLoading(false);
  };

  const handleSave = async () => {
    console.log(notifications);
    setLoading(true);
    try {
      await updateNotificationsProfile(dispatch, notifications);
      setSuccess('Notificaciones guardadas correctamente');
      await fetchNotifications();
    } catch (error) {
      console.log(error);
      // setFailure('Error al guardar las notificaciones');
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div className={classes.container}>
      <EnhancedBox>
        <Typography variant="body1" fontWeight="bold">
          Mis Notificaciones
        </Typography>
        <Typography
          variant="body1"
          margin={'16px 0px'}
          width={'100%'}
          style={{ textWrap: 'pretty' }}
        >
          Habilita y deshabilita las notificaciones que deseas recibir según los
          productos que tengas contratados. Recuerda que cada rol tiene
          notificaciones específicas.
        </Typography>
        <EnhancedTab startValue={startValue} tabs={simulateTabs()} />
        <DialogActions>
          <CustomButton onClick={() => handleSave()} color="primary">
            Guardar
          </CustomButton>
        </DialogActions>
      </EnhancedBox>
    </div>
  );
};

export default Notifications;
