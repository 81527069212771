import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatMoney } from '../../../../../../../lib/functions/utils';
import {
  graphNameTranslation,
  hexToRGBA,
} from '../../../../../../../lib/designSystem/BarChart';
import { Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';
const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const colorsData: { [key: string]: string } = {
  'Compras 12-24M': '#FFC400',
  'Compras últimos 12M': '#03B4DC',
  'NC/Reclamo 12-24M': '#B8336A',
  'NC/Reclamo últimos 12M': '#FF1744',
  'Ventas 12-24M': '#FFC400',
  'Ventas últimos 12M': '#07D2FE',
  'Ventas netas (Venta - NC/Reclamo) 12M': '#4CAF50',
  'Ventas netas (Venta - NC/Reclamo) 12-24M': '#23395B',
  'Factorizado ultimos 12 meses': '#07D2FE',
  'NC/Reclamo ultimos 12M': '#FF1744',
  'Factorizado ultimos 12-24 meses': '#0F23DF',
  'Últimos 12M': '#4CAF50',
  '12-24M': '#0F23DF',
};

const getCurrentYearLabel = (month: string, key: any) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // Note: getMonth() returns 0 for January, 11 for December
  const monthIndex = MONTH_NAMES.indexOf(month);
  const categoryKey = key.split(' ')[0];
  const label = ['Compras', 'Ventas', 'Factorizado', 'NC/Reclamo'].includes(
    categoryKey,
  )
    ? categoryKey
    : '';
  if (key.includes('12-24')) {
    if (monthIndex <= currentMonth) {
      return `${currentYear - 1} ${label}`;
    } else {
      return `${currentYear - 2} ${label}`;
    }
  } else {
    if (monthIndex <= currentMonth) {
      return `${currentYear} ${label}`;
    } else {
      return `${currentYear - 1} ${label} `;
    }
  }
};

const customTooltipFormatter = (value: any, name: any, props: any) => {
  const { payload } = props;
  if (!payload.name) return [value, name];
  const newLabel = getCurrentYearLabel(payload.name, name);
  return [formatMoney(value), newLabel];
};

const CustomLegend = ({ payload, onClick, visibleLines }: any) => {
  const tooltipTitle = (title: string) => (
    <div style={{ padding: '10px' }}>
      <Typography variant="body1">{title}</Typography>
    </div>
  );
  return (
    <div style={{ padding: '10px' }}>
      {payload.map((entry: any, index: any) => (
        <div key={index} onClick={(e) => onClick(entry, index, e)}>
          <LightTooltip title={tooltipTitle(entry.dataKey)} placement="right">
            <Circle
              style={{
                color: visibleLines.includes(entry.dataKey)
                  ? entry.color
                  : '#ccc',
                cursor: 'pointer',
                textDecoration: visibleLines.includes(entry.dataKey)
                  ? 'none'
                  : 'line-through',
              }}
            />
          </LightTooltip>
        </div>
      ))}
    </div>
  );
};

const SimpleLineChart = ({ data, aspect, handleSelectData, noMoney }: any) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [finalData, setFinalData] = useState<any>([]);
  const [visibleLines, setVisibleLines] = useState<string[]>([]);

  console.log(data);

  const handleLineClick = (data: any) => {
    if (!Object.keys(data).length) return;
    if (handleSelectData) {
      handleSelectData(data.activeLabel);
      if (selectedIndex === data.activeLabel) {
        setSelectedIndex(null);
      } else {
        setSelectedIndex(data.activeLabel);
      }
    }
  };

  const formatLineMoney = (value: number) => {
    if (value > 1000000000) return `${formatMoney(value / 1000000000)}MM`;
    if (value > 1000000) return `${formatMoney(value / 1000000)}M`;
    if (value > 1000) return `${formatMoney(value / 1000)}K`;
    return noMoney ? `${value}` : formatMoney(value);
  };

  const headers = new Set();

  useEffect(() => {
    const dataWithFilledBlanks = data.map((item: any) => {
      headers.forEach((header: any) => {
        if (item[header] === undefined) {
          item[header] = 0;
        }
      });
      return item;
    });

    setFinalData(dataWithFilledBlanks);
  }, [data]);

  useEffect(() => {
    if (finalData.length > 0) {
      setVisibleLines(
        Object.keys(finalData[0]).filter((key) => key !== 'month'),
      );
    }
  }, [finalData]);
  const handleLegendClick = (dataKey: string) => {
    setVisibleLines((prev) => {
      if (prev.includes(dataKey)) {
        // If only one line is visible and it's clicked again, show all lines
        if (prev.length === 1) {
          return Object.keys(finalData[0]).filter((key) => key !== 'month');
        } else {
          return [dataKey]; // Make the clicked line the only visible one
        }
      } else {
        // Add the clicked line to the list of visible lines
        return [...prev, dataKey];
      }
    });
  };
  const createLineArray = (data: any) => {
    const keys = Object.keys(data[0] || {}).filter(
      (key) => key !== 'name' && key !== 'year' && key !== 'dateKey',
    );
    return keys.map((key) => ({
      name: key,
      dataKey: key,
      stroke: colorsData[key],
    }));
  };

  const customDot = (props: any) => {
    const { cx, cy, stroke, payload } = props;
    const isSelected = payload.name === selectedIndex;
    const newStroke = !selectedIndex
      ? stroke
      : isSelected
        ? stroke
        : hexToRGBA(stroke, 0.5);

    return (
      <circle
        cx={cx}
        cy={cy}
        r={isSelected ? 6 : 2}
        fill={newStroke}
        stroke={stroke}
        strokeWidth={2}
      />
    );
  };

  const lineArrayDefault = createLineArray(data);

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer aspect={aspect}>
        <LineChart
          data={finalData.length ? finalData : data}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
          onClick={handleLineClick}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatLineMoney} />
          <Tooltip formatter={customTooltipFormatter} />
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            content={(props: any) => (
              <CustomLegend
                {...props}
                visibleLines={visibleLines}
                onClick={(e: any) => handleLegendClick(e.dataKey)}
              />
            )}
          />

          {lineArrayDefault.map((line: any) => (
            <Line
              type="monotone"
              key={line.name}
              name={graphNameTranslation(line.name)}
              dataKey={line.dataKey}
              stroke={line.stroke}
              fontSize={12}
              dot={customDot}
              hide={!visibleLines.includes(line.name)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleLineChart;
