import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  IconButton,
  Autocomplete,
  Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { AssignmentTurnedIn, Delete } from '@mui/icons-material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../../../../lib/designSystem/WhiteTooltip';
import { isDateAWeekDay } from '../../../../../../../../../lib/functions/dates';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
    },
    tableCell: {
      padding: '8px !important',
      overflow: 'hidden',
      minWidth: '50px',
      maxWidth: '180px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableCellTasa: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '50px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
      textAlign: 'right',
    },
    tableCellNames: {
      padding: '8px !important',
      overflow: 'hidden',
      maxWidth: '80px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    textField: {
      fontSize: '14px !important',
      padding: '8px !important',
    },
    textFieldAutocomplete: {
      fontSize: '14px !important',
      padding: '0px !important',
      minWidth: '120px !important',
    },
    iconButton: {
      padding: '5px',
    },
  }),
);

const ResellsTable = (props: any) => {
  const { resells, setResells, funds, canForcePaymentDate } = props;
  const classes = useStyles();

  const calculateDays = (start: Date, end: Date) => {
    const days = Math.floor(
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
    );
    return days;
  };

  const dateValidators = (date: Date, type: string, rowIndex: number) => {
    if (canForcePaymentDate) {
      const days = calculateDays(
        type === 'startDate' ? date : new Date(resells[rowIndex].startDate),
        type === 'endDate' ? date : new Date(resells[rowIndex].endDate),
      );
      if (days <= 0) {
        alert(
          'La fecha de vencimiento no puede ser menor a la fecha de liberación',
        );
        returnTempDateToActualDate(rowIndex);
        return false;
      }
      return days;
    }
    if (!isDateAWeekDay(date) && type === 'endDate') {
      alert('La fecha de vencimiento debe ser un día hábil');
      returnTempDateToActualDate(rowIndex);
      return false;
    }
    if (date < new Date() && type === 'endDate') {
      alert('La fecha de vencimiento no puede ser menor a la fecha actual');
      returnTempDateToActualDate(rowIndex);
      return false;
    }
    const days = calculateDays(
      type === 'startDate' ? date : new Date(resells[rowIndex].startDate),
      type === 'endDate' ? date : new Date(resells[rowIndex].endDate),
    );
    if (days <= 0) {
      alert(
        'La fecha de vencimiento no puede ser menor a la fecha de liberación',
      );
      returnTempDateToActualDate(rowIndex);
      return false;
    }
    return days;
  };

  const returnTempDateToActualDate = (rowIndex: number) => {
    const newResells = resells.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          tempEndDate: doc.endDate,
        };
      }
      return doc;
    });
    setResells(newResells);
  };

  const handleChangeTempDate = (e: any, type: string, rowIndex: number) => {
    const newResells = resells.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          [type]: e.target.value,
        };
      }
      return doc;
    });
    setResells(newResells);
  };

  const handleChangeDate = (value: any, type: string, rowIndex: number) => {
    const days = dateValidators(new Date(value), type, rowIndex);
    if (!days) return;
    const resell = resells[rowIndex];
    const newResells = resells.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          [type]: value,
          days,
          rateCost: (doc.amount * parseFloat(resell.rate) * days) / 30 / 100,
        };
      }
      return doc;
    });
    setResells(newResells);
  };

  const handleChangeRate = (e: any, rowIndex: number) => {
    const value = e.target.value;
    console.log('value', value);

    // Permitir cualquier valor que termina en un punto
    if (value.endsWith('.')) {
      const newResells = resells.map((doc: any, i: any) => {
        if (i === rowIndex) {
          return {
            ...doc,
            rate: value, // Guardar el valor temporalmente con el punto
            rateCost: (doc.amount * parseFloat(value) * doc.days) / 30 / 100,
          };
        }
        return doc;
      });
      setResells(newResells);
      return;
    }

    const numbered = parseFloat(value);

    // Validaciones de número
    if (isNaN(numbered) || numbered < 0 || numbered > 100) return;

    const newResells = resells.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          rate: numbered, // Guardar el valor parseado como número
          rateCost: (doc.amount * numbered * doc.days) / 30 / 100,
        };
      }
      return doc;
    });

    setResells(newResells);
  };

  const handleChangeFund = (e: any, value: any, rowIndex: number) => {
    const newResells = resells.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          fundId: value ? value.id : null,
          fund: value ? value : null,
        };
      }
      return doc;
    });
    setResells(newResells);
  };

  const assignConditionsForAllResells = () => {
    console.log(resells[0]);
    const newResells = resells.map((doc: any, i: any) => {
      if (i === 0) {
        return doc;
      }
      const rateCost =
        ((doc.amount * resells[0].rate) / 30 / 100) * resells[0].days;
      return {
        ...doc,
        rate: resells[0].rate,
        rateCost,
        startDate: resells[0].startDate,
        endDate: resells[0].endDate,
        tempEndDate: resells[0].endDate,
        fundId: resells[0].fundId,
        fund: resells[0].fund,
        days: resells[0].days,
      };
    });
    console.log(newResells);
    setResells([
      ...newResells.map((resell: any, index: any) => ({
        ...resell,
        key: index,
      })),
    ]); // Ensure a new array reference with unique keys
  };

  const deleteRow = (rowIndex: number) => {
    const newInvoices = resells.filter((doc: any, i: any) => i !== rowIndex);
    setResells(newInvoices);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead style={{ background: '#efefef' }}>
          <TableCell style={{ textAlign: 'right' }}></TableCell>

          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Folio
          </TableCell>
          <TableCell
            className={classes.tableCellTasa}
            style={{ textAlign: 'right' }}
          >
            Solicitud
          </TableCell>
          <TableCell
            className={classes.tableCellNames}
            style={{ textAlign: 'left' }}
          >
            Cliente
          </TableCell>
          <TableCell
            className={classes.tableCellNames}
            style={{ textAlign: 'left' }}
          >
            Deudor
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Monto
          </TableCell>

          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Liberación
          </TableCell>
          <TableCell
            className={classes.tableCellTasa}
            style={{ textAlign: 'right' }}
          >
            Tasa
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha inicio
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha vencimiento
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'center' }}
          >
            Fondo
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Días
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Diferencia precio
          </TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {resells.map((row: any, rowIndex: number) => {
            return (
              <React.Fragment key={rowIndex}>
                <TableRow>
                  <TableCell>
                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => deleteRow(rowIndex)}
                      >
                        <Delete />
                      </IconButton>
                      {rowIndex === 0 && (
                        <LightTooltip
                          placement="right"
                          title={
                            <Typography style={{ padding: 8 }}>
                              Asignar condiciones a todas
                            </Typography>
                          }
                        >
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => assignConditionsForAllResells()}
                          >
                            <AssignmentTurnedIn />
                          </IconButton>
                        </LightTooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.folio}
                  </TableCell>
                  <TableCell className={classes.tableCellTasa}>
                    {row.factoringRequestId}
                  </TableCell>
                  <TableCell className={classes.tableCellNames}>
                    {row.businessName}
                  </TableCell>
                  <TableCell className={classes.tableCellNames}>
                    {row.stakeholderName}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {formatMoney(row.amount)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatDate(row.paymentDate)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCellTasa}
                    style={{ textAlign: 'right' }}
                  >
                    <TextField
                      value={row.rate}
                      onChange={(e) => handleChangeRate(e, rowIndex)}
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    <TextField
                      value={row.startDate}
                      type="date"
                      onChange={(e) =>
                        handleChangeDate(e.target.value, 'startDate', rowIndex)
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    <TextField
                      value={row.tempEndDate}
                      type="date"
                      onChange={(e) =>
                        handleChangeTempDate(e, 'tempEndDate', rowIndex)
                      }
                      onBlur={() =>
                        handleChangeDate(
                          resells[rowIndex].tempEndDate,
                          'endDate',
                          rowIndex,
                        )
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    <Autocomplete
                      key={row.fundId || `fund-${rowIndex}`} // Clave única para forzar re-render
                      options={funds}
                      value={row.fund}
                      getOptionLabel={(option: any) =>
                        `${option.name} | ${option.id}`
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            classes: { input: classes.textFieldAutocomplete },
                          }}
                        />
                      )}
                      onChange={(e, value) =>
                        handleChangeFund(e, value, rowIndex)
                      }
                      fullWidth
                    />
                  </TableCell>

                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {row.days}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.rateCost)}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResellsTable;
