import React, { useState } from 'react';
import { DialogActions, Grid, TextField, Typography } from '@mui/material';
import { formatDate, formatMoney } from '../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../lib/designSystem/Button';
import PayersAssignation from './components/Payers';
import EnhancedTab from '../../../../../../lib/designSystem/TabSimulation';
import SubPrductAssingnation from './components/Subproducts';

const AssignationModule = (props: any) => {
  const {
    stakeholderConcentrations,
    setStakeholderConcentrations,
    subProductConcentrations,
    setSubProductConcentrations,
    setOpenShoppingCart,
    amount,
    setAmount,
    validUntil,
    setValidUntil,
    handleSubmit,
    payers,
    subProducts,
  } = props;
  console.log('AssignationModule rendered');

  const daysTillExpiration = validUntil
    ? (new Date(validUntil).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24)
    : 0;
  const [days, setDays] = useState(Math.floor(daysTillExpiration));

  const handleChangeDays = (value: any) => {
    if (value < 0) return;

    setDays(parseInt(value));
    const date = new Date();
    date.setDate(date.getDate() + parseInt(value));
    setValidUntil(date);
  };

  const handleChangeAmount = (value: any) => {
    const cleanValue = value.split('.').join('').split('$').join('');
    if (isNaN(cleanValue)) return;
    if (cleanValue < 0) return;
    setAmount(Number(cleanValue));
  };

  const startValue = 0;

  const tabs = [
    {
      index: 0,
      label: 'Deudores',
      component: (
        <PayersAssignation
          concentrations={stakeholderConcentrations}
          setConcentrations={setStakeholderConcentrations}
          amount={amount}
          payers={payers}
        />
      ),
    },
    {
      index: 0,
      label: 'Subproductos',
      component: (
        <SubPrductAssingnation
          concentrations={subProductConcentrations}
          setConcentrations={setSubProductConcentrations}
          amount={amount}
          subProducts={subProducts}
        />
      ),
    },
  ];

  // if (loading) {
  //   return <CenteredCircularProgress type="dialog" />;
  // }
  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Typography variant="body1">
          Acá debes ingresar el monto de la línea y si tiene concentraciones que
          la limitan
        </Typography>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Grid container spacing={2} alignItems={'top'}>
          <Grid item xs={6}>
            <Typography fontWeight={600} variant="body1">
              Monto total
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Monto"
              type="text"
              value={formatMoney(amount)}
              onChange={(e) => handleChangeAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Días para el vencimiento
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Días"
              type="number"
              value={days}
              onChange={(e) => handleChangeDays(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600} variant="body1">
              Fecha
            </Typography>
            <Typography marginTop={3} fontWeight={600} variant="body1">
              {validUntil ? formatDate(validUntil) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: '10px 0px' }}>
        <EnhancedTab startValue={startValue} tabs={tabs} title="Asignaciones" />
      </div>
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton onClick={() => handleSubmit()} color="primary">
          Enviar
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default AssignationModule;
