import React from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { Typography } from '@mui/material';

const SuperDebtResponse = (props: any) => {
  const { response, searched } = props;

  if (!Object.keys(response.rows).length)
    return (
      <div style={{ padding: '0px' }}>
        <Typography variant="body1">
          {searched ? 'No se encontraron resultados' : ''}{' '}
        </Typography>
      </div>
    );

  return (
    <EnhancedTable
      exportToFile="xlsx"
      dataHeaders={response.headers}
      data={response.rows}
      rowsPerPageDefault={20}
    />
  );
};

export default SuperDebtResponse;
