import React, { useEffect, useState } from 'react';
import DebtorChart from './components/DebtorChart';
import { Select, Typography, MenuItem } from '@mui/material';
import EnhacedBox from '../../../../../../../lib/designSystem/BigBox';
import { Grid } from '@mui/material';
import moment from 'moment';
import {
  getDebtorConcentrations,
  getFundsConcentrations,
} from '../../../../../../../lib/api/concentrations';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';

const MyWalletConcentrations = () => {
  const [debtorType, setDebtorType] = useState<
    'byCompany' | 'bySector' | 'bySalesSegment'
  >('byCompany');
  const [clientType, setClientType] = useState<
    'byCompany' | 'bySector' | 'bySalesSegment'
  >('byCompany');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, 'months').format('MM/DD/YYYY'),
  );
  const [concentrations, setConcentrations] = useState<any>([]);
  const [fundsConcentrations, setFundsConcentrations] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const retrieveData = async () => {
    setLoading(true);
    const response = await getDebtorConcentrations(dispatch, startDate);
    if (response) setConcentrations(response);
    const fundsResponse = await getFundsConcentrations(dispatch, startDate);
    if (fundsResponse) setFundsConcentrations(fundsResponse);
    setLoading(false);
  };

  useEffect(() => {
    if (startDate) {
      retrieveData();
    }
  }, [startDate]);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá puedes revisar como está colocada tu cartera."
        variant="body1"
        item={
          <Select
            label="Fecha de inicio"
            defaultValue={startDate}
            fullWidth
            style={{ minWidth: '300px', float: 'right' }}
            onChange={(e) => setStartDate(e.target.value)}
          >
            <MenuItem
              value={moment().subtract(1, 'month').format('MM/DD/YYYY')}
            >
              Último mes
            </MenuItem>
            <MenuItem
              value={moment().subtract(3, 'months').format('MM/DD/YYYY')}
            >
              Últimos 3 meses
            </MenuItem>
            <MenuItem
              value={moment().subtract(6, 'months').format('MM/DD/YYYY')}
            >
              Últimos 6 meses
            </MenuItem>
            <MenuItem
              value={moment().subtract(12, 'months').format('MM/DD/YYYY')}
            >
              Últimos 12 meses
            </MenuItem>
          </Select>
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EnhacedBox>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">Concentraciones por deudor</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="Tipo de concentrado"
                  defaultValue="byCompany"
                  fullWidth
                  style={{ maxWidth: '200px', float: 'right' }}
                  onChange={(e) =>
                    setDebtorType(
                      e.target.value as
                        | 'byCompany'
                        | 'bySector'
                        | 'bySalesSegment',
                    )
                  }
                >
                  <MenuItem value="byCompany">Por empresa</MenuItem>
                  <MenuItem value="bySector">Por sector</MenuItem>
                  <MenuItem value="bySalesSegment">
                    Por segmento de ventas
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <DebtorChart type={debtorType} data={concentrations.debtor} />
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={6}>
          <EnhacedBox>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">
                  Concentraciones por cliente
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="Tipo de concentrado"
                  defaultValue="byCompany"
                  fullWidth
                  style={{ maxWidth: '200px', float: 'right' }}
                  onChange={(e) =>
                    setClientType(
                      e.target.value as
                        | 'byCompany'
                        | 'bySector'
                        | 'bySalesSegment',
                    )
                  }
                >
                  <MenuItem value="byCompany">Por empresa</MenuItem>
                  <MenuItem value="bySector">Por sector</MenuItem>
                  <MenuItem value="bySalesSegment">
                    Por segmento de ventas
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <DebtorChart type={clientType} data={concentrations.client} />
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={12}>
          <EnhacedBox>
            <Typography variant="h5">Concentraciones por fondos</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <DebtorChart type={'byCompany'} data={fundsConcentrations} />
            </div>
          </EnhacedBox>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyWalletConcentrations;
