import React from 'react';

import { Dialog } from '@mui/material';
import EnhancedTab from '../../../../../../../lib/designSystem/TabSimulation';
import { cellFormat } from '../../../../../../../lib/designSystem/Table/cellFormat';
import FactoringExtensionMissingApprovalContainer from './components/Approval';
import ResellMain from './components/NewResell';
import ResellPayments from './components/Payments';

const ResellDetails = (props: any) => {
  const {
    resell,
    setResell,
    open,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    edit,
    setEdit,
  } = props;
  const startValue = 0;

  const newResell = !resell.status;

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <ResellMain
          resell={resell}
          setResell={setResell}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
          edit={edit}
          setEdit={setEdit}
        />
      ),
    },
    {
      index: 0,
      label: 'Aprobación',
      disabled: true,
      component: (
        <FactoringExtensionMissingApprovalContainer
          resell={resell}
          setResell={setResell}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
          edit={edit}
        />
      ),
    },
    {
      index: 0,
      disabled: newResell,
      label: 'Pagos',
      component: (
        <ResellPayments
          resell={resell}
          setResell={setResell}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
        />
      ),
    },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={resell.id ? `Venta #${resell.id}` : 'Nueva venta a fondo'}
          startValue={startValue}
          tabs={tabs}
          chip={cellFormat(resell.status || 'Nuevo', 'status')}
        />
      </div>
    </Dialog>
  );
};

export default ResellDetails;
