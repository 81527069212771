import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getBusiness } from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import SIIProfile from './SII';
import BusinessBalanceProfile from './FinancialStatements';
import Scoring from './Scoring';
import { AuthState } from '../../services/redux/authSlice';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../lib/functions/utils';
import TGRProfile from './TGR';
import Others from './Others';
import Profile from './Profile';
import { setInitialTab } from '../../lib/functions/setInitialTab';

export const profileTabs: any = {
  client: {
    id: 0,
    label: 'Cliente',
    component: <Profile />,
    mainPage: 'profile',
    subPage: 'summary',
    index: 0,
  },
  sii: {
    id: 1,
    label: 'SII',
    component: <SIIProfile />,
    mainPage: 'sii',
    subPage: 'summary',
    index: 1,
  },
  tgr: {
    id: 2,
    label: 'TGR',
    component: <TGRProfile />,
    mainPage: 'tgr',
    subPage: 'debts',
    index: 2,
  },
  financial: {
    id: 3,
    label: 'Balances',
    component: <BusinessBalanceProfile />,
    mainPage: 'financial',
    subPage: 'summary',
    subPaths: [],
    index: 3,
  },
  scoring: {
    id: 4,
    label: 'Scoring',
    component: <Scoring />,
    mainPage: 'scoring',
    subPage: 'summary',
    index: 4,
  },
  others: {
    id: 5,
    label: 'Otros',
    component: <Others />,
    mainPage: 'others',
    subPage: 'bankruptcy',
    index: 5,
  },
};

const BusinessProfile = () => {
  const { id, mainPage } = useParams<{
    id: string;
    businessId: string;
    mainPage: string;
  }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState({ name: '', companies: [] });
  const [active, setAcitve] = useState(false);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [startValue, setStartValue] = useState(0);

  const handleNavigation = (index: number) => {
    console.log(companyTabs[index].mainPage);
    navigate(
      `/companies/${id}/${companyTabs[index].mainPage}/${companyTabs[index].subPage}`,
    );
  };

  const setTabsForFactoring = (active: boolean) => {
    const newTabs: any = [];
    if (validateCompanyTypes(user, 'FinancingHub')) {
      if (validatePermissions(user, 'client_read'))
        newTabs.push(profileTabs.client);
    }
    if (validatePermissions(user, 'sii_read_profile'))
      newTabs.push(profileTabs.sii);
    if (validatePermissions(user, 'tgr_read') && active)
      newTabs.push(profileTabs.tgr);

    if (validatePermissions(user, 'scoring_read'))
      newTabs.push(profileTabs.scoring);

    if (validateCompanyTypes(user, 'FactoringFS')) {
      if (validatePermissions(user, 'financial_states_read'))
        newTabs.push(profileTabs.financial);
    }
    newTabs.push(profileTabs.others);
    console.log(newTabs);
    return newTabs;
  };

  const setTabsForAccountant = () => {
    return [
      profileTabs.client,
      profileTabs.sii,
      profileTabs.tgr,
      profileTabs.financial,
      profileTabs.scoring,
      profileTabs.others,
    ];
  };

  const setTabs = (active: boolean) => {
    let newProfileTabs: any = [];
    if (validateCompanyTypes(user, 'Accountant')) {
      newProfileTabs = setTabsForAccountant();
    } else {
      newProfileTabs = setTabsForFactoring(active);
    }
    setInitialTab(
      setCompanyTabs,
      setStartValue,
      mainPage,
      newProfileTabs,
      `companies/${id}`,
    );
  };

  const mainBusiness = async () => {
    try {
      setLoading(true);

      if (!id) {
        setLoading(false);
        return alert('Ha ocurrido un error');
      }

      const business = await getBusiness(dispatch, id);
      console.log(business);
      setBusiness(business);
      setAcitve(business.companies[0].active);
      setTabs(business.companies[0].active);
      setLoading(false);
    } catch (error) {
      window.location.href = '/companies/added';
    }
  };

  useEffect(() => {
    mainBusiness();
  }, []);
  // const longPage = `${mainPage}/${page}`

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        title={business.name}
        startValue={startValue}
        infoChip={active ? 'Conectado SII' : 'No conectado SII'}
        colorChip={active ? '#3bc14a' : '#E8C547'}
        tabs={companyTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default BusinessProfile;
