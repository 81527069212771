import React, { useState, useEffect } from 'react';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import { Typography } from '@mui/material';
import CustomPieChart from '../../../../../lib/designSystem/PieChart';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import { getSiiDebt } from '../../../../../lib/api/dashboard';

const SiiDebt: React.FC = () => {
  const dispatch = useDispatch();
  const [siiDebt, setSiiDebt] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await getSiiDebt(dispatch);
      console.log(relations);
      setSiiDebt(relations.bigPieChartData || []);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  const handleClick = () => {
    window.location.href = '/home/siiResponsabilities';
  };

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhacedBox>
        <Typography>Monto adeudado SII</Typography>
        <CustomPieChart onClick={handleClick} data={siiDebt} money />
      </EnhacedBox>
    </div>
  );
};

export default SiiDebt;
