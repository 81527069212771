import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../designSystem/Button';
import CustomAlert from '../../designSystem/Alert';
import MenuDialog from '../../designSystem/MenuDialog';
import { datesShortCuts } from '../../functions/const/datesShortCuts'; // Atajos de fechas
import { useDispatch } from 'react-redux';

dayjs.extend(utc);

interface Filter {
  type: 'date' | 'text' | 'select';
  label: string;
  options?: { value: string; label: string }[]; // For select type
}

interface SearchComponentProps {
  filters: Filter[];
  searchData: (queryParams: any) => Promise<void>;
  pagination?: { pageSize: number };
  dateKey?: string;
}

const GenericSearcher: React.FC<SearchComponentProps> = ({
  filters,
  searchData,
  pagination,
  dateKey,
}) => {
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [useShortcuts, setUseShortcuts] = useState(false);
  const [shortcut, setShortcut] = useState('');

  const dispatch = useDispatch();

  const handleInputChange = (label: string, value: any) => {
    setFilterValues((prev) => ({ ...prev, [label]: value }));
  };

  const handleSearch = async () => {
    if (startDate && endDate && startDate > endDate) {
      setAlertMessage('La fecha de inicio no puede ser mayor a la fecha fin');
      setOpenAlert(true);
      return;
    }

    let queryParams = '';

    console.log(startDate, endDate);

    if (dateKey) {
      const formattedStartDate = startDate
        ? encodeURIComponent(startDate.toISOString())
        : '';
      const formattedEndDate = endDate
        ? encodeURIComponent(endDate.toISOString())
        : '';
      queryParams += `startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    for (const key in filterValues) {
      if (filterValues[key] !== undefined && filterValues[key] !== '') {
        queryParams += `&${encodeURIComponent(key)}=${encodeURIComponent(filterValues[key])}`;
      }
    }

    console.log(queryParams);
    await searchData(queryParams);
  };

  const handleChangeDateNormal = (value: any, type: string) => {
    if (type === 'start') {
      setStartDate(value ? value.startOf('day').utc() : null);
    } else {
      setEndDate(value ? value.endOf('day').utc() : null);
    }
  };

  const handleChangeShortcut = (shortcut: string) => {
    const selectedShortcut = datesShortCuts.find((sc) => sc.label === shortcut);
    if (selectedShortcut) {
      setStartDate(dayjs(selectedShortcut.startDate).startOf('day').utc());
      setEndDate(dayjs(selectedShortcut.endDate).endOf('day').utc());
      setShortcut(shortcut);
    }
  };

  return (
    <MenuDialog buttonLabel="Buscar" buttonColor="secondary">
      <div style={{ padding: '10px' }}>
        {dateKey && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body1" fontWeight="bold">
                Fechas
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                onClick={() => setUseShortcuts(!useShortcuts)}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {useShortcuts ? 'Ocultar' : 'Mostrar'} atajos
              </Typography>
            </div>
            {useShortcuts ? (
              <FormControl fullWidth>
                <InputLabel id="shortcut-label">Atajo</InputLabel>
                <Select
                  labelId="shortcut-label"
                  value={shortcut}
                  onChange={(e) =>
                    handleChangeShortcut(e.target.value as string)
                  }
                  label="Atajo"
                >
                  {datesShortCuts.map((sc) => (
                    <MenuItem key={sc.label} value={sc.label}>
                      {sc.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div>
                <div style={{ padding: '5px 0px' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label="Fecha de inicio"
                      value={startDate}
                      onChange={(e) => handleChangeDateNormal(e, 'start')}
                      sx={{ minWidth: '100%' }}
                      slotProps={{ field: { clearable: true } }}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ padding: '5px 0px' }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="es-mx"
                  >
                    <DatePicker
                      label="Fecha de fin"
                      value={endDate}
                      onChange={(e) => handleChangeDateNormal(e, 'end')}
                      sx={{ minWidth: '100%' }}
                      slotProps={{ field: { clearable: true } }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {filters.map((filter, index) => (
        <div key={index} style={{ padding: '5px 0px' }}>
          {filter.type === 'text' && (
            <TextField
              label={filter.label}
              value={filterValues[filter.label] || ''}
              onChange={(e) => handleInputChange(filter.label, e.target.value)}
              fullWidth
              variant="outlined"
            />
          )}
          {filter.type === 'select' && filter.options && (
            <FormControl fullWidth>
              <InputLabel>{filter.label}</InputLabel>
              <Select
                value={filterValues[filter.label] || ''}
                onChange={(e) =>
                  handleInputChange(filter.label, e.target.value)
                }
                label={filter.label}
              >
                {filter.options.map((option, idx) => (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      ))}
      <div style={{ padding: '10px 0px', textAlign: 'right' }}>
        <CustomButton color="primary" onClick={() => handleSearch()}>
          Buscar
        </CustomButton>
      </div>
      <CustomAlert
        open={openAlert}
        title="Problemas con la búsqueda"
        text={alertMessage}
        onClose={() => setOpenAlert(false)}
        severity="error"
        setOpen={setOpenAlert}
      />
    </MenuDialog>
  );
};

export default GenericSearcher;
