import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Chip,
  Grid,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../lib/functions/utils';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import {
  createFundPayments,
  getPaymentSuggestions,
  updateFundPayment,
} from '../../../../../../../../lib/api';

const NewFundPayment = (props: any) => {
  const { open, setOpen, setSuccess, setUp, fund, edit, fundPayment } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [business, setBusiness] = useState<any>(null);
  const [documents, setDocuments] = useState<any>([]);
  const [date, setDate] = useState<any>(new Date());

  const validParams = () => {
    if (!documents.length || !business) {
      return false;
    }
    if (!documents.every((doc: any) => doc.amount)) {
      return false;
    }
    return true;
  };

  const handleChangeBusiness = (event: any, newValue: any) => {
    setBusiness(newValue);
  };

  const handleChangeDocument = (event: any, newValue: any) => {
    setDocuments(newValue);
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      const payments = documents.map((doc: any) => ({
        documentId: doc.id,
        businessIdentifier: business.identifier,
        date: new Date(date),
        amount: doc.amount,
        fundId: fund.id,
      }));
      if (edit) {
        await updateFundPayment(dispatch, {
          id: fund.id,
          paymentId: fundPayment.id,
          payment: payments[0],
        });
      } else {
        const newPayload = {
          payments,
        };
        await createFundPayments(dispatch, fund.id, newPayload);
      }
      setOpen(false);
      setSuccess('Pago guardado correctamente');
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpSuggestions = async () => {
    setLoading(true);
    try {
      const response = await getPaymentSuggestions(dispatch, { id: fund.id });
      console.log(response);
      setSuggestions(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpSuggestions();
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
      >
        <HeaderWithActions
          title={edit ? `Editar pago` : `Nuevo pago`}
          type="dialog"
        />
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <DialogContent>
            <Grid container spacing={0} padding={'10px 0px'}>
              <Grid item xs={5} padding="0px 16px">
                <Typography variant="body1">
                  Ingrese los datos del nuevo pago del fondo <b>{fund.name}</b>
                </Typography>
                <Autocomplete
                  style={{ margin: '8px 0px' }}
                  options={suggestions.businesses}
                  getOptionLabel={(option: any) =>
                    `${option.name} | ${option.identifier}`
                  }
                  renderInput={(params: any) => (
                    <TextField {...params} label="De quíen fue el pago?" />
                  )}
                  onChange={handleChangeBusiness}
                  fullWidth
                />
                <TextField
                  label="Monto"
                  fullWidth
                  type="date"
                  margin="dense"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
                <Autocomplete
                  options={suggestions.documents || []}
                  style={{ margin: '8px 0px' }}
                  getOptionLabel={(option: any) =>
                    `${option.folio} | ${option.businessName} | ${option.stakeholderName}`
                  }
                  renderInput={(params: any) => (
                    <TextField {...params} label="Documentos del pago" />
                  )}
                  renderTags={(value: any) => {
                    return value.map((option: any, index: any) => (
                      <Chip
                        key={index}
                        style={{ margin: '4px' }}
                        label={option.folio}
                      />
                    ));
                  }}
                  defaultValue={[]}
                  multiple
                  onChange={handleChangeDocument}
                  fullWidth
                  disabled={!business}
                />
              </Grid>
              <Grid
                item
                xs={7}
                padding="0px 16px"
                style={{ borderLeft: '1px solid grey' }}
              >
                <Typography variant="body1">
                  Asigne los montos a los documentos seleccionados
                </Typography>

                <div style={{ padding: '10px 0px' }}>
                  {documents.map((document: any, index: any) => (
                    <Grid
                      container
                      key={index}
                      style={{ padding: '2px 0px' }}
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          - {document.folio}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          {formatMoney(document.amount)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Monto notificado"
                          fullWidth
                          margin="dense"
                          value={formatMoney(document.amount)}
                          onChange={(e) => {
                            const nonFomatted = formatMoneyBack(e.target.value);
                            const newDocuments = documents.map((doc: any) => {
                              if (doc.id === document.id) {
                                return { ...doc, amount: nonFomatted };
                              }
                              return doc;
                            });
                            setDocuments(newDocuments);
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewFundPayment;
