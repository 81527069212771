import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TransferList from './Components/TransferList';
import CustomButton from '../../designSystem/Button';
import { getUsersToTransfer } from '../../api/portfolio';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../designSystem/CircularProgress';
import CustomAlert from '../../designSystem/Alert';

interface PortfolioTransferProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedUserId?: number;
}

const PortfolioTransfer: React.FC<PortfolioTransferProps> = (props) => {
  const { open, setOpen, selectedUserId } = props;
  const dispatch = useDispatch();
  const [usersOptions, setUsersOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const response = await getUsersToTransfer(dispatch);
    if (response) {
      setUsersOptions(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [reload]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography variant="h6"> Transferir cartera</Typography>
        </DialogTitle>
        <DialogContent>
          <TransferList
            options={usersOptions}
            setLoading={setLoading}
            setOpen={setOpen}
            reload={reload}
            setReload={setReload}
            setError={setError}
            setSuccess={setSuccess}
            selectedUserId={selectedUserId}
          />
        </DialogContent>
      </Dialog>
      <CustomAlert
        open={error}
        setOpen={setError}
        severity="error"
        text="Error al transferir la cartera"
      />
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text="Cartera transferida con éxito"
      />
    </div>
  );
};

export default PortfolioTransfer;
