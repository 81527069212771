import { requestHandler } from '../../api';

export const getScrapingStatus = async (
  dispatch: any,
  scrapingType: string,
  businessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/pruffRush/scrapingStatus/${scrapingType}/${businessIdentifier}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllSycronizations = async (
  dispatch: any,
  startDate: Date | null,
  endDate: Date | null,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/pruffRush/syncronizations?startDate=${startDate}&endDate=${endDate}`,
    dispatch,
  });
  if (response) return response.data;
};

export const executeScraping = async (
  dispatch: any,
  scrapingType: string,
  businessIdentifier: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/pruffRush/executeScraping/${scrapingType}/${businessIdentifier}`,
    dispatch,
  });
  if (response) return response.data;
};
