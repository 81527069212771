import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { AuthState } from '../../../../../services/redux/authSlice';
import UploadList from '../../../../../lib/common/UploadList';

const SettingsCompliance = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant="body1">
            Acá puedes subir los documentos que necesitas para trabajar con
            nosotros.
          </Typography>
        </div>
      </div>
      <UploadList
        products={['UAF', 'PEP']}
        requestType={`Clients,${user?.businessIdentifier},Compliance`}
      />
    </div>
  );
};

export default SettingsCompliance;
