import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { getComitees } from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';

const FinalNodeDialog = (props: any) => {
  const { open, setOpen, finalNode, setFinalNode, isNew, handleSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [comitees, setComitees] = useState([]);
  const dispatch = useDispatch();

  const handleSelectComitee = (e: any) => {
    setFinalNode({ ...finalNode, comiteeId: e.target.value });
  };

  const fetchComitees = async () => {
    setLoading(true);
    try {
      const response = await getComitees(dispatch);
      setComitees(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComitees();
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {isNew ? 'Crear Nodo decisión' : 'Editar Nodo decisión'}
      </DialogTitle>
      {loading && <CenteredCircularProgress type="dialog" />}
      {!loading && (
        <DialogContent>
          <div style={{ padding: '10px 0px' }}>
            <Typography variant="body1">
              Acá puedes definir el nodo final de tu motor de decisión si este
              no es aprobado o rechazado de manera automática. Para eso, puedes
              asignar un comité a cierto nodo de decisión y despúes ocuparlo
              dentro del motor de decisión
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                variant="outlined"
                placeholder="Revisión manual I, Advertencia I, etc..."
                fullWidth
                value={finalNode.name}
                onChange={(e) =>
                  setFinalNode({ ...finalNode, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                variant="outlined"
                placeholder="Revisión manual I, Advertencia I, etc..."
                fullWidth
                value={finalNode.description}
                onChange={(e) =>
                  setFinalNode({ ...finalNode, description: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ padding: '10px 0px' }}>
                {!comitees.length && (
                  <Typography variant="body1" color="red">
                    No hay comités disponibles, por favor crea uno y vuelve a
                    intentar.
                  </Typography>
                )}
                {comitees.length && (
                  <FormControl
                    style={{ minWidth: 300 }}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="filter-label">
                      Comités Disponibles
                    </InputLabel>
                    <Select
                      labelId="filter-label"
                      id="Filtrar por estado"
                      value={finalNode.comiteeId}
                      fullWidth
                      onChange={(e) => handleSelectComitee(e)}
                      label="Roles Disponibles"
                    >
                      {comitees.map((comitee: any, index: any) => (
                        <MenuItem key={index} value={comitee.id}>
                          {comitee.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => {
            setOpen(false);
          }}
          color="secondary"
        >
          Cancelar
        </CustomButton>
        <CustomButton
          onClick={() => {
            handleSubmit();
          }}
          color="primary"
          disabled={loading || !comitees.length}
        >
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default FinalNodeDialog;
