import React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import dayjs from 'dayjs';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { Search } from '@mui/icons-material';
import 'dayjs/locale/es';
dayjs.locale('es');
const years = Array.from({ length: 3 }, (_, i) => dayjs().year() - i);
const months = Array.from(
  { length: 12 },
  (_, i) =>
    dayjs().month(i).format('MMMM').charAt(0).toUpperCase() +
    dayjs().month(i).format('MMMM').slice(1),
);

const MonthYearSearcher = (props: any) => {
  const { setUp, year, setYear, month, setMonth } = props;
  const handleYearChange = (event: any) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event: any) => {
    setMonth(event.target.value);
  };

  const handleSearch = async () => {
    await setUp();
  };

  return (
    <Grid container justifyContent="left" spacing={2}>
      <Grid item xs={5}>
        <TextField
          select
          label="Año"
          fullWidth
          value={year}
          onChange={handleYearChange}
          style={{ marginRight: 10 }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={5}>
        <TextField
          select
          fullWidth
          label="Mes"
          value={month}
          onChange={handleMonthChange}
        >
          {months.map((month, index) => (
            <MenuItem key={index} value={index}>
              {month}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <CustomButton
          onClick={() => handleSearch()}
          color="secondary"
          startIcon={<Search />}
          style={{ marginTop: '10px' }}
        >
          Buscar
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default MonthYearSearcher;
