import React from 'react';
import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import { cellFormat } from '../../../../../../../../../../lib/designSystem/Table/cellFormat';
import { Info } from '@mui/icons-material';

interface Request {
  source: string;
  pendingAccounts: any[];
  documentsApplied: any[];
  businessName: string;
  businessIdentifier: string;
  amountToDepositTotal: number;
  documents: any[];
  payments: any[];
  amountToApply: number;
  createdAt: Date;
}

interface Props {
  request: Request;
}

const PayOutSummary = (props: Props) => {
  const { request } = props;
  console.log('requestz', request);

  let businesName = '';
  let businessIdentifier = '';

  const paid = request.payments.every((payment: any) => payment.executedAt);

  const divStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0px',
  };

  const DetailsSummary = () => (
    <div>
      <div style={divStyle}>
        <Typography fontWeight={600} variant="body1">
          Estado
        </Typography>
        <Typography variant="body1">{cellFormat(paid, 'paid')}</Typography>
      </div>
      <div style={divStyle}>
        <Typography fontWeight={600} variant="body1">
          Destinatario
        </Typography>
        <Typography variant="body1">{businesName}</Typography>
      </div>
      <div style={divStyle}>
        <Typography fontWeight={600} variant="body1">
          Rut cliente
        </Typography>
        <Typography variant="body1">{businessIdentifier}</Typography>
      </div>
      <div style={divStyle}>
        <Typography fontWeight={600} variant="body1">
          Fecha de solicitud
        </Typography>
        <Typography variant="body1">{formatDate(request.createdAt)}</Typography>
      </div>
    </div>
  );

  const AmountSummary = () => {
    switch (request.source) {
      case 'payment-request-passive': {
        businesName =
          request.pendingAccounts && request.pendingAccounts[0].businessName;
        businessIdentifier =
          request.pendingAccounts &&
          request.pendingAccounts[0].businessIdentifier;
        const amountPendingAccounts = request.pendingAccounts.reduce(
          (acc: any, payment: any) => {
            return acc + payment.amount;
          },
          0,
        );
        const amountAppliedDocuments = request.documentsApplied.reduce(
          (acc: any, payment: any) => {
            return acc + payment.amount;
          },
          0,
        );
        return (
          <div>
            <Typography fontWeight={600} variant="h6">
              Resumen Montos
            </Typography>
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Cuentas pendientes
              </Typography>
              <Typography variant="body1">
                {formatMoney(amountPendingAccounts)}
              </Typography>
            </div>
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Documentos aplicados
              </Typography>
              <Typography variant="body1">
                {formatMoney(-amountAppliedDocuments)}
              </Typography>
            </div>
            <Divider />
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Total a pagar
              </Typography>
              <Typography variant="body1">
                {formatMoney(amountPendingAccounts - amountAppliedDocuments)}
              </Typography>
            </div>
          </div>
        );
      }
      case 'factoring-request': {
        businesName = request.businessName;
        businessIdentifier = request.businessIdentifier;
        const documentsAmount = request.documents.reduce(
          (acc: any, payment: any) => {
            return acc + payment.amount;
          },
          0,
        );
        return (
          <div>
            <Typography fontWeight={600} variant="h6">
              Resumen Montos
            </Typography>
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Documentos
              </Typography>
              <Typography variant="body1">
                {formatMoney(documentsAmount)}
              </Typography>
            </div>
            <div style={divStyle}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography fontWeight={600} variant="body1">
                  Descuentos
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      Para más detalles, ir a la sección de{' '}
                      <a href="/finance/financing/factoring">factoring</a>
                    </Typography>
                  }
                >
                  <Info
                    style={{
                      marginLeft: 6,
                      fontSize: 16,
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </div>

              <Typography variant="body1">
                {formatMoney(documentsAmount - request.amountToDepositTotal)}
              </Typography>
            </div>
            <Divider />
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Total a pagar
              </Typography>
              <Typography variant="body1">
                {formatMoney(request.amountToDepositTotal)}
              </Typography>
            </div>
          </div>
        );
      }
      case 'payment-request': {
        const documentsAmount = request.documents.reduce(
          (acc: any, payment: any) => {
            return acc + payment.amount;
          },
          0,
        );
        businesName = request.documents[0].businessName;
        businessIdentifier = request.documents[0].businessIdentifier;
        return (
          <div>
            <Typography fontWeight={600} variant="h6">
              Resumen Montos
            </Typography>
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Documentos
              </Typography>
              <Typography variant="body1">
                {formatMoney(documentsAmount)}
              </Typography>
            </div>
            <Divider />
            <div style={divStyle}>
              <Typography fontWeight={600} variant="body1">
                Total a pagar
              </Typography>
              <Typography variant="body1">
                {formatMoney(documentsAmount)}
              </Typography>
            </div>
          </div>
        );
      }
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DetailsSummary />
        </Grid>
        <Grid item xs={6}>
          {AmountSummary()}
        </Grid>
      </Grid>
    </div>
  );
};

export default PayOutSummary;
