type Entry = {
  identifier: string;
  buyAmount: number;
  salesAmount: number;
  salesCancelAmount: number;
  salesCreditNoteAmount: number;
};

type Data = {
  dataPayers: {
    [key: string]: Entry[];
  };
};

type Result = {
  buyAmount: number;
  salesAmount: number;
  salesCancelAmount: number;
  salesCreditNoteAmount: number;
  averageSales: number;
  averageBuy: number;
  averageNC: number;
};

const calculateAmounts = (
  entries: Entry[],
  identifiers: string[],
  months: number,
): Result => {
  const result: Result = {
    buyAmount: 0,
    salesAmount: 0,
    salesCancelAmount: 0,
    salesCreditNoteAmount: 0,
    averageSales: 0,
    averageBuy: 0,
    averageNC: 0,
  };
  for (const entry of entries) {
    if (identifiers.includes(entry.identifier)) {
      result.buyAmount += entry.buyAmount;
      result.salesAmount += entry.salesAmount;
      result.salesCancelAmount += entry.salesCancelAmount;
      result.salesCreditNoteAmount += entry.salesCreditNoteAmount;
    }
  }

  result.averageBuy = result.buyAmount / months;
  result.averageSales = result.salesAmount / months;
  result.averageNC = result.salesCreditNoteAmount / months;

  return result;
};
const monthsCalculator = (filters: string[]): number => {
  if (filters.includes('Últimos 12M')) {
    return 12;
  } else if (filters.includes('Últimos 24M')) {
    return 24;
  } else if (filters.includes('Últimos 6M')) {
    return 6;
  } else if (filters.includes('Últimos 3M')) {
    return 3;
  } else {
    return filters.length;
  }
};
export const totalCalculator = (
  data: Data,
  identifiers: string[],
  filters: string[],
): Result => {
  const months = monthsCalculator(filters);
  if (filters.includes('Últimos 3M')) {
    return calculateAmounts(data.dataPayers['Últimos 3M'], identifiers, months);
  } else if (filters.includes('Últimos 6M')) {
    return calculateAmounts(data.dataPayers['Últimos 6M'], identifiers, months);
  } else if (filters.includes('Últimos 12M')) {
    return calculateAmounts(
      data.dataPayers['Últimos 12M'],
      identifiers,
      months,
    );
  } else if (filters.includes('Últimos 24M')) {
    return calculateAmounts(
      data.dataPayers['Últimos 24M'],
      identifiers,
      months,
    );
  } else {
    const result: Result = {
      buyAmount: 0,
      salesAmount: 0,
      salesCancelAmount: 0,
      salesCreditNoteAmount: 0,
      averageSales: 0,
      averageBuy: 0,
      averageNC: 0,
    };

    for (const [key, value] of Object.entries(data.dataPayers)) {
      if (filters.includes(key)) {
        const partialResult = calculateAmounts(value, identifiers, months);
        result.buyAmount += partialResult.buyAmount;
        result.salesAmount += partialResult.salesAmount;
        result.salesCancelAmount += partialResult.salesCancelAmount;
        result.salesCreditNoteAmount += partialResult.salesCreditNoteAmount;
      }
    }

    result.averageBuy = result.buyAmount / months;
    result.averageSales = result.salesAmount / months;
    result.averageNC = result.salesCreditNoteAmount / months;

    return result;
  }
};
