import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuDialog from '../../designSystem/MenuDialog';

interface FilterField {
  key: string;
  filterName: string;
  type?: string;
  mode?: string;
  defaultValues?: any[]; // Campo para valores preseleccionados
}

interface FilterComponentProps {
  data: any[];
  filterFields: FilterField[];
  setFilteredData: (data: any[]) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  data,
  filterFields,
  setFilteredData,
}) => {
  const [allFilterOptions, setAllFilterOptions] = useState<{
    [key: string]: any[];
  }>({});
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: any;
  }>({});

  // useRef para controlar la carga inicial
  const isInitialMount = useRef(true);

  // useEffect para inicializar las opciones y los filtros seleccionados por defecto
  useEffect(() => {
    const initialOptions: { [key: string]: Set<any> } = {};
    const initialSelectedFilters: { [key: string]: any } = {};

    filterFields.forEach(({ key, defaultValues }) => {
      initialOptions[key] = new Set();
      if (defaultValues) {
        initialSelectedFilters[key] = defaultValues;
      }
    });

    data.forEach((item) => {
      filterFields.forEach(({ key }) => {
        initialOptions[key].add(item[key]);
      });
    });

    // Agregar valores preseleccionados a las opciones si no están presentes
    Object.entries(initialSelectedFilters).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => initialOptions[key].add(value));
      } else {
        initialOptions[key].add(values);
      }
    });

    const optionsObject = Object.fromEntries(
      Object.entries(initialOptions).map(([key, value]) => {
        const filterField = filterFields.find((f) => f.key === key);
        if (filterField?.type === 'includes') {
          return [
            key,
            Array.from(value).filter(
              (v) => typeof v === 'string' && !v.includes(','),
            ),
          ];
        }
        return [key, Array.from(value)];
      }),
    );

    setAllFilterOptions(optionsObject);

    if (isInitialMount.current) {
      // Solo se ejecuta en la carga inicial
      setSelectedFilters((prev) => ({ ...prev, ...initialSelectedFilters }));
      isInitialMount.current = false; // Marcar como cargado
    }
  }, [data, filterFields]); // Dependencias para volver a calcular las opciones si cambian los datos o campos de filtro

  const handleChange = (event: any, value: any, reason: any, field: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateFilters = () => {
    // Si no hay filtros seleccionados, mostramos todos los datos originales
    if (
      Object.values(selectedFilters).every(
        (filter) => !filter || (Array.isArray(filter) && filter.length === 0),
      )
    ) {
      setFilteredData(data);
      return;
    }

    let filtered = [...data];
    filterFields.forEach(({ key, mode }) => {
      if (
        selectedFilters[key] &&
        !(
          Array.isArray(selectedFilters[key]) &&
          selectedFilters[key].length === 0
        )
      ) {
        filtered = filtered.filter((item) => {
          const filterField = filterFields.find((f) => f.key === key);
          if (filterField?.type === 'includes') {
            if (Array.isArray(selectedFilters[key])) {
              return selectedFilters[key].some((selectedValue) =>
                item[key]?.includes(selectedValue),
              );
            }
            return item[key]?.includes(selectedFilters[key]);
          }
          if (mode === 'multiple' && Array.isArray(selectedFilters[key])) {
            return selectedFilters[key].includes(item[key]);
          }
          return item[key] === selectedFilters[key];
        });
      }
    });
    setFilteredData(filtered);
  };

  // Ejecutar updateFilters solo cuando selectedFilters cambie
  useEffect(() => {
    updateFilters();
  }, [selectedFilters]);

  const customTextField = (params: any, label: string) => (
    <TextField {...params} label={label} />
  );

  const renderFilter = ({ key, filterName, mode }: FilterField) => (
    <div style={{ padding: '10px' }} key={key}>
      <Autocomplete
        multiple={mode === 'multiple'}
        style={{ minWidth: '100%' }}
        options={allFilterOptions[key] || []}
        value={selectedFilters[key] || (mode === 'multiple' ? [] : null)}
        getOptionLabel={(option) => option || ''}
        renderInput={(params) => customTextField(params, filterName)}
        onChange={(event, value, reason) =>
          handleChange(event, value, reason, key)
        }
        fullWidth
      />
    </div>
  );

  return (
    <MenuDialog buttonLabel="Filtrar" buttonColor="secondary">
      <div style={{ padding: '10px' }}>
        <Typography variant="body1" fontWeight="bold">
          Filtros
        </Typography>
        <div>{filterFields.map((field) => renderFilter(field))}</div>
      </div>
    </MenuDialog>
  );
};

export default FilterComponent;
