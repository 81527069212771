import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tooltip,
  TextField,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '4px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
  }),
);

const firstCellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '4px 16px',
};

const PayersTable = (props: any) => {
  const { requestPayers, setRequestPayers } = props;
  // const [page, setPage] = useState(0);
  const classes = useStyles();
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  // Handlers for opening and closing the tooltip.
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

  const handleChange = (id: string, newValue: string) => {
    // Parse the input as a float
    const numericValue = parseFloat(newValue);

    console.log(id);

    // Validate the parsed number
    setRequestPayers({
      ...requestPayers,
      [id]: { ...requestPayers[id], paymentDays: numericValue },
    });
  };

  // Handlers for opening and closing the tooltip.

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableCell title={'Cuenta'} style={firstCellStyle}>
            Nombre Pagador
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Días para pago
          </TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {Object.keys(requestPayers).map((row: any, rowIndex: any) => (
            <TableRow key={rowIndex}>
              <Tooltip
                title={requestPayers[row].stakeholderName}
                open={openTooltipIndex === rowIndex}
                onClose={() => setOpenTooltipIndex(null)}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {},
                    },
                  ],
                }}
              >
                <TableCell
                  style={firstCellStyle}
                  onMouseEnter={() => setOpenTooltipIndex(rowIndex)}
                  onMouseLeave={() => setOpenTooltipIndex(null)} // Close the tooltip when the mouse leaves
                  onClick={() => setOpenTooltipIndex(null)}
                >
                  {requestPayers[row].stakeholderName}
                </TableCell>
              </Tooltip>
              <TableCell
                className={classes.tableCell}
                style={{ textAlign: 'right' }}
              >
                <TextField
                  value={requestPayers[row].paymentDays}
                  type="number"
                  placeholder="29"
                  onChange={(e) => handleChange(row, e.target.value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PayersTable;
