import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Dialog,
} from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { ContactForm } from '../../../../../BusinessProfile/Profile/components/Contacts';
import { validateChileanPhone } from '../../../../../../lib/functions/utils';
import { createContact, editContact } from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import BusinessSearcher from '../../../../../../lib/common/BusinessSearcher/businessSearcher';

const NewContact = (props: any) => {
  const {
    contact,
    setContact,
    setOpen,
    open,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<any>(null);
  const [searched, setSearched] = useState(false);

  const validateAttributes = () => {
    if (
      contact.name === '' ||
      contact.email === '' ||
      contact.role === '' ||
      contact.phone === ''
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    if (!validateChileanPhone(contact.phone)) {
      alert('El teléfono debe ser un número chileno');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateAttributes()) return;
    setLoading(true);
    try {
      if (contact.id) {
        await editContact(dispatch, contact.id, {
          name: contact.name,
          email: contact.email,
          role: contact.role,
          phone: contact.phone,
        });
        setSuccessOpen('Contacto editado con éxito');
      } else {
        await createContact(dispatch, {
          ...contact,
          businessIdentifier: business.identifier,
        });
        setSuccessOpen('Contacto creado con éxito');
      }
      setOpen(false);
      await setUp();
    } catch (error) {
      setFailureOpen('Ha ocurrido un error');
    }
    setLoading(false);
  };
  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {' '}
        {contact.id ? 'Editar contacto' : 'Nuevo contacto'}{' '}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            {!contact.id && (
              <div>
                <BusinessSearcher
                  setBusiness={setBusiness}
                  setSearched={setSearched}
                />
              </div>
            )}
            {contact.id && searched && <Divider />}

            {(business || contact.id) && (
              <div style={{ padding: '10px 0px' }}>
                <ContactForm
                  selectedContact={contact}
                  setSelectedContact={setContact}
                />
              </div>
            )}
            {!business && searched && (
              <div style={{ padding: '10px 0px' }}>
                <Typography variant="body1">
                  {' '}
                  No encontramos una empresa con ese rut en tu red. Por favor
                  verifica el rut o crea la empresa antes de agregar un
                  contacto.
                </Typography>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={!business && !contact.id}
          color="primary"
          onClick={() => handleSubmit()}
        >
          {' '}
          {contact.id ? 'Guardar' : 'Crear'}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewContact;
