import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { getBusinessCrossInvoicesSummary } from '../../../../../lib/api';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';

const DashboardSummary: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [crossInvoicesData, setCrossInvoicesData] = useState({
    historicData: [],
    dataPayers: { total: [] },
    dataFactorings: { total: [] },
  });
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    const fetchRelations = async () => {
      if (!id) return;
      const crossInvoices = await getBusinessCrossInvoicesSummary(dispatch, id);
      console.log(crossInvoices);
      setCrossInvoicesData(crossInvoices || []);
      setLoading(false);
    };
    fetchRelations();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <HeaderWithActions
          variant="body1"
          title="Acá encontrarás las facturas que esta empresa se ha emitido y
       recibido con sus pagadores"
        />
        <EnhancedTable
          finalSumRow
          exportToFile="xlsx"
          tableSize="small"
          dataHeaders={[
            { key: 'name', label: 'Razon Social' },
            { key: 'identifier', label: 'Rut' },
            { key: 'emittedCount', label: 'Facturas emitidas' },
            { key: 'emittedAmount', label: 'Monto emitido', type: 'money' },
            { key: 'recievedCount', label: 'Facturas recibidas' },
            { key: 'recievedAmount', label: 'Monto recibido', type: 'money' },
            // { key: 'crossAmount', label: 'Monto cruzado', type: 'money' },
            // { key: 'crossInvoices', label: 'Facturas cruzadas' },
          ]}
          data={crossInvoicesData.dataPayers.total}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardSummary;
