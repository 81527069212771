// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserEnrollment } from '../../services/redux/authSlice'; // Import the action
import {
  createBusiness,
  getUser,
  startEnrolation,
  validateCredentials,
} from '../../lib/api';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/store';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import EnhancedBox from '../../lib/designSystem/BigBox';
import CustomButton from '../../lib/designSystem/Button';
import CustomAlert from '../../lib/designSystem/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { activateRelationBetweenReceiverAndReceiver } from '../../lib/api/express';

const AutoEnrollment = () => {
  const domain = window.location.hostname;
  const clientId = domain.split('.')[0];
  const dispatch = useDispatch();
  const [termsAgree, setTermsAgree] = useState(false);
  const [canSeePassword, setCanSeePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({
    name: '',
    email: '',
    encryptedId: '',
    company: { taxIdentifier: '', name: '' },
  });
  const auth = useSelector((state: RootState) => state.auth);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [password, setPassword] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [enable, setEnable] = useState(false);

  const fetchUserProfile = async () => {
    setLoading(true);
    const userProfile = await getUser(dispatch, { id: auth.user?.id });
    console.log(userProfile);
    setUserProfile(userProfile);
    setLoading(false);
  };

  const handleEnable = async () => {
    try {
      await activateRelationBetweenReceiverAndReceiver(
        dispatch,
        clientId as string,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnrolation = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Validando credenciales');
      await validateCredentials(dispatch, {
        taxPassword: password,
        taxIdentifier: userProfile.company.taxIdentifier,
      });
      setLoadingMessage('Registrando empresa');
      const response = await createBusiness(dispatch, {
        name: userProfile.company.name,
        identifier: userProfile.company.taxIdentifier,
        taxPassword: password,
        enrolledPath: 'auto',
      });
      setLoadingMessage(
        'Comenzando sincronización con el SII, esto quedará en segundo plano. Por mientras, te redirigiremos a tu cuenta.',
      );
      await startEnrolation(dispatch, {
        taxPassword: password,
        taxIdentifier: userProfile.company.taxIdentifier,
        businessId: response.id,
      });
      dispatch(
        updateUserEnrollment({
          autoEnrrolled: true,
          businessId: response.id,
          businessIdentifier: userProfile.company.taxIdentifier,
        }),
      );
      if (enable) {
        await handleEnable();
      }
      setOpenSuccess(true);
    } catch (error: any) {
      console.log(error);
      setOpenFailure(true);
    }
    setLoading(false);
    setLoadingMessage('');
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);
  const handleClickShowPassword = () => {
    setCanSeePassword(!canSeePassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  if (loading)
    return <CenteredCircularProgress type="dialog" text={loadingMessage} />;

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <EnhancedBox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">¡Bienvenido!</Typography>
          </div>
          <Typography variant="h6">
            Comencemos conectando tu SII con nuestra plataforma. Así podremos
            obtener las facturas que deseas factorizar de manera eficiente.
          </Typography>
          <Typography variant="h6">
            Este es el rut ascociado a tu cuenta:
          </Typography>
          <Typography variant="h6">
            <b>{userProfile.company.taxIdentifier}</b>
          </Typography>
          <Typography variant="h6">
            A continuación, ingresa tu contraseña del SII para que podamos
            acceder a tus facturas.
          </Typography>
          <TextField
            fullWidth
            label="Contraseña"
            type={canSeePassword ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {canSeePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setEnable(!enable)}
                value="allowExtraEmails"
                color="primary"
                checked={enable}
              />
            }
            label={
              <Typography>
                Autorizo al factoring a visualizar mis datos del SII y TGR
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setTermsAgree(!termsAgree)}
                value="allowExtraEmails"
                color="primary"
                checked={termsAgree}
              />
            }
            label={
              <span>
                He leído y estoy de acuerdo con los{' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation to prevent checkbox from toggling
                    window.open(
                      'https://faaast.s3.amazonaws.com/Terminos+y+Condiciones.pdf',
                    );
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  términos y condiciones
                </span>
              </span>
            }
          />
          <div style={{ padding: '20px 5px 0px', textAlign: 'center' }}>
            <CustomButton
              onClick={() => handleEnrolation()}
              color="primary"
              disabled={!termsAgree || !password.length || !enable}
            >
              Guardar
            </CustomButton>
          </div>
        </EnhancedBox>
      </div>
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text="Se están configurando tu cuenta, dentro de los próximos minutos ya podrás visualizar y operar tus facturas."
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        text="La contraseña ingresada no es correcta o no es la del rut indicado, por favor intenta nuevamente."
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default AutoEnrollment;
