import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { getBusinessTgr } from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import { formatMoney } from '../../../../../lib/functions/utils';

const TGRAgreements: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tgrData, setTgrData] = useState({
    businessAgreements: [],
    businessAgreementsCuotas: [],
    summary: {
      agreementsCount: '',
      overdueCount: '',
      overdueAmount: '',
      amountRemaining: '',
    },
  });
  const [allTgrCuotas, setAllTgrCuotas] = useState<any>([]);
  const [filteredTgrCuotas, setFilteredTgrCuotas] = useState<any>([]);
  const [allTGRDebts, setAllTGRDebts] = useState<any>([]);
  const [filteredTGRDebts, setFilteredTGRDebts] = useState<any>([]);
  const [selectedAgreement, setSelectedAgreement] = useState<any>(null);

  const { id } = useParams<{ id: string }>();

  const fetchRelations = async () => {
    if (!id) return;
    const tgrData = await getBusinessTgr(dispatch, id);
    console.log(tgrData);
    setTgrData(tgrData || []);
    setAllTgrCuotas(tgrData.businessAgreementsCuotas);
    setFilteredTgrCuotas(tgrData.businessAgreementsCuotas);
    setAllTGRDebts(tgrData.businessAgreemetDebs);
    setFilteredTGRDebts(tgrData.businessAgreemetDebs);
    setLoading(false);
  };
  useEffect(() => {
    fetchRelations();
  }, []);

  const handleClickRowAgreement = (id: any) => {
    const foundAgreement: any = tgrData.businessAgreements.find(
      (agreement: any) => agreement.id === id,
    );
    if (!foundAgreement) return;

    if (selectedAgreement && selectedAgreement.id === id) {
      setSelectedAgreement(null);
      setFilteredTgrCuotas(allTgrCuotas);
      setFilteredTGRDebts(allTGRDebts);
      return;
    }
    const newCuotas = allTgrCuotas.filter(
      (cuota: any) => cuota.proposalId === foundAgreement.proposalId,
    );
    console.log(newCuotas);
    setFilteredTgrCuotas(newCuotas);
    const newDebts = allTGRDebts.filter(
      (debt: any) => debt.proposalId === foundAgreement.proposalId,
    );
    console.log(newDebts);
    setFilteredTGRDebts(newDebts);
    setSelectedAgreement(foundAgreement);
  };

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h5">
        <b>Resumen</b>
      </Typography>
      <Grid style={{ paddingTop: 10 }} container spacing={3} rowSpacing={1}>
        <Grid item xs={3}>
          <EnhacedBox small centerText>
            <Typography variant="h6">Convenios vigentes</Typography>
            <Typography variant="h1">
              {tgrData.summary.agreementsCount}
            </Typography>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox small centerText>
            <Typography variant="h6">Cuotas vencidas</Typography>
            <Typography variant="h1">{tgrData.summary.overdueCount}</Typography>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox small centerText>
            <Typography variant="h6">Monto vencido</Typography>
            <Typography variant="h1">
              {formatMoney(Number(tgrData.summary.overdueAmount))}
            </Typography>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox small centerText>
            <Typography variant="h6">Monto por pagar</Typography>
            <Typography variant="h1">
              {formatMoney(Number(tgrData.summary.amountRemaining))}
            </Typography>
          </EnhacedBox>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            <b>Convenios vigentes</b>
          </Typography>
          <Typography variant="body1">
            Seleccione un convenio para filtrar los detalles
          </Typography>

          <EnhancedTable
            finalSumRow
            exportToFile="xlsx"
            tableSize="small"
            dataHeaders={[
              { key: 'proposalId', label: 'N° de propuesta' },
              { key: 'resolutionId', label: 'N° de resolución' },
              { key: 'paymentType', label: 'Tipo de pago' },
              { key: 'actualAmount', label: 'Saldo actual', type: 'money' },
              {
                key: 'resolutionDate',
                label: 'Fecha de resolución',
                type: 'date',
              },
              { key: 'cuotasCount', label: 'N° de cuotas' },
              { key: 'cuotasDue', label: 'Cuotas vencidas' },
            ]}
            data={tgrData.businessAgreements}
            handleClickRow={handleClickRowAgreement}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Deudas</b>
          </Typography>
          <EnhancedTable
            exportToFile="xlsx"
            finalSumRow
            tableSize="small"
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              { key: 'proposalId', label: 'N° de propuesta' },
              { key: 'dueDate', label: 'Fecha de vencimiento', type: 'date' },
              {
                key: 'amountRemaining',
                label: 'Saldo remanente',
                type: 'money',
              },
              { key: 'interestCondition', label: 'Cond. otorgada Multas (%)' },
              {
                key: 'penaltyCondition',
                label: 'Cond. otorgada Intereses (%)',
              },
            ]}
            data={filteredTGRDebts}
            handleClickRow={handleClickRowAgreement}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>Cuotas</b>
          </Typography>
          <div>
            <EnhancedTable
              exportToFile="xlsx"
              finalSumRow
              tableSize="small"
              dataHeaders={[
                { key: 'cuotaId', label: 'N° de cuota' },
                { key: 'proposalId', label: 'N° de propuesta' },
                { key: 'dueDate', label: 'Vencimiento', type: 'date' },
                { key: 'amount', label: 'Monto', type: 'money' },
                { key: 'paid', label: 'Pagado', type: 'boolean-yes' },
                { key: 'due', label: 'Vencida', type: 'due-relaxed' },
              ]}
              data={filteredTgrCuotas}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TGRAgreements;
