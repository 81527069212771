import { requestHandler } from '../../api';

export const publishInvoiceDebt = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/documentPublication`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const getInvoicePublications = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentPublication`,
    dispatch,
  });
  if (response) return response.data;
};

export const unpublishInvoiceDebt = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/documentPublication/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getQueryRut = async (dispatch: any, rut: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/documentPublication/query/${rut}`,
    dispatch,
  });
  if (response) return response.data;
};
