import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../../../../lib/designSystem/Alert';
import { getDocumentsAssignedToFund } from '../../../../../../../../../../lib/api';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'stakeholderName', label: 'Pagador' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'isPaid', label: 'Pagado', type: 'vission' },
];

const FundDocuments = (props: any) => {
  const {
    fund,
    handleOpenAssignationModal,
    // handleOpenNewPaymentModal,
    // setFundPayment,
  } = props;
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [documents, setDocuments] = useState<any>([]);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await getDocumentsAssignedToFund(dispatch, {
        id: fund.id,
      });
      setDocuments(response.actualDocuments);
    } catch (error) {
      alert('Error al cargar los pagos');
    }
    setLoading(false);
  };

  // const handleRegisterPayment = (id: string) => {
  //   setFundPayment({ fundId: fund.id, documentId: id });
  //   handleOpenNewPaymentModal(true);
  //   // setOpen(false);
  // };

  // const actions: any = [
  //   {
  //     actionLabel: 'Registrar Pago',
  //     action: handleRegisterPayment,
  //   },
  // ];

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => handleOpenAssignationModal(),
      name: 'Asignar',
      color: 'primary',
    },
  ];

  useEffect(() => {
    fetchDocuments();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá encontrarás todos los documentos asignados a este fondo"
        variant="body1"
        buttons={buttonsData}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={documents}
        rowsPerPageDefault={50}
      />
      {/* <NewFund
        open={openNewFund}
        setOpen={setOpenNewFund}
        setSuccess={setSuccess}
        setUp={fetchFunds}
      /> */}
      <CustomAlert
        open={success.length}
        title={success}
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default FundDocuments;
