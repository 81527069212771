import { requestHandler } from '../../api';

export const getCertificates = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/settings/certificates`,
    dispatch,
  });
  if (response) return response.data;
};

export const getSelectedCertificate = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/settings/certificates/selected`,
    dispatch,
  });
  if (response) return response.data;
};

export const addOwnCertificates = async (dispatch: any, data: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    headers,
    data,
    url: `internal/settings/certificates/own`,
    dispatch,
  });
  if (response) return response.data;
};

export const addCertificates = async (dispatch: any, data: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const response = await requestHandler({
    method: 'post',
    headers,
    data,
    url: `internal/settings/certificates/other`,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCertificate = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/settings/certificates/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const selectCertificate = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/settings/certificates/${id}/select`,
    dispatch,
  });
  if (response) return response.data;
};
