import React from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { formatDate } from '../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import { CheckCircle } from '@mui/icons-material';

const SuperDebtDialogDetails = (props: any) => {
  const { packages, integrationStatus } = props;
  return <div style={{ paddingTop: '10px' }}></div>;
};

export default SuperDebtDialogDetails;
