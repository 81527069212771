import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  getBusinessPendingBusinessAccounts,
  getPendingBusinessAccounts,
} from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
// import Management from './components/Management';
// import NewManagement from './components/NewManagement';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import NewAccount from './components/NewAccount';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'folio', label: 'Folio' },
  { key: 'businessName', label: 'Acreedor' },
  { key: 'stakeholderName', label: 'Deudor' },
  { key: 'category', label: 'Categoría' },
  { key: 'extraData', label: 'Observación' },
  { key: 'issuedDate', label: 'Fecha', type: 'date' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'paidAt', label: 'Pagado', type: 'date' },
];

const Accounts = (props: any) => {
  const { businessIdentifier } = props;
  const dispatch = useDispatch();
  // get invoices from /api/internal/collection/invoices/:company_id
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState('');
  const [accountType, setAccountType] = useState('Pagar');
  // const [businessIdentifier, setBusinessIdentifier] = useState<any>(null);
  const [filteredAccounts, setFilteredAccounts] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({ paidAt: '' });

  const [value, setValue] = useState(null);

  const newAccount = {
    id: '',
    category: '',
    observation: '',
    type: '',
    createdAt: null,
    amount: 0,
  };
  const [account, setAccount] = useState(newAccount);

  const fetchPendingAccounts = async () => {
    setLoading(true);
    try {
      const response = businessIdentifier
        ? await getBusinessPendingBusinessAccounts(dispatch, businessIdentifier)
        : await getPendingBusinessAccounts(dispatch);
      setAccounts(response);
      const preFilteredAccounts = response.filter(
        (account: any) => account.type === accountType,
      );
      setFilteredAccounts(preFilteredAccounts);
    } catch (error) {
      alert('Error al cargar las cuentas pendientes');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPendingAccounts();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nueva Cuenta',
      action: handleOpen,
      color: 'primary',
    },
  ];

  const handleChange = (event: any, newValue: any) => {
    const accountPrefiltered = accounts.filter(
      (account: any) => account.type === accountType,
    );
    setValue(newValue);
    if (!newValue) {
      // setBusinessIdentifier(null);
      return setFilteredAccounts(accountPrefiltered);
    }
    const filtered = accountPrefiltered.filter(
      (x: any) => x.stakeholderIdentifier === newValue.stakeholderIdentifier,
    );
    // setBusinessIdentifier(newValue.businessIdentifier);
    console.log('filtered', filtered);
    setFilteredAccounts(filtered);
    return;
  };

  const handleChangeType = (newValue: any) => {
    setAccountType(newValue);
    const accountPrefiltered = accounts.filter(
      (account: any) => account.type === newValue,
    );
    setFilter({ status: '' });
    setValue(null);
    // setBusinessIdentifier(null);
    setFilteredAccounts(accountPrefiltered);
  };

  const uniqueAccountForFilter = () => {
    const uniqueAccounts = accounts.reduce((acc: any, account: any) => {
      if (
        !acc.find((x: any) =>
          accountType === 'Pagar'
            ? x.businessIdentifier === account.businessIdentifier
            : x.stakeholderIdentifier === account.stakeholderIdentifier,
        )
      ) {
        acc.push(account);
      }
      return acc;
    }, []);
    return uniqueAccounts.filter((x: any) => x.type === accountType);
  };

  const AutoCompleteFilter = () => (
    <div style={{ flexGrow: 1 }}>
      <Autocomplete
        style={{ width: '100%' }}
        options={uniqueAccountForFilter()}
        value={value}
        getOptionLabel={(option: any) =>
          accountType === 'Pagar'
            ? `${option.businessName} - ${option.businessIdentifier}`
            : `${option.stakeholderName} - ${option.stakeholderIdentifier}`
        }
        renderInput={(params) => (
          <TextField style={{ minWidth: 100 }} {...params} label="Cliente" />
        )}
        onChange={handleChange}
        fullWidth
      />
    </div>
  );

  const handleFilter = (value: string, type: string) => {
    const preFilteredAccounts = accounts.filter(
      (account: any) => account.type === accountType,
    );
    const filterHash = filter;
    filterHash[type] = value;
    const allFiltersAreEmpty = Object.values(filterHash).every(
      (value) => value === '',
    );
    if (allFiltersAreEmpty) {
      setFilter({ paidAt: '' });
      setFilteredAccounts(preFilteredAccounts);
    } else {
      setFilter(filterHash);
      console.log('filterHash', filterHash);
      console.log('value', value);
      const filtered = preFilteredAccounts.filter((document) => {
        return Object.entries(filterHash).every(([key, value]) => {
          if (value === '') return true;
          if (key === 'paidAt') {
            console.log('document', document.paidAt);
            return value === 'Pagado'
              ? document.paidAt
              : document.paidAt === null;
          }
          console.log('document');
          return document[key] === value;
        });
      });
      setFilteredAccounts(filtered);
    }
  };

  const Filter = () => (
    <div style={{ flexGrow: 1 }}>
      <FormControl fullWidth>
        <InputLabel id="filter-label">Estado</InputLabel>
        <Select
          fullWidth
          value={filter['paidAt']}
          onChange={(e) => handleFilter(e.target.value as string, 'paidAt')}
          label={'Estado'}
        >
          <MenuItem value={''}>Todos</MenuItem>
          <MenuItem value={'Pagado'}>Pagado</MenuItem>
          <MenuItem value={'Pendiente'}>Pendiente</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  if (loading && !businessIdentifier)
    return <CenteredCircularProgress type="layout" />;
  if (loading && businessIdentifier)
    return <CenteredCircularProgress relative />;

  return (
    <div>
      <HeaderWithActions
        buttons={!businessIdentifier ? buttons : []}
        variant="body1"
        item={
          !businessIdentifier ? (
            <div style={{ display: 'flex', minWidth: '500px' }}>
              <AutoCompleteFilter />
              <Filter />
            </div>
          ) : (
            <Filter />
          )
        }
        // title="Esto son todos las cuentas pendientes que tienes con tus clientes"
        customTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              onClick={() => handleChangeType('Pagar')}
              style={{
                ...(accountType === 'Pagar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por pagar
            </Typography>
            <Typography style={{ padding: '0px 4px' }}> | </Typography>
            <Typography
              onClick={() => handleChangeType('Cobrar')}
              style={{
                ...(accountType === 'Cobrar' ? { fontWeight: 'bold' } : {}),
                cursor: 'pointer',
              }}
            >
              Por cobrar
            </Typography>
          </div>
        }
      />
      <EnhancedTable
        exportToFile="xlsx"
        dataHeaders={
          accountType === 'Pagar'
            ? headers.filter((x: any) => x.key !== 'stakeholderName')
            : headers.filter((x: any) => x.key !== 'businessName')
        }
        data={filteredAccounts}
        rowsPerPageDefault={50}
        finalSumRow
        // actions={actions}
      />
      {open && (
        <NewAccount
          open={open}
          setOpen={setOpen}
          account={account}
          setAccount={setAccount}
          setUp={fetchPendingAccounts}
          setSuccessOpen={setSuccess}
        />
      )}
      <CustomAlert
        open={success.length}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        text={success}
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Accounts;
