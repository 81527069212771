import { requestHandler } from '../../api';

export const getDebtorConcentrations = async (
  dispatch: any,
  startingDate: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/concentrations/debtor?startingDate=${startingDate}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFundsConcentrations = async (
  dispatch: any,
  startingDate: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/concentrations/funds?startingDate=${startingDate}`,
    dispatch,
  });
  if (response) return response.data;
};
