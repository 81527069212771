import React from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { formatDate } from '../../../../../../lib/functions/utils';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import { CheckCircle } from '@mui/icons-material';

const CobranzaOnlineDialogDetails = (props: any) => {
  const { packages, integrationStatus } = props;
  return (
    <div style={{ paddingTop: '10px' }}>
      <Typography variant="h6">Pricing</Typography>
      <Grid container spacing={2}>
        {packages.map((element: any) => (
          <Grid item xs={4} key={element.id} style={{ display: 'flex' }}>
            <Card sx={{ borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">
                  {element.fixedCost} UF / mes
                </Typography>
                <Typography variant="body1">{element.description}</Typography>
                {integrationStatus &&
                  integrationStatus.integrationPackage.id === element.id && (
                    <LightTooltip
                      title={
                        <Typography style={{ padding: '8px' }}>
                          Integrado el {formatDate(integrationStatus.createdAt)}
                        </Typography>
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '16px',
                          cursor: 'pointer',
                        }}
                      >
                        <CheckCircle
                          style={{
                            fontSize: '14px',
                            marginRight: '4px',
                            color: '#0F23DF',
                          }}
                        />
                        <Typography variant="caption">Seleccionado</Typography>
                      </div>
                    </LightTooltip>
                  )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CobranzaOnlineDialogDetails;
