import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import {
  getBusinessAddresses,
  getBusinessPartners,
  getSIIBusinessDetails,
} from '../../../../../lib/api/business';
import { Typography } from '@mui/material';

const businessDetailsHeaders = [
  { key: 'activitiesStart', label: 'Inicio de actividades', type: 'date' },
  { key: 'capital', label: 'Capital Inicial', type: 'money-K' },
  { key: 'type', label: 'Tipo' },
  { key: 'subType', label: 'Subtipo' },
];

const partnersHeaders = [
  { key: 'name', label: 'Nombre' },
  { key: 'lastName', label: 'Apellido' },
  { key: 'initialCapital', label: 'Capital Inicial', type: 'money-K' },
  { key: 'participation', label: 'Participación', type: 'percentage-soft' },
  { key: 'profits', label: 'Utilidades', type: 'percentage-soft' },
  { key: 'incorporationDate', label: 'Fecha de incorporación', type: 'date' },
];

const addressesHeaders = [
  { key: 'address', label: 'Dirección' },
  { key: 'region', label: 'Región' },
  { key: 'commune', label: 'Comuna' },
];
const BusinessProfileDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const [partners, setPartners] = useState<any>([]);
  const [addresses, setAddresses] = useState<any>([]);

  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const fetchRelations = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    const detailsResponse = await getSIIBusinessDetails(dispatch, id);
    const partnersResponse = await getBusinessPartners(dispatch, id);
    const addressesResponse = await getBusinessAddresses(dispatch, id);

    if (detailsResponse) setDetails(detailsResponse);
    if (partnersResponse) setPartners(partnersResponse);
    if (addressesResponse) setAddresses(addressesResponse);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      <div style={{ padding: '10px 0px' }}>
        <Typography margin="4px 0px" variant="body1" fontWeight="bold">
          Detalles de la empresa
        </Typography>
        <EnhancedTable
          dataHeaders={businessDetailsHeaders}
          data={details}
          rowsPerPageDefault={5}
        />
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Typography margin="4px 0px" variant="body1" fontWeight="bold">
          Socios
        </Typography>
        <EnhancedTable
          dataHeaders={partnersHeaders}
          data={partners}
          rowsPerPageDefault={5}
        />
      </div>
      <div style={{ padding: '10px 0px' }}>
        <Typography margin="4px 0px" variant="body1" fontWeight="bold">
          Direcciones
        </Typography>
        <EnhancedTable
          dataHeaders={addressesHeaders}
          data={addresses}
          rowsPerPageDefault={5}
        />
      </div>

      {loading && <CenteredCircularProgress relative />}
    </div>
  );
};

export default BusinessProfileDetails;
