import {
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { postCollectionManagement } from '../../../../../../../../lib/api';
import {
  getCollectionManagementOptionsByStateId,
  getCollectionManagementStates,
} from '../../../../../../../../lib/api/collectionManagementStates';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import moment from 'moment';

interface propsType {
  openModal: boolean;
  setOpenModal: any;
  invoiceId: string;
  setOpenAlert: any;
  setInvoiceId: any;
  request: any;
  setSelectedRequest: any;
  companyId: number;
  setCompanyId: any;
}

interface CollectionState {
  collectionManagementOptions: any[];
  companyId: number;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

const NewManagement: React.FC<propsType> = (props: propsType) => {
  const [option, setOption] = useState<any>(null);
  const [description, setDescription] = useState('');
  const [checkFields, setCheckField] = useState(false);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [stateId, setStateId] = useState<number>(0);
  const [stateName, setStateName] = useState('');
  const [stateOptions, setStateOptions] = useState<CollectionState[]>([]);
  const [loading, setLoading] = useState(false);
  const [contactMethod, setContactMethod] = useState('');
  const [createdAt, setCreatedAt] = useState(
    moment()
      .utcOffset(new Date().getTimezoneOffset() * -1)
      .format('YYYY-MM-DD'),
  );
  const dispatch = useDispatch();

  const checkInputFields = () => {
    if (description === '' || option === '') {
      return true;
    }
    return false;
  };

  const getOptions = async (selectedStateId: number) => {
    setLoading(true);
    const result = await getCollectionManagementOptionsByStateId(
      dispatch,
      selectedStateId,
    );
    setOptions(result);
    setLoading(false);
  };

  const getStatesOptions = async () => {
    const result = await getCollectionManagementStates(dispatch);
    setStateOptions(result);
  };

  const handleSelectState = async (value: number) => {
    setDisabled(true);
    setStateId(value);
    const selectedState = stateOptions.find((element) => element.id === value);
    setStateName(selectedState ? selectedState.name : '');
    await getOptions(value);
    setDisabled(false);
  };

  useEffect(() => {
    setDisabled(true);
    setStateId(0);
    setStateName('');
    setOption(null);
    setDescription('');
    setCheckField(false);
    getStatesOptions();
    setDisabled(false);
  }, [props.openModal]);

  const handleSend = async () => {
    setCheckField(checkInputFields());
    if (checkInputFields()) return;
    await postCollectionManagement(dispatch, {
      description: description,
      type: contactMethod,
      optionId: option.id,
      documentId: props.invoiceId,
      companyId: props.companyId,
      state: stateName,
      createdAt: new Date(createdAt).toISOString(),
    });
    props.setOpenModal(false);
    props.setOpenAlert(true);
    location.reload();
  };

  const handleClose = () => {
    props.setInvoiceId({});
    props.setSelectedRequest({});
    props.setOpenModal(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={props.openModal} onClose={() => handleClose()}>
      <DialogTitle> Crear nueva gestión de cobranza</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para crear una nueva gestión debes completar la siguiente información
        </DialogContentText>
        <Grid container spacing={2} style={{ paddingTop: '12px' }}>
          <Grid item xs={12}>
            <TextField
              error={checkFields && option === ''}
              select
              value={stateId}
              label="Estado gestión"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                handleSelectState(e.target.value as unknown as number)
              }
              disabled={disabled}
            >
              {stateOptions.map((element: CollectionState) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={checkFields && option === ''}
              select
              value={option}
              label="Subestado de gestión"
              variant="outlined"
              fullWidth
              onChange={(e) => setOption(e.target.value)}
              disabled={disabled || !stateId}
            >
              {options.map((element: any) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Método de contacto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value)}
                disabled={disabled || !stateId || !option}
                label="Método de contacto"
              >
                {['Llamada', 'Correo', 'Whatsapp'].map((element) => {
                  return (
                    <MenuItem key={element} value={element}>
                      {element}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Fecha de creación"
              variant="outlined"
              type="date"
              value={createdAt}
              fullWidth
              onChange={(e) => {
                console.log(e.target.value);
                setCreatedAt(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={checkFields && description === ''}
              id="outlined-basic"
              label="Observación"
              variant="outlined"
              fullWidth
              multiline
              disabled={disabled || !stateId || !option}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => handleClose()}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => handleSend()}
          disabled={disabled || !stateId || !option}
        >
          {' '}
          Crear
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewManagement;
