import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import InforPreview from '../../../../../../../../../common/InforPreview';
import { approveExpressFactoringRequest } from '../../../../../../../../../../../../../lib/api/express';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';

const ShoppingCartFactoringQuoted = (props: any) => {
  const { setOpenShoppingcart, selectedRequest, handleCancelQuote, setUp } =
    props;
  const [loading, setLoading] = React.useState(false);

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      type="Sent"
      status="Cotizada"
      selectedRequest={selectedRequest}
    />
  );

  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const companyRecieverId = user?.clientId;
      if (!companyRecieverId) return setLoading(false);
      await approveExpressFactoringRequest(
        dispatch,
        companyRecieverId,
        selectedRequest.quoteActive.id,
      );
    } catch (error) {
      alert('Error al aceptar la solicitud');
      console.log(error);
    }
    setOpenShoppingcart(false);
    await setUp();
    setLoading(false);
  };
  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <div>
        <SimulationDoneFrozen
          infoDiv={infoDiv}
          selectedRequest={selectedRequest}
        />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingcart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
          <CustomButton color="error" onClick={() => handleCancelQuote()}>
            Rechazar
          </CustomButton>
          <CustomButton onClick={() => handleAccept()} color="primary">
            Aceptar
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringQuoted;
