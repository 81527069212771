import React, { useState, useEffect } from 'react';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { Typography } from '@mui/material';
import { getInvoiceClaimed } from '../../../../../lib/api/dashboard';

const InvoicesClaimed: React.FC = () => {
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRelations = async () => {
      setLoading(true);
      const relations = await getInvoiceClaimed(dispatch);
      console.log(relations);
      setInvoices(relations || []);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <Typography variant="h5">Facturas Reclamadas</Typography>
      <EnhancedTable
        dataHeaders={[
          { key: 'folio', label: 'Folio' },
          { key: 'businessName', label: 'Nombre Emisor' },
          { key: 'stakeholderName', label: 'Nombre receptor' },
          { key: 'amount', label: 'Monto', type: 'money' },
          { key: 'cancelDate', label: 'Fecha de reclamo', type: 'date' },
        ]}
        data={invoices}
        rowsPerPageDefault={5}
      />
    </div>
  );
};

export default InvoicesClaimed;
