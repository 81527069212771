import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { getBusinessTgDebt } from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import { getFiscalDebtCertificate } from '../../../../../lib/api/fiscalDebt';
import CustomAlert from '../../../../../lib/designSystem/Alert';

const TGRDebts: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tgrData, setTgrData] = useState<any>({});
  const [filteredTgrData, setFilteredTgrData] = useState<any>({});
  const [filter, setFilter] = useState('');
  const [loadingCertificate, setLoadingCertificate] = useState(false);
  const [errorCertificate, setErrorCertificate] = useState(false);

  const { id } = useParams<{ id: string }>();

  const fetchRelations = async () => {
    if (!id) return;
    const tgrDataResponse = await getBusinessTgDebt(dispatch, id);
    console.log(tgrDataResponse);
    setTgrData(tgrDataResponse || []);
    handleShowActualDebt(tgrDataResponse);
    setFilter('actual');
    setLoading(false);
  };
  useEffect(() => {
    fetchRelations();
  }, []);

  const handleShowActualDebt = (data?: any) => {
    const workingData = data || tgrData;
    const filteredData = workingData.filter(
      (item: any) =>
        item.debtStatus === 'Vigente' ||
        item.debtStatus === 'Vencida' ||
        item.debtStatus === 'Convenio',
    );
    setFilteredTgrData(filteredData);
  };

  const handleShowAllDebt = (data?: any) => {
    const workingData = data || tgrData;
    setFilteredTgrData(workingData);
  };

  const handleChange = () => {
    if (filter === 'actual') {
      handleShowAllDebt();
      setFilter('all');
    } else {
      handleShowActualDebt();
      setFilter('actual');
    }
  };

  const handleDownloadStatement = async () => {
    setLoadingCertificate(true);
    const url = await getFiscalDebtCertificate(dispatch, id as string);
    if (url) {
      window.open(url, '_blank');
    } else {
      setErrorCertificate(true);
    }
    setLoadingCertificate(false);
  };

  if (loading) return <CenteredCircularProgress />;

  // const Filter = (
  //   <FormControl fullWidth style={{ minWidth: '200px' }}>
  //     <InputLabel id="filter-label">Filtrar por estado</InputLabel>
  //     <Select
  //       labelId="filter-label"
  //       id="Filtrar por estado"
  //       fullWidth
  //       value={filter}
  //       onChange={(e) => handleFilter(e.target.value as string)}
  //       label={'Filtrar por estado'}
  //     >
  //       <MenuItem value={''}>Todos</MenuItem>
  //       {[...new Set(creditLineRequests.map((request) => request.status))].map(
  //         (status) => (
  //           <MenuItem value={status} key={status}>
  //             {status}
  //           </MenuItem>
  //         ),
  //       )}
  //     </Select>
  //   </FormControl>
  // );
  const buttons: CustomButtonProps[] = [
    {
      name: 'Descargar cartola',
      action: handleDownloadStatement,
      color: 'secondary',
      disabled: loadingCertificate,
    },
    {
      name: filter === 'actual' ? 'Ver histórico' : 'Ver vigente',
      action: handleChange,
      color: 'primary',
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderWithActions
            title={
              filter === 'actual'
                ? 'Este es el certificado de deuda actual.'
                : 'Este es el historial de deuda desde que se enroló en la plataforma.'
            }
            buttons={buttons}
            variant="body1"
          />
          <EnhancedTable
            finalSumRow
            exportToFile="xlsx"
            tableSize="small"
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              { key: 'form', label: 'Formulario' },
              { key: 'dueDate', label: 'Vencimiento', type: 'date' },
              { key: 'amount', label: 'Deuda neta', type: 'money' },
              { key: 'adjust', label: 'Reajuste ', type: 'money' },
              { key: 'interest', label: 'Interés', type: 'money' },
              { key: 'penalty', label: 'Multa', type: 'money' },
              { key: 'total', label: 'Total', type: 'money' },
              { key: 'agreementMessage', label: 'Convenio' },
              { key: 'proceedingMessage', label: 'Cobranza' },
              { key: 'debtStatus', label: '', type: 'debt-status' },
            ]}
            data={filteredTgrData}
            rowsPerPageDefault={100}
          />
        </Grid>
      </Grid>
      <CustomAlert
        open={errorCertificate}
        severity="error"
        text="No hay cartola fiscal disponible."
        onClose={() => setErrorCertificate(false)}
      ></CustomAlert>
    </>
  );
};

export default TGRDebts;
