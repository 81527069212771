import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import BusinessInvoicesReceived from './components/receivedInvoices';
import BusinessInvoicesEmitted from './components/emittedInvoices';

const BusinessInvoices = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const { active } = props;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <BusinessInvoicesEmitted active={active} id={id} />
      </Grid>
      <Grid item xs={12}>
        {id && <BusinessInvoicesReceived />}
      </Grid>
    </Grid>
  );
};

export default BusinessInvoices;
