import React from 'react';
import EnhancedTable from '../../../../lib/designSystem/Table';
import { Container, Typography } from '@mui/material';

const SiiComercialYearsHeaders = [
  { key: 'year', label: 'Año comercial' },
  { key: 'businessIdentifier', label: 'RUT' },
  { key: 'businessName', label: 'Razón social' },
  { key: 'salesSegment', label: 'Tramo según ventas' },
  { key: 'minSalesSegmentCLP', label: 'Ventas mínimas (anual)', type: 'money' },
  { key: 'maxSalesSegmentCLP', label: 'Ventas máximas (anual)', type: 'money' },
  { key: 'collaborators', label: 'N° de trabajadores' },
  {
    key: 'createdDate',
    label: 'Inicio actividades',
    type: 'date',
  },
  {
    key: 'termType',
    label: 'Términ',
    type: 'date',
  },
  { key: 'constributorType', label: 'Tipo Contribuyente' },
  { key: 'constributorSubType', label: 'SubTipo Contribuyente' },
  { key: 'economicSegment', label: 'Rubro económico' },
  { key: 'economicSubSegment', label: 'Subrubro económico' },
  { key: 'economicActivity', label: 'Actividad económica' },
  { key: 'region', label: 'Región' },
  { key: 'province', label: 'Provincia' },
  { key: 'commune', label: 'Comuna' },
  { key: 'Rpresunt', label: 'R_PRESUNTA' },
  { key: 'OtherRegimes', label: 'OTROS_REGIMENES' },
];

const SiiComercialYears = (props: any) => {
  const { response, searched } = props;
  console.log(response);

  if (!response.length)
    return (
      <div style={{ padding: '0px' }}>
        <Typography variant="body1">
          {searched ? 'No se encontraron resultados' : ''}{' '}
        </Typography>
      </div>
    );
  return (
    <Container>
      <EnhancedTable
        exportToFile="xlsx"
        dataHeaders={SiiComercialYearsHeaders}
        data={response || []}
        rowsPerPageDefault={10}
      />
    </Container>
  );
};

export default SiiComercialYears;
