import React, { useEffect, useState } from 'react';
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { buttonsAvailable } from './buttonsAvailable';
import { ClientConfigState } from '../../../services/redux/clientConfigSlice';
import SettingsDialog from '../../../pages/ExpressHome/SettingsDialog';
import { Settings } from '@mui/icons-material';

const activeFontColor = '#0F23DF';
const activeWeight = '300';

// Styled List Item Icon with conditional active styles

const useStyles = makeStyles(() =>
  createStyles({
    sidebar: {
      minWidth: 210, // Adjust the width as needed
      height: '90%', // Set the sidebar to full height
      backgroundColor: 'ffffff', // Change the background color
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRight: '1px solid #eaecf0',
    },
    logo: {
      padding: '20px 0px 0px',
      textAlign: 'center',
      width: '100%',
      height: '52px',
      borderBottom: '1px solid #eaecf0',
    },
    upItems: {
      width: '100%',
    },
    navList: {
      width: '80%',
    },
    avatarSection: {
      cursor: 'pointer',
      width: '100%',
      padding: '10px',
      overflowX: 'scroll',
      borderTop: '1px solid #eaecf0',
      display: 'flex',
      alignItems: 'center',
    },
    avatarSectionActive: {
      borderTop: `1px solid ${activeFontColor}`,
    },
    avatarActive: {
      color: `${activeFontColor} !important`,
    },
    avatar: {
      width: 45, // Adjust the size of the avatar as needed
      height: 45, // Match the size of the avatar to the text
      margin: '0px 12px 0px 0px',
    },
  }),
);

const setNavButtons = () => {
  const navButtons: any = [];
  navButtons.push(buttonsAvailable['myInvoices']);
  navButtons.push(buttonsAvailable['requests']);

  const uniqueNavButtons = Array.from(
    new Set(navButtons.map((a: any) => a.label)),
  ).map((label) => {
    return navButtons.find((a: any) => a.label === label);
  });
  return uniqueNavButtons;
};

const ExpressSidebar: React.FC = () => {
  const classes = useStyles();
  const currentPath = window.location.pathname;
  const [openSettings, setOpenSettings] = useState(false);

  const [activeItem, setActiveItem] = useState(''); // Default active item

  const expressConfig = useSelector(
    (state: { clientConfiguration: ClientConfigState }) =>
      state.clientConfiguration.configs,
  );

  const navButtons = setNavButtons();
  useEffect(() => {
    const newActiveItem = currentPath.split('/')[2];
    console.log('active', newActiveItem);
    console.log('active', newActiveItem);
    setActiveItem(newActiveItem);
  }, []);

  const handleNavigation = (id: string) => {
    console.log('id', id);
    const newActiveItem = id.split('/')[1];
    setActiveItem(newActiveItem);
    window.location.href = `/${id}`;
  };

  const StyledListItemButton = styled(ListItemButton)(
    ({ active }: { active: boolean }) => ({
      margin: '6px 8px',
      borderRadius: '16px',
      '& .MuiListItemText-primary': {
        fontSize: 17, // Set the weight you want on hover
      },
      '&:hover': {
        backgroundColor: '#efefef',
        // Target the icons on hover
        '& .MuiListItemIcon-root': {
          color: expressConfig?.pruffConnect_styles_mainColor,
        },
        // Also, make sure the text gets the intended weight
        '& .MuiListItemText-primary': {
          fontWeight: activeWeight, // Set the weight you want on hover
        },
      },
      ...(active && {
        backgroundColor: '#efefef',
        // Also, make sure the text gets the intended weight when active
        '& .MuiListItemText-primary': {
          fontWeight: activeWeight, // Set the weight you want when active
          fontSize: 17, // Set the weight you want on hover
        },
        // Ensure the icons are white when active
      }),
      // Assuming `active` class is applied when the item is active
      '&.active': {
        backgroundColor: '#efefef',
        fontWeight: activeWeight, // Set the weight you want when active
        '& .MuiListItemIcon-root': {},
        // Also, make sure the text gets the intended weight when active
        '& .MuiListItemText-primary': {
          fontWeight: activeWeight, // Set the weight you want when active
        },
      },
    }),
  );

  const StyledListItemIcon = styled(ListItemIcon)(
    ({ active }: { active: boolean }) => ({
      margin: '0px 10px 0px 0px',
      minWidth: 0,
      '&:hover': {
        color: '#ffffff',
      },
      ...(active && {
        color: expressConfig?.pruffConnect_styles_mainColor,
      }),
    }),
  );

  return (
    <div className={classes.sidebar}>
      {/* Logo Section */}
      <div className={classes.upItems}>
        {/* Navigation Section */}
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            paddingTop: 2,
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {navButtons.map((button, index) => {
            const active = button.id.split('/')[1] === activeItem;
            return (
              <div key={index} style={{ position: 'relative' }}>
                {' '}
                {/* Container with relative positioning */}
                <div style={{ padding: '0px 0px' }}>
                  <StyledListItemButton
                    key={index}
                    active={active}
                    onClick={() => handleNavigation(button.id)}
                  >
                    <StyledListItemIcon active={active}>
                      {button.icon}
                    </StyledListItemIcon>

                    <ListItemText primary={button.label} />
                  </StyledListItemButton>
                </div>
                {/* {button.label === 'Financiamiento' &&
                pendingRequestsIcon !== 0 ? (
                  <Typography
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'white',
                      fontSize: 12,
                      padding: '4px 8px',
                      borderRadius: 50,
                      fontWeight: '400',
                      margin: '1px 2px',
                      backgroundColor: '#E8C547',
                    }}
                  >
                    {pendingRequestsIcon}
                  </Typography>
                ) : (
                  <div />
                )} */}
              </div>
            );
          })}
          <StyledListItemButton
            active={false}
            onClick={() => setOpenSettings(true)}
          >
            <StyledListItemIcon active={false}>
              <Settings />
            </StyledListItemIcon>

            <ListItemText primary={'Configuración'} />
          </StyledListItemButton>
        </List>
      </div>
      <SettingsDialog open={openSettings} setOpen={setOpenSettings} />
    </div>
  );
};

export default ExpressSidebar;
