import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { getBusinessInvoicesSummary } from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { formatMoney } from '../../../../../lib/functions/utils';
import BuyAndSalesChart from './Components/LineChart';
import { Info } from '@mui/icons-material';
import { LightTooltip } from '../../../../../lib/designSystem/WhiteTooltip';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import { chartCalculator } from './Utils/chartCalculator';
import { totalCalculator } from './Utils/totalCalculator';
const spanishMonths: any = {
  Enero: 0,
  Febrero: 1,
  Marzo: 2,
  Abril: 3,
  Mayo: 4,
  Junio: 5,
  Julio: 6,
  Agosto: 7,
  Septiembre: 8,
  Octubre: 9,
  Noviembre: 10,
  Diciembre: 11,
};

interface SummaryBoxProps {
  title?: string;
  content?: React.JSX.Element;
  children?: any;
}

const chartDataFilters = [
  'Ventas netas (Venta - NC/Reclamo) 12M',
  'Compras últimos 12M',
  'NC/Reclamo últimos 12M',
  'Ventas últimos 12M',
  'Ventas netas (Venta - NC/Reclamo) 12-24M',
  'Compras 12-24M',
  'NC/Reclamo 12-24M',
  'Ventas 12-24M',
];

const chartDataFiltersColors = {
  'Compras 12-24M': '#0F23DF',
  'Compras últimos 12M': '#03B4DC',
  'NC/Reclamo 12-24M': '#B8336A',
  'NC/Reclamo últimos 12M': '#FF1744',
  'Ventas 12-24M': '#FFC400',
  'Ventas últimos 12M': '#07D2FE',
  'Ventas netas (Venta - NC/Reclamo) 12M': '#4CAF50',
  'Ventas netas (Venta - NC/Reclamo) 12-24M': '#23395B',
};

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #e0e0e0',
      padding: '30px 0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
    },
    numberBox: {
      border: '1px solid #e0e0e0',
      padding: '0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
      tableLayout: 'fixed', // this can help with column overflow issues
      textAlign: 'center',
    },
  }),
);

const SummaryBox: React.FC<SummaryBoxProps> = ({ content, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      {/* <Typography variant="h4">{title}</Typography> */}
      {content}
      {children}
    </div>
  );
};

const DashboardSummary: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [invoicesData, setInvoicesData] = useState<{
    historicData: any;
    dataPayers: any;
    summary: any;
  }>({
    historicData: [],
    summary: { salesAmountAVG: '', buyAmountAVG: '' },
    dataPayers: { total: [] },
  });
  const { id } = useParams<{ id: string }>();
  const [selectedMonthsInvoices, setSelectedMonthsInvoices] = useState<any>([]);
  const [selectedMonthsCreditNotes, setSelectedMonthsCreditNotes] =
    useState<any>([]);
  const [buyInvoiceAVG, setBuyInvoiceAVG] = useState<any>(0);
  const [buyInvoiceAmount, setBuyInvoiceAmount] = useState<any>(0);
  const [salesInvoiceAVG, setSalesInvoiceAVG] = useState<any>(0);
  const [salesInvoiceAmount, setSalesInvoiceAmount] = useState<any>(0);
  // const [salesCancelAmount, setSalesCancelAmount] = useState<any>(0);
  const [salesInvoiceCreditNoteAmount, setSalesInvoiceCreditNoteAmount] =
    useState<any>(0);
  const [salesInvoiceCreditNoteAVG, setSalesInvoiceCReditNoteAVG] =
    useState<any>(0);
  // const [buyCreditNoteAmount, setBuyCreditNoteAmount] = useState<any>(0);
  // const [salesCreditNoteAmount, setSalesCreditNoteAmount] = useState<any>(0);
  const [invoiceDataPayers, setInvoiceDataPayers] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [totals, setTotals] = useState<any>({});
  const [selectedChartDataFilter, setSelectedChartDataFilter] = useState<any>([
    'Ventas netas (Venta - NC/Reclamo) 12M',
    'Compras últimos 12M',
  ]);

  const DetailsFilter = (props: any) => {
    const { type } = props;
    const dateSetter =
      type === 'invoice'
        ? setSelectedMonthsInvoices
        : setSelectedMonthsCreditNotes;
    const selectedMonths =
      type === 'invoice' ? selectedMonthsInvoices : selectedMonthsCreditNotes;

    const handleSelectChange = (event: any) => {
      const newSelectedMonths = event.target.value;
      if (newSelectedMonths.length === 0) return dateSetter(['Últimos 12M']); // si no hay seleccionamos, volvemos al original

      if (newSelectedMonths.includes('Últimos 24M')) {
        if (!selectedMonths.includes('Últimos 24M')) {
          return dateSetter(['Últimos 24M']);
        }
      }

      if (newSelectedMonths.includes('Últimos 12M')) {
        if (!selectedMonths.includes('Últimos 12M')) {
          return dateSetter(['Últimos 12M']);
        }
      }

      if (newSelectedMonths.includes('Últimos 6M')) {
        if (!selectedMonths.includes('Últimos 6M')) {
          return dateSetter(['Últimos 6M']);
        }
      }

      if (newSelectedMonths.includes('Últimos 3M')) {
        if (!selectedMonths.includes('Últimos 3M')) {
          return dateSetter(['Últimos 3M']);
        }
      }
      const allSelectedAlready =
        selectedMonths.includes('Últimos 24M') ||
        selectedMonths.includes('Últimos 12M') ||
        selectedMonths.includes('Últimos 6M') ||
        selectedMonths.includes('Últimos 3M');
      if (allSelectedAlready && newSelectedMonths.length > 1) {
        const newSelectedMonthsFiltered = newSelectedMonths.filter(
          (month: any) =>
            month !== 'Últimos 24M' &&
            month !== 'Últimos 12M' &&
            month !== 'Últimos 6M' &&
            month !== 'Últimos 3M',
        );
        return dateSetter(newSelectedMonthsFiltered);
      }
      dateSetter(newSelectedMonths);
    };

    const sortedKeysByDate = Object.keys(invoicesData.dataPayers).sort(
      (a, b) => {
        if (a === 'Últimos 24M') return -1;
        if (b === 'Últimos 24M') return 1;
        const [monthA, yearA] = a.split('-');
        const [monthB, yearB] = b.split('-');
        if (yearA !== yearB) return parseInt(yearB) - parseInt(yearA);
        return spanishMonths[monthB] - spanishMonths[monthA];
      },
    );

    return (
      <div style={{ width: '100%' }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={
              type === 'invoice'
                ? selectedMonthsInvoices
                : selectedMonthsCreditNotes
            }
            onChange={handleSelectChange}
            renderValue={(selected) => selected.join(', ')}
          >
            {sortedKeysByDate.map((data: any) => (
              <MenuItem key={data} value={data}>
                <Checkbox
                  checked={
                    (type === 'invoice'
                      ? selectedMonthsInvoices
                      : selectedMonthsCreditNotes
                    ).indexOf(data) > -1
                  }
                />
                <ListItemText primary={data} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const dataPayersToShow = (type: string) => {
    const dataHash = invoicesData;
    const monthsToSum = selectedMonthsInvoices;
    const allMonthPayers = monthsToSum.reduce((acc: any, month: any) => {
      return acc.concat(dataHash.dataPayers[month]);
    }, []);
    const mergedMonthsSumByIdentifier = allMonthPayers.reduce(
      (acc: any, payerObject: any) => {
        if (!acc[payerObject.identifier]) {
          acc[payerObject.identifier] = {
            id: payerObject.identifier,
            name: payerObject.name,
            salesAmount: 0,
            salesDocuments: 0,
            salesDocumentsConfirmed: 0,
            buyAmount: 0,
            salesCancelAmount: 0,
            salesCreditNoteAmount: 0,
            salesCreditNoteDocuments: 0,
          };
        }
        acc[payerObject.identifier].salesAmount += payerObject.salesAmount;
        acc[payerObject.identifier].salesDocuments +=
          payerObject.salesDocuments;
        acc[payerObject.identifier].salesDocumentsConfirmed +=
          payerObject.salesDocumentsConfirmed;
        acc[payerObject.identifier].buyAmount += payerObject.buyAmount;
        acc[payerObject.identifier].salesCancelAmount +=
          payerObject.salesCancelAmount;
        acc[payerObject.identifier].salesCreditNoteAmount +=
          payerObject.salesCreditNoteAmount;
        acc[payerObject.identifier].salesCreditNoteDocuments +=
          payerObject.salesCreditNoteDocuments;
        return acc;
      },
      {},
    );
    let newSalesInvoiceCreditNoteAmount = 0;
    Object.keys(mergedMonthsSumByIdentifier).forEach((key) => {
      const payer = mergedMonthsSumByIdentifier[key];
      if (type === 'invoice') {
        payer.cancelPercentage = !payer.salesAmount
          ? 0
          : payer.salesCancelAmount / payer.salesAmount;
        payer.creditNotePercentage = !payer.salesAmount
          ? 0
          : payer.salesCreditNoteAmount / payer.salesAmount;
        payer.salesDocumentsConfirmedPercentage = !payer.salesDocuments
          ? 0
          : payer.salesDocumentsConfirmed / payer.salesDocuments;
      }
      newSalesInvoiceCreditNoteAmount += payer.salesCreditNoteAmount;
    });
    const mergedMonthsSum: { salesAmount: number; buyAmount: number }[] =
      Object.values(mergedMonthsSumByIdentifier);
    const buySummary = mergedMonthsSum.reduce(
      (acc: any, payer: any) => acc + payer.buyAmount,
      0,
    );
    console.log('buySummary', buySummary);
    const salesSummary = mergedMonthsSum.reduce(
      (acc: any, payer: any) => acc + payer.salesAmount,
      0,
    );
    let monthsToDivide = monthsToSum.length;
    if (monthsToSum[0] === 'Últimos 24M') monthsToDivide = 24;
    else if (monthsToSum[0] === 'Últimos 12M') monthsToDivide = 12;
    else if (monthsToSum[0] === 'Últimos 6M') monthsToDivide = 6;
    else if (monthsToSum[0] === 'Últimos 3M') monthsToDivide = 3;

    setBuyInvoiceAVG(buySummary / monthsToDivide);
    setBuyInvoiceAmount(buySummary);
    setSalesInvoiceAVG(salesSummary / monthsToDivide);
    setSalesInvoiceAmount(salesSummary);
    setInvoiceDataPayers(mergedMonthsSum);
    // setSalesCancelAmount(newSalesCancelAmount);
    setSalesInvoiceCreditNoteAmount(newSalesInvoiceCreditNoteAmount);
    setSalesInvoiceCReditNoteAVG(
      newSalesInvoiceCreditNoteAmount / monthsToDivide,
    );
  };

  const handleSelectChartDataFilter = (selectedFilters: any) => {
    setSelectedChartDataFilter(selectedFilters);
    setChartData(
      chartCalculator(
        invoicesData,
        selected,
        selectedFilters,
        selected.length === 0,
      ),
    );
  };

  useEffect(() => {
    const fetchRelations = async () => {
      if (!id) return;
      const invoices = await getBusinessInvoicesSummary(
        dispatch,
        id,
        'invoices',
      );
      setInvoicesData(invoices || []);
      setSelectedMonthsInvoices(['Últimos 12M']);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  useEffect(() => {
    dataPayersToShow('invoice');
  }, [selectedMonthsInvoices]);

  useEffect(() => {
    setChartData(
      chartCalculator(
        invoicesData,
        selected,
        selectedChartDataFilter,
        selected.length === 0,
      ),
    );
    setTotals(totalCalculator(invoicesData, selected, selectedMonthsInvoices));
  }, [selected, selectedMonthsInvoices]);

  if (loading) return <CenteredCircularProgress />;
  console.log(chartData);
  return (
    <div>
      <Grid container spacing={2} rowSpacing={1}>
        <Grid item xs={8}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">Facturas </Typography>
            <LightTooltip
              placement="right"
              title={
                <Typography variant="body1" padding={1}>
                  Información de los últimos 24 meses
                </Typography>
              }
            >
              <Info style={{ fontSize: 16, marginLeft: 8 }} />
            </LightTooltip>
          </div>
        </Grid>
        <Grid item xs={4}>
          <DetailsFilter type="invoice" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={8}>
              <SummaryBox
                title={`Compras y ventas`}
                content={
                  <>
                    <div
                      style={{
                        padding: '0px 8px 10px 8px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Typography variant="body2">
                          Selecciona los gráficos que deseas ver
                        </Typography>
                      </div>
                      <div>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simplex-select"
                          multiple
                          value={selectedChartDataFilter}
                          onChange={(event) =>
                            handleSelectChartDataFilter(event.target.value)
                          }
                          renderValue={(selectedChartDataFilter) =>
                            selectedChartDataFilter.join(', ')
                          }
                          fullWidth
                          style={{ width: '300px', height: '30px' }}
                        >
                          {chartDataFilters.map((data) => (
                            <MenuItem key={data} value={data}>
                              <Checkbox
                                checked={
                                  selectedChartDataFilter.indexOf(data) > -1
                                }
                              />
                              <ListItemText
                                primary={data}
                                style={{
                                  color:
                                    chartDataFiltersColors[
                                      data as keyof typeof chartDataFiltersColors
                                    ],
                                }}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <BuyAndSalesChart
                      aspect={4.0 / 1.08}
                      yName="Compras"
                      xName="Ventas"
                      xKey="salesAmount"
                      yKey="buyAmount"
                      data={chartData}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Compras total:</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0
                          ? totals.buyAmount
                          : buyInvoiceAmount,
                      )}
                    </Typography>
                  </EnhacedBox>
                </Grid>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Compras (promedio):</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0 ? totals.averageBuy : buyInvoiceAVG,
                      )}
                    </Typography>
                  </EnhacedBox>
                </Grid>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Ventas total:</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0
                          ? totals.salesAmount
                          : salesInvoiceAmount,
                      )}
                    </Typography>
                  </EnhacedBox>
                </Grid>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Ventas (promedio):</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0
                          ? totals.averageSales
                          : salesInvoiceAVG,
                      )}
                    </Typography>
                  </EnhacedBox>
                </Grid>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Venta con NC:</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0
                          ? totals.salesCreditNoteAmount
                          : salesInvoiceCreditNoteAmount,
                      )}
                    </Typography>
                  </EnhacedBox>
                </Grid>
                <Grid item xs={6}>
                  <EnhacedBox small margin="0px" centerText>
                    <Typography variant="body1">
                      <b>Venta con NC (prom.):</b>
                    </Typography>
                    <Typography variant="body1">
                      {formatMoney(
                        selected.length > 0
                          ? totals.averageNC
                          : salesInvoiceCreditNoteAVG,
                      )}{' '}
                      (
                      {selected.length > 0
                        ? (
                            (totals.salesCreditNoteAmount /
                              totals.salesAmount) *
                            100
                          ).toFixed(2)
                        : (
                            (salesInvoiceCreditNoteAmount /
                              salesInvoiceAmount) *
                            100
                          ).toFixed(2)}
                      %)
                    </Typography>
                  </EnhacedBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <EnhancedTable
                selected={selected}
                setSelected={setSelected}
                finalSumRow
                tableSize="small"
                exportToFile="xlsx"
                dataHeaders={[
                  { key: 'name', label: 'Razon Social' },
                  { key: 'buyAmount', label: 'Compras', type: 'money' },
                  { key: 'salesAmount', label: 'Ventas', type: 'money' },
                  {
                    key: 'salesDocuments',
                    label: 'N° Ventas',
                  },
                  {
                    key: 'salesDocumentsConfirmedPercentage',
                    label: 'Acuse',
                    type: 'percentage',
                  },
                  {
                    key: 'creditNotePercentage',
                    label: 'NC / Ventas',
                    type: 'percentage',
                  },
                  {
                    key: 'salesCreditNoteAmount',
                    label: 'NC monto',
                    type: 'money',
                  },
                  {
                    key: 'salesCreditNoteDocuments',
                    label: 'N° NC ',
                  },
                ]}
                data={invoiceDataPayers.map((payer: any) => {
                  return {
                    ...payer,
                  };
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardSummary;
