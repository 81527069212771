import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Autocomplete,
  TextField,
} from '@mui/material';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import {
  createCreditLine,
  getAvailableClientsProductsForCreditLine,
} from '../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { AuthState } from '../../../../../../../../../services/redux/authSlice';

const CreateCreditLineDialog = (props: any) => {
  const { open, setOpen, business, handleDetails, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [creditLine, setCreditLine] = useState({
    product: '',
    businessIdentifier: business ? business.identifier : '',
  });
  const [clientsWithAvailableProductHash, setClientsWithAvailableProductsHash] =
    useState<any>({});
  const clientsWithAvailableProductArray = Object.values(
    clientsWithAvailableProductHash,
  );
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const dispatch = useDispatch();

  const handleSelectBusiness = (event: any, newValue: any) => {
    console.log(newValue);
    setCreditLine({
      ...creditLine,
      businessIdentifier: newValue.businessIdentifier,
    });
  };

  const handleChange = (e: any) => {
    setCreditLine({ ...creditLine, [e.target.name]: e.target.value });
  };

  const handleCreateNew = async () => {
    setLoading(true);
    try {
      const creditLineResponse = await createCreditLine(dispatch, {
        businessIdentifier: creditLine.businessIdentifier,
        status: 'No solicitada',
        productId: creditLine.product,
        creatorId: user?.id,
      });
      setLoading(false);
      await setUp();
      return await handleDetails(creditLineResponse.id);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpOptions = async () => {
    setLoading(true);
    try {
      const response = await getAvailableClientsProductsForCreditLine(dispatch);
      console.log(response);
      setClientsWithAvailableProductsHash(response);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const businessAvailableProducts = creditLine.businessIdentifier.length
    ? clientsWithAvailableProductHash[creditLine.businessIdentifier]
        .productAvailable
    : [];

  useEffect(() => {
    setUpOptions();
  }, []);

  return (
    <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Solicitar línea de crédito</DialogTitle>
      {loading && (
        <DialogContent style={{ width: '500px' }}>
          <CenteredCircularProgress type="dialog" />
        </DialogContent>
      )}
      {!loading && (
        <DialogContent style={{ width: '500px' }}>
          <Typography variant="body1" gutterBottom>
            Acá podrás solicitar una línea de crédito para un cliente en algun
            producto en específico.
          </Typography>
          {!business && (
            <div style={{ padding: '10px 0px' }}>
              <Autocomplete
                options={clientsWithAvailableProductArray}
                getOptionLabel={(option: any) =>
                  `${option.businessName} - ${option.businessIdentifier}`
                }
                renderInput={(params) => (
                  <TextField
                    style={{ minWidth: 250 }}
                    {...params}
                    label="Selecciona el cliente "
                  />
                )}
                onChange={handleSelectBusiness}
                fullWidth
              />
            </div>
          )}
          <div style={{ padding: '10px 0px' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="filter-label">Productos disponibles</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                name="product"
                value={creditLine.product}
                onChange={(e: any) => handleChange(e)}
                label="Productos disponibles"
              >
                {businessAvailableProducts.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
      )}
      {!loading && (
        <DialogActions>
          <CustomButton
            onClick={() => setOpen(false)}
            color="secondary"
            variant="contained"
          >
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => handleCreateNew()}
            color="primary"
            variant="contained"
          >
            Comenzar
          </CustomButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CreateCreditLineDialog;
