import ExcelJS from 'exceljs';
import { Header } from '../../Table';
import { formatDate } from '../../../functions/utils';

export const exportToExcel = async (
  data: any[],
  headers: Header[],
  fileName: string = `${new Date().toDateString()}.xlsx`,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  const parsedData: any[][] = [headers.map((header) => header.label)];
  for (const row of data) {
    const rowValues = headers.map((header) =>
      header.type === 'date' ? formatDate(row[header.key]) : row[header.key],
    );
    parsedData.push(rowValues);
  }
  // Add data to worksheet
  parsedData.forEach((rowData, rowIndex) => {
    const row = worksheet.addRow(rowData);
    if (rowIndex === 0) {
      // Make the first row bold, visible, and add grey background color
      row.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getColumn(cell.col).width = 20;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFDDDDDD' }, // Grey background color
        };
      });
    }
  });
  // Save workbook
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
};
