import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Tree from 'react-d3-tree';
import { LightTooltip } from '../../../../../../../../../../../lib/designSystem/WhiteTooltip';
import TooltipContent from './components/explanationTooltip';

const buildTree = (nodes: any) => {
  // Map nodes by id for quick lookup
  console.log(nodes);
  const nodesMap = new Map(
    nodes.map((node: any) => [node.id, { ...node, children: [] }]),
  );

  // Function to recursively build the tree
  const buildTreeRecursive = (nodeId: any) => {
    const node: any = nodesMap.get(nodeId);
    // Find children of the current node from ranges
    const childrenIds = (node.ranges || []).map((range: any) => range.nodeId);
    // Recursively build the tree for each child
    node.children = childrenIds.map((childId: any) =>
      buildTreeRecursive(childId),
    );
    return node;
  };

  // Find root nodes (nodes without a parent)
  const allChildIds = new Set(
    nodes.flatMap((node: any) =>
      (node.ranges || []).map((range: any) => range.nodeId),
    ),
  );
  const rootNodes = nodes.filter((node: any) => !allChildIds.has(node.id));

  // Build the tree starting from each root node
  // Note: This supports multiple root nodes
  const tree = rootNodes.map((rootNode: any) =>
    buildTreeRecursive(rootNode.id),
  );

  // If there's only one root node, return it directly; otherwise, wrap in a common root
  return tree.length === 1 ? tree[0] : { name: 'Root', children: tree };
};

const textStyle = {
  fontSize: '16px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
};

export const circleColor = (node: any) => {
  switch (node.type) {
    case 'final':
      switch (node.name) {
        case 'Aprobado':
          return '#3bc14a';
        case 'Rechazado':
          return '#D62839';
        default:
          return '#E8C547';
      }
    default:
      return '#0F23DF';
  }
};

const renderCircleSvgNode = ({
  nodeDatum,
  toggleNode,
  isHovered,
  handleClick,
  handleHover,
}: any) => (
  <g
    onMouseOver={() => handleHover(nodeDatum.id, true)}
    onMouseOut={() => handleHover(nodeDatum.id, false)}
    onClick={() => handleClick(nodeDatum.id, true)}
  >
    <circle
      r="20"
      fill={circleColor(nodeDatum)}
      stroke="none"
      onClick={toggleNode}
    />
    <text
      fill="white"
      strokeWidth="0"
      textAnchor="middle"
      style={textStyle}
      x="0"
      y="5"
      fontSize="12px"
    >
      {nodeDatum.id}
    </text>
    <foreignObject
      x={-((nodeDatum.value.length ? nodeDatum.value.length : 7) * 12 + 20)}
      y="-30"
      width="30%"
      height="30"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          fontWeight: 'bold',
        }}
      >
        <LightTooltip
          title={<TooltipContent nodeDatum={nodeDatum} />}
          placement="left"
          open={isHovered}
        >
          <div>
            {nodeDatum.fatherNodeId !== 0 && (
              <Typography fontSize={16} variant="body1">
                {nodeDatum.fatherNodeId !== 0 && nodeDatum.value !== ''
                  ? `${nodeDatum.value}`
                  : 'Caso Base'}
              </Typography>
            )}
          </div>
        </LightTooltip>
      </div>
    </foreignObject>
  </g>
);

const TreeVisualization = ({ nodes }: any) => {
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);

  const handleHover = (nodeId: string, isHover: boolean) => {
    console.log('Nodo hover:', nodeId);
    setHoveredNode(isHover ? nodeId : null);
  };
  const handleNodeClick = (nodeId: string, isHover: boolean) => {
    console.log('Nodo clickeado:', nodeId);
    // Aquí puedes manejar la lógica adicional que necesites
  };

  if (!nodes.length) {
    return (
      <div style={{ padding: '20px 0px' }}>
        <Typography variant="body1">
          No hay nodos creados todavía, puedes comenzar a agregarlos aquí a la
          derecha.
        </Typography>
      </div>
    );
  }
  const treeData = buildTree(nodes);

  const containerWidth = 1000;
  const containerHeight = 800;
  const initialTranslate = {
    x: containerWidth / 1.6,
    y: containerHeight / 30,
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
      <Tree
        data={treeData}
        zoom={1 / (nodes.length / 12)}
        orientation="vertical"
        pathFunc="diagonal"
        renderCustomNodeElement={(rd3tProps: any) => {
          return renderCircleSvgNode({
            ...rd3tProps,
            isHovered: hoveredNode === rd3tProps.nodeDatum.id,
            handleClick: handleNodeClick,
            handleHover,
          });
        }}
        translate={initialTranslate}
      />
    </div>
  );
};

export default TreeVisualization;
