import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../../../../../../lib/designSystem/Table';
import { validatePermissions } from '../../../../../../../../../../../lib/functions/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { DialogActions, Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import { useParams } from 'react-router-dom';
import {
  executeCession,
  getCompanyDetails,
  getSelectedCertificate,
} from '../../../../../../../../../../../lib/api/express';
import CessionConfirmationDialog from './components/confirmation';

const documentsHeaders = [
  { key: 'folio', label: 'Folio' },
  {
    key: 'stakeholderName',
    label: 'Pagador',
  },
  {
    key: 'cedded',
    label: 'Cedida',
    type: 'cession-lights',
    canNull: true,
  },
  {
    key: 'confirmDate',
    label: 'Confirmada',
    type: 'confirm-lights',
    canNull: true,
  },
  {
    key: 'cancelDate',
    label: 'Reclamos',
    type: 'cancel-lights',
    canNull: true,
  },
  {
    key: 'amountDocument',
    label: 'Monto',
    type: 'money',
  },
];

const DocumentCessionModule = (props: any) => {
  const {
    selectedRequest,
    setOpenShoppingCart,
    setSuccessOpen,
    setFailureOpen,
  } = props;
  const { clientId } = useParams<{
    mainPage: string;
    subPage: string;
    clientId: string;
  }>();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [openConfirmationModule, setOpenConfirmationModule] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
  const [passphrase, setPassphrase] = useState<string>('');
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const cantSelectRow = (row: any) => {
    if (row.cedded === 'No cedido') return false;

    return true;
  };
  const openCessionModalConfirm = () => {
    setOpenConfirmationModule(true);
  };

  const handleCession = async () => {
    setLoading(true);
    try {
      const folios = selectedRequest.documents
        .filter((doc: any) => selected.includes(doc.id))
        .map((doc: any) => doc.folio);
      const cessionResponse = await executeCession(
        dispatch,
        clientId as string,
        {
          certificateId: selectedCertificate.id,
          passphrase,
          folios,
        },
      );
      if (cessionResponse.status === 'failed') {
        setFailureOpen(
          `Error al ceder documentos. ${
            cessionResponse.successFolios.length
              ? `Solo se cedieron los siguientes folios: ${cessionResponse.successFolios}. En unos minutos se reflejarán los cambios.`
              : ''
          } Los siguientes folios no pudieron ser cedidos: ${cessionResponse.failedFolios}`,
        );
      } else {
        setSuccessOpen(
          `Documentos cedidos correctamente. Folios: ${cessionResponse.successFolios}. En unos minutos se reflejarán los cambios.`,
        );
        setOpenConfirmationModule(false);
        setOpenShoppingCart(false);
      }
      setOpenConfirmationModule(false);
    } catch (error) {
      setFailureOpen(`Error al ceder documentos.`);
    }
    setLoading(false);
  };

  const canSelect = () => {
    if (!selectedCertificate) return false;
    if (!validatePermissions(user, 'factoring_cession_create')) return false;
    return true;
  };

  const buttons: CustomButtonProps[] = [
    {
      name: 'Ceder',
      action: openCessionModalConfirm,
      color: 'primary',
      disabled:
        !validatePermissions(user, 'factoring_cession_create') ||
        !selected.length ||
        !selectedCertificate,
    },
  ];

  const setUpCompanyDetails = async () => {
    setLoading(true);
    try {
      const response = await getCompanyDetails(dispatch, clientId as string);
      const selectedCertificateFromResponse = await getSelectedCertificate(
        dispatch,
        clientId as string,
      );
      setSelectedCertificate(selectedCertificateFromResponse);
      setCompanyDetails(response);
    } catch (error) {
      console.log(selectedCertificate);
      console.log(error);
      alert('No se pudieron obtener los detalles de la empresa');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpCompanyDetails();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions variant="body1" buttons={buttons} />
      <EnhancedTable
        dataHeaders={documentsHeaders}
        data={selectedRequest.documents}
        rowsPerPageDefault={5}
        finalSumRow
        selected={canSelect() ? selected : null}
        setSelected={setSelected}
        cantSelectRow={cantSelectRow}
      />
      {!selectedCertificate && (
        <Typography margin="dense" variant="caption" color="error">
          Para poder ceder facturas, debes tener un certificado seleccionado el
          cual tenga acceso a la empresa.
        </Typography>
      )}
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Salir
        </CustomButton>
      </DialogActions>
      {openConfirmationModule && (
        <CessionConfirmationDialog
          open={openConfirmationModule}
          setOpen={setOpenConfirmationModule}
          handleCession={handleCession}
          passphrase={passphrase}
          setPassphrase={setPassphrase}
          selectedCertificate={selectedCertificate}
          companyDetails={companyDetails}
          selected={selected}
        />
      )}
    </div>
  );
};

export default DocumentCessionModule;
