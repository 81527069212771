import React, { useEffect, useState } from 'react';
import {
  createBusinessBankAccount,
  updateBusinessBankAccount,
} from '../../api';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import CustomButton from '../../designSystem/Button';
import CenteredCircularProgress from '../../designSystem/CircularProgress';
import {
  expressCreateBankAccount,
  expressUpdateBankAccount,
} from '../../api/express/bankAccounts';
import CompanyBusinessSearcher from '../CompanyBusinessSearcher';
import BusinessSearcher from '../BusinessSearcher/businessSearcher';

export const bankCodes = [
  { id: 1, name: 'BANCO DE CHILE' },
  { id: 9, name: 'BANCO INTERNACIONAL' },
  { id: 12, name: 'BANCO DEL ESTADO DE CHILE' },
  { id: 14, name: 'SCOTIABANK SUD AMERICANO' },
  { id: 16, name: 'BANCO CREDITO E INVERSIONES' },
  { id: 17, name: 'BANCO DO BRASIL S.A' },
  { id: 18, name: 'BANCO CENTRAL DE CHILE' },
  { id: 27, name: 'CORPBANCA' },
  { id: 28, name: 'BANCO BICE' },
  { id: 29, name: 'BANCO DE A. EDWARDS' },
  { id: 31, name: 'HSBC BANK (CHILE)' },
  { id: 33, name: 'CITIBANK N.A.' },
  { id: 35, name: 'BANCO DE SANTIAGO' },
  { id: 37, name: 'BANCO SANTANDER CHILE' },
  { id: 38, name: 'BANCO EXTERIOR' },
  { id: 39, name: 'BANCO ITAU' },
  { id: 41, name: 'JP MORGAN CHASE BANK, N.A.' },
  { id: 43, name: 'BANCO DE LA NACION ARGENTINA' },
  { id: 45, name: 'THE BANK OF TOKYO MITSUBISHI UFJ,LTD.' },
  { id: 46, name: 'ABN AMRO BANK (CHILE)' },
  { id: 49, name: 'BANCO SECURITY' },
  { id: 51, name: 'BANCO FALABELLA' },
  { id: 52, name: 'DEUTSCHE BANK (CHILE)' },
  { id: 53, name: 'BANCO RIPLEY' },
  { id: 54, name: 'RABOBANK' },
  { id: 55, name: 'BANCO CONSORCIO' },
  { id: 56, name: 'BANCO PENTA' },
  { id: 57, name: 'BANCO PARIS' },
  { id: 504, name: 'BANCO BILBAO VIZCAYA ARGENTARIA, CHILE' },
  { id: 507, name: 'BANCO DEL DESARROLLO' },
  { id: 734, name: 'FINANCIERA CONOSUR' },
];

const accountTypes = [
  { id: 'Cuenta corriente', name: 'Cuenta corriente' },
  { id: 'Cuenta vista', name: 'Cuenta vista' },
  { id: 'Cuenta de ahorro', name: 'Cuenta de ahorro' },
];

interface BankAccountDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  businessId?: string;
  setSuccess: (value: string) => void;
  setFailure: (value: string) => void;
  handleSuccessExit?: () => void;
  selectedBankAccount: any;
  setSelectedBankAccount: (value: any) => void;
  clientId?: string;
}

const BankAccountDialog = (props: BankAccountDialogProps) => {
  const {
    open,
    setOpen,
    businessId,
    setSuccess,
    setFailure,
    handleSuccessExit,
    selectedBankAccount,
    setSelectedBankAccount,
    clientId,
  } = props;
  const [businessIsHolder, setBusinessIsHolder] = useState(true);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState<any>(null);
  const [searched, setSearched] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateBankAccount = async (payload: any) => {
    if (clientId) {
      return expressUpdateBankAccount(
        dispatch,
        clientId,
        selectedBankAccount.id,
        payload,
      );
    }
    if (!businessId) {
      alert('No se ha seleccionado una empresa');
      return;
    }
    return updateBusinessBankAccount(
      dispatch,
      businessId,
      selectedBankAccount.id,
      payload,
    );
  };

  const handleCreateBankAccount = async (payload: any) => {
    if (clientId) {
      return expressCreateBankAccount(dispatch, clientId, payload);
    }
    console.log(businessId, business);
    if (!businessId && !business) {
      alert('No se ha seleccionado una empresa');
      return;
    }
    return createBusinessBankAccount(
      dispatch,
      businessId || business.identifier,
      payload,
    );
  };

  const handleAccountNumber = (e: any) => {
    if (isNaN(e.target.value)) return;
    setSelectedBankAccount({
      ...selectedBankAccount,
      accountNumber: e.target.value,
    });
  };

  const validateAttributes = () => {
    if (
      selectedBankAccount.bankId === '' ||
      selectedBankAccount.accountType === '' ||
      selectedBankAccount.accountNumber === '' ||
      selectedBankAccount.currency === ''
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    if (!businessIsHolder) {
      if (
        selectedBankAccount.holderName === '' ||
        selectedBankAccount.holderIdentifier === ''
      ) {
        alert('Debes completar todos los campos');
        return false;
      }
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateAttributes()) {
      return;
    }
    setLoading(true);
    try {
      const businessIdentifier = businessId
        ? String(businessId)
        : business.identifier;
      const payload: any = {
        bankName: bankCodes.find(
          (x: any) => x.id === selectedBankAccount.bankId,
        )?.name,
        bankId: selectedBankAccount.bankId,
        type: selectedBankAccount.accountType,
        accountNumber: selectedBankAccount.accountNumber,
        currency: selectedBankAccount.currency,
        email: selectedBankAccount.email,
        holderName: selectedBankAccount.holderName,
        holderIdentifier: selectedBankAccount.holderIdentifier,
        businessIsHolder,
        businessIdentifier,
      };
      console.log(payload);
      const response = selectedBankAccount.id
        ? await handleUpdateBankAccount(payload)
        : await handleCreateBankAccount(payload);
      if (response) {
        setSuccess('Cuenta bancaria guardada correctamente');
        handleSuccessExit && (await handleSuccessExit());
        setOpen(false);
      }
    } catch (error) {
      setFailure('Error al guardar la cuenta bancaria');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (businessId) setSearched(true);
  }, [businessId]);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {selectedBankAccount.id ? 'Editar' : 'Crear'} cuenta bancaria
      </DialogTitle>
      <DialogContent>
        {!businessId && (
          <div>
            <BusinessSearcher
              setBusiness={setBusiness}
              setSearched={setSearched}
            />
          </div>
        )}
        {(businessId || business) && searched && (
          <div>
            <Divider />
            <FormControl margin="dense" fullWidth>
              <InputLabel id="filter-label">Banco</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={selectedBankAccount.bankId}
                onChange={(e) =>
                  setSelectedBankAccount({
                    ...selectedBankAccount,
                    bankId: e.target.value,
                  })
                }
                label="Banco"
              >
                {bankCodes.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <InputLabel id="filter-label">Tipo</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={selectedBankAccount.accountType}
                onChange={(e) =>
                  setSelectedBankAccount({
                    ...selectedBankAccount,
                    accountType: e.target.value,
                  })
                }
                label="Tipo"
              >
                {accountTypes.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Número de cuenta"
              value={selectedBankAccount.accountNumber}
              fullWidth
              margin="dense"
              onChange={(e) => handleAccountNumber(e)}
            />
            <TextField
              label="Email (Opcional)"
              value={selectedBankAccount.email}
              fullWidth
              margin="dense"
              onChange={(e) =>
                setSelectedBankAccount({
                  ...selectedBankAccount,
                  email: e.target.value,
                })
              }
            />
            <FormControl margin="dense" fullWidth>
              <InputLabel id="filter-label">Moneda</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                fullWidth
                value={selectedBankAccount.currency}
                onChange={(e) =>
                  setSelectedBankAccount({
                    ...selectedBankAccount,
                    currency: e.target.value,
                  })
                }
                label="Moneda"
              >
                {['CLP', 'USD'].map((x: any, index: any) => (
                  <MenuItem key={index} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              {/* Add a checbox to see if we have to open a form for holder name and holder identifier */}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={businessIsHolder}
                      onChange={(e) => setBusinessIsHolder(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="¿La empresa es titular de la cuenta?"
                />
                {!businessIsHolder && (
                  <div>
                    <TextField
                      label="Nombre del titular"
                      value={selectedBankAccount.holderName}
                      fullWidth
                      margin="dense"
                      onChange={(e) =>
                        setSelectedBankAccount({
                          ...selectedBankAccount,
                          holderName: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label="RUT del titular"
                      value={selectedBankAccount.holderIdentifier}
                      fullWidth
                      margin="dense"
                      onChange={(e) =>
                        setSelectedBankAccount({
                          ...selectedBankAccount,
                          holderIdentifier: e.target.value,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Salir
        </CustomButton>
        <CustomButton color="primary" onClick={handleSave}>
          Guardar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default BankAccountDialog;
