import React, { useEffect, useState } from 'react';
import { getDocumentById } from '../../../../../../../../lib/api/collectionNotifications';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { formatDateToLocal } from '../../../../../../../../lib/designSystem/Table/cellFormat';
import { formatMoney } from '../../../../../../../../lib/functions/utils';

const DocumentSummary = (props: { documentId: string }) => {
  const { documentId } = props;
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<any>(null);

  const setUpDocument = async () => {
    setLoading(true);
    try {
      const document = await getDocumentById(documentId);
      setDocument(document);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUpDocument();
  }, []);

  if (loading)
    return (
      <div style={{ padding: '16px' }}>
        <CircularProgress />
      </div>
    );

  return (
    <div style={{ padding: '16px', minWidth: '300px' }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography fontWeight={600}>Folio</Typography>
          <Typography>{document?.folio}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={600}>Emisor</Typography>
          <Typography>{document?.businessName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={600}>Fecha emisión</Typography>
          <Typography>{formatDateToLocal(document?.issuedDate)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={600}>Monto</Typography>
          <Typography>{formatMoney(document?.amount)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DocumentSummary;
