import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatMoney } from './utils';

export const generateNominee = (id: any, rows: any) => {
  const doc = new jsPDF();
  let startY = 20; // Starting Y position for the first table
  const amountToPayOut = rows.reduce((acc: number, row: any) => {
    return acc + row.amount;
  }, 0);
  const amount = rows.reduce((acc: number, row: any) => {
    return acc + row.amount;
  }, 0);

  const businessIdentifier = rows[0].businessDestinationIdentifier;
  const businessName = rows[0].businessDestinationName;
  doc.setFontSize(14);
  // summary table
  const leftStart = 14;
  const left2column = 120;
  doc.text('Estado de nómina de Giro', leftStart, startY); // Adjust the Y position for the title
  //
  startY = startY + 10;
  const startVariables = leftStart + 36;
  const startVariables2column = left2column + 36;

  doc.setFontSize(10);

  doc.text('Tipo de solicitud:', leftStart, startY);
  doc.text('Factoring', startVariables, startY);

  doc.text('Identificador: ', left2column, startY);
  doc.text(String(id), startVariables2column, startY);

  doc.text('Razón social: ', leftStart, startY + 10);
  doc.text(businessName, startVariables, startY + 10);

  doc.text('Rut: ', left2column, startY + 10);
  doc.text(businessIdentifier, startVariables2column, startY + 10);

  doc.text('Monto operación: ', leftStart, startY + 20);
  doc.text(formatMoney(amount), startVariables, startY + 20);

  doc.text('Monto a girar: ', left2column, startY + 20);
  doc.text(formatMoney(amountToPayOut), startVariables2column, startY + 20);

  doc.setFontSize(12);
  startY = startY + 35;
  doc.text('Transferencias', leftStart, startY); // Adjust the Y position for the title

  // if (user.companyId){
  //   doc.addImage(`https://pruff-public.s3.amazonaws.com/company-logos/${user.companyId}.png`, 'JPEG', 100, startY, 60, 40);
  // }

  const columns = [
    { title: 'N°', dataKey: 'index' },
    { title: 'Banco Origen', dataKey: 'bankAccountOriginName' },
    { title: 'Banco Destino', dataKey: 'bankAccountDestinationName' },
    { title: 'Cuenta', dataKey: 'bankAccountDestinationAccountType' },
    { title: 'N° Cuenta', dataKey: 'bankAccountDestinationNumber' },
    { title: 'Titular', dataKey: 'bankAccountDestinationHolderName' },
    { title: 'Rut', dataKey: 'bankAccountDestinationHolderIdentifier' },
    { title: 'Moneda', dataKey: 'currency' },
    { title: 'Monto', dataKey: 'amountFormatted' },
    { title: 'Giro', dataKey: 'executedAt' },
  ];

  autoTable(doc, {
    columns: columns,
    startY: startY + 5,
    body: rows,
    theme: 'grid',
    headStyles: {
      fillColor: '#efefef', // or '#2980b9' in hex format
      textColor: '#1b1b1b',
      fontStyle: 'bold',
      halign: 'left', // horizontal alignment
      valign: 'middle', // vertical alignment
      lineColor: [44, 62, 80],
      fontSize: 10,
    },
    bodyStyles: {
      textColor: [50, 50, 50],
      fontSize: 8,
    },
    styles: {
      font: 'helvetica', // choose the font
      lineColor: [44, 62, 80],
      lineWidth: 0.1,
    },
  });

  // Save the PDF
  doc.save(`Factoring-Solicitud#${id}-Nómina.pdf`);
};
