import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Dialog,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import PayoutInvoices from '../Invoices';
import PayoutPendingAccounts from '../PendingAccounts';
const NewPayOut = (props: any) => {
  const { setOpen, open, setSuccessOpen, setFailureOpen, setUp } = props;
  const [payOutType, setPayOutType] = useState<null | string>(null);
  const [selected, setSelected] = useState(false);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Nuevo pago</DialogTitle>
      {!selected && (
        <div>
          <DialogContent>
            <Typography variant="body1" margin={'8px 0px'}>
              Seleccione el tipo de pago que desea realizar
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="payOutType">Tipo de pago</InputLabel>
              <Select
                labelId="payOutType"
                value={payOutType}
                onChange={(e) => setPayOutType(e.target.value as string)}
                label="Tipo de pago"
              >
                <MenuItem value="invoices">A proveedores</MenuItem>
                <MenuItem value="pendingAccounts">
                  Devolución de pasivos
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => setOpen(false)}>
              {' '}
              Cancelar{' '}
            </CustomButton>
            <CustomButton color="primary" onClick={() => setSelected(true)}>
              {' '}
              Avanzar{' '}
            </CustomButton>
          </DialogActions>
        </div>
      )}
      {payOutType === 'invoices' && selected && (
        <div>
          <PayoutInvoices
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
            setUp={setUp}
            setOpen={setOpen}
          />
        </div>
      )}
      {payOutType === 'pendingAccounts' && selected && (
        <div>
          <PayoutPendingAccounts
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
            setUp={setUp}
            setOpen={setOpen}
          />
        </div>
      )}
    </Dialog>
  );
};

export default NewPayOut;
