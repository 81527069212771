import React from 'react';
import { Typography } from '@mui/material';
import { operatorTypes } from '../../NewNodeDialog';

const RangesExplanation = (props: any) => {
  const { nodeDatum } = props;
  const baseCase = nodeDatum.ranges.find((range: any) => range.value === '');

  let rangesSorted;
  switch (nodeDatum.operation) {
    case 'eq':
      rangesSorted = nodeDatum.ranges.sort((a: any, b: any) => {
        if (a.value === '') return 1;
        if (b.value === '') return -1;
        return a.value - b.value;
      });
      break;
    case 'gt':
      rangesSorted = nodeDatum.ranges.sort((a: any, b: any) => {
        if (a.value === '') return -1;
        if (b.value === '') return 1;
        return a.value - b.value;
      });
      break;
    case 'lt':
      rangesSorted = nodeDatum.ranges.sort((a: any, b: any) => {
        if (a.value === '') return 1;
        if (b.value === '') return -1;
        return a.value - b.value;
      });
      break;
    default:
      rangesSorted = nodeDatum.ranges;
  }

  return (
    <div>
      {nodeDatum.operation === 'eq' && (
        <div>
          <Typography variant="body1" margin={'8px 0px'}>
            <b>Explicación del nodo:</b>
          </Typography>
          <Typography variant="body1">
            Si el valor es <b>Igual</b> a:
          </Typography>
          <div style={{ paddingLeft: '20px' }}>
            {rangesSorted.map((range: any) => {
              if (range.value === '') return null;
              return (
                <Typography key={range.value} variant="body1">
                  - {range.value} se ejecutará el nodo {range.nodeId}
                </Typography>
              );
            })}
            {baseCase && (
              <Typography variant="body1">
                - Cualquier otro valor se ejecutará el nodo {baseCase.nodeId}
              </Typography>
            )}
          </div>
        </div>
      )}
      {nodeDatum.operation === 'gt' && (
        <div>
          <Typography variant="body1" margin={'8px 0px'}>
            <b>Explicación del nodo:</b>
          </Typography>
          <Typography variant="body1">
            Si el valor es <b>Mayor</b> a:
          </Typography>
          <div style={{ paddingLeft: '20px' }}>
            {rangesSorted.map((range: any) => {
              if (range.value === '') return null;
              return (
                <Typography key={range.value} variant="body1">
                  - {range.value} se ejecutará el nodo {range.nodeId}
                </Typography>
              );
            })}
            {baseCase && (
              <Typography variant="body1">
                - Si el valor es menor a todos, se ejecutará el nodo{' '}
                {baseCase.nodeId}
              </Typography>
            )}
          </div>
        </div>
      )}
      {nodeDatum.operation === 'lt' && (
        <div>
          <Typography variant="body1" margin={'8px 0px'}>
            <b>Explicación del nodo:</b>
          </Typography>
          <Typography variant="body1">
            Si el valor es <b>Menor</b> a:
          </Typography>
          <div style={{ paddingLeft: '20px' }}>
            {rangesSorted.map((range: any) => {
              if (range.value === '') return null;
              return (
                <Typography key={range.value} variant="body1">
                  - {range.value} se ejecutará el nodo {range.nodeId}
                </Typography>
              );
            })}
            {baseCase && (
              <Typography variant="body1">
                - Si el valor es mayor a todos, se ejecutará el nodo{' '}
                {baseCase.nodeId}
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const TooltipContent = ({ nodeDatum }: any) => {
  console.log(nodeDatum);
  const type = operatorTypes.find(
    (operator: any) => operator.id === nodeDatum.operation,
  )?.name;

  return (
    <div style={{ padding: '10px' }}>
      <Typography variant="body1">
        <b>ID:</b> {nodeDatum.id}
      </Typography>
      <Typography variant="body1">
        <b>Nombre:</b> {nodeDatum.name}
      </Typography>
      <Typography variant="body1">
        <b>Descripción:</b> {nodeDatum.description}
      </Typography>
      {nodeDatum.type !== 'final' && (
        <Typography variant="body1">
          <b>Operación:</b> {type}
        </Typography>
      )}
      {nodeDatum.ranges.length ? (
        <div style={{ paddingTop: '10px' }}>
          <RangesExplanation nodeDatum={nodeDatum} />
        </div>
      ) : null}
    </div>
  );
};

export default TooltipContent;
