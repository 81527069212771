export const datesShortCuts = [
  {
    label: 'Última semana',
    startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    endDate: new Date(),
  },
  {
    label: 'Últimos 15 días',
    startDate: new Date(new Date().setDate(new Date().getDate() - 15)),
    endDate: new Date(),
  },
  {
    label: 'Último mes',
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  },
  {
    label: 'Últimos 3 meses',
    startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
    endDate: new Date(),
  },
  {
    label: 'Últimos 6 meses',
    startDate: new Date(new Date().setDate(new Date().getDate() - 180)),
    endDate: new Date(),
  },
  {
    label: 'Último año',
    startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
    endDate: new Date(),
  },
];
