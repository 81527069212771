// LoginForm.tsx
import React, { useState } from 'react';
import { sendPasswordResetEmail } from '../../../../lib/api';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import { Box, Typography } from '@mui/material';
import CustomAlert from '../../../../lib/designSystem/Alert';
import Logo from '../../../../assets/img/pruff9.svg';

const PasswordRecoveryMain = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoding] = useState(false);
  const [failureText, setFailureText] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoding(true);
    const response = await sendPasswordResetEmail({ email });
    console.log(response);
    if (response.success) {
      setSuccess(true);
    } else {
      setFailureText(response.message);
    }
    setLoding(false);
  };
  if (loading) return <CenteredCircularProgress type="dialog" />;
  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100%' }}>
      <Grid container>
        <Grid
          item
          xs={0}
          md={5}
          style={{
            background: 'linear-gradient(to bottom, #0F23DF, #0F23DF, #0F23DF)',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        ></Grid>
        <Grid item xs={12} md={7}>
          <div style={{ width: '60%', margin: 'auto', padding: '' }}>
            <div style={{ textAlign: 'center', padding: '60px 15px 20px' }}>
              <img width={230} src={Logo} />
              <Typography variant="h4" margin="40px 0px">
                Recuperar contraseña{' '}
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <CustomButton
                onClick={(e) => handleSubmit(e)}
                fullWidth
                color="primary"
                variant="contained"
              >
                Enviar link
              </CustomButton>
            </Box>
            {failureText.length ? (
              <Box sx={{ mt: 2 }}>
                <Typography color="error">{failureText}</Typography>
              </Box>
            ) : null}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                ¿Ya tienes una cuenta? <a href={`/`}>Inicia sesión</a>
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          md={4}
          style={{
            background: 'linear-gradient(to bottom, #29B2FF, #38A0FF, #2970FF)',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        />
      </Grid>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text="Se te envió un correo con un link para recuperar tu contraseña"
        onClose={() => (window.location.href = `/`)}
      />
    </div>
  );
};

export default PasswordRecoveryMain;
