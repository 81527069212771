import { requestHandler } from '../../api';

export const uploadFilesFromRequest = async (
  dispatch: any,
  data: any,
  requesData: any,
) => {
  const { requestId, requestType } = requesData;
  console.log('data', data);
  const response = await requestHandler({
    method: 'patch',
    url: `internal/files/requestType/${requestType}/requestId/${requestId}`,
    data,
    headers: {},
    dispatch,
  });
  if (response) return response.data;
};

export const downloadFilesFromRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/files/requestType/${data.requestType}/requestId/${data.requestId}/file/${data.fileName}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getFilesFromRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/files/requestType/${data.requestType}/requestId/${data.requestId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateFileNameFromRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/files/requestType/${data.requestType}/requestId/${data.requestId}/name`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const getRequiredFiles = async (
  dispatch: any,
  requestType: string,
  requestId: string,
  names: string[],
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/files/requestType/${requestType}/requestId/${requestId}/requiredFiles/${names.join(',')}`,
    dispatch,
  });
  if (response) return response.data;
};
