import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { generateRelatedColors } from './generateColors';
import { formatMoney } from '../../../../../../lib/functions/utils';
import { CustomTooltip } from './customTooltip';

const formatYAxis = (tickItem: any) => {
  if (tickItem >= 1000000) {
    return `$${tickItem / 1000000} M`;
  } else if (tickItem >= 1000) {
    return `$${tickItem / 1000} K`;
  }
  return `$${tickItem}`;
};

const sortData = (data: any[]) => {
  return data.sort((a, b) => {
    const aTotal = Object.values(a).reduce(
      (acc: number, curr) => acc + (typeof curr === 'number' ? curr : 0),
      0,
    );
    const bTotal = Object.values(b).reduce(
      (acc: number, curr) => acc + (typeof curr === 'number' ? curr : 0),
      0,
    );
    return bTotal - aTotal;
  });
};

const formatXAxis = (tickItem: any) => {
  if (tickItem.length > 20) {
    return `${tickItem.slice(0, 20)}...`;
  }
  return tickItem;
};
interface FactoringBarChartProps {
  dataChart: any[];
}

const FactoringBarChart = (props: FactoringBarChartProps) => {
  const { dataChart } = props;
  const companies = [
    ...new Set(dataChart.map((item) => Object.keys(item).slice(1)).flat()),
  ];
  const colors = generateRelatedColors(191, 95, 44, companies.length);
  const longestCompanyNameLength = Math.max(
    ...companies.map((company) => company.length),
  );
  // Set the bottom margin based on the longest company name length
  const bottomMargin = longestCompanyNameLength * 4;

  return (
    <ResponsiveContainer width="95%" height={600} style={{ padding: '24px' }}>
      <BarChart
        data={sortData(dataChart)}
        margin={{ top: 10, right: 10, left: 10, bottom: 120 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="factoring"
          angle={-45}
          textAnchor="end"
          tickFormatter={formatXAxis}
        />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip
          content={<CustomTooltip />}
          formatter={formatMoney}
          position={{ y: -100 }}
          cursor={{ fill: 'transparent' }}
        />
        {companies.map((company, index) => (
          <Bar key={index} dataKey={company} stackId="a" fill={colors[index]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FactoringBarChart;
