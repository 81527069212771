import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
// import ExcelJS from 'exceljs';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Uploader from '../../../../../../lib/designSystem/Uploader';
import Papa from 'papaparse';
import CustomButton from '../../../../../../lib/designSystem/Button';
import InvoicesTable from './components/InvoicesTable';
import { validaRut } from '../../../../../../lib/functions/utils';
import {
  executeInvoiceGeneration,
  getBusinessWithCertificates,
} from '../../../../../../lib/api/sii';
import { FormControl, Select, MenuItem } from '@mui/material';

import CustomAlert from '../../../../../../lib/designSystem/Alert';
import TheBillerFinalizedModal from './components/finalizedModal';

const parseCSVToBillerExample = (csvContent: any) => {
  const rows = Papa.parse(csvContent, { header: false }).data;
  const billerExample: any = [];
  let currentInvoice: any = null;

  rows.forEach((row: any) => {
    if (row[0] === 'FACTURA') {
      if (currentInvoice) {
        billerExample.push(currentInvoice);
      }
      currentInvoice = {
        identifier: row[1],
        name: row[2],
        issuedDate: row[3],
        amount: 0, // You will need to calculate this based on details
        amountWithIva: 0, // You will need to calculate this based on details
        commune: row[4],
        address: row[5],
        email: row[6],
        details: [],
      };
    } else if (row[0] === 'DETALLE' && currentInvoice) {
      const item = {
        subject: row[1],
        qty: parseInt(row[2], 10),
        amount: parseFloat(row[3]),
      };
      currentInvoice.details.push(item);
      currentInvoice.amount += item.amount;
    }
  });

  if (currentInvoice) {
    billerExample.push(currentInvoice);
  }

  // Calculate amountWithIva for each invoice
  billerExample.forEach((invoice: any) => {
    invoice.amountWithIva = invoice.amount * 1.19; // Assuming 19% IVA
  });

  return billerExample;
};

const BillerExample = [
  {
    identifier: '11111111-1',
    name: 'Razón social ejemplo',
    issuedDate: new Date().toISOString().split('T')[0],
    amount: 20000,
    amountWithIva: 23800,
    commune: 'Comuna ejemplo',
    address: 'Dirección ejemplo',
    email: 'Email receptor ejemplo',
    details: [
      {
        subject: 'Detalle ejemplo 1',
        qty: 1,
        amount: 10000,
      },
      {
        subject: 'Detalle ejemplo 2',
        qty: 2,
        amount: 10000,
      },
    ],
  },
  {
    identifier: '11111111-1',
    name: 'Razón social 2',
    issuedDate: new Date().toISOString().split('T')[0],
    amount: 10000,
    amountWithIva: 11900,
    commune: 'Comuna ejemplo 2',
    address: 'Dirección ejemplo 2',
    email: 'Email receptor ejemplo 2',
    details: [
      {
        subject: 'Detalle ejemplo 1',
        qty: 1,
        amount: 10000,
      },
    ],
  },
];

const TheBiller: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<any>([]);
  const [openExcelUploader, setOpenExcelUploader] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [passphrase, setPassphrase] = useState('');
  const [selectedCertificate, setSelectedCertificate] = useState<any | null>(
    null,
  );
  const [successInvoices, setSuccessInvoices] = useState<any[]>([]);
  const [resultInvoices, setResultInvoices] = useState<any[]>([]);
  const [availableCertificates, setAvailableCertificates] = useState<any[]>([]);
  const [failed, setFailed] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [finalizedModal, setFinalizedModal] = useState(false);
  const [code, setCode] = useState('33');
  const dispatch = useDispatch();

  const handleCreateInvoices = async () => {
    setLoading(true);
    try {
      const response = await executeInvoiceGeneration(dispatch, {
        invoices,
        certificateId: selectedCertificate.id,
        passphrase,
      });
      if (response.status === 'failed') {
        setFailed(response.message);
        setLoading(false);
        return;
      }
      const erroredInvoices = response.error.map((invoice: any) => ({
        ...invoice,
        status: false,
      }));
      const successInvoices = response.success.map((invoice: any) => ({
        ...invoice,
        status: true,
      }));
      const allInvoices = [...successInvoices, ...erroredInvoices].map(
        (invoice, index) => ({
          index: index + 1,
          ...invoice,
        }),
      );
      setSuccessInvoices(successInvoices);
      setResultInvoices(allInvoices);
      setFinalizedModal(true);
      handleClose();
    } catch (error) {
      console.log('error', error);
      alert(error);
    }
    setLoading(false);
  };

  const addInvoice = () => {
    setInvoices([
      ...invoices,
      {
        identifier: '',
        name: '',
        code: '33',
        issuedDate: '',
        amount: 0,
        amountWithIVA: 0,
        commune: '',
        address: '',
        email: '',
        details: [],
      },
    ]);
  };

  const hanldeChangeCode = (e: any) => {
    const { value } = e.target;
    const newInvoices: any = invoices.map((invoice: any, index: number) => {
      invoice.code = value;
      return invoice;
    });
    setInvoices(newInvoices);
    setCode(value);
  };

  const handleFileChange = async (event: any) => {
    const uploadedFile = event.target.files[0];
    const fileText = await uploadedFile.text();
    const parsedFile = await parseCSVToBillerExample(fileText);
    if (!parsedFile) {
      alert('El archivo no tiene el formato correcto');
      return;
    }
    if (parsedFile.length === 0) {
      alert('No se encontraron facturas en el archivo');
      return;
    }
    setInvoices(parsedFile);
    setSuccess('Facturas cargadas correctamente');
    setOpenExcelUploader(false);
  };

  const downloadCSVExample = async () => {
    const rows = [];
    for (const invoice of BillerExample) {
      rows.push([
        'FACTURA',
        invoice.identifier,
        invoice.name,
        invoice.issuedDate,
        invoice.commune,
        invoice.address,
        invoice.email,
      ]);
      for (const item of invoice.details) {
        rows.push(['DETALLE', item.subject, item.qty, item.amount]);
      }
    }

    const csvContent = rows.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const checkConfirmation = () => {
    if (invoices.length === 0) {
      alert('No hay facturas para confirmar');
      return;
    }
    if (!code) {
      alert('No hay tipo de factura seleccionado');
      return;
    }
    for (let index = 0; index < invoices.length; index++) {
      const invoice = invoices[index];
      if (!invoice.identifier) {
        alert(`La factura ${index + 1} no tiene rut`);
        return;
      }
      if (!validaRut(invoice.identifier)) {
        alert(`La factura ${index + 1} tiene un rut inválido`);
        return;
      }
      if (!invoice.name) {
        alert(`La factura ${index + 1} no tiene razón social`);
        return;
      }
      if (!invoice.issuedDate) {
        alert(`La factura ${index + 1} no tiene fecha de emisión`);
        return;
      }
      if (!invoice.commune) {
        alert(`La factura ${index + 1} no tiene comuna`);
        return;
      }
      if (!invoice.address) {
        alert(`La factura ${index + 1} no tiene dirección`);
        return;
      }
      if (!invoice.email) {
        alert(`La factura ${index + 1} no tiene email`);
        return;
      }
      if (!invoice.details.length) {
        alert(`La factura ${index + 1} no tiene detalles`);
        return;
      }
      for (let j = 0; j < invoice.details.length; j++) {
        const details = invoice.details[j];
        if (!details.subject) {
          alert(
            `La entrada ${j + 1} de la factura ${index + 1} no tiene detalle`,
          );
          return;
        }
        if (!details.qty) {
          alert(
            `La entrada ${j + 1} de la factura ${index + 1} no tiene cantidad`,
          );
          return;
        }
        if (!details.amount) {
          alert(
            `La entrada ${j + 1} de la factura ${index + 1} no tiene monto`,
          );
          return;
        }
      }
    }
    const numberedInvoices = invoices.map((invoice: any, index: number) => ({
      index: index + 1,
      ...invoice,
    }));
    setInvoices(numberedInvoices);
    hanldeChangeCode({ target: { value: code } } as any);
    return setOpenConfirmation(true);
  };

  const buttons: CustomButtonProps[] = [
    {
      action: () => setOpenExcelUploader(true),
      name: 'Agregar csv',
      color: 'secondary',
    },
    {
      action: () => checkConfirmation(),
      name: 'Facturar',
      color: 'primary',
      disabled: invoices.length === 0 || !selectedCertificate,
    },
  ];

  const handleChange = (event: any, newValue: any) => {
    setSelectedCertificate(newValue);
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const selectedCertificateFromResponse =
        await getBusinessWithCertificates(dispatch);
      setAvailableCertificates(selectedCertificateFromResponse);
    } catch (error) {
      alert('No se pudieron obtener los certificados');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleClose = () => {
    setOpenConfirmation(false);
    setPassphrase('');
  };

  if (loading) return <CenteredCircularProgress type="layout" />;

  if (!availableCertificates.length)
    return (
      <div>
        <Typography variant="h5">No hay certificados disponibles</Typography>
        <Typography variant="body1">
          Para emitir facturas al SII, debes ingresar a lo menos un certificado.
          Esto puedes hacerlo desde configuración/SII/certificados
        </Typography>
      </div>
    );
  return (
    <div>
      <HeaderWithActions
        // title="Seleccione el emisor y complete los datos de las facturas."
        customTitle={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Autocomplete
              options={availableCertificates}
              value={selectedCertificate}
              fullWidth
              getOptionLabel={(option: any) =>
                `${option.businessName} (${option.businessIdentifier})`
              }
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione el emisor" />
              )}
              onChange={handleChange}
            />
            <FormControl style={{ marginLeft: 4, width: 200 }} fullWidth>
              <InputLabel id="filter-label">Tipo de factura</InputLabel>
              <Select
                labelId="filter-label"
                fullWidth
                value={code}
                label="Tipo de factura"
                onChange={(e) => hanldeChangeCode(e)}
              >
                <MenuItem value="33">Factura Electrónica </MenuItem>
                <MenuItem value="34">
                  Factura no Afecta o Exenta Electrónica{' '}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        }
        variant="body1"
        buttons={buttons}
      />
      <InvoicesTable invoices={invoices} setInvoices={setInvoices} />
      <DialogActions>
        <CustomButton color="primary" onClick={() => addInvoice()}>
          {' '}
          Agregar factura{' '}
        </CustomButton>
      </DialogActions>
      {finalizedModal && (
        <TheBillerFinalizedModal
          finalizedModal={finalizedModal}
          setFinalizedModal={setFinalizedModal}
          resultInvoices={resultInvoices}
          successInvoices={successInvoices}
          handleClose={handleClose}
        />
      )}
      {openConfirmation && (
        <Dialog
          maxWidth="md"
          fullWidth
          open={openConfirmation}
          onClose={() => handleClose()}
        >
          <DialogTitle>
            <Typography variant="h5">Confirmar facturas</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ padding: '8px 0px ' }}>
              {' '}
              Estás utilizando el certificado de{' '}
              <b>
                {selectedCertificate.businessName} (
                {selectedCertificate.businessIdentifier})
              </b>
              .
            </Typography>
            <Typography>Se emitirán las siguientes facturas al SII</Typography>
            <EnhancedTable
              data={invoices}
              dataHeaders={[
                { key: 'code', label: 'Tipo de factura' },
                { key: 'name', label: 'Empresa' },
                { key: 'identifier', label: 'Rut' },
                { key: 'issuedDate', label: 'Fecha de emisión' },
                { key: 'amount', label: 'Monto', type: 'money' },
                { key: 'amountWithIVA', label: 'Monto con Iva', type: 'money' },
                { key: 'commune', label: 'Comuna' },
                { key: 'address', label: 'Dirección' },
                { key: 'email', label: 'Email' },
              ]}
            />
            <Typography variant="body1" style={{ padding: '8px 0px ' }}>
              Para emitir {invoices.length}{' '}
              {invoices.length > 1 ? 'facturas' : 'factura'}, debes ingresar la
              constraseña del certificado.
            </Typography>
            <TextField
              margin="dense"
              label="Contraseña del certificado"
              variant="outlined"
              type="password"
              fullWidth
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton color="secondary" onClick={() => handleClose()}>
              {' '}
              Cancelar{' '}
            </CustomButton>
            <CustomButton
              color="primary"
              onClick={() => handleCreateInvoices()}
              disabled={!passphrase.length}
            >
              {' '}
              Confirmar{' '}
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      {openExcelUploader && (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openExcelUploader}
          onClose={() => setOpenExcelUploader(false)}
        >
          <DialogTitle>
            <Typography variant="h5">Subir Facturas</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Sube un archivo csv con las facturas que quieras ingresar al SII.
              Este debe seguir el formato de{' '}
              <span
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  color: '#0F23DF',
                }}
                onClick={downloadCSVExample}
              >
                ejemplo
              </span>
              .
            </Typography>
            <Grid item xs={12}>
              <Uploader
                handleFileChange={handleFileChange}
                buttonText="Agregar CSV"
                accept=".csv"
                uploadedFileName={''}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomButton
              color="secondary"
              onClick={() => setOpenExcelUploader(false)}
            >
              {' '}
              Cerrar{' '}
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
      <CustomAlert
        open={failed.length > 0}
        setOpen={setFailed}
        severity="error"
        text={failed}
        handleClose={() => setFailed('')}
      />
      <CustomAlert
        open={success.length > 0}
        setOpen={setSuccess}
        severity="success"
        text={success}
        handleClose={() => setSuccess('')}
      />
    </div>
  );
};

export default TheBiller;
