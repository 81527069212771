import { requestHandler } from '../../api';

export const fetchCreditLineEvaluations = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/product/creditLine`,
    dispatch,
  });
  if (response) return response.data;
};

export const fetchCreditLineEvaluation = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/product/creditLine/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const fetchDecisionEnginesAvailables = async (
  dispatch: any,
  revisionObjective: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/decisionEngine/availables?revisionObjective=${revisionObjective}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createCreditLineEvaluation = async (
  dispatch: any,
  payload: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: 'internal/decisionEngine/creditLine',
    data: payload,
    dispatch,
  });
  if (response) return response.data;
};

export const selectDecisionEngineAsDefault = async (
  dispatch: any,
  engineId: string,
) => {
  const response = await requestHandler({
    method: 'put',
    url: `internal/decisionEngine/${engineId}/default`,
    dispatch,
  });
  if (response) return response.data;
};
