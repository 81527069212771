import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { formatMoney } from '../../functions/utils';

const colors = ['#0F23DF', '#03B4DC', '#07D2FE'];

const CustomPieChart = ({
  data,
  money,
  onClick,
}: {
  data: any;
  money?: boolean;
  onClick?: any;
}) => {
  const coloredData = data.map((entry: any, index: any) => ({
    ...entry,
    color: colors[index % colors.length],
  }));

  // Custom label function
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
    const x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    // Determine text anchor based on position relative to the center
    const textAnchor = x > cx ? 'start' : 'end';

    // Adjust the dominantBaseline based on the angle
    const angleMod = midAngle % 360;
    const dominantBaseline =
      angleMod > 90 && angleMod < 270 ? 'hanging' : 'alphabetic';

    return (
      <text
        style={{
          fontFamily: " 'Nunito', sans-serif",
          fontSize: 13,
          fontWeight: 600,
        }}
        x={x}
        y={y}
        fill={colors[index % colors.length]}
        textAnchor={textAnchor}
        dominantBaseline={dominantBaseline}
      >
        {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ''}
      </text>
    );
  };
  const total = data.reduce((sum: any, entry: any) => sum + entry.value, 0);

  const handleClick = () => {
    console.log(onClick);
    if (onClick) {
      onClick();
    }
  };

  return (
    <ResponsiveContainer width="100%" height={260}>
      <PieChart
        style={{ cursor: 'pointer', padding: '0px 0px', outline: 'none' }}
        {...(onClick && { onClick: handleClick })}
      >
        <Pie
          data={coloredData}
          cx="50%"
          cy="50%"
          z={1000}
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={65}
          outerRadius={100}
          dataKey="value"
        >
          {coloredData.map((entry: any, index: any) => (
            <Cell
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={entry.color}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name) => [
            money ? formatMoney(Number(value)) : value,
            name,
          ]}
          contentStyle={{
            fontFamily: " 'Nunito', sans-serif",
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            border: 'none',
            borderRadius: '4px',
            color: 'black',
          }}
          itemStyle={{ color: 'black' }}
        />
        <text
          style={{ fontFamily: " 'Nunito', sans-serif", fontWeight: '400' }}
          x="50%"
          y="50%"
          fill="#0F23DF"
          textAnchor="middle"
          dominantBaseline="middle"
          className="total-value"
        >
          {money ? `${formatMoney(total / 1000000)}MM` : total}
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
