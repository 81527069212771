import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import {
  deleteCollectionManagementById,
  getCollectionManagementsByCompanyId,
} from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  flattenArrayObject,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import FilterButton from '../../../../../../lib/designSystem/FilterButton';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';

const adminHeaders: { key: string; label: string; type: any }[] = [
  { key: 'element_type', label: 'Tipo', type: 'collection-type' },
  { key: 'createdAt', label: 'Creado', type: 'date' },
  { key: 'invoice_stakeholder_name', label: 'Deudor', type: '' },
  { key: 'invoice_folio', label: 'Folio factura', type: '' },
  { key: 'userOwner_name', label: 'Usuario', type: '' },
  { key: 'state', label: 'Estado gestión', type: '' },
];

const regularHeaders: { key: string; label: string; type: any }[] = [
  { key: 'element_type', label: 'Tipo', type: 'collection-type' },
  { key: 'createdAt', label: 'Creado', type: 'date' },
  { key: 'invoice_stakeholder_name', label: 'Deudor', type: '' },
  { key: 'invoice_folio', label: 'Folio factura', type: '' },
];

const Management: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [collectionsData, setCollectionsData] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [filters, setFilters] = useState<
    { key: string; options: string[]; label: string; value: any }[]
  >([]);
  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteCollectionManagementById(dispatch, id);
      setOpenAlert(true);
      await fetchCollections();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const canShowOpenDetails = (id: number) => {
    return true;
  };

  const actions = [
    {
      action: handleDelete,
      actionLabel: 'Eliminar',
      canShow: canShowOpenDetails,
    },
  ];

  const fetchCollections = async () => {
    const results: any[] =
      flattenArrayObject(await getCollectionManagementsByCompanyId(dispatch)) ||
      [];
    setCollectionsData(results);
    setOriginalData(results);
    const ownerOptions = [
      ...new Set(results.map((x: any) => x.userOwner_name)),
    ];
    const stakeHolderOptions = [
      ...new Set(results.map((x: any) => x.invoice_stakeholder_name)),
    ];
    if (validatePermissions(user, 'collection_admin')) {
      setFilters([
        {
          key: 'invoice_stakeholder_name',
          label: 'Deudor',
          options: stakeHolderOptions,
          value: '',
        },
        {
          key: 'userOwner_name',
          label: 'Usuario',
          options: ownerOptions,
          value: '',
        },
      ]);
    } else {
      setFilters([
        {
          key: 'invoice_stakeholder_name',
          label: 'Deudor',
          options: stakeHolderOptions,
          value: '',
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title={
          'Todas las gestiones de cobranza realizadas' +
          (validatePermissions(user, 'collection_admin')
            ? ' por tu empresa'
            : '')
        }
        variant={'body1'}
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <FilterButton
                buttonLabel="Filtrar"
                filters={filters}
                setData={setCollectionsData}
                originalData={originalData}
              />
            ),
          },
        ]}
      />
      <EnhancedTable
        dataHeaders={
          validatePermissions(user, 'collection_admin')
            ? adminHeaders
            : regularHeaders
        }
        exportToFile="xlsx"
        data={collectionsData}
        rowsPerPageDefault={100}
        actions={actions}
        showActions
      />
      <CustomAlert
        open={openAlert}
        title="Gestión eliminada"
        onClose={() => {
          setOpenAlert(false);
        }}
        setOpen={setOpenAlert}
        text=""
        severity="success"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default Management;
