import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

export const CustomTooltip = (
  tableData: any,
  column1 = 'Costo',
  column2 = 'Valor',
) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>{column1}</TableCell>
        <TableCell>{column2}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tableData.map((row: any, index: number) => (
        <TableRow key={index}>
          <TableCell style={{ ...(row.bold ? { fontWeight: 'bold' } : {}) }}>
            <Typography style={{ textAlign: 'left' }}>{row.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={{ textAlign: 'right' }}>{row.value}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
