import React from 'react';
import { DialogActions } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import InvoicesPreview from '../../../../../../../../../common/documentsPreview';
import InforPreview from '../../../../../../../../../common/InforPreview';

const ShoppingCartFactoringReviewed = (props: any) => {
  const { setOpenShoppingcart, selectedRequest, handleCancelQuote } = props;

  const handleFoward = async () => {
    await handleCancelQuote();
  };

  return (
    <div>
      <div style={{ minHeight: 100 }}>
        <InforPreview
          requestType="factoring"
          status="Revisada"
          type="Sent"
          selectedRequest={selectedRequest}
        />
        <InvoicesPreview selectedRequest={selectedRequest} />
        <DialogActions>
          <CustomButton
            onClick={() => setOpenShoppingcart(false)}
            color="secondary"
          >
            Salir
          </CustomButton>
          <CustomButton color="error" onClick={() => handleFoward()}>
            Cancelar solicitud
          </CustomButton>
        </DialogActions>
      </div>
    </div>
  );
};

export default ShoppingCartFactoringReviewed;
