import { generateNominee } from '../../../../functions/pdfGenerator';
import { formatDate, formatMoney } from '../../../../functions/utils';

export const handleExportGenericPDF = (payments: any, id: string) => {
  const paymentsFormatted = payments.map((x: any, index: number) => {
    return {
      index: index + 1,
      ...x,
      amountFormatted: formatMoney(x.amount),
      executedAt: x.executedAt && formatDate(x.executedAt),
    };
  });
  generateNominee(id, paymentsFormatted);
};
