import { Chip, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../lib/designSystem/Button';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatDate, formatMoney } from '../../../../../lib/functions/utils';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { Campaign } from '@mui/icons-material';

interface InvoiceProps {
  status: any;
  folio: string;
  amount: string;
  issuedDate: string;
  companyOwner: string;
  uuid: string;
  type: string;
  oldBusiness?: string;
  handleClickFromInvoice: any;
}

const Invoice: React.FC<InvoiceProps> = (props) => {
  const navigate = useNavigate();

  const { encryptedCompanyId, encryptedBusinessIdentifier } = useParams<{
    encryptedCompanyId: string;
    encryptedBusinessIdentifier: string;
  }>();

  return (
    <Grid
      container
      style={{
        border: 'solid 0.1px',
        borderColor: '#FFFF',
        padding: '16px',
        borderRadius: '5px',
        backgroundColor: '#FFFF',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px',
        marginTop: '20px',
        alignItems: 'center',
      }}
    >
      <Grid item xs={2}>
        <div>
          <Typography variant="body1"> #{props.folio}</Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div>
          {!props.status && (
            <Chip variant="outlined" label="Pendiente de pago" color="info" />
          )}
          {props.status && (
            <Chip variant="outlined" label="Pagada" color="success" />
          )}
        </div>
      </Grid>
      <Grid item xs={2}>
        <div>
          <Typography variant="body1">
            {' '}
            {formatDate(new Date(props.issuedDate))}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {props.type === 'cession' && (
              <Tooltip
                title={`La factura ha sido cedida por ${props.oldBusiness} a ${props.companyOwner}`}
                arrow
              >
                <SwapHorizontalCircleOutlinedIcon
                  style={{ marginRight: '5px' }}
                />
              </Tooltip>
            )}
            <Typography variant="body1">
              <span>{props.companyOwner} </span>
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div>
          <Typography variant="body1">
            {' '}
            {formatMoney(parseInt(props.amount))}{' '}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div>
          <CustomButton
            startIcon={<Campaign />}
            color="primary"
            variant="contained"
            disabled={props.status}
            onClick={() =>
              props.handleClickFromInvoice(props.amount, props.uuid)
            }
          >
            Informar pago
          </CustomButton>
          {/* <CustomButton
              color="secondary"
              variant="outlined"
              onClick={() =>
                navigate(
                  `../payment/${encryptedCompanyId}/${encryptedBusinessIdentifier}/${props.uuid}`,
                )
              }
            >
              Ver factura
            </CustomButton> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default Invoice;
