import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activateIntegration } from '../../../../../../lib/api';
import Disclaimer from '../../../../../../lib/designSystem/Disclaimer';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { AuthState } from '../../../../../../services/redux/authSlice';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import DialogTitle from '../../../../../../lib/designSystem/DialogTitle';

// TODO: Change disclaimer text to a real one
interface CobranzaOnlineProps {
  id: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  setReload: (reload: number) => void;
  reload: number;
  packages: any[];
}

const CobranzaOnline: React.FC<CobranzaOnlineProps> = (props) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { open, setOpen, id, reload, setReload, packages } = props;
  const [loading, setLoading] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [packageId, setPackageId] = useState('');
  const dispatch = useDispatch();

  const checkElements = () => {
    if (
      contactName &&
      contactLastName &&
      contactPhone &&
      email &&
      password &&
      repeatPassword &&
      packageId
    ) {
      return true;
    }
    setErrorMessage('Todos los campos son requeridos.');
    setShowError(true);
    setError(true);
    return false;
  };

  const checkPasswords = () => {
    if (password === repeatPassword) {
      return true;
    }
    setPasswordError(true);
    setShowError(true);
    setErrorMessage('Las contraseñas no coinciden.');
    return false;
  };

  const handleActivate = async () => {
    if (checkElements() && checkPasswords()) {
      await postActivation();
    }
  };

  const postActivation = async () => {
    setLoading(true);
    try {
      await activateIntegration(dispatch, id, {
        contactName,
        contactLastName,
        contactPhone,
        email,
        password,
        packageId,
      });
      setLoading(false);
      setShowAlert(true);
      setReload(reload + 1);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      console.log(error);
      setErrorMessage('Error al activar la integración, intente nuevamente.');
    }
  };
  return (
    <div>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle setOpen={setOpen}>
          <img
            src="https://pruff-public.s3.amazonaws.com/Integrations/cobranzaOnline.svg"
            alt="cobranzaOnlineLogo"
          />
        </DialogTitle>
        {loading ? (
          <div style={{ minHeight: '200px' }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        ) : (
          <div>
            <DialogContent>
              <div style={{ padding: '12px 0px' }}>
                <Typography fontWeight={700} variant="body1">
                  Configuración
                </Typography>
                <Typography variant="body1">
                  {' '}
                  Rellena este formulario para registrarte en Cobranza Online y
                  poder empezar a publicar facturas morosas en los Burós más
                  importantes.
                </Typography>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Nombre de contacto"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    variant="outlined"
                    margin="none"
                    error={error && !contactName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Apellido de contacto"
                    value={contactLastName}
                    onChange={(e) => setContactLastName(e.target.value)}
                    variant="outlined"
                    margin="none"
                    error={error && !contactLastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Telefono de contacto"
                    value={contactPhone}
                    onChange={(e) => setContactPhone(e.target.value)}
                    variant="outlined"
                    margin="none"
                    error={error && !contactPhone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Correo electronico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="none"
                    variant="outlined"
                    error={error && !email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Crear una contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    margin="none"
                    type="password"
                    error={(error && !password) || passwordError}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Repetir contraseña"
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    variant="outlined"
                    margin="none"
                    type="password"
                    error={(error && !repeatPassword) || passwordError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Plan a contratar
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={packageId}
                      label="Plan a contratar"
                      onChange={(e) => setPackageId(e.target.value)}
                    >
                      {packages.map((pack: any) => (
                        <MenuItem key={pack.id} value={pack.id}>
                          {`${pack.name} | ${pack.fixedCost} UF / mes`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Disclaimer
                text={
                  <span>
                    Al activar esta integración, aceptas los{' '}
                    <a
                      target="_blank"
                      href="https://docs.google.com/document/d/1Pg8049u89a_ijfb-5Qb8hGi6gVEnPHx3DRrStd6gENs/edit"
                      rel="noreferrer"
                    >
                      términos y condiciones
                    </a>{' '}
                    de Cobranza Online.
                  </span>
                }
              />
            </DialogContent>
            <DialogActions>
              <CustomButton
                color="secondary"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Salir
              </CustomButton>
              <CustomButton
                color="primary"
                onClick={() => handleActivate()}
                disabled={loading}
              >
                Activar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <CustomAlert
        open={showError}
        setOpen={setShowError}
        severity="error"
        text={errorMessage}
        title="Error"
      />
      <CustomAlert
        open={showAlert}
        setOpen={setShowAlert}
        severity="success"
        text="Integración activada correctamente."
        title="Éxito"
      />
    </div>
  );
};

export default CobranzaOnline;
