import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import ProductsSummary from './components/Summary';

const tabs = [
  {
    id: 1,
    label: 'Resumen',
    component: <ProductsSummary />,
    path: 'summary',
  },
];

const Products = () => {
  const { page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const navigate = useNavigate();

  const [startValue, setStartValue] = useState(0);
  const handleNavigation = (index: number) => {
    navigate(`/products/${companyTabs[index].path}`);
  };

  const setUpTabs = async () => {
    const newProfileTabs: any = [...tabs];
    let startValueDefault = 0;
    startValueDefault = newProfileTabs.findIndex((x: any) => x.path === page);
    setCompanyTabs(newProfileTabs);
    console.log(newProfileTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);
  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        tabType="secondary"
        startValue={startValue}
        tabs={companyTabs}
        onTabChange={handleNavigation}
        // canShowTab={checkCanAccessView()}
      />
    </div>
  );
};

export default Products;
