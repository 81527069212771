import { CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  cellFormat,
  formatDateToLocal,
} from '../../../../../../lib/designSystem/Table/cellFormat';
import ErrorIcon from '@mui/icons-material/Error';
import FactoringSteps from './components/factoringSteps';
import CreditLineSteps from './components/creditLineSteps';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { syncInvoicesCessionWithSII } from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { validateCompanyTypes } from '../../../../../../lib/functions/utils';
import { Report, Warning } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';

const InforPreview = (props: any) => {
  const {
    selectedRequest,
    status,
    type,
    requestType,
    setSelectedRequest,
    setUp,
  } = props;
  const [miniLoading, setMiniLoading] = useState(false);
  const [nothingNew, setNothingNew] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const canSeeAllTabs = validateCompanyTypes(user, 'FinancingHub');
  const dispatch = useDispatch();

  const handleSyncWithSII = async () => {
    setMiniLoading(true);
    try {
      const response = await syncInvoicesCessionWithSII(dispatch, {
        quoteId: selectedRequest.quoteActive.id,
      });
      addNewSyncDataToInvoices(
        response.documentsWithCessionData,
        selectedRequest.documents,
      );
      console.log('response', response);
      if (response.update) {
        await setUp();
      } else {
        console.log('no hay cambios');
        setNothingNew(true);
      }
    } catch (error) {
      console.log('error', error);
    }
    setMiniLoading(false);
  };

  const addNewSyncDataToInvoices = (
    invoicesWithNewData: any,
    oldsInvoices: any,
  ) => {
    const invoicesMerged = oldsInvoices.map((oldInvoice: any) => {
      const newInvoice = invoicesWithNewData.find(
        (invoice: any) => invoice.id === oldInvoice.id,
      );
      if (newInvoice) {
        return {
          ...oldInvoice,
          cancelDate: newInvoice.cancelled,
          cedded: newInvoice.cedded,
        };
      }
    });
    setSelectedRequest({
      ...selectedRequest,
      invoices: invoicesMerged,
    });
  };

  return (
    <div style={{ paddingTop: 8 }}>
      {type === 'Recieved' ? (
        <div
          style={{
            padding: '0px 0px 12px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight="bold">Cliente</Typography>
          <Typography>{selectedRequest.businessName}</Typography>
        </div>
      ) : (
        selectedRequest.recipient && (
          <div
            style={{
              padding: '8px 0px 12px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography fontWeight="bold">Financiera</Typography>
            <Typography>{selectedRequest.recipientName}</Typography>
          </div>
        )
      )}
      {type === 'Recieved' ? (
        <div
          style={{
            padding: '0px 0px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight="bold">Contacto</Typography>
          <Typography>{selectedRequest.userOwnerName}</Typography>
        </div>
      ) : (
        <div
          style={{
            padding: '0px 0px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight="bold">Ejecutivo</Typography>
          <Typography textAlign="right">
            {selectedRequest.executiveName
              ? `${selectedRequest.executiveName}`
              : 'Sin asignar'}
          </Typography>
        </div>
      )}
      <div
        style={{
          padding: '0px 0px 10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontWeight="bold">Email</Typography>
        {type === 'Recieved' ? (
          <Typography>({selectedRequest.userOwnerEmail})</Typography>
        ) : (
          <Typography textAlign="right">
            {selectedRequest.executiveName
              ? `${selectedRequest.executiveEmail}`
              : 'Sin asignar'}
          </Typography>
        )}
      </div>
      <div
        style={{
          padding: '4px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontWeight="bold">Fecha operación</Typography>
        <Typography>
          {formatDateToLocal(selectedRequest.requestDate)}
        </Typography>
      </div>

      <div
        style={{
          padding: '4px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontWeight="bold">Estado</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {selectedRequest.exception &&
            cellFormat(selectedRequest.exception, 'boolean-exception-null')}
          <Typography>{cellFormat(status, 'status')}</Typography>
        </div>
      </div>

      <div style={{ padding: '4px 0px' }}>
        <Typography fontWeight="bold">Pasos a seguir</Typography>
        {requestType === 'factoring' && (
          <FactoringSteps
            selectedRequest={selectedRequest}
            status={status}
            type={type}
          />
        )}
        {status === 'En revisión' &&
          canSeeAllTabs &&
          selectedRequest.subProductCategory === 'invoice' && (
            <div style={{ padding: '20px 0px' }}>
              {miniLoading ? (
                <CircularProgress />
              ) : (
                <div>
                  <CustomButton onClick={handleSyncWithSII}>
                    Sincronizar SII
                  </CustomButton>
                  {nothingNew && (
                    <Typography
                      style={{
                        color: 'red',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ErrorIcon style={{ marginRight: 5 }} />
                      No se encontraron cambios
                    </Typography>
                  )}
                </div>
              )}
            </div>
          )}
        {requestType === 'creditLine' && (
          <CreditLineSteps
            selectedRequest={selectedRequest}
            status={status}
            type={type}
          />
        )}
      </div>
    </div>
  );
};

export default InforPreview;
