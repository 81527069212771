import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EnrolationForm from './pages/PublicLandings/EnrolationForm';
import LoginForm from './pages/LoginForm/Main/Login';
import PasswordRecoveryLanding from './pages/PublicLandings/PasswordRecovery';
import ConfirmQuote from './pages/PublicLandings/ConfirmQuote';
import ConfirmUserRegistrationAdmin from './pages/PublicLandings/ConfirmUseRegistrationAdmin';
import PasswordRecoveryMain from './pages/LoginForm/Main/PasswordRecovery';
import PaymentLink from './pages/PublicLandings/PaymentLink';

import InvoiceInformation from './pages/PublicLandings/PaymentLink/components/InvoiceInformation';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/submitCredentials/:id" element={<EnrolationForm />} />
        <Route path="/password-recovery" element={<PasswordRecoveryMain />} />
        <Route
          path="/confirmRegistration/createPassword/:code"
          element={<ConfirmUserRegistrationAdmin />}
        />
        <Route
          path="/passwordRecovery/:code"
          element={<PasswordRecoveryLanding />}
        />
        <Route path="/factoring/simulation/:code" element={<ConfirmQuote />} />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier"
          element={<PaymentLink />}
        />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier/:invoiceId"
          element={<InvoiceInformation />}
        />
        <Route path="/*" element={<LoginForm />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
