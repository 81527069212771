import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';

interface DetailModalProps {
  title: string;
  description: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  customComponent?: React.ReactNode;
}

const DetailModal: React.FC<DetailModalProps> = (props) => {
  const { title, description, setOpen, customComponent } = props;

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>
        {' '}
        <Typography variant="h4">{title}</Typography>{' '}
      </DialogTitle>
      <DialogContent>
        {' '}
        <Typography variant="body1"> {description} </Typography>{' '}
        <div>{customComponent}</div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Cerrar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DetailModal;
