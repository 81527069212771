import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Autocomplete,
  FormControl,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getActiveBusinessRelationsForUser } from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import {
  executeScraping,
  getScrapingStatus,
} from '../../../../lib/api/pruffRush';
import EnhancedTable from '../../../../lib/designSystem/Table';

const activeScrapings = [
  { scrapingId: 'siiDocuments', scrapingName: 'Documentos SII' },
  { scrapingId: 'tgr', scrapingName: 'TGR' },
];

const NewEvaluation = (props: any) => {
  const { setOpen, open, setSuccessOpen, setFailureOpen, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState<any>([]);
  const [business, setBusiness] = useState<any>(null);
  const dispatch = useDispatch();
  const [scrapingSelected, setScrapingSelected] = useState<any>(null);
  const [lastStateScraping, setLastStateScraping] = useState<any>([]);

  const validateAttributes = () => {
    return true;
  };

  const handleSubmit = async () => {
    if (!validateAttributes()) return;
    setLoading(true);
    try {
      await executeScraping(dispatch, scrapingSelected.scrapingId, business.id);
      setSuccessOpen(
        'Sincronización creada correctamente. Recuerda que podría demorar unos minutos en completarse.',
      );
      setOpen(false);
      await setUp();
    } catch (error) {
      setFailureOpen('Ha ocurrido un error');
      setLoading(false);
    }
  };

  const fetchAvailableBusinesses = async () => {
    setLoading(true);
    try {
      const businessesResponse =
        await getActiveBusinessRelationsForUser(dispatch);
      setBusinesses(businessesResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSelectScraping = async (scrapingValue: any) => {
    const scraping = activeScrapings.find(
      (scraping) => scraping.scrapingId === scrapingValue,
    );
    if (!scraping) return;
    setScrapingSelected(scraping);
    setLoading(true);
    try {
      const lastStateScrapingResponse = await getScrapingStatus(
        dispatch,
        scraping.scrapingId,
        business.id,
      );
      setLastStateScraping(lastStateScrapingResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAvailableBusinesses();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setBusiness(newValue);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Nueva sincronización</DialogTitle>
      <DialogContent>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <Typography variant="body1" style={{ margin: '8px 0px' }}>
              A continuación selecciona la empresa
            </Typography>
            <Autocomplete
              options={businesses.relations}
              value={business}
              getOptionLabel={(option) =>
                `${option.businessName} - ${option.identifier}`
              }
              renderInput={(params) => (
                <TextField
                  style={{ minWidth: 250 }}
                  {...params}
                  label="Selecciona el cliente "
                />
              )}
              onChange={handleChange}
              fullWidth
            />
            {!business ? null : (
              <FormControl fullWidth margin="normal">
                <InputLabel id="filter-label">
                  Sincronización a realizar
                </InputLabel>
                <Select
                  labelId="filter-label"
                  label="Sincronización a realizar"
                  value={scrapingSelected?.scrapingId}
                  onChange={(e) => handleSelectScraping(e.target.value)}
                >
                  {activeScrapings.map((scraping: any) => (
                    <MenuItem key={scraping.id} value={scraping.scrapingId}>
                      {scraping.scrapingName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {scrapingSelected ? (
              <div>
                {lastStateScraping.length > 0 ? (
                  <div>
                    <Typography variant="body1" style={{ margin: '8px 0px' }}>
                      Últimas sincronizaciones
                    </Typography>
                    <EnhancedTable
                      dataHeaders={[
                        { label: 'Nombre', key: 'name' },
                        {
                          label: 'Última sincronización',
                          key: 'status',
                          type: 'date-large',
                        },
                      ]}
                      rowsPerPageDefault={10}
                      data={lastStateScraping}
                    />
                  </div>
                ) : (
                  <div>
                    <Typography variant="body1" style={{ margin: '8px 0px' }}>
                      No se ha realizado ninguna sincronización
                    </Typography>
                  </div>
                )}
                <Typography
                  variant="body1"
                  color="warning"
                  style={{ margin: '8px 0px' }}
                >
                  Recuerda que la sincronización podría demorar unos minutos.
                </Typography>
              </div>
            ) : null}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={business === null || scrapingSelected === null || loading}
          color="primary"
          onClick={() => handleSubmit()}
        >
          {' '}
          Sincronizar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewEvaluation;
