import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CustomButton from '../../designSystem/Button';
import { createBusinessContact, updateBusinessContact } from '../../api';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomAlert from '../../designSystem/Alert';
import CenteredCircularProgress from '../../designSystem/CircularProgress';

interface ContactFormDialogProps {
  open: boolean;
  businessId?: string;
  onClose?: () => void;
  setOpen: (open: boolean) => void;
  initialContact?: any;
  tiny?: boolean;
  setUp?: () => void;
  invoicingOption?: boolean;
}
const companyRoles = [
  { id: 'Gerente de finanzas', name: 'Gerente de finanzas' },
  { id: 'Gerente general', name: 'Gerente general' },
  { id: 'Gerente de operaciones', name: 'Gerente de operaciones' },
  { id: 'Gerente de ventas', name: 'Gerente de ventas' },
  { id: 'Jefe de finanzas', name: 'Jefe de finanzas' },
  { id: 'Jefe de operaciones', name: 'Jefe de operaciones' },
  { id: 'Ejecutivo', name: 'Ejecutivo' },
  { id: 'Analista', name: 'Analista' },
  { id: 'Asistente', name: 'Asistente' },
  { id: 'Otro', name: 'Otro' },
];

const ContactFormDialog: React.FC<ContactFormDialogProps> = ({
  open,
  onClose,
  setOpen,
  businessId,
  setUp,
  initialContact,
  tiny = false,
  invoicingOption = false,
}) => {
  const { id, page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const dispatch = useDispatch();
  const pageBusinessId = businessId || id;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [failed, setFailed] = useState('');
  const [contact, setContact] = useState<any>(
    initialContact || {
      name: '',
      email: '',
      phone: '',
      role: '',
      invoicing: invoicingOption ? 1 : 0,
    },
  );

  const handleSave = async () => {
    if (!pageBusinessId) {
      return;
    }
    if (!validateAttributes()) {
      return;
    }
    setLoading(true);
    try {
      const invoicing = contact.invoicing ? true : false;
      const response = contact.id
        ? await updateBusinessContact(dispatch, pageBusinessId, {
            contactId: contact.id,
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            role: contact.role,
            invoicing,
          })
        : await createBusinessContact(dispatch, pageBusinessId, {
            id: Number(pageBusinessId),
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            role: contact.role,
            invoicing,
          });
      if (response) {
        setSuccess('Contacto guardado con éxito');
        setUp ? await setUp() : null;
        setOpen(false);
      }
    } catch (error) {
      setFailed('Ha ocurrido un error');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (initialContact) {
      setContact(initialContact);
    }
  }, [initialContact]);

  const validateAttributes = () => {
    if (contact.name === '' || contact.email === '' || contact.role === '') {
      alert('Debes completar todos los campos');
      return false;
    }
    return true;
  };

  const itemSize = tiny ? 3 : 12;

  return (
    <div>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>{contact.id ? 'Editar' : 'Crear'} contacto</DialogTitle>
        {loading && (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        )}
        {!loading && (
          <div>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={itemSize}>
                  <TextField
                    label="Nombre"
                    value={contact.name}
                    fullWidth
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={itemSize}>
                  <TextField
                    label="Email"
                    value={contact.email}
                    fullWidth
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={itemSize}>
                  <TextField
                    label="Teléfono"
                    value={contact.phone}
                    fullWidth
                    placeholder="5691232133"
                    onChange={(e) =>
                      setContact({ ...contact, phone: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={itemSize}>
                  <FormControl fullWidth>
                    <InputLabel id="role-label">{'Rol'}</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role-select"
                      fullWidth
                      value={contact.role}
                      onChange={(e) =>
                        setContact({
                          ...contact,
                          role: e.target.value,
                        })
                      }
                      label={'Rol'}
                    >
                      {companyRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {invoicingOption && (
                  <Grid item xs={12} sm={itemSize}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">
                        {'Enviar correos de cobranza'}
                      </InputLabel>
                      <Select
                        labelId="role-label"
                        id="role-select"
                        fullWidth
                        value={contact.invoicing}
                        onChange={(e) =>
                          setContact({
                            ...contact,
                            invoicing: e.target.value,
                          })
                        }
                        label={'Enviar correos de cobranza'}
                      >
                        <MenuItem value={1}>Si</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <CustomButton color="secondary" onClick={onClose}>
                Cancelar
              </CustomButton>
              <CustomButton color="primary" onClick={handleSave}>
                Guardar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </Dialog>
      <CustomAlert
        open={success !== ''}
        title="Éxito"
        onClose={() => {
          setSuccess('');
        }}
        setOpen={setSuccess}
        text={success}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failed !== ''}
        title="Error"
        onClose={() => {
          setFailed('');
        }}
        setOpen={setFailed}
        text={failed}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default ContactFormDialog;
