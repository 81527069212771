import { Alert, Chip, Grid, Snackbar, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import CustomButton from '../../../../../lib/designSystem/Button';
import { useNavigate, useParams } from 'react-router-dom';
import WireInformation from '../WireInformation';
import { ChevronLeft } from '@mui/icons-material';
import NotifyPayment from '../NotifyPayment';
import { getInvoiceEncrypted } from '../../../../../lib/api';
import { formatDate, formatMoney } from '../../../../../lib/functions/utils';

const styles = createStyles({
  layoutWrapper: {
    display: 'flex',
    height: '100vh', // Covers the entire viewport height
  },
  content: {
    width: '100%',
    flexGrow: 1, // Allows it to grow and consume available space
    display: 'flex', // Added to facilitate flex-based layout
    flexDirection: 'column', // Stack children vertically
  },
  layout: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '20px',
    whiteSpace: 'nowrap',
    backgroundColor: '#F8FAFC',
    boxSizing: 'border-box',
    height: '100vh',
  },
  side: {
    flexGrow: 1, // Allows it to grow and consume available space
    overflowX: 'auto', // Enables vertical scrolling
    padding: '20px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
  },
  loading: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});

interface LayoutProps extends WithStyles<typeof styles> {}

const InvoiceInformation: React.FC<LayoutProps> = ({ classes }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [company, setCompany] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [invoiceType, setInvoiceType] = useState<string>('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [payments, setPayments] = useState<any[]>([]);

  const getInvoice = async () => {
    if (
      encryptedBusinessIdentifier !== undefined &&
      encryptedCompanyId !== undefined &&
      invoiceId !== undefined
    ) {
      const response = await getInvoiceEncrypted(
        encryptedCompanyId,
        encryptedBusinessIdentifier,
        invoiceId,
      );
      console.log(response);
      setCompany(response.company);
      setInvoice(response.invoice);
      setBusiness(response.business);
      setInvoiceType(response.type);
      setPayments(response.payments);
      setLoading(false);
    }
  };

  const { encryptedCompanyId, encryptedBusinessIdentifier, invoiceId } =
    useParams<{
      encryptedCompanyId: string;
      encryptedBusinessIdentifier: string;
      invoiceId: string;
    }>();

  useEffect(() => {
    setLoading(true);
    getInvoice();
    setLoading(false);
  }, []);

  const invoiceLeftAmount =
    invoice.amount -
    payments.reduce((acc: number, payment: any) => acc + payment.amount, 0);

  return (
    <div>
      <NotifyPayment
        amount={0}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleClose={() => setOpenModal(false)}
        originInvoice={invoiceId}
        setSnackBar={setSnackbar}
      />
      <Snackbar
        onClose={() => setSnackbar({ message: '', open: false })}
        open={snackbar.open}
      >
        <Alert
          onClose={() => setSnackbar({ message: '', open: false })}
          severity="success"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className={classes.layoutWrapper}>
        <div className={classes.content}>
          {!loading && (
            <Grid container>
              <Grid item xs={3}>
                <div className={classes.side}>
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    {' '}
                    <img
                      width={175}
                      src={`https://pruff-public.s3.amazonaws.com/company-logos/${company.id}.png`}
                    />
                  </div>
                  <WireInformation
                    encryptedBusinessIdentifier={
                      encryptedBusinessIdentifier || ''
                    }
                    encryptedCompanyId={encryptedCompanyId || ''}
                  />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <CustomButton
                    startIcon={<CampaignIcon />}
                    color="primary"
                    variant="contained"
                    style={{ margin: '20px 20px 20px 20px' }}
                    onClick={() => setOpenModal(true)}
                  >
                    Informar pago
                  </CustomButton>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className={classes.layout}>
                  <HeaderWithActions
                    type="pageHeader"
                    title={`Factura #${invoice.folio}`}
                    variant="h2"
                    buttons={[
                      {
                        action: () => {
                          navigate(
                            `../payment/${encryptedCompanyId}/${encryptedBusinessIdentifier}`,
                          );
                        },
                        color: 'secondary',
                        name: 'Documentos',
                        startIcon: <ChevronLeft />,
                      },
                    ]}
                  />
                  <HeaderWithActions
                    type="subHeader"
                    title={`A continuación encontrarás información relevante del documento.`}
                    variant="body1"
                  />
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: '7px',
                      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px',
                      padding: '23px',
                    }}
                  >
                    <center>
                      <Typography
                        style={{ paddingBottom: '10px' }}
                        variant="h3"
                      >
                        {formatMoney(invoice.amount)}
                      </Typography>
                      {invoice.paid && <Chip label="Pagado" color="success" />}
                      {!invoice.paid && (
                        <Chip
                          label={`Por pagar: ${formatMoney(invoiceLeftAmount)}`}
                          color="info"
                        />
                      )}
                      <Grid container>
                        {' '}
                        <Grid item xs={6}>
                          {' '}
                          <Typography variant="h5">Receptor</Typography>
                        </Grid>{' '}
                        <Grid item xs={6}>
                          {' '}
                          <Typography variant="h6">{business.name}</Typography>
                        </Grid>{' '}
                      </Grid>
                      <Grid container>
                        {' '}
                        <Grid item xs={6}>
                          <Typography variant="h5">
                            Emisor {invoiceType === 'cession' && 'Original'}
                          </Typography>
                        </Grid>{' '}
                        <Grid item xs={6}>
                          {' '}
                          <Typography variant="h5">
                            {invoice.business?.name}
                          </Typography>
                        </Grid>{' '}
                      </Grid>
                      {invoiceType === 'cession' && (
                        <Grid container>
                          {' '}
                          <Grid item xs={6}>
                            <Typography variant="h5">Cesionario</Typography>
                          </Grid>{' '}
                          <Grid item xs={6}>
                            {' '}
                            <Typography variant="h5">{company.name}</Typography>
                          </Grid>{' '}
                        </Grid>
                      )}
                      <Grid container>
                        {' '}
                        <Grid item xs={6}>
                          {' '}
                          <Typography variant="h5">Fecha emisión</Typography>
                        </Grid>{' '}
                        <Grid item xs={6}>
                          <Typography variant="h5">
                            {formatDate(invoice.issuedDate)}
                          </Typography>
                        </Grid>{' '}
                      </Grid>
                      <Grid container></Grid>
                    </center>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(InvoiceInformation);
