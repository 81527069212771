import React from 'react';
import InforPreview from '../../../../../../../../../common/InforPreview';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';

const ShoppingCartFactoringCeded = (props: any) => {
  const { openShoppingCart, setOpenShoppingcart, selectedRequest } = props;

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      selectedRequest={selectedRequest}
      status="Cedida"
      type="Sent"
    />
  );

  return (
    <div>
      <SimulationDoneFrozen
        selectedRequest={selectedRequest}
        infoDiv={infoDiv}
      />
      <DialogActions>
        <CustomButton
          onClick={() => setOpenShoppingcart(false)}
          color="secondary"
        >
          Salir
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default ShoppingCartFactoringCeded;
