import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { toggleUser } from '../../../../../../lib/api/users';
import { useDispatch } from 'react-redux';

interface DeactivateUserProps {
  userId: number;
  userEmail: string;
  open: boolean;
  handleClose: () => void;
  reload: number;
  setReload: (value: number) => void;
  setFailedText: (value: string) => void;
  setSuccessText: (value: string) => void;
  setFailed: (value: boolean) => void;
  setSuccess: (value: boolean) => void;
  setUserToTransfer: (value: any) => void;
  setOpenPortfolioTransfer: (value: boolean) => void;
}

const DeactivateUser: React.FC<DeactivateUserProps> = (props) => {
  const {
    open,
    handleClose,
    userEmail,
    userId,
    reload,
    setReload,
    setSuccess,
    setFailed,
    setSuccessText,
    setFailedText,
    setUserToTransfer,
    setOpenPortfolioTransfer,
  } = props;
  const dispatch = useDispatch();

  const handleDeactivateUser = async () => {
    try {
      await toggleUser(dispatch, userId, false);
      setReload(reload + 1);
      setSuccessText('Usuario desactivado correctamente');
      setSuccess(true);
      handleClose();
    } catch (error) {
      setFailedText('Error al desactivar el usuario');
      setFailed(true);
    }
  };

  const handleTransferPortfolio = () => {
    console.log('Transfer portfolio');
    setUserToTransfer(userId);
    handleClose();
    setOpenPortfolioTransfer(true);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h6">Desactivar usuario: {userEmail}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que deseas desactivar este usuario?
          </Typography>
          <br />
          <Typography variant="body2">
            Al desactivar un usuario, este no podrá acceder a Pruff.
            Recomendamos revisar la{' '}
            <a href="#" onClick={() => handleTransferPortfolio()}>
              {' '}
              cartera asociada
            </a>{' '}
            a este usuario y traspasarla a otro usuario antes de desactivarlo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} color="secondary">
            Cancelar
          </CustomButton>
          <CustomButton onClick={handleDeactivateUser} color="primary">
            Desactivar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeactivateUser;
