import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatMoney } from '../../../../../../../../../lib/functions/utils';
import {
  graphNameTranslation,
  hexToRGBA,
} from '../../../../../../../../../lib/designSystem/BarChart';
import { Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { LightTooltip } from '../../../../../../../../../lib/designSystem/WhiteTooltip';

const colorsArray = [
  '#0F23DF',
  '#03B4DC',

  '#FFC400',
  '#07D2FE',
  '#D500F9',
  '#651FFF',
  '#FF5722',
  '#00BCD4',
  '#E91E63',
  '#3F51B5',
  '#FFEB3B',
  '#00E676',
  '#673AB7',
  '#1DE9B6',
  '#FF9100',
  '#009688',
  '#D32F2F',
  '#303F9F',
  '#FFD600',
  '#00C853',
  '#9C27B0',
  '#76FF03',
  '#FF3D00',
  '#00BFA5',
  '#C51162',
  '#536DFE',
  '#FFEA00',
  '#64DD17',
  '#8E24AA',
  '#64FFDA',
  '#FF6F00',
  '#004D40',
  '#880E4F',
  '#1A237E',
  '#FFAB00',
  '#00B8D4',
  '#6200EA',
  '#00BFA5',
];

const CustomLegend = ({ payload, onClick, visibleLines }: any) => {
  const tooltipTitle = (title: string) => (
    <div style={{ padding: '10px' }}>
      <Typography variant="body1">{title}</Typography>
    </div>
  );
  return (
    <div
      style={{
        padding: '0px 20px',
        marginTop: '-30px',
        maxHeight: '150px',
        overflowY: 'auto',
      }}
    >
      {payload.map((entry: any, index: any) => (
        <div key={index} onClick={(e) => onClick(entry, index, e)}>
          <LightTooltip title={tooltipTitle(entry.dataKey)} placement="right">
            <Circle
              style={{
                color: visibleLines.includes(entry.dataKey)
                  ? entry.color
                  : '#ccc',
                cursor: 'pointer',
                textDecoration: visibleLines.includes(entry.dataKey)
                  ? 'none'
                  : 'line-through',
              }}
            />
          </LightTooltip>
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
  if (active && payload && payload.length) {
    const filteredPayload = payload.filter((entry: any) => entry.value > 0);
    if (filteredPayload.length === 0) {
      return null;
    }
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          position: 'absolute',
          top: coordinate.y,
          left: coordinate.x,
          transform: 'translate(10%, 0%)',
          zIndex: 1000,
        }}
      >
        <p className="label">{`${label}`}</p>
        {filteredPayload.map((entry: any, index: any) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${formatMoney(entry.value)}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const SimpleLineChart = ({
  data,
  aspect,
  noMoney,
  selectedMonths,
  setSelectedMonths,
  handleSearch,
}: any) => {
  const [finalData, setFinalData] = useState<any>([]);
  const [visibleLines, setVisibleLines] = useState<string[]>([]);

  const handleLineClick = (data: any) => {
    console.log(data);
    if (!Object.keys(data).length) return;

    const month = data.activeLabel;
    if (selectedMonths.includes(month)) {
      return;
    } else {
      setSelectedMonths([month]);
      handleSearch(month);
    }
  };

  const formatLineMoney = (value: number) => {
    if (value > 1000000000) return `${formatMoney(value / 1000000000)}MM`;
    if (value > 1000000) return `${formatMoney(value / 1000000)}M`;
    if (value > 1000) return `${formatMoney(value / 1000)}K`;
    return noMoney ? `${value}` : formatMoney(value);
  };

  const headers = new Set();
  data.forEach((item: any) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'month') headers.add(key);
    });
  });

  useEffect(() => {
    const dataWithFilledBlanks = data.map((item: any) => {
      headers.forEach((header: any) => {
        if (item[header] === undefined) {
          item[header] = 0;
        }
      });
      return item;
    });

    setFinalData(dataWithFilledBlanks);
  }, [data]);

  useEffect(() => {
    if (finalData.length > 0) {
      setVisibleLines(
        Object.keys(finalData[0]).filter((key) => key !== 'month'),
      );
    }
  }, [finalData]);
  const handleLegendClick = (dataKey: string) => {
    setVisibleLines((prev) => {
      if (prev.includes(dataKey)) {
        // If only one line is visible and it's clicked again, show all lines
        if (prev.length === 1) {
          return Object.keys(finalData[0]).filter((key) => key !== 'month');
        } else {
          return [dataKey]; // Make the clicked line the only visible one
        }
      } else {
        // Add the clicked line to the list of visible lines
        return [...prev, dataKey];
      }
    });
  };

  const createLineArray = (data: any) => {
    const keys = Object.keys(data[0] || {}).filter(
      (key) => key !== 'name' && key !== 'year' && key !== 'dateKey',
    );
    return keys.map((key, index) => ({
      name: key,
      dataKey: key,
      stroke: colorsArray[index % colorsArray.length],
    }));
  };

  const customDot = (props: any) => {
    const { cx, cy, stroke, payload } = props;
    const isSelected = selectedMonths.includes(payload.name);
    console.log(selectedMonths, payload.month);
    const newStroke = !selectedMonths
      ? stroke
      : isSelected
        ? stroke
        : hexToRGBA(stroke, 0.5);

    return (
      <circle
        cx={cx}
        cy={cy}
        r={isSelected ? 6 : 2}
        fill={newStroke}
        stroke={stroke}
        strokeWidth={2}
      />
    );
  };

  const lineArrayDefault = createLineArray(data);

  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer aspect={aspect}>
        <LineChart
          data={finalData.length ? finalData : data}
          margin={{
            top: 5,
            right: 10,
            left: 10,
            bottom: 5,
          }}
          onClick={handleLineClick}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatLineMoney} />
          <Tooltip content={CustomTooltip} />
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            content={(props: any) => (
              <CustomLegend
                {...props}
                visibleLines={visibleLines}
                onClick={(e: any) => handleLegendClick(e.dataKey)}
              />
            )}
          />

          {lineArrayDefault.map((line: any) => (
            <Line
              type="monotone"
              key={line.name}
              name={graphNameTranslation(line.name)}
              dataKey={line.dataKey}
              stroke={line.stroke}
              fontSize={12}
              dot={customDot}
              hide={!visibleLines.includes(line.dataKey)}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SimpleLineChart;
