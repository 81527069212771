import React, { useEffect, useState } from 'react';

import {
  Typography,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { getPayInConciliations } from '../../../../../../../../../../lib/api/conciliations';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';
import CustomButton from '../../../../../../../../../../lib/designSystem/Button';
import {
  checkReverseConciliation,
  reverseDocumentConciliation,
} from '../../../../../../../../../../lib/api/conciliations';

const PaymentConciliationAssginationDone = (props: any) => {
  const { payIn, bigSetup, setOpen } = props;
  const [documentConciliations, setDocumentConciliations] = useState<any[]>([]);
  const [fncConciliations, setFncConciliations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const [payInConciliation, setPayInConciliation] = useState<null | number>(
    null,
  );

  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getPayInConciliations(dispatch, {
        id: payIn.id,
      });
      setDocumentConciliations(response.documentConciliations);
      setFncConciliations(response.fncConciliations);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const unDoConciliation = async () => {
    if (!payInConciliation) return;
    setLoading(true);
    try {
      setOpenWarning(false);
      await reverseDocumentConciliation(dispatch, payInConciliation);
      await bigSetup();
      await setOpen(false);
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const openConfirmConciliation = async (id: number) => {
    setPayInConciliation(id);
    setLoading(true);
    try {
      const response = await checkReverseConciliation(dispatch, id);
      setWarningMessages(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setOpenWarning(true);
  };

  const actions = [
    {
      action: openConfirmConciliation,
      actionLabel: 'Reversar',
    },
  ];

  useEffect(() => {
    setUp();
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <Typography variant="body1" padding="10px 0px" fontWeight={600}>
            Documentos conciliados en este pago
          </Typography>
          {documentConciliations.length ? (
            <EnhancedTable
              dataHeaders={[
                { key: 'folio', label: 'Folio' },
                { key: 'amount', label: 'Monto', type: 'money' },
                {
                  key: 'createdAt',
                  label: 'Fecha de conciliación',
                  type: 'date',
                },
                {
                  key: 'amountConciliated',
                  label: 'Monto conciliado',
                  type: 'money',
                },
              ]}
              data={documentConciliations}
              actions={actions}
            />
          ) : (
            <Typography variant="body1">
              No hay conciliaciones en este pago
            </Typography>
          )}
          <Typography variant="body1" padding="10px 0px" fontWeight={600}>
            FNC conciliado en este pago
          </Typography>
          {fncConciliations.length ? (
            <div>
              <EnhancedTable
                dataHeaders={[
                  { key: 'id', label: 'Folio' },
                  { key: 'amount', label: 'Monto', type: 'money' },
                  {
                    key: 'createdAt',
                    label: 'Fecha de conciliación',
                    type: 'date',
                  },
                  {
                    key: 'amount',
                    label: 'Monto conciliado',
                    type: 'money',
                  },
                ]}
                data={fncConciliations}
              />
            </div>
          ) : (
            <Typography variant="body1">
              No hay conciliaciones en este pago
            </Typography>
          )}
        </div>
      )}
      <Dialog maxWidth="md" open={openWarning}>
        <DialogTitle>Reversar conciliación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que quieres reversar esta conciliación? Se ejecutarán
            las siguientes acciones:
          </Typography>
          <div style={{ padding: '10px 0px' }}>
            {warningMessages.map((message) => (
              <Typography key={message} variant="body1" color="error">
                - {message}
              </Typography>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton color="secondary" onClick={() => setOpenWarning(false)}>
            Salir
          </CustomButton>
          <CustomButton onClick={() => unDoConciliation()} color="error">
            Reversar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentConciliationAssginationDone;
