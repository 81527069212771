import { requestHandler } from '../../api';

export const getAllBankAccounts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/company/bankAccounts`,
    dispatch,
  });
  if (response) return response.data;
};

export const getOwnBankAccounts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/bankAccount/own`,
    dispatch,
  });
  if (response) return response.data;
};
