import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  IconButton,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Add, Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  formatMoney,
  formatMoneyBack,
} from '../../../../../../../../lib/functions/utils';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
    },
    tableCell: {
      padding: '8px !important',
      overflow: 'hidden',
      minWidth: '100px',
      whiteSpace: 'nowrap',
      fontSize: '14px !important', // Reduced font size
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    textField: {
      fontSize: '14px !important',
      padding: '8px !important',
    },
    iconButton: {
      padding: '5px',
    },
  }),
);

const InvoicesTable = (props: any) => {
  const { invoices, setInvoices } = props;
  const classes = useStyles();

  const [expandedRows, setExpandedRows] = useState<any>({});

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows({
      ...expandedRows,
      [rowIndex]: !expandedRows[rowIndex],
    });
  };

  const addDetail = (rowIndex: number) => {
    const newSelectedInvoices = invoices.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          details: [
            ...(doc.details ? doc.details : []),
            {
              subject: '',
              qty: 0,
              amount: 0,
            },
          ],
        };
      }
      return doc;
    });
    setInvoices(newSelectedInvoices);
    if (!expandedRows[rowIndex]) toggleRowExpansion(rowIndex);
  };

  const handleChangeInvoice = (
    value: string,
    key: string,
    rowIndex: number,
  ) => {
    const newInvoices = invoices.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          [key]: value,
        };
      }
      return doc;
    });
    setInvoices(newInvoices);
  };

  const handleChangeDetails = (
    value: any,
    name: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const newInvoices = invoices.map((doc: any, i: any) => {
      if (i === rowIndex) {
        const newDetails = doc.details.map((detail: any, j: any) => {
          if (j === subIndex) {
            return {
              ...detail,
              [name]: name === 'amount' ? formatMoneyBack(value) : value,
            };
          }
          return detail;
        });
        const newAmount = newDetails.reduce(
          (acc: number, detail: any) => acc + detail.amount * detail.qty,
          0,
        );
        return {
          ...doc,
          details: newDetails,
          amount: newAmount,
          amountWithIVA: newAmount * 1.19,
        };
      }
      return doc;
    });
    setInvoices(newInvoices);
  };

  const deleteRow = (rowIndex: number) => {
    const newInvoices = invoices.filter((doc: any, i: any) => i !== rowIndex);
    setInvoices(newInvoices);
  };

  const deleteDetail = (rowIndex: number, subIndex: number) => {
    const newInvoices = invoices.map((doc: any, i: any) => {
      if (i === rowIndex) {
        const newDetails = doc.details.filter(
          (subDoc: any, j: any) => j !== subIndex,
        );
        const newAmount = newDetails.reduce(
          (acc: number, detail: any) => acc + detail.amount * detail.qty,
          0,
        );
        return {
          ...doc,
          details: newDetails,
          amount: newAmount,
          amountWithIVA: newAmount * 1.19,
        };
      }
      return doc;
    });
    setInvoices(newInvoices);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead style={{ background: '#efefef' }}>
          <TableCell className={classes.tableCell}>Detalle</TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Rut
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Razón social
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Fecha emisión
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'left' }}
          >
            Email
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Monto
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Iva
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Total
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}></TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {invoices.map((row: any, rowIndex: number) => {
            const isExpanded = !!expandedRows[rowIndex];
            const hasDetails = row.details && row.details.length > 0;
            return (
              <React.Fragment key={rowIndex}>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => addDetail(rowIndex)}
                    >
                      <Add />
                    </IconButton>
                    {row.details && row.details.length}{' '}
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => toggleRowExpansion(rowIndex)}
                      aria-label="expand row"
                      size="small"
                    >
                      {row.details ? (
                        expandedRows[rowIndex] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      value={row.identifier}
                      onChange={(e) =>
                        handleChangeInvoice(
                          e.target.value,
                          'identifier',
                          rowIndex,
                        )
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <TextField
                      value={row.name}
                      onChange={(e) =>
                        handleChangeInvoice(e.target.value, 'name', rowIndex)
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    <TextField
                      value={
                        row.issuedDate ? row.issuedDate.split('T')[0] : null
                      }
                      type="date"
                      onChange={(e) =>
                        handleChangeInvoice(
                          e.target.value,
                          'issuedDate',
                          rowIndex,
                        )
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    <TextField
                      value={row.email}
                      onChange={(e) =>
                        handleChangeInvoice(e.target.value, 'email', rowIndex)
                      }
                      InputProps={{ classes: { input: classes.textField } }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.amount)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.amount * 0.19)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.amount * 1.19)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => deleteRow(rowIndex)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {isExpanded && hasDetails && (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Acción</TableCell>
                            <TableCell style={{ textAlign: 'left' }}>
                              Detalle
                            </TableCell>
                            <TableCell style={{ textAlign: 'left' }}>
                              Cantidad
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              Monto
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.details.map((subDoc: any, subIndex: number) => (
                            <TableRow key={`sub-${rowIndex}-${subIndex}`}>
                              <TableCell className={classes.tableCell}>
                                <IconButton
                                  className={classes.iconButton}
                                  onClick={() =>
                                    deleteDetail(rowIndex, subIndex)
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: 'left' }}
                              >
                                <TextField
                                  value={subDoc.subject}
                                  onChange={(e) =>
                                    handleChangeDetails(
                                      e.target.value,
                                      'subject',
                                      rowIndex,
                                      subIndex,
                                    )
                                  }
                                  InputProps={{
                                    classes: { input: classes.textField },
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: 'left' }}
                              >
                                <TextField
                                  value={subDoc.qty}
                                  type="number"
                                  onChange={(e) =>
                                    handleChangeDetails(
                                      e.target.value,
                                      'qty',
                                      rowIndex,
                                      subIndex,
                                    )
                                  }
                                  InputProps={{
                                    classes: { input: classes.textField },
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: 'right' }}
                              >
                                <TextField
                                  value={formatMoney(subDoc.amount)}
                                  inputProps={{ style: { textAlign: 'right' } }}
                                  onChange={(e) =>
                                    handleChangeDetails(
                                      e.target.value,
                                      'amount',
                                      rowIndex,
                                      subIndex,
                                    )
                                  }
                                  InputProps={{
                                    classes: { input: classes.textField },
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{ textAlign: 'right' }}
                              >
                                {formatMoney(subDoc.amount * subDoc.qty)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoicesTable;
