import React, { useEffect, useState } from 'react';
import EnhancedTab from '../../../../lib/designSystem/TabSimulation';
import ActiveMoney from './components/TheBiller';
import { useNavigate, useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';

const FinanceSII: React.FC = () => {
  const { subPage } = useParams<{ page: string; subPage: string }>();
  const navigate = useNavigate();
  const handleNavigation = (index: number) => {
    navigate(`/finance/reporting/${reportsTabs[index].path}`);
  };
  const [companyTabs, setCompanyTabs] = useState<any>([]);
  const [startValue, setStartValue] = useState(0);
  const [loading, setLoading] = useState(true);

  const reportsTabs = [
    {
      label: 'Facturador',
      component: <ActiveMoney />,
      path: 'biller',
    },
  ];

  const setUpTabs = async () => {
    const newReportTabs: any = [...reportsTabs];
    let startValueDefault = 0;
    startValueDefault = newReportTabs.findIndex((x: any) => x.path === subPage);
    setCompanyTabs(newReportTabs);
    console.log(newReportTabs);
    console.log(startValueDefault);
    setStartValue(startValueDefault === -1 ? 0 : startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUpTabs();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <div>
        <EnhancedTab
          startValue={startValue}
          tabs={companyTabs}
          onTabChange={handleNavigation}
        />
      </div>
    </div>
  );
};

export default FinanceSII;
