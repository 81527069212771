import { requestHandler } from '../../api';

export const getCollectionNotificationsForMonth = async (
  dispatch: any,
  month: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/notifications/${month}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getDocumentById = async (documentId: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/collection/document/${documentId}`,
  });
  if (response) return response.data;
};
