// Dahsboard

import { requestHandler } from '../../api';

export const getInvoiceClaimed = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/invoices/claimed`,
    dispatch,
  });
  if (response) return response.data;
};

export const getInvoiceNotConfirmed = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/invoices/notConfirmed`,
    dispatch,
  });
  if (response) return response.data;
};

export const getInvoiceNotFinanciable = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/invoices/financiable`,
    dispatch,
  });
  if (response) return response.data;
};

export const getAllInvoices = async (dispatch: any, query: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/invoices/all?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBankrruptcyPosts = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/bankruptcyPosts`,
    dispatch,
  });
  if (response) return response.data;
};
export const getSiiDebt = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/sii/debt`,
    dispatch,
  });
  if (response) return response.data;
};

export const lastSiiMessages = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/sii/messages`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCessionsSummary = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/cessions/summary`,
    dispatch,
  });
  if (response) return response.data;
};

export const lastCessionMonthly = async (dispatch: any, month?: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/cessions/monthly${month ? `?month=${month}` : ''}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCompleteTGR = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/tgr`,
    dispatch,
  });
  if (response) return response.data;
};

export const getProspectsData = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/dashboard/prospects`,
    dispatch,
  });
  if (response) return response.data;
};
