import { requestHandler, requestHandlerPublic } from '../../../api';

export const submitFactoringQuoteQuotation = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/quote/${data.id}/submit`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const rejectFactoringQuote = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/quote/${data.id}/reject`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const publicAcceptQuote = async (data: any) => {
  const response = await requestHandlerPublic({
    method: 'post',
    url: `public/openFinancing/quote/accept`,
    data,
  });
  if (response) return response.data;
};
