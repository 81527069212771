import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  formatDate,
  validatePermissions,
} from '../../../../../../../lib/functions/utils';
import EnhacedBox from '../../../../../../../lib/designSystem/BigBox';
import { Edit, Save } from '@mui/icons-material';
import {
  chileanCommunes,
  chileanRegions,
  compnaySizes,
  economicActivity,
  economicSector,
} from '../contants';
import { updateBusinessDetails } from '../../../../../../../lib/api';
import { AuthState } from '../../../../../../../services/redux/authSlice';

interface Contact {
  id: number;
  name: string;
  email: string;
  phone: string;
  role: string;
  businessName: string;
  businessId: number;
}
interface BusinessDetails {
  businessIdentifier: string;
  region: string;
  commune: string;
  address: string;
  mainContact: Contact;
  size: string;
  uaf: Date;
  economicActivity: string;
  economicSector: string;
  page: string;
}

const translateIdToName = (id: string, value: any) => {
  if (!value) return null;

  let options: any[] = [];
  if (value === 'size') {
    options = compnaySizes;
  } else if (value === 'economicSector') {
    options = economicSector;
  } else if (value === 'economicActivity') {
    options = economicActivity;
  } else if (value === 'region') {
    options = chileanRegions;
  } else if (value === 'commune') {
    options = chileanRegions.map((x: any) => chileanCommunes[x.id]).flat();
  }
  const option = options.find((x: any) => x.id === id);
  return option?.name;
};

const EditableSelectField = (props: any) => {
  const { editing, value, setActualValues, actualValues, label, options } =
    props;

  if (editing) {
    return (
      <FormControl fullWidth>
        <InputLabel id="filter-label">{label}</InputLabel>
        <Select
          labelId="filter-label"
          id="Filtrar por estado"
          fullWidth
          value={actualValues[value]}
          onChange={(e) =>
            setActualValues({
              ...actualValues,
              [value]: e.target.value,
            })
          }
          label={label}
        >
          {options.map((x: any, index: any) => (
            <MenuItem key={index} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <div>
      <Typography variant="body1" fontWeight={600}>
        {label}
      </Typography>
      <Tooltip title={translateIdToName(actualValues[value], value)}>
        <Typography
          variant="body1"
          style={{
            overflow: 'hidden', // Ensure the content doesn't overflow.
            textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
            whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
          }}
        >
          {translateIdToName(actualValues[value], value)}
        </Typography>
      </Tooltip>
    </div>
  );
};

const EditableDateField = (props: any) => {
  const { editing, value, setActualValues, actualValues, label } = props;

  if (editing) {
    return (
      <TextField
        label={label}
        type="date"
        fullWidth
        value={actualValues[value]}
        onChange={(e) => {
          setActualValues({
            ...actualValues,
            uaf: e.target.value,
          });
        }}
      />
    );
  }

  return (
    <div>
      <Typography variant="body1" fontWeight={600}>
        {label}
      </Typography>
      <Typography variant="body1">
        {actualValues[value] && formatDate(actualValues[value])}
      </Typography>
    </div>
  );
};

const EditableCompleteAddress = (props: any) => {
  const { editing, setActualValues, actualValues } = props;

  if (editing) {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">Región</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                value={actualValues.region}
                onChange={(e) =>
                  setActualValues({
                    ...actualValues,
                    region: e.target.value,
                  })
                }
                label="Región"
              >
                {chileanRegions.map((x: any, index: any) => (
                  <MenuItem key={index} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">Comuna</InputLabel>
              <Select
                labelId="filter-label"
                id="Filtrar por estado"
                disabled={!actualValues.region}
                value={actualValues.commune}
                onChange={(e) =>
                  setActualValues({
                    ...actualValues,
                    commune: e.target.value,
                  })
                }
                label="Comuna"
              >
                {actualValues.region &&
                  chileanCommunes[actualValues.region].map(
                    (x: any, index: any) => (
                      <MenuItem key={index} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ),
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Dirección"
              value={actualValues.address}
              onChange={(e) => {
                setActualValues({
                  ...actualValues,
                  address: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1" fontWeight={600}>
        Dirección
      </Typography>
      <Typography variant="body1">
        {actualValues.address},{' '}
        {translateIdToName(actualValues.commune, 'commune')},{' '}
        {translateIdToName(actualValues.region, 'region')}
      </Typography>
    </div>
  );
};

const MainInfo = (props: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const { businessDetails, setBusinessDetails, setLoading } = props;
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [editing, setEditing] = useState(false);

  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const handleSave = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    console.log('save', businessDetails);
    setLoading(true);
    try {
      const businessObject = {
        size: businessDetails.size,
        economicSector: businessDetails.economicSector,
        economicActivity: businessDetails.economicActivity,
        address: businessDetails.address,
        commune: businessDetails.commune,
        region: businessDetails.region,
        uaf: businessDetails.uaf && new Date(businessDetails.uaf),
      };
      console.log(businessObject);
      const response = await updateBusinessDetails(dispatch, id, {
        ...businessObject,
      });
      console.log(response);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setFailed(true);
    }
    setLoading(false);
  };

  const handleEdit = async () => {
    if (editing) {
      await handleSave();
      return setEditing(false);
    }
    setEditing(true);
  };

  return (
    <EnhacedBox>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body1" fontWeight={600}>
          Información de la empresa
        </Typography>
        <IconButton
          onClick={() => handleEdit()}
          disabled={!validatePermissions(user, 'businesses_create_new')}
        >
          {editing ? <Save /> : <Edit />}
        </IconButton>
      </div>
      <div style={{ padding: '20px 0px' }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="body1" fontWeight={600}>
              Rut
            </Typography>
            <Typography variant="body1">
              {businessDetails?.businessIdentifier}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" fontWeight={600}>
              Email {editing && '(Cambiar en contactos)'}
            </Typography>
            <Typography variant="body1">
              {businessDetails?.mainContact &&
                businessDetails.mainContact.email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <EditableCompleteAddress
              editing={editing}
              value={businessDetails}
              setActualValues={setBusinessDetails}
              actualValues={businessDetails}
            />
          </Grid>
          <Grid item xs={3}>
            <EditableSelectField
              editing={editing}
              value="size"
              setActualValues={setBusinessDetails}
              actualValues={businessDetails}
              label="Tamaño"
              options={compnaySizes}
            />
          </Grid>
          <Grid item xs={3}>
            <EditableSelectField
              editing={editing}
              value="economicSector"
              setActualValues={setBusinessDetails}
              actualValues={businessDetails}
              label="Sector económico"
              options={economicSector}
            />
          </Grid>
          <Grid item xs={3}>
            <EditableSelectField
              editing={editing}
              value="economicActivity"
              setActualValues={setBusinessDetails}
              actualValues={businessDetails}
              label="Actividad económica"
              options={economicActivity}
            />
          </Grid>
          <Grid item xs={3}>
            <EditableDateField
              editing={editing}
              value="uaf"
              setActualValues={setBusinessDetails}
              actualValues={businessDetails}
              label="UAF"
            />
          </Grid>
        </Grid>
      </div>
    </EnhacedBox>
  );
};

export default MainInfo;
