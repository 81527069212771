import React, { useEffect } from 'react';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import {
  Box,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  getAutoCollectionConfig,
  postAutoCollectionConfig,
} from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { Save } from '@mui/icons-material';
import CustomAlert from '../../../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface AutoCollectionProps {
  businessIdentifier: string;
}

const AutoCollection: React.FC<AutoCollectionProps> = (props) => {
  const { businessIdentifier } = props;
  const [selectedContacts, setSelectedContacts] = React.useState<string[]>([]);
  const [daysToCollect, setDaysToCollect] = React.useState<number>(15);
  const [contacts, setContacts] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [activeCollection, setActiveCollection] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();
  const handleChange = (event: SelectChangeEvent<typeof selectedContacts>) => {
    const {
      target: { value },
    } = event;
    setSelectedContacts(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const getData = async (businessIdentifier: string) => {
    setLoading(true);
    const data = await getAutoCollectionConfig(dispatch, businessIdentifier);
    setActiveCollection(data.autoCollection);
    setDaysToCollect(data.daysToCollect);
    setContacts(data.contacts);
    setSelectedContacts(
      data.selectedContacts.map(
        (contact: any) => `${contact.id}-${contact.name}`,
      ),
    );
    setLoading(false);
  };

  const postData = async () => {
    setLoading(true);
    try {
      await postAutoCollectionConfig(
        dispatch,
        businessIdentifier,
        selectedContacts.map((contact) => parseInt(contact.split('-')[0])),
        daysToCollect,
        activeCollection,
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
      return;
    }
    setSuccess(true);
  };

  useEffect(() => {
    getData(businessIdentifier);
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ padding: '3px' }}>
        <Typography variant="h6" gutterBottom>
          {' '}
          Activar cobranza automática
        </Typography>
        <InputLabel id="days-to-collect-label">
          Al activar esta funcionalidad, se enviarán facturas al momento de
          emitirlas en SII y se enviarán notificaciones de pago.
        </InputLabel>
        <Switch
          color="primary"
          size="medium"
          value={activeCollection}
          onChange={() => setActiveCollection(!activeCollection)}
          checked={activeCollection}
        />
      </div>
      {activeCollection && (
        <div>
          <div style={{ padding: '3px' }}>
            <Typography variant="h6" gutterBottom>
              {' '}
              Días al vencimiento de la factura{' '}
            </Typography>
            <InputLabel id="days-to-collect-label">
              Ingresa la cantidad de días que se le dará al cliente para pagar
              sus facturas.
            </InputLabel>
            <TextField
              id="days-to-collect"
              aria-label="days-to-collect-label"
              type="number"
              variant="outlined"
              defaultValue="15"
              value={daysToCollect}
              onChange={(e) => {
                setDaysToCollect(Number(e.target.value));
              }}
            />
          </div>
          <div style={{ padding: '3px' }}>
            <Typography variant="h6" gutterBottom>
              {' '}
              Contactos de facturación{' '}
            </Typography>
            <InputLabel id="contacts-select-label">
              Selecciona los contactos que recibirán las facturas.
            </InputLabel>
            <Select
              labelId="contacts-select-label"
              id="contacts-select"
              multiple
              value={selectedContacts}
              onChange={handleChange}
              style={{ minWidth: '200px' }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => (
                    <Chip key={value} label={value.split('-')[1]} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {contacts.map((contact) => (
                <MenuItem
                  key={contact.id}
                  value={`${contact.id}-${contact.name}`}
                >
                  {contact.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      )}
      <div style={{ padding: '10px 0' }}>
        <CustomButton
          startIcon={<Save />}
          color="primary"
          onClick={() => postData()}
        >
          {' '}
          Guardar{' '}
        </CustomButton>
      </div>
      <CustomAlert
        open={success}
        setOpen={setSuccess}
        severity="success"
        text={'Se han guardado correctamente los cambios.'}
        handleClose={() => setSuccess(false)}
      />
      <CustomAlert
        open={error}
        setOpen={error}
        severity="error"
        text={'Hubo un problema guardadon los cambios, intenta nuevamente.'}
        handleClose={() => setError(false)}
      />
    </div>
  );
};

export default AutoCollection;
