import React, { useState, useEffect } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import {
  createDecisionNode,
  getAllDecisionEngines,
  getAllFinalNodes,
  getDecisionNode,
  updateDecisionNode,
} from '../../../../../../lib/api';
import FinalNodeDialog from './components/nodeDialog';

export interface DecisionNode {
  id?: number | null;
  name: string;
  description: string;
  type: string;
  revisionObjective: string;
  comiteeId?: number | null;
}

const DecisonNodesFinal = (props: any) => {
  const { setCreating } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [finalNodes, setFinalNodes] = useState<DecisionNode[]>([]);
  const newFinalNode = {
    id: null,
    name: '',
    comiteeId: null,
    description: '',
    type: 'final',
    revisionObjective: 'none',
  };
  const [finalNodeSelected, setFinalNodeSelected] =
    useState<DecisionNode>(newFinalNode);
  const [openAddFinalNodeDialog, setOpenAddFinalNodeDialog] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');

  const startButtons: CustomButtonProps[] = [
    {
      action: () => hanldeCreateComitee(),
      name: 'Agregar nodo comité',
      color: 'primary',
    },
  ];

  const hanldeCreateComitee = () => {
    setFinalNodeSelected(newFinalNode);
    setIsNew(true);
    setOpenAddFinalNodeDialog(true);
  };

  const validateSubmit = () => {
    if (finalNodeSelected.name === '') {
      alert('El nombre es requerido');
      return false;
    }
    if (finalNodeSelected.comiteeId === null) {
      alert('El comité es requerido');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateSubmit()) return;
    setLoading(true);
    try {
      if (isNew) {
        delete finalNodeSelected.id;
        await createDecisionNode(dispatch, finalNodeSelected);
        setSuccessMessage('Comité creado con éxito');
      } else {
        await updateDecisionNode(dispatch, finalNodeSelected);
        setSuccessMessage('Comité actualizado con éxito');
      }
      setOpenAddFinalNodeDialog(false);
      setSuccess(true);
      await fetchNodes();
    } catch (error) {
      setFailed(true);
      setFailedMessage('Error con el comité');
      console.log(error);
    }
    setLoading(false);
  };

  const handleEdit = async (id: any) => {
    setIsNew(false);
    try {
      const response = await getDecisionNode(dispatch, { id });
      setFinalNodeSelected(response);
      setOpenAddFinalNodeDialog(true);
    } catch (error) {
      alert('Error al obtener el comité');
      console.log(error);
    }
  };

  const fetchNodes = async () => {
    setLoading(true);
    try {
      const response = await getAllFinalNodes(dispatch);
      setFinalNodes(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNodes();
  }, []);

  const canShowEdit = (id: number) => {
    const node = finalNodes.find((n: DecisionNode) => n.id === id);
    if (node) {
      return !['Aprobado', 'Rechazado'].includes(node.name);
    }
    return false;
  };

  const actions: any = [
    { action: handleEdit, actionLabel: 'Editar', canShow: canShowEdit },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Aquí encontrarás todos los nodos que están habilitados para tomar una decisión. Estos se insertan dentro de tus motores de decisión."
        variant="body1"
        buttons={startButtons}
      />
      <EnhancedTable
        dataHeaders={[
          { key: 'name', label: 'Nombre' },
          { key: 'severity', label: 'Severidad' },
          { key: 'description', label: 'Descripción' },
          { key: 'comiteeName', label: 'Comite asignado' },
        ]}
        data={finalNodes}
        actions={actions}
        showActions
        rowsPerPageDefault={10}
      />
      {openAddFinalNodeDialog && (
        <FinalNodeDialog
          open={openAddFinalNodeDialog}
          setOpen={setOpenAddFinalNodeDialog}
          finalNode={finalNodeSelected}
          setFinalNode={setFinalNodeSelected}
          isNew={isNew}
          fetchNodes={fetchNodes}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default DecisonNodesFinal;
