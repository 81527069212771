import React, { useState } from 'react';

import { Dialog } from '@mui/material';
import EnhancedTab from '../../../../../../../../lib/designSystem/TabSimulation';
import { cellFormat } from '../../../../../../../../lib/designSystem/Table/cellFormat';
import DiscountMain from './components/NewDiscount';

const DiscountDetails = (props: any) => {
  const {
    discount,
    setDiscount,
    open,
    setOpen,
    setSuccess,
    setUp,
    setFailure,
    edit,
  } = props;
  const [startValue, setStartValue] = useState(0);

  const tabs = [
    {
      index: 0,
      label: 'Resumen',
      component: (
        <DiscountMain
          discount={discount}
          setDiscount={setDiscount}
          setOpen={setOpen}
          setSuccess={setSuccess}
          setUp={setUp}
          setFailure={setFailure}
          edit={edit}
        />
      ),
    },
    // {
    //   index: 0,
    //   label: 'Aprobación',
    //   component: (
    //     <FactoringExtensionMissingApprovalContainer
    //       extension={extension}
    //       setExtension={setExtension}
    //       setOpen={setOpen}
    //       setSuccess={setSuccess}
    //       setUp={setUp}
    //       setFailure={setFailure}
    //       edit={edit}
    //     />
    //   ),
    // },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={discount.id ? `Descuento #${discount.id}` : 'Nuevo descuento'}
          startValue={startValue}
          tabs={tabs}
          chip={cellFormat(discount.status || 'Nuevo', 'status')}
        />
      </div>
    </Dialog>
  );
};

export default DiscountDetails;
