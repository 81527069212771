import React, { useEffect, useState } from 'react';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import {
  deleteCertificate,
  getCertificates,
  selectCertificate,
} from '../../../../../lib/api/express';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../services/redux/authSlice';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import AddCertificate from './components/AddCertificate';
import CustomAlert from '../../../../../lib/designSystem/Alert';

const SettingsCertificates = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [addCertificate, setAddCertificate] = useState<boolean>(false);
  const [failed, setFailed] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const setUp = async () => {
    if (!user?.clientId) return;
    setLoading(true);
    try {
      const certificatesResponse = await getCertificates(
        dispatch,
        user?.clientId,
      );
      setCertificates(certificatesResponse);
    } catch (error) {}
    setLoading(false);
  };

  const handleCreate = () => {
    setAddCertificate(true);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      name: 'Agregar',
      action: handleCreate,
      color: 'primary',
    },
  ];

  const handleSelect = async (id: number) => {
    if (!user?.clientId) return;
    setLoading(true);
    try {
      await selectCertificate(dispatch, user?.clientId, id);
      setSuccess('Certificado seleccionado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo seleccionar el certificado');
    }
  };

  const handleDelete = async (id: number) => {
    if (!user?.clientId) return;
    setLoading(true);
    try {
      await deleteCertificate(dispatch, user?.clientId, id);
      setSuccess('Certificado eliminado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo eliminadar el certificado');
    }
  };

  const canShowSelect = (id: number) => {
    const certificate = certificates.find(
      (certificate) => certificate.id === id,
    );
    return !certificate?.selected;
  };

  const actions = [
    {
      action: handleSelect,
      actionLabel: 'Seleccionar',
      canShow: canShowSelect,
    },
    {
      action: handleDelete,
      actionLabel: 'Eliminar',
    },
  ];

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        title="Acá podrás ver los certificados de tu empresa, puedes seleccionar el favorito para hacer las cesiones"
        buttons={buttons}
      />
      <EnhancedTable
        data={certificates}
        dataHeaders={[
          { key: 'taxName', label: 'Nombre' },
          { key: 'taxIdentifier', label: 'Rut' },
          { key: 'valid', label: 'Válido', type: 'boolean-active' },
          { key: 'selected', label: 'Seleccionado', type: 'vission' },
        ]}
        actions={actions}
      />
      {addCertificate && (
        <AddCertificate
          open={addCertificate}
          setOpen={setAddCertificate}
          setFailed={setFailed}
          setSuccess={setSuccess}
          setUp={setUp}
        />
      )}
      <CustomAlert
        open={!!success}
        setOpen={setSuccess}
        severity="success"
        text={success}
        handleClose={() => setSuccess('')}
      />
    </div>
  );
};

export default SettingsCertificates;
