import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreditLineForGiver,
  getCreditLinesForGiver,
} from '../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import { RootState } from '../../../../../../../services/redux/store';
import { validatePermissions } from '../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import ShoppingDialogRecieved from './components/ShoppingDialog';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../lib/designSystem/HeaderWithActions';
import CreateCreditLineDialog from './components/createCreditLine';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FilterComponent from '../../../../../../../lib/common/FilterNonSearch';

export interface CreditLine {
  status: string;
  companyReceiverId: number;
  productId: number;
  productName: string;
  description?: string;
}

const dataHeadersDefault = [
  { key: 'id', label: 'ID' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'productName', label: 'Producto' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'minutesSinceUpdate', label: 'Estancado', type: 'formatMinutes' },
  { key: 'createdAt', label: 'Creado en', type: 'date-large' },
  { key: 'status', label: 'Estado', type: 'status' },
  { key: 'userNames', label: 'Ejecutivos' },
];

const CreditLineRequested: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [creditLineRequests, setCreditLineRequests] = useState<CreditLine[]>(
    [],
  );
  const [filteredCreditLineRequests, setFilteredCreditLineRequests] = useState<
    CreditLine[]
  >([]);
  const [filter, setFilter] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);
  const [shoppingCartType, setShoppingCartType] = useState('');
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openRequest, setOpenRequest] = useState<CreditLine | null>(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successText, setSuccesText] = useState('');
  const [failedMessage, setFailedText] = useState('');
  const [dataHeaders, setDataHeaders] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccesText(text);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedText(text);
  };
  const pendingRequestsRedux = useSelector(
    (state: RootState) => state.notification.pendingRequests,
  );

  const handleDetails = async (id: number) => {
    setMiniLoading(true);
    try {
      setOpenDialog(false);
      const request = await getCreditLineForGiver(dispatch, {
        id,
      });
      console.log(request);
      setOpenRequest(request);
      setMiniLoading(false);
      setShoppingCartType(request.status);
      setOpenShoppingCart(true);
    } catch (error) {
      setMiniLoading(false);
      return alert('error');
    }
  };

  const canShowOpenDetails = (id: number) => {
    const request = creditLineRequests.find((x: any) => x.id === id);
    if (!request) return alert('Ha ocurrido un error');

    return true;
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
      canShow: canShowOpenDetails,
    },
  ];

  const setUp = async () => {
    setLoading(true);
    console.log(pendingRequestsRedux);
    const cerditLineResponse = await getCreditLinesForGiver(dispatch);
    setCreditLineRequests(cerditLineResponse || []);
    setFilteredCreditLineRequests(cerditLineResponse || []);
    // if (validatePermissions(user, 'financing_factoring_read_all')) {
    //   dataHeadersDefault.push({ key: 'recipientName', label: 'Ejecutivo' });
    // }
    setDataHeaders(dataHeadersDefault);

    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttonsData: CustomButtonProps[] = [
    {
      name: 'Filtro',
      action: () => {},
      color: 'primary',
      customButton: (
        <FilterComponent
          data={creditLineRequests}
          filterFields={[
            { key: 'businessName', filterName: 'Nombre' },
            {
              key: 'userNames',
              filterName: 'Ejecutivos',
              type: 'includes',
            },
            {
              key: 'status',
              filterName: 'Estado',
              mode: 'multiple',
              defaultValues: [
                'En revisión',
                'Asignada',
                'No solicitada',
                'Creada',
                'Validando',
                'Revisada',
              ],
            },
            ...(validatePermissions(user, 'business_read_all')
              ? [
                  {
                    key: 'users',
                    filterName: 'Ejecutivos',
                    type: 'includes',
                  },
                ]
              : []),
          ]}
          setFilteredData={setFilteredCreditLineRequests}
        />
      ),
    },
    {
      action: () => setOpenDialog(true),
      name: 'Solicitar',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_creditLine_create'),
    },
  ];

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title="Acá verás todas las líneas de crédito que se han solicitado"
        variant="body1"
        buttons={buttonsData}
      />
      {miniLoading && <CenteredCircularProgress type="layout" />}
      <EnhancedTable
        dataHeaders={dataHeaders}
        data={filteredCreditLineRequests}
        rowsPerPageDefault={25}
        showActions
        actions={actions}
        exportToFile="xlsx"
      />
      <ShoppingDialogRecieved
        shoppingCartType={shoppingCartType}
        openShoppingCart={openShoppingCart}
        setOpenShoppingCart={setOpenShoppingCart}
        openRequest={openRequest}
        setSelectedRequest={setOpenRequest}
        setUp={setUp}
        setSuccessOpen={setSuccessOpen}
        setFailureOpen={setFailureOpen}
      />
      {openDialog && (
        <CreateCreditLineDialog
          open={openDialog}
          setOpen={setOpenDialog}
          handleDetails={handleDetails}
          setUp={setUp}
        />
      )}
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};

export default CreditLineRequested;
