import React, { useEffect, useState } from 'react';
import { DialogActions, TextField, Typography } from '@mui/material';

import CustomButton from '../../../../../../../../../../../lib/designSystem/Button';
import {
  getCreditLineMissingApprovals,
  makeApprovalCreditLine,
  makeRejectCreditLine,
} from '../../../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { validatePermissions } from '../../../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import MissingApprovalContainer from '../../../../../../../Factoring/components/Recieved/components/ShoppingDialog/components/ApprovalModule/MissingApprovalContainer';
import ComiteeUpdateDialog from './components/comiteeUpdate';
import DeleteComiteRevision from './components/DeleteRevision';

const CreditLineApprovalContainer = (props: any) => {
  const {
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setUp,
    setFailureOpen,
  } = props;
  const [approvalsRequired, setApprovalsRequired] = useState<any[]>([]);
  const [approvals, setApprovals] = useState<any[]>([]);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [canApprove, setCanApprove] = useState(false);
  const [comitee, setComitee] = useState<any>(null);
  const [table, setTable] = useState<any>('comitee');
  const [openComiteeUpdate, setOpenComiteeUpdate] = useState(false);
  const [openDeleteReview, setOpenDeleteReview] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const setUpApprovals = async () => {
    setLoading(true);
    try {
      const responseApprovals = await getCreditLineMissingApprovals(dispatch, {
        id: openRequest.id,
      });
      setApprovalsRequired(responseApprovals.rolesToApprove);
      setApprovals(responseApprovals.approvals);
      setComitee(responseApprovals.comitee);
      if (
        responseApprovals.rolesToApprove.some(
          (role: any) => role.userCanApprove,
        )
      ) {
        setCanApprove(true);
      }
      if (openRequest.status === 'Rechazada') {
        setCanApprove(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleApprove = async () => {
    setLoading(true);
    try {
      await makeApprovalCreditLine(dispatch, {
        id: openRequest.id,
        message: reason,
      });
      setOpenShoppingCart(false);
      setSuccessOpen('Hemos dado tu aprobación a la Línea de crédito.');
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleReject = async () => {
    setLoading(true);
    try {
      await makeRejectCreditLine(dispatch, {
        id: openRequest.id,
        message: reason,
      });
      setOpenShoppingCart(false);
      setSuccessOpen('Hemos dado tu aprobación a la Línea de crédito.');
      await setUp();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const canEdit =
    openRequest.status === 'Asignada' ||
    openRequest.status === 'En revisión' ||
    openRequest.status === 'Activa';

  useEffect(() => {
    setUpApprovals();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <MissingApprovalContainer
        approvals={approvals}
        missingApprovals={approvalsRequired}
        comitee={comitee}
        table={table}
        setTable={setTable}
      />
      {openRequest.status === 'Rechazada' && (
        <Typography variant="body1" color="error" fontWeight="bold">
          Esta solicitud ha sido rechazada
        </Typography>
      )}
      {canApprove && (
        <TextField
          margin="normal"
          fullWidth
          label="Motivo"
          value={reason}
          multiline
          onChange={(e) => setReason(e.target.value)}
        />
      )}
      <DialogActions>
        {validatePermissions(user, 'comitee_change') && canEdit && (
          <CustomButton
            onClick={() => setOpenComiteeUpdate(true)}
            color="warning"
          >
            Comité
          </CustomButton>
        )}
        {validatePermissions(user, 'comitee_delete_approval') && canEdit && (
          <CustomButton onClick={() => setOpenDeleteReview(true)} color="error">
            Borrar revisión
          </CustomButton>
        )}
        <CustomButton
          onClick={() => setOpenShoppingCart(false)}
          color="secondary"
        >
          Cerrar
        </CustomButton>
        <CustomButton
          onClick={handleReject}
          color="error"
          variant="contained"
          disabled={!canApprove}
        >
          Rechazar
        </CustomButton>
        <CustomButton
          onClick={handleApprove}
          color="success"
          variant="contained"
          disabled={!canApprove}
        >
          Aprobar
        </CustomButton>
      </DialogActions>
      {openComiteeUpdate && (
        <ComiteeUpdateDialog
          openRequest={openRequest}
          open={openComiteeUpdate}
          setOpen={setOpenComiteeUpdate}
          setFailure={setFailureOpen}
          setUp={setUpApprovals}
          setSuccess={setSuccessOpen}
          setBigOpen={setOpenShoppingCart}
          comitee={comitee}
          // setUp={setUp}
        />
      )}
      {openDeleteReview && (
        <DeleteComiteRevision
          openRequest={openRequest}
          open={openDeleteReview}
          setOpen={setOpenDeleteReview}
          setFailure={setFailureOpen}
          setUp={setUpApprovals}
          setSuccess={setSuccessOpen}
          setBigOpen={setOpenShoppingCart}
        />
      )}
    </div>
  );
};

export default CreditLineApprovalContainer;
