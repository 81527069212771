import React, { useState } from 'react';
import ShoppingCartFactoringCreated from './components/1_ShoppingCartCreated';
import ShoppingCartFactoringRejected from './components/1.9_ShoppingCartRejected';
import ShoppingCartFactoringAccepted from './components/3_ShoppingCartAccepted';
import ShoppingCartFactoringManaged from './components/5_ShoppingCartConfirmed';
import ShoppingCartFactoringQuoted from './components/2_ShoppingCartQuoted';
import ShoppingCartFactoringReviewed from './components/1.5_ShoppingCartReviewed';
import ShoppingCartFactoringInReview from './components/1.2_ShoppingCartInReview';
import ShoppingCartFactoringInMissingInfo from './components/1.3_ShoppingCartMissingInfo';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import { useDispatch } from 'react-redux';
import { decrementPendingRequests } from '../../../../../../../../../../../services/redux/notificactionSlice';
import ShoppingCartFactoringCeded from './components/4_ShoppingCartCedded';
import { rejectFactoringQuote } from '../../../../../../../../../../../lib/api/factoring/updateStatus';

const Summary = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    setUp,
    openRequest,
    setOpenRequest,
    setSuccessOpen,
    setFailureOpen,
  } = props;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCancelQuote = async () => {
    setLoading(true);
    try {
      await rejectFactoringQuote(dispatch, {
        id: openRequest.quoteActive.id,
        status: 'REJECTED',
      });
      dispatch(decrementPendingRequests());
      setSuccessOpen('Se ha cancelado la solicitud.');
      await setUp();
    } catch (error) {
      setFailureOpen('Lamentablemente ha ocurrido un error');
      console.log(error);
    }
    setLoading(false);
    setOpenShoppingCart(false);
  };

  return (
    <div>
      <div style={{ minHeight: 100 }}>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            {shoppingCartType === 'Creada' && (
              <ShoppingCartFactoringCreated
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'En revisión' && (
              <ShoppingCartFactoringInReview
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                setSelectedRequest={setOpenRequest}
                handleCancelQuote={handleCancelQuote}
                setUp={setUp}
              />
            )}
            {shoppingCartType === 'Faltan datos' && (
              <ShoppingCartFactoringInMissingInfo
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'Revisada' && (
              <ShoppingCartFactoringReviewed
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'Cotizada' && (
              <ShoppingCartFactoringQuoted
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                setUp={setUp}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'Rechazada' && (
              <ShoppingCartFactoringRejected
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'Aceptada' && (
              <ShoppingCartFactoringAccepted
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                setUp={setUp}
                handleCancelQuote={handleCancelQuote}
                setOpenRequest={setOpenRequest}
              />
            )}
            {shoppingCartType === 'Activa' && (
              <ShoppingCartFactoringManaged
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
            {shoppingCartType === 'Cedida' && (
              <ShoppingCartFactoringCeded
                openShoppingCart={openShoppingCart}
                setOpenShoppingcart={setOpenShoppingCart}
                selectedRequest={openRequest}
                handleCancelQuote={handleCancelQuote}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
